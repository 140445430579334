import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const intialState = {
    deviceTypes: [],
    devices: [],
    loading: true,
    flag: false,
    message: '',
    errors: '',
};
const sendDeviceTypesStart = (state, action) => {
    return updateObject(state, { deviceTypesLoading: true });
};
const sendDeviceTypesSuccess = (state, action) => {
    return updateObject(state, { deviceTypesLoading: false, deviceTypes: action.list.data });
};
const sendDevicesSuccess = (state, action) => {
    return updateObject(state, { loading: action.flag, devices: action.list });
};
const sendDevicesStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const addNewDeviceSuccess = (state, action) => {
    return updateObject(state, { errors: action.errors, flag: action.flag, message: action.message });
};
const sendDeleteSuccess = (state, action) => {
    return updateObject(state, { message: action.message });
};
const sendBulkSuccess = (state, action) => {
    return updateObject(state, { message: action.message });
};
const sendDeviceUpdateSuccess = (state, action) => {
    return updateObject(state, { message: action.message, errors: action.errors });
};
const returnDeviceSuccess = (state, action) => {
    return updateObject(state, { message: action.message });
};

const assignDeviceSuccess = (state, action) => {
    return updateObject(state, { message: action.message });
};
const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DEVICE_TYPES_START:
            return sendDeviceTypesStart(state, action);
        case actionTypes.GET_DEVICE_TYPES_SUCCESS:
            return sendDeviceTypesSuccess(state, action);
        case actionTypes.GET_DEVICES_START:
            return sendDevicesStart(state, action);
        case actionTypes.GET_DEVICES_SUCCESS:
            return sendDevicesSuccess(state, action);
        case actionTypes.ADD_NEW_DEVICE_SUCCESS:
            return addNewDeviceSuccess(state, action);
        case actionTypes.DELETE_DEVICE_SUCCESS:
            return sendDeleteSuccess(state, action);
        case actionTypes.BULK_UPLOAD_DEVICES_SUCCESS:
            return sendBulkSuccess(state, action);
        case actionTypes.UPDATE_DEVICE_SUCCESS:
            return sendDeviceUpdateSuccess(state, action);
        case actionTypes.RETURN_ASSIGN_DEVICE:
            return returnDeviceSuccess(state, action);
        case actionTypes.ASSIGN_DEVICE:
            return assignDeviceSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
