import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetricConfiguration from 'components/Configuration/MetricConfiguration';
import { getDate, /*formatPhone, roundOff,*/ getFullTime, getSplitTimeConfig } from 'utility/utility';
import * as actions from 'redux-store/actions';
import SweetAlert from '../SweetAlert/SweetAlert';
import PerfectScrollbar from 'components/ScrollBarHOC/scrollBarHOC';

class ShipmentMetricConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            status: this.props.status,
            shipment_id: this.props.shipment_id,
            configurationProfileDetails: {},
            showSaveConfirmation: false,
            saveConfirmationTitle: 'Are you sure you want to update profile configuration for this shipment?',
            popView: false,
            popTitle: 'Saved successfully.',
            popStatus: 'success',
        };
    }
    componentDidMount() {
        //console.log(this.state);
        if(this.props.shipment_id) {
            this.props.onGetShipmentMetricConfiguration({
                shipment_id: this.props.shipment_id,
                onComplete: (data) => {
                    this.setShipmentConfigurationDetails(data);
                }
            });
        } else if(this.props.configuration_profile_id) {
            this.getConfigurationProfileDetails({
                id: this.props.configuration_profile_id
            });            
        }
        
    }

    componentWillUnmount() {
        // empty
        
    }

    componentDidUpdate() {
        // empty
        /* eslint-disable */
        //console.log('componentDidUpdate..............');
    }

    setShipmentConfigurationDetails = (details) => {
        const configurationProfileDetails = {...details};
        //console.log('----configurationProfileDetails---> ', configurationProfileDetails);
        
        if(configurationProfileDetails?.thresholds?.TEMPERATURE?.above_th_duration) {
            configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold = getSplitTimeConfig(configurationProfileDetails.thresholds.TEMPERATURE.above_th_duration);
        } else {
            if(configurationProfileDetails?.thresholds?.TEMPERATURE) {
                configurationProfileDetails.thresholds.TEMPERATURE.above_th_duration = 0;
                configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold = {d: 0, h: 0, m: 0};
            }
        }
        if(configurationProfileDetails?.thresholds?.TEMPERATURE?.below_th_duration) {
            configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold = getSplitTimeConfig(configurationProfileDetails.thresholds.TEMPERATURE.below_th_duration);
        } else {
            if(configurationProfileDetails?.thresholds?.TEMPERATURE) {
                configurationProfileDetails.thresholds.TEMPERATURE.below_th_duration = 0;
                configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold = {d: 0, h: 0, m: 0};
            }
        }

        if(configurationProfileDetails.product_type){
            configurationProfileDetails.product_type = {color: configurationProfileDetails.product_color, name: configurationProfileDetails.product_type};
        }

        if(configurationProfileDetails.product_profile_config) {
            configurationProfileDetails.product_profile_config.map(pp => {
                pp.threshold_duration = Math.round(pp.threshold_duration / 60);
                pp.temperature_representation = configurationProfileDetails.temperature_representation;
            });
            configurationProfileDetails.thresholds.TEMPERATURE.temperature_representation = configurationProfileDetails.temperature_representation;
            configurationProfileDetails.thresholds.temperature_representation = configurationProfileDetails.temperature_representation;
        }

        this.setState({configurationProfileDetails});
    }

    validateDeviceConfigurationUI = () => {
        const configurationProfileDetails = {...this.state.configurationProfileDetails};
        configurationProfileDetails.errors = {};

        const t_tolerance = (((configurationProfileDetails.thresholds.TEMPERATURE.max_limit-configurationProfileDetails.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0) === '-1' ? 0 : (((configurationProfileDetails.thresholds.TEMPERATURE.max_limit-configurationProfileDetails.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0);
        const t_threshold = Number(configurationProfileDetails.thresholds.TEMPERATURE.threshold);
        if(t_threshold > t_tolerance || t_threshold === -1){
            console.log("condition---->",t_threshold > t_tolerance,t_threshold === -1,t_threshold,t_tolerance)
            configurationProfileDetails.temperature_tolerance_err = true;
            configurationProfileDetails.errors.TEMPERATURE = `Temperature tolernace must be less than or equals to ${t_tolerance === '-1' ? 0 : t_tolerance} °${configurationProfileDetails.thresholds.TEMPERATURE.representation}`;
            configurationProfileDetails.thresholds.updated = true;
        }else{
            configurationProfileDetails.temperature_tolerance_err = false;
        }

        const h_tolerance = (((configurationProfileDetails.thresholds.HUMIDITY.max_limit-configurationProfileDetails.thresholds.HUMIDITY.min_limit)/2)-1).toFixed(0) === '-1' ? 0 : (((configurationProfileDetails.thresholds.HUMIDITY.max_limit-configurationProfileDetails.thresholds.HUMIDITY.min_limit)/2)-1).toFixed(0);
        const h_threshold = Number(configurationProfileDetails.thresholds.HUMIDITY.threshold);
        if(h_threshold > h_tolerance || h_threshold === -1 ){
            configurationProfileDetails.humidity_tolerance_err = true;
            configurationProfileDetails.errors.HUMIDITY = `Humidty tolerance must be less than or equals to ${h_tolerance === '-1' ? 0 : h_tolerance} %RH`;
            configurationProfileDetails.thresholds.updated = true;
        }
        else{
            configurationProfileDetails.humidity_tolerance_err = false;
        }

        if(configurationProfileDetails.temperature_tolerance_err || configurationProfileDetails.humidity_tolerance_err) {
            this.setState({
                configurationProfileDetails,
                showSaveConfirmation: false,
                showSaveSuccess: true,
                saveSuccessTitle: 'The given data was invalid.',
                saveSuccessStatus: 'warning',
            });
            const fieldSection = document.querySelectorAll('.fld-sec');
            if(fieldSection.length) {
                fieldSection[0].scrollIntoView();
            }
        }else{
            this.saveShipmentConfiguration();
        }
    }

    saveShipmentConfiguration = async () => {
        const configurationProfileDetails = JSON.parse(JSON.stringify(this.state.configurationProfileDetails));
        
        if(configurationProfileDetails.thresholds?.TEMPERATURE?.timeAboveThreshold) {
            configurationProfileDetails.thresholds.TEMPERATURE.above_th_duration = getFullTime(configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold);
        }
        if(configurationProfileDetails.thresholds?.TEMPERATURE?.timeBelowThreshold) {
            configurationProfileDetails.thresholds.TEMPERATURE.below_th_duration = getFullTime(configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold);
        }
        if(configurationProfileDetails.product_profile_config) {
            //configurationProfileDetails.product_profile_config = JSON.parse(JSON.stringify(configurationProfileDetails.product_profile_config));
            configurationProfileDetails.product_profile_config.forEach(c => {
                c.threshold_duration = c.threshold_duration * 60;
            });

            if(configurationProfileDetails.thresholds.TEMPERATURE.temperature_representation) {
                configurationProfileDetails.temperature_representation = configurationProfileDetails.thresholds.TEMPERATURE.temperature_representation;
            }
        }

        if(configurationProfileDetails.thresholds?.TEMPERATURE?.threshold){
            configurationProfileDetails.thresholds.TEMPERATURE.threshold = Number(configurationProfileDetails.thresholds.TEMPERATURE.threshold);
        }
        if(configurationProfileDetails.thresholds?.HUMIDITY?.threshold){
            configurationProfileDetails.thresholds.HUMIDITY.threshold = Number(configurationProfileDetails.thresholds.HUMIDITY.threshold);
        }
        
        if(configurationProfileDetails.thresholds?.LID?.max_limit) {
            configurationProfileDetails.thresholds.LID.max_limit = Number(configurationProfileDetails.thresholds.LID.max_limit || configurationProfileDetails.thresholds.LID.value);
        }

        await this.props.onUpdateShipmentMetricConfiguration({
            id: this.props.shipment_id,
            configurationProfileDetails,
            onComplete: async (data) => {
                //console.log(data);
                //console.log(this.props.configurationProfileError);
                if(data.success) {
                    await this.setState({
                        popView: true,
                        popStatus: 'success',
                        popTitle: data.message || 'Shipment configuration updated successfully',
                    });
                    setTimeout(() => {
                        this.props.closeShipmentMetricConfiguration(true);
                    }, 1000);
                } else if(data?.data && !data.success) {
                    
                    if(configurationProfileDetails.product_profile_config) {
                        configurationProfileDetails.product_profile_config = JSON.parse(JSON.stringify(configurationProfileDetails.product_profile_config));
                        configurationProfileDetails.product_profile_config.forEach(c => {
                            c.threshold_duration = c.threshold_duration / 60;
                        });
                    }
                    configurationProfileDetails.errors = {};
                    Object.keys(data.data).forEach((errorKey, i) => {
                        const obj = errorKey.split('.');
                        if(errorKey.indexOf('product_profile_config') >= 0 ) {
                            configurationProfileDetails.errors[obj[0]] = configurationProfileDetails.errors[obj[0]] || {};
                            configurationProfileDetails.errors[obj[0]][obj[1]] = data.data[errorKey];
                        } else {
                            configurationProfileDetails.errors[obj[1]] = data.data[errorKey];
                        }
                    });
                    
                    this.setState({
                        configurationProfileDetails,
                        popView: true,
                        popStatus: 'warning',
                        popTitle: data.message || data.error,
                    });
                    
                } else {
                    this.setState({
                        popView: true,
                        popStatus: 'danger',
                        popTitle: data.message || 'Shipment configuration update failed.'
                    });
                }
                
            }
        });
    }

    setConfigurationProfileDetails = (details) => {
        let configurationProfileDetails = {...details};

        //configurationProfileDetails.apply_changes_to_active_shipments = false;
        
        configurationProfileDetails.id = details.id;
        //configurationProfileDetails.profile_name = details.profile_name;
        //configurationProfileDetails.description = details.description;
        configurationProfileDetails.updated_by = details.updated_by;
        configurationProfileDetails.updated_at = details.updated_at;
        //console.log('------> ', configurationProfileDetails.id);
        
        if(configurationProfileDetails.thresholds.hasOwnProperty('TEMPERATURE')){
            //if(configurationProfileDetails.thresholds.TEMPERATURE.timeAbove) {
                configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold = getSplitTimeConfig(configurationProfileDetails.thresholds.TEMPERATURE.above_th_duration);
            //}
            //if(configurationProfileDetails.thresholds.TEMPERATURE.timeBelow) {
                configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold = getSplitTimeConfig(configurationProfileDetails.thresholds.TEMPERATURE.below_th_duration);
            //}
            if(configurationProfileDetails.product_profile_id){
                configurationProfileDetails.thresholds.TEMPERATURE.product_profile_id = configurationProfileDetails.product_profile_id;
            }
        }
        else{
            configurationProfileDetails.thresholds.TEMPERATURE = {
                representation: '',
                min_limit: 0,
                max_limit: 0,
                threshold: 0,
                above_th_duration: 0,
                below_th_duration: 0,
                timeAboveThreshold: {d: 0, h: 0, m: 0},
                timeBelowThreshold: {d: 0, h: 0, m: 0}
            };
        }
        if(configurationProfileDetails.thresholds.hasOwnProperty('HUMIDITY')){}
        else{
            configurationProfileDetails.thresholds.HUMIDITY = {
                min_limit: 0,
                max_limit: 0,
                threshold: 0
            };
        }
        
        if(configurationProfileDetails.thresholds.LID) {
            if(configurationProfileDetails.thresholds.LID.value){}
            else {
                configurationProfileDetails.thresholds.LID.value = configurationProfileDetails.thresholds.LID.max_limit;
            }
            if(configurationProfileDetails.thresholds.LID.max_limit === 80) {
                configurationProfileDetails.thresholds.LID.isLumenDefault = true;
            } else {
                configurationProfileDetails.thresholds.LID.isLumenDefault = false;
            }
        } else {
            configurationProfileDetails.thresholds.LID = {
                value: 0,
                max_limit: 0,
                isLumenDefault: false
            };
        }
        if(configurationProfileDetails.thresholds.SHOCK) {
            if( configurationProfileDetails.thresholds.SHOCK.value === 1.5 ||
                configurationProfileDetails.thresholds.SHOCK.value === 3 ||
                configurationProfileDetails.thresholds.SHOCK.value === 5
                ) {
                configurationProfileDetails.thresholds.SHOCK.isShockDefault = true;
            } else {
                configurationProfileDetails.thresholds.SHOCK.isShockDefault = false;
            }   
        } else {
            configurationProfileDetails.thresholds.SHOCK = {
                value: 0,
                isShockDefault: false
            };
        }

        this.setState({configurationProfileDetails});
    };
    
    setProductProfileDetails = (details) => {
        let productProfileDetails = {...details};
        const {configurationProfileDetails} = this.state;

        if(productProfileDetails.config.hasOwnProperty('total_shelf_life_duration')){
            configurationProfileDetails.total_shelf_life_duration = productProfileDetails.config.total_shelf_life_duration || '';
        }

        if(productProfileDetails.config.product_profile_config) {
            configurationProfileDetails.product_profile_config = productProfileDetails.config.product_profile_config;
            configurationProfileDetails.product_profile_config.map(pp => {
                pp.threshold_duration = Math.round(pp.threshold_duration / 60);
            });
        }
        if(productProfileDetails.config.product_type){
            configurationProfileDetails.product_type = JSON.parse(JSON.stringify(productProfileDetails.config.product_type));
        }
        configurationProfileDetails.thresholds.temperature_representation = productProfileDetails?.config?.representation || 'C';
        
        this.setState({configurationProfileDetails});
    };

    getConfigurationProfileDetails = (data) => {
        this.props.onGetConfigurationProfileDetails({
            profileID: data.id,
            onComplete: (configurationProfileDetails) => {
                this.setConfigurationProfileDetails(configurationProfileDetails, false, true);
                if(configurationProfileDetails.product_profile_id) {
                    this.props.onGetProductProfileDetails({
                        profileID: configurationProfileDetails.product_profile_id,
                        onComplete: (productProfileDetails) => {
                            this.setProductProfileDetails(productProfileDetails, false);
                        }
                    });
                }
            }
        })
    }

    render () {
        const CONFIGURATION_PROFILE_DETAILS = this.state.configurationProfileDetails || {};
        return (
            <>
            <div className="overlay">
                <div className="geo-modal config config-modal">
                    <div className="geo-header">
                        <div className="g-h-txt">Device Configuration Profile</div>
                        <div
                            className="material-icons geo-close-icn"
                            onClick={() => {
                                this.props.closeShipmentMetricConfiguration();
                            }}
                        >clear</div>
                    </div>
                    <div className="p-l-e" style={{position:'absolute', textAlign: 'right', top: '26px', right: '52px'}}>
                        <div style={{fontSize:'10px'}}>Last updated by</div>
                        <div style={{fontSize:'12px', fontWeight: '600'}}>{CONFIGURATION_PROFILE_DETAILS.updated_by || '--'}</div>
                        <div style={{fontSize:'12px', fontWeight: '500'}}>{CONFIGURATION_PROFILE_DETAILS.updated_at ? getDate((CONFIGURATION_PROFILE_DETAILS.updated_at), false, false, true) : '--'}</div>
                    </div>
                    <div className="geo-body form-sec s-c">
                        <PerfectScrollbar>
                        {this.props.shipmentProfileLoading || this.props.shipmentProfileSaving || this.props.shipmentProfileConfigLoading? <div className="loading abs" /> : ''}
                        <div className="n-form ship-det">
                            <MetricConfiguration
                                configurationProfileLoading={this.props.configurationProfileLoading}
                                CONFIGURATION_PROFILE_DETAILS={{...CONFIGURATION_PROFILE_DETAILS}}
                                key={`${CONFIGURATION_PROFILE_DETAILS.id}_${CONFIGURATION_PROFILE_DETAILS.company_id}`}
                                showProductProfile={false}
                                isShipmentConfiguration={true}
                                isProductProfileShipment={CONFIGURATION_PROFILE_DETAILS.product_profile_config?.length}
                                productProfileList={this.props.productProfileList}
                                disableEdit={this.props.disableEdit}
                                disableCommunicationIntervalEdit={true}
                            />
                        </div>
                        </PerfectScrollbar>
                    </div>
                    <div className="geo-ftr">
                        {this.props.configurationProfileSaving ? <div className="loading abs" style={{marginTop:'-10px'}}/> : ''}
                        <div 
                            className="c-btn g"
                            onClick={(e)=>{
                                this.props.closeShipmentMetricConfiguration();
                            }}
                        >{this.state.status === 'New' || this.state.status === 'In-Transit' ? 'Cancel' : 'Close'}</div>
                        {(this.state.status === 'New' || this.state.status === 'In-Transit') && !this.props.disableEdit ?
                        <div 
                            className="c-btn"
                            style={(this.props.configurationProfileLoading || this.props.configurationProfileSaving)? {opacity: '0.5', cursor: 'not-allowed'}: {}}
                            onClick={(e) => {
                                e.stopPropagation();
                                if(this.props.configurationProfileLoading || this.props.configurationProfileSaving) {} 
                                else {
                                    this.validateDeviceConfigurationUI();                                            
                                }                             
                            }}
                        >Update</div>
                        : ''}
                    </div>
                </div>
            </div>
            <SweetAlert
                show={this.state.showSaveConfirmation}
                title={this.state.saveConfirmationTitle}
                className="save-warning"
                status={'warning'}
                showCancelBtn={true}
                cancelBtnText="NO"
                confirmBtnText="YES"
                onCancelClick={() => {
                    this.setState({showSaveConfirmation: false});
                }}
                onConfirmClick={async () => {
                    if(this.props.configurationProfileSaving) {}
                    else{
                        this.saveShipmentConfigurationConfirm();
                    }
                }}
            />
            <SweetAlert
                className="ship-config-pop"
                show={this.state.popView}
                title={this.state.popTitle}
                status={this.state.popStatus}
                onClose={() => {
                    this.setState({ popView: false });
                }}
            />
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        configurationProfileSaving: state.dashboard.configurationProfileSaving,
        configurationProfileLoading: state.configuration.configurationProfileLoading,
        configurationProfile: state.configuration.configurationProfile,
        productProfileLoading: state.configuration.productProfileLoading,
        productProfile: state.configuration.productProfile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onGetShipmentMetricConfiguration: (obj) => dispatch(actions.getShipmentConfiguration(obj)),
        onUpdateShipmentMetricConfiguration: (obj) => dispatch(actions.updateShipmentConfiguration(obj)),
        onGetConfigurationProfileDetails: (obj) => dispatch(actions.getConfigurationProfile(obj)),
        onGetProductProfileDetails: (obj) => dispatch(actions.getProductProfile(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentMetricConfiguration);