import * as actionTypes from './actionTypes';
import { AXIOS } from '../../utility/Axios';
import { sendLogout, sendNetworkError, sendInternalServerError } from './company';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;
// const getToken = () => localStorage.getItem('access_token');

const ENV = parseInt(process.env.REACT_APP_CUSTOMER_ID,10);

export const updateProfileSuccess = (message, errors) => {
    return {
        type: actionTypes.GET_USER_UPDATE_PROFILE_SUCCESS,
        message,
        errors,
    };
};

export const deleteUserSuccess = message => {
    return {
        type: actionTypes.DISABLE_USER_SUCCESS,
        message,
    };
};

export const shipmentStatisticsSuccess = message => {
    return {
        type: actionTypes.USER_SHIPMENT_STATISTICS,
        message,
    };
};

export const activateUserSuccess = message => {
    return {
        type: actionTypes.ACTIVATE_USER_SUCCESS,
        message,
    };
};
export const enableDisableUserSuccess = message => {
    return {
        type: actionTypes.ENABLE_DISABLE_USER_SUCCESS,
        message,
    };
};
export const getUserListSuccess = (flag, list) => {
    return {
        type: actionTypes.GET_ALL_USERS_LIST_SUCCESS,
        flag,
        list,
    };
};
export const updateProfile = data => async dispatch => {
    let requestUrl = apis.users.update_profile(data.id);
    await AXIOS({
        method: 'PUT',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(updateProfileSuccess(response.response.data.error, {}));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    updateProfileSuccess(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data.errors
                    )
                );
            } else {
                dispatch(updateProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`, {}));
            }
        } else {
            if (response.data && response.data.data) {
                dispatch(updateProfileSuccess('Updated Profile Successfully!', {}));
            } else {
                dispatch(updateProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`, {}));
            }
        }
    });
};

export const deleteUser = id => async dispatch => {
    let requestUrl = apis.users.delete_user(id);
    await AXIOS({
        method: 'post',
        url: requestUrl,
        data: id,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(deleteUserSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            if (response.data && response.data.data) {
                dispatch(
                    deleteUserSuccess(response.data.data === 'User disabled successfully' ? '' : response.data.data)
                );
            } else {
                dispatch(deleteUserSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getShipmentsStatistics = id => async dispatch => {
    let requestUrl = apis.users.get_shipment_statistics(id);
    await AXIOS({
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(shipmentStatisticsSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            if (response.data && response.data.data) {
                if (
                    parseInt(response.data.data[0].new || 0, 10) ||
                    parseInt(response.data.data[0].intransit || 0, 10) ||
                    parseInt(response.data.data[0].destination || 0, 10)
                ) {
                    dispatch(
                        shipmentStatisticsSuccess(
                            'There are active shipments associated to this user. Are you sure to delete?'
                        )
                    );
                } else {
                    dispatch(shipmentStatisticsSuccess('No active shipments'));
                }
            } else {
                dispatch(shipmentStatisticsSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const activateUser = id => async dispatch => {
    let requestUrl = apis.users.activate_user(id);
    await AXIOS({
        method: 'POST',
        url: requestUrl,
        data: id,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(activateUserSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            if (response.data && response.data.data) {
                dispatch(
                    activateUserSuccess(response.data.data === 'User enabled successfully' ? '' : response.data.data)
                );
            } else {
                dispatch(activateUserSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const enableDisableUser = data => async dispatch => {
    let requestUrl = apis.users.enable_disable_user(data.id);
    await AXIOS({
        method: 'PUT',
        url: requestUrl,
        data: {status: data.status},
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(enableDisableUserSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            if (response.data && response.data.data) {
                dispatch(
                    enableDisableUserSuccess(response.data.data)
                );
            } else {
                dispatch(enableDisableUserSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getUserList = (companyId) => async dispatch => {
    dispatch(getUserListSuccess(true, []));
    // let requestUrl = apis.users.get_user_list(5);
    let requestUrl = apis.users.get_user_list(companyId?companyId:'');
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getUserListSuccess(false, []));
        } else {
            if (response.data && response.data.data) {
                dispatch(getUserListSuccess(false, response.data.data));
            } else {
                dispatch(getUserListSuccess(false, []));
            }
        }
    });
};
