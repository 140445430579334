import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';
const intialState = {
    data: {},
    user: {},
    userDetails: {},
    loading: false,
    logOutLoading: false,
    error: false,
    emailData: '',
    message: '',
    forgotMessage: '',
    registerStatus: '',
    company: ['paxafe', 'valuelabs', 'google', 'amazon', 'reliance'],
    companyIndex: '',
    codeStatus: '',
    mail: '',
    role: '',
    forgotFlag: false,
    content: '',
    updatedAt: '',
    forcePassword: '',
    token: '',
    updateMessage: '',
    updateUserMessage: '',
    changePasswordErr: [],
    updateUserError: {},
    passwordErrorObj: {},
    resendVerificationCodeStatus: false,
    serverError: false
};
const getActiveUserStart = state => {
    return updateObject(state, { loading: true, error: false, networkError: false, serverError: false,  });
};
const getActiveUserSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        data: action.data,
        userDetails: action.user,
        error: '',
        role: action.role,
        networkError: false,
        serverError: false,
        maintenanceMode: false
    });
};
const getUpdateUserProfileSuccess = (state, action) => {
    return updateObject(state, { loading: false, updateUserMessage: action.user });
};
const getActiveUserFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error, mail: action.email, networkError: action.networkError, serverError: action.serverError, maintenanceMode: action.maintenanceMode });
};
const getUserDetailsFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error, mail: action.email, networkError: action.networkError, serverError: action.serverError, maintenanceMode: action.maintenanceMode });
};
const getUserNotFound = (state, action) => {
    //console.log(action.error);
    return updateObject(state, { loading: false, emailData: action.error });
};
const sendEmailMessage = (state, action) => {
    console.log("message>>>>>>>>>>>>>",action)
    return updateObject(state, { message: action.message });
};
const getRegister = (state, action) => {
    return updateObject(state, { registerStatus: action.message, mail: action.mail });
};
// const logOutUserStart = (state, action) => {
//     return updateObject(state, { logOutLoading: true, error: false });
// }
const setpassword = (state, action) => {
    return updateObject(state, { forgotMessage: action.message, forgotFlag: action.flag });
};
const updatepassword = (state, action) => {
    if(action.error){   
        const [object] = action?.error;
        state.passwordErrorObj = object
    }
    return updateObject(state, { updateMessage: action.message, updateFlag: action.flag, changePasswordErr: state.passwordErrorObj });
};
const resetpassword = (state, action) => {
    return updateObject(state, { resetMessage: action.message, resetFlag: action.flag});
};
// const logOutUserSuccess = (state, action) => {
//     const user = Object.assign({}, action.user);
//     return updateObject(state, { logOutLoading: false, user });
// }
// const logOutUserFailed = (state, action) => {
//     return updateObject(state, { logOutLoading: false, error: true });
// }
const sendCompanyName = (state, action) => {
    return updateObject(state, { companyIndex: action.company });
};
const getCode = (state, action) => {
    return updateObject(state, {
        verificationCodeStatus: action.verificationCodeStatus
    });
};
const sendTCData = (state, action) => {
    return updateObject(state, { updatedAt: action.updatedAt, content: action.content });
};
const updateUserFailed = (state, action) => {
    return updateObject(state, { updateUserMessage: action.error , updateUserError: action.errorObj});
};
const resendVerificationCode = (state, action) => {
    return updateObject(state, {resendVerificationCodeStatus: true});
};
const setAcceptTermsOfService = (state, action) => {
    return updateObject(state, { acceptTerms: true });
};
const acceptTermsOfServiceFailed = (state, action) => {
    return updateObject(state, { acceptTermsResponse: action.error });
};
const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ACTIVE_USER_START:
            return getActiveUserStart(state, action);
        case actionTypes.GET_ACTIVE_USER_SUCCESS:
            return getActiveUserSuccess(state, action);
        case actionTypes.GET_ACTIVE_USER_FAILED:
            return getActiveUserFailed(state, action);
        case actionTypes.GET_USER_DETAILS_FAILED:
            return getUserDetailsFailed(state, action);
        case actionTypes.GET_USER_UPDATE_PROFILE_SUCCESS:
            return getUpdateUserProfileSuccess(state, action);
        // case actionTypes.LOGOUT_USER_START: return logOutUserStart(state, action);
        // case actionTypes.LOGOUT_USER_SUCCESS: return logOutUserSuccess(state, action);
        // case actionTypes.LOGOUT_USER_FAILED: return logOutUserFailed(state, action);
        case actionTypes.GET_USER_NOT_FOUND:
            return getUserNotFound(state, action);
        case actionTypes.SEND_EMAIL_ID:
            return sendEmailMessage(state, action);
        case actionTypes.SET_PASSWORD:
            return setpassword(state, action);
        case actionTypes.UPDATE_PASSWORD:
            return updatepassword(state, action);
        case actionTypes.RESET_PASSWORD:
            return resetpassword(state, action);
        case actionTypes.GET_REGISTER:
            return getRegister(state, action);
        case actionTypes.GET_COMPANY_NAME:
            return sendCompanyName(state, action);
        case actionTypes.GET_VERIFICATION_CODE:
            return getCode(state, action);
        case actionTypes.SEND_VERIFICATION_CODE:
            return resendVerificationCode(state, action);
        case actionTypes.GET_TC_DATA:
            return sendTCData(state, action);
        case actionTypes.GET_UPDATE_USER_FAILED:
            return updateUserFailed(state, action);
        case actionTypes.ACCEPT_TERMS_SUCCESS:
            return setAcceptTermsOfService(state, action);
        case actionTypes.ACCEPT_TERMS_FAILED:
            return acceptTermsOfServiceFailed(state, action);
        default:
            return state;
    }
};
export default reducer;
