import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';
const intialState = {
    loading: false,
    data: {},
    shipmentListMap: [],
    shipmentList: [],
    shipmentListLoading: true,
    filtersCount: [],
    shipmentDetails: {status: 'New',legs:[],live_tracker_data:{route_path:[]}},
    shipmentDetailsLoading: true,
    carriersLoading: false,
    carriers: [],
    createShipment: {shipmentId: null},
    configurationProfileLoading: false,
    configurationProfile: {},
    configurationProfileSaving: false,
    configurationProfileSuccess: false,
    companyListLoading: false,
    companyList: [],
    createNewDeviceShipmentLoading: false,
    shipmentTrackerData: {data:[]},
    saveGeoFenceError: false,
    shipmentReportsLoading: false,
    shipmentReports: [],
    shipmentReportDeleting: false,
    shipmentReportDelete: {},
    shipmentReportCreating: false,
    shipmentReportCreate: {},
    shipmentReportsError: false,
    assignDeviceToShipmentLoading: false,
    assignDeviceToShipmentErrors: false,
    assignDeviceToShipmentMessage: ''
};
const getDashboardDetailsStart = (state) => {
    return updateObject(state, { loading: true, error: false });
};
const getDashboardDetailsSuccess = (state, action) => {
    return updateObject(state, { loading: false, data: action.data, error: false });
};
const getDashboardDetailsFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};
const getConfigurationListStart = (state) => {
    return updateObject(state, { configLoading: true, error: false });
};
const getConfigurationListSuccess = (state, action) => {
    return updateObject(state, { configLoading: false, configurations: action.data, error: false });
};
const getConfigurationListFailed = (state, action) => {
    return updateObject(state, { configLoading: false, error: action.error });
};
const setConfigurationStart = (state) => {
    return updateObject(state, { updatingConfig: true, error: false });
};
const setConfigurationSuccess = (state, action) => {
    return updateObject(state, { updatingConfig: false, configUpdate: action.data, error: false });
};
const setConfigurationFailed = (state, action) => {
    return updateObject(state, { updatingConfig: false, error: action.error });
};
const setConfigurationOrderStart = (state) => {
    return updateObject(state, { updatingConfig: true, error: false });
};
const setConfigurationOrderSuccess = (state, action) => {
    return updateObject(state, { updatingConfig: false, configUpdate: action.data, error: false });
};
const setConfigurationOrderFailed = (state, action) => {
    return updateObject(state, { updatingConfig: false, error: action.error });
};
const getSearchCategoryStart = (state) => {
    return updateObject(state, { searchCategoryLoading: true, error: false });
};
const getSearchCategorySuccess = (state, action) => {
    return updateObject(state, { searchCategoryLoading: false, searchCategory: action.data, error: false });
};
const getSearchCategoryFailed = (state, action) => {
    return updateObject(state, { searchCategoryLoading: false, error: action.error });
};

const getShipmentListMapStart = (state, action) => {
    return updateObject(state, { shipmentListMapLoading: true, error: false, shipmentListMap: [] });
};
const getShipmentListMapSuccess = (state, action) => {

    return updateObject(state, {
        shipmentListMapLoading: false,
        shipmentListMap: action.data,
        error: false
    });
};
const getShipmentListMapFailed = (state, action) => {
    return updateObject(state, { shipmentListMapLoading: false, error: action.error });
};
const getShipmentListStart = (state, action) => {
    const shipmentList = state.shipmentList;
    if(action.page === 1) {
        shipmentList.length = 0;
    }
    return updateObject(state, { shipmentListLoading: true, error: false, shipmentList: shipmentList });
};
const getShipmentListSuccess = (state, action) => {
    let LIST = [...action.data];
    if (action.meta) {
        if(action.meta.current_page === 1) {
        } else {
            LIST = [...state.shipmentList, ...action.data];
        }
    }
    return updateObject(state, {
        shipmentListLoading: false,
        isNextPage: action.isNextPage,
        meta: action.meta,
        shipmentList: LIST,
        error: false,
        filtersCount: action.filtersCount,
    });
};
const getShipmentListFailed = (state, action) => {
    return updateObject(state, { shipmentListLoading: false, error: action.error });
};
const getDashboardShipmentListStart = (state, action) => {
    return updateObject(state, { shipmentListMapLoading: true, error: false, shipmentListMap: [] });
};
const getDashboardShipmentListSuccess = (state, action) => {
    return updateObject(state, {
        shipmentListMapLoading: false,
        shipmentListMap: action.data?.length ? action.data : [{empty:true}],
        activeCount: action.activeCount,
        individualStatus: action.individualStatus,
        error: false
    });
};
const getDashboardShipmentListFailed = (state, action) => {
    return updateObject(state, { shipmentListMapLoading: false, error: action.error });
};
const getCompanyListStart = (state) => {
    return updateObject(state, { companyListLoading: true, error: false });
};
const getCompanyListSuccess = (state, action) => {
    const companyList = [...action.data];
    companyList.sort((a,b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
        }
        if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
        }
        return 0;
    });
    return updateObject(state, { companyListLoading: false, companyList, error: false });
};
const getCompanyListFailed = (state, action) => {
    return updateObject(state, { companyCategoryLoading: false, error: action.error });
};
const getShipmentDetailsStart = (state) => {
    return updateObject(state, { shipmentDetailsLoading: true, error: false });
};
const getShipmentDetailsSuccess = (state, action) => {
    return updateObject(state, { shipmentDetailsLoading: false, shipmentDetails: action.data, error: false });
};
const getShipmentDetailsFailed = (state, action) => {
    return updateObject(state, { shipmentDetailsLoading: false, error: action.error, shipmentDetails: {status: '',legs:[],live_tracker_data:{route_path:[]}}});
};
const getShipmentTimelineStart = (state) => {
    return updateObject(state, { shipmentTimelineLoading: true, error: false });
};
const getShipmentTimelineSuccess = (state, action) => {
    return updateObject(state, { shipmentTimelineLoading: false, shipmentTimeline: action.data, error: false });
};
const getShipmentTimelineFailed = (state, action) => {
    return updateObject(state, { shipmentTimelineLoading: false, error: action.error });
};
const getShipmentRouteStart = (state) => {
    return updateObject(state, { shipmentRouteLoading: true, error: false });
};
const getShipmentRouteSuccess = (state, action) => {
    return updateObject(state, { shipmentRouteLoading: false, shipmentRoute: action.data, error: false });
};
const getShipmentRouteFailed = (state, action) => {
    return updateObject(state, { shipmentRouteLoading: action.details, shipmentTimeline: action.timeline });
};
const setShipmentLiveData = (state, action) => {
    if(action.resetChart) {
        const {shipmentTrackerData} = state;
        shipmentTrackerData.update = false;
        return updateObject(state, { shipmentTrackerData });
    } else if(action.list) {
        return updateObject(state, { shipmentDetails: action.details, shipmentTimeline: action.timeline, shipmentTrackerData: {data: [...state.shipmentTrackerData.data, action.trackerData], update: true}, shipmentList: action.list });
    } else {
        return updateObject(state, { shipmentDetails: action.details, shipmentTimeline: action.timeline, shipmentTrackerData: {data: [...state.shipmentTrackerData.data, action.trackerData], update: true} });
    }
};
const createNewShipmentStart = (state) => {
    return updateObject(state, { createShipmentLoading: true, error: false });
};
const createNewShipmentSuccess = (state, action) => {
    return updateObject(state, { createShipmentLoading: false, createShipment: action.data, error: false });
};
const createNewShipmentFailed = (state, action) => {
    return updateObject(state, { createShipmentLoading: false, error: action.error });
};
const createNewDeviceShipmentStart = (state) => {
    return updateObject(state, { createNewDeviceShipmentLoading: true, error: false });
};
const createNewDeviceShipmentSuccess = (state, action) => {
    return updateObject(state, { createNewDeviceShipmentLoading: false, createNewDeviceShipment: action.data, error: false });
};
const createNewDeviceShipmentFailed = (state, action) => {
    return updateObject(state, { createNewDeviceShipmentLoading: false, error: action.error });
};
const setShipmentUpdateStart = (state) => {
    return updateObject(state, { setShipmentUpdateLoading: true, error: false });
};
const setShipmentUpdateSuccess = (state, action) => {
    return updateObject(state, { setShipmentUpdateLoading: false, setShipmentUpdate: action.data, error: false });
};
const setShipmentUpdateFailed = (state, action) => {
    return updateObject(state, { setShipmentUpdateLoading: false, error: action.error });
};
const updateShipmentDetailsStart = (state) => {
    return updateObject(state, { shipmentDescriptionLoading: true, error: false });
};
const updateShipmentDetailsSuccess = (state, action) => {
    return updateObject(state, { shipmentDescriptionLoading: false, shipmentDescription: action.data, shipmentDescriptionError: false });
};
const updateShipmentDetailsFailed = (state, action) => {
    return updateObject(state, { shipmentDescriptionLoading: false, shipmentDescriptionError: action.error });
};
const getShipmentConfigurationStart = (state) => {
    return updateObject(state, { configurationProfileLoading: true, error: false });
};
const getShipmentConfigurationSuccess = (state, action) => {
    return updateObject(state, { configurationProfileLoading: false, configurationProfile: action.data, configurationProfileError: false });
};
const getShipmentConfigurationFailed = (state, action) => {
    return updateObject(state, { configurationProfileLoading: false, configurationProfileError: action.error });
};
const updateShipmentConfigurationStart = (state) => {
    return updateObject(state, { configurationProfileSaving: true, error: false });
};
const updateShipmentConfigurationSuccess = (state, action) => {
    const configurationProfile = {...state.configurationProfile};
    if(configurationProfile.product_profile_config){
        configurationProfile.product_profile_config.forEach(c => {
            c.threshold_duration = c.threshold_duration * 60;
        });
    }
    return updateObject(state, { configurationProfileSaving: false, configurationProfileSuccess: action.data, configurationProfileError: false, configurationProfile });
};
const updateShipmentConfigurationFailed = (state, action) => {
    return updateObject(state, { configurationProfileSaving: false, configurationProfileError: action.error });
};
const getShipmentTrackerDataStart = (state) => {
    return updateObject(state, { shipmentTrackerDataLoading: true, error: false });
};
const getShipmentTrackerDataSuccess = (state, action) => {
    return updateObject(state, { shipmentTrackerDataLoading: false, shipmentTrackerData: {data: action.data, update: false}, error: false });
};
const getShipmentTrackerDataFailed = (state, action) => {
    return updateObject(state, { shipmentTrackerDataLoading: false, error: action.error });
};
const getCarriersStart = (state) => {
    return updateObject(state, { carriersLoading: true, error: false });
};
const getCarriersSuccess = (state, action) => {
    return updateObject(state, { carriersLoading: false, carriers: action.data, error: false });
};
const getCarriersFailed = (state, action) => {
    return updateObject(state, { carriersLoading: false, error: action.error });
};
const getNotificationsStart = (state) => {
    return updateObject(state, { notificationsLoading: true, error: false });
};
const getNotificationsSuccess = (state, action) => {
    return updateObject(state, { notificationsLoading: false, notifications: action.data.data || [], notificationsMeta: action.data.meta || {}, error: false, unreadNotifications: action.data.unreadNotifications || state.unreadNotifications });
};
const getNotificationsFailed = (state, action) => {
    return updateObject(state, { notificationsLoading: false, error: action.error });
};
const getUnreadNotificationsStart = (state) => {
    return updateObject(state, { unreadNotificationsLoading: true, error: false });
};
const getUnreadNotificationsSuccess = (state, action) => {
    return updateObject(state, { unreadNotificationsLoading: false, unreadNotifications: action.data.total || 0, error: false });
};
const getUnreadNotificationsFailed = (state, action) => {
    return updateObject(state, { unreadNotificationsLoading: false, error: action.error });
};
const setNotificationReadStart = (state) => {
    return updateObject(state, { setNotificationReadLoading: true, error: false,  setNotificationRead: ''});
};
const setNotificationReadSuccess = (state, action) => {
    return updateObject(state, { setNotificationReadLoading: false, setNotificationRead: action.data, error: false });
};
const setNotificationReadFailed = (state, action) => {
    return updateObject(state, { setNotificationReadLoading: false, error: action.error });
};
const saveGeoFenceStart = (state) => {
    return updateObject(state, { saveGeoFenceLoading: true, saveGeoFenceError: false});
};
const saveGeoFenceSuccess = (state, action) => {
    const shipmentDetails = {...state.shipmentDetails};
    const newGeo = {};
    if(action.data && action.geofence) {
        newGeo.id = action.data.geofenceId;
        newGeo.category = action.geofence.category;
        newGeo.center = JSON.parse(action.geofence.center);
        newGeo.geofence = JSON.parse(action.geofence.geofence);
        newGeo.geofenceName = action.geofence.geofenceName;
        newGeo.radius = action.geofence.radius;
        newGeo.type = action.geofence.type;
    }
    let index = -1;
    if(shipmentDetails.geofences) {
        index = shipmentDetails.geofences.findIndex(gf => gf.geofenceId === action.data.geofenceId);
    } else {
        shipmentDetails.geofences = [];
    }
    if(shipmentDetails.geofences.length === 0 || index === -1) {
        shipmentDetails.geofences.push(newGeo);
    }    
    // console.log(action);
    // console.log(shipmentDetails);
    return updateObject(state, { shipmentDetails: shipmentDetails, saveGeoFenceLoading: false, geoFenceSaved: action.data.hasOwnProperty('geoFenceSaved') ? action.geoFenceSaved : true, saveGeoFenceError: false });    
};
const saveGeoFenceFailed = (state, action) => {
    return updateObject(state, { saveGeoFenceLoading: false, saveGeoFenceError: action.error });
};
const deleteGeoFenceStart = (state) => {
    return updateObject(state, { deleteGeoFenceLoading: true, error: false});
};
const deleteGeoFenceSuccess = (state, action) => {
    const shipmentDetails = {...state.shipmentDetails};
    if(action && action.id) {
        //console.log('action: ', action);
        const index = shipmentDetails.geofences.findIndex(gf => gf.id === action.id);
        //console.log('inedx: ', index);
        shipmentDetails.geofences.splice(index, 1);
    }
    return updateObject(state, { shipmentDetails: shipmentDetails, deleteGeoFenceLoading: false, geoFenceDeleted: action.data.hasOwnProperty('geoFenceDeleted') ? action.geoFenceDeleted : true, error: false });    
};
const deleteGeoFenceFailed = (state, action) => {
    return updateObject(state, { deleteGeoFenceLoading: false, deleteGeofenceError: action.error });
};

const getShipmentReportsStart = (state) => {
    return updateObject(state, { shipmentReportsLoading: true, shipmentReportsErrors: false });
};
const getShipmentReportsSuccess = (state, action) => {
    return updateObject(state, { shipmentReportsLoading: false, shipmentReports: action.data || [], shipmentReportsErrors: false});
};
const getShipmentReportsFailed = (state, action) => {
    return updateObject(state, { shipmentReportsLoading: false, shipmentReportsErrors: action.error });
};

const createShipmentReportStart = (state) => {
    return updateObject(state, { shipmentReportCreating: true, shipmentReportsErrors: false });
};
const createShipmentReportSuccess = (state, action) => {
    const shipmentReports = state.shipmentReports;
    if(action.data.data) {
        shipmentReports.unshift(action.data.data);
    }
    return updateObject(state, { shipmentReportCreating: false, shipmentReports, shipmentReportCreated: action.data || {data:{}, message: 'Error parsing response!'}, shipmentReportsErrors: false});
};
const createShipmentReportFailed = (state, action) => {
    if(action.errors) {
        return updateObject(state, { shipmentReportCreating: false, shipmentReportsErrors: action.errors });
    } else if(action.message){
        return updateObject(state, { shipmentReportCreating: false, shipmentReportsErrors: {message: action.errors.message} });
    }
    
};

const deleteShipmentReportStart = (state) => {
    return updateObject(state, { shipmentReportDeleting: true, shipmentReportsErrors: false });
};
const deleteShipmentReportSuccess = (state, action) => {
    const shipmentReports = state.shipmentReports;
    const reportIndex = shipmentReports.findIndex(rep => rep.id === action.id);
    shipmentReports.splice(reportIndex, 1);
    return updateObject(state, { shipmentReportDeleting: false, shipmentReports, shipmentReportDelete: action.message || '', shipmentReportsErrors: false});
};
const deleteShipmentReportFailed = (state, action) => {
    return updateObject(state, { shipmentReportDeleting: false, shipmentReportsErrors: action.error });
};

const assignDeviceToShipmentStart = (state) => {
    return updateObject(state, { assignDeviceToShipmentLoading: true, assignDeviceToShipmentErrors: false });
};
const assignDeviceToShipmentSuccess = (state, action) => {
    return updateObject(state, { assignDeviceToShipmentLoading: false, assignDeviceToShipmentErrors: false, assignDeviceToShipmentMessage: action.data});
};
const assignDeviceToShipmentFailed = (state, action) => {
    return updateObject(state, { assignDeviceToShipmentLoading: false, assignDeviceToShipmentErrors: action.error });
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DASHBOARD_DETAILS_START:
            return getDashboardDetailsStart(state, action);
        case actionTypes.GET_DASHBOARD_DETAILS_SUCCESS:
            return getDashboardDetailsSuccess(state, action);
        case actionTypes.GET_DASHBOARD_DETAILS_FAILED:
            return getDashboardDetailsFailed(state, action);
        case actionTypes.GET_CONFIGURATION_LIST_START:
            return getConfigurationListStart(state, action);
        case actionTypes.GET_CONFIGURATION_LIST_SUCCESS:
            return getConfigurationListSuccess(state, action);
        case actionTypes.GET_CONFIGURATION_LIST_FAILED:
            return getConfigurationListFailed(state, action);
        case actionTypes.SET_CONFIGURATION_START:
            return setConfigurationStart(state, action);
        case actionTypes.SET_CONFIGURATION_SUCCESS:
            return setConfigurationSuccess(state, action);
        case actionTypes.SET_CONFIGURATION_FAILED:
            return setConfigurationFailed(state, action);
        case actionTypes.SET_CONFIGURATION_ORDER_START:
            return setConfigurationOrderStart(state, action);
        case actionTypes.SET_CONFIGURATION_ORDER_SUCCESS:
            return setConfigurationOrderSuccess(state, action);
        case actionTypes.SET_CONFIGURATION_ORDER_FAILED:
            return setConfigurationOrderFailed(state, action);
        case actionTypes.GET_SEARCH_CATEGORY_START:
            return getSearchCategoryStart(state, action);
        case actionTypes.GET_SEARCH_CATEGORY_SUCCESS:
            return getSearchCategorySuccess(state, action);
        case actionTypes.GET_SEARCH_CATEGORY_FAILED:
            return getSearchCategoryFailed(state, action);
        case actionTypes.GET_SHIPMENT_LIST_MAP_START:
            return getShipmentListMapStart(state, action);
        case actionTypes.GET_SHIPMENT_LIST_MAP_SUCCESS:
            return getShipmentListMapSuccess(state, action);
        case actionTypes.GET_SHIPMENT_LIST_MAP_FAILED:
            return getShipmentListMapFailed(state, action);
        case actionTypes.GET_DASHBOARD_SHIPMENT_LIST_START:
            return getDashboardShipmentListStart(state, action);
        case actionTypes.GET_DASHBOARD_SHIPMENT_LIST_SUCCESS:
            return getDashboardShipmentListSuccess(state, action);
        case actionTypes.GET_DASHBOARD_SHIPMENT_LIST_FAILED:
            return getDashboardShipmentListFailed(state, action);
        case actionTypes.GET_SHIPMENT_LIST_START:
            return getShipmentListStart(state, action);
        case actionTypes.GET_SHIPMENT_LIST_SUCCESS:
            return getShipmentListSuccess(state, action);
        case actionTypes.GET_SHIPMENT_LIST_FAILED:
            return getShipmentListFailed(state, action);
        case actionTypes.GET_COMPANY_LIST_START:
            return getCompanyListStart(state, action);
        case actionTypes.GET_COMPANY_LIST_SUCCESS:
            return getCompanyListSuccess(state, action);
        case actionTypes.GET_COMPANY_LIST_FAILED:
            return getCompanyListFailed(state, action);
        case actionTypes.GET_SHIPMENT_DETAILS_START:
            return getShipmentDetailsStart(state, action);
        case actionTypes.GET_SHIPMENT_DETAILS_SUCCESS:
            return getShipmentDetailsSuccess(state, action);
        case actionTypes.GET_SHIPMENT_DETAILS_FAILED:
            return getShipmentDetailsFailed(state, action);
        case actionTypes.GET_SHIPMENT_TIMELINE_START:
            return getShipmentTimelineStart(state, action);
        case actionTypes.GET_SHIPMENT_TIMELINE_SUCCESS:
            return getShipmentTimelineSuccess(state, action);
        case actionTypes.GET_SHIPMENT_TIMELINE_FAILED:
            return getShipmentTimelineFailed(state, action);
        case actionTypes.GET_SHIPMENT_ROUTE_START:
            return getShipmentRouteStart(state, action);
        case actionTypes.GET_SHIPMENT_ROUTE_SUCCESS:
            return getShipmentRouteSuccess(state, action);
        case actionTypes.GET_SHIPMENT_ROUTE_FAILED:
            return getShipmentRouteFailed(state, action);
        case actionTypes.SET_LIVE_DATA:
            return setShipmentLiveData(state, action);
        case actionTypes.CREATE_NEW_SHIPMENT_START:
            return createNewShipmentStart(state, action);
        case actionTypes.CREATE_NEW_SHIPMENT_SUCCESS:
            return createNewShipmentSuccess(state, action);
        case actionTypes.CREATE_NEW_SHIPMENT_FAILED:
            return createNewShipmentFailed(state, action);
        case actionTypes.CREATE_NEW_DEVICE_SHIPMENT_START:
            return createNewDeviceShipmentStart(state, action);
        case actionTypes.CREATE_NEW_DEVICE_SHIPMENT_SUCCESS:
            return createNewDeviceShipmentSuccess(state, action);
        case actionTypes.CREATE_NEW_DEVICE_SHIPMENT_FAILED:
            return createNewDeviceShipmentFailed(state, action);
        case actionTypes.SET_SHIPMENT_UPDATE_START:
            return setShipmentUpdateStart(state, action);
        case actionTypes.SET_SHIPMENT_UPDATE_SUCCESS:
            return setShipmentUpdateSuccess(state, action);
        case actionTypes.SET_SHIPMENT_UPDATE_FAILED:
            return setShipmentUpdateFailed(state, action);
        case actionTypes.UPDATE_SHIPMENT_DETAILS_START:
            return updateShipmentDetailsStart(state, action);
        case actionTypes.UPDATE_SHIPMENT_DETAILS_SUCCESS:
            return updateShipmentDetailsSuccess(state, action);
        case actionTypes.UPDATE_SHIPMENT_DETAILS_FAILED:
            return updateShipmentDetailsFailed(state, action);
        case actionTypes.GET_SHIPMENT_CONFIGURATION_START:
            return getShipmentConfigurationStart(state, action);
        case actionTypes.GET_SHIPMENT_CONFIGURATION_SUCCESS:
            return getShipmentConfigurationSuccess(state, action);
        case actionTypes.GET_SHIPMENT_CONFIGURATION_FAILED:
            return getShipmentConfigurationFailed(state, action);
        case actionTypes.UPDATE_SHIPMENT_CONFIGURATION_START:
            return updateShipmentConfigurationStart(state, action);
        case actionTypes.UPDATE_SHIPMENT_CONFIGURATION_SUCCESS:
            return updateShipmentConfigurationSuccess(state, action);
        case actionTypes.UPDATE_SHIPMENT_CONFIGURATION_FAILED:
            return updateShipmentConfigurationFailed(state, action);
        case actionTypes.GET_SHIPMENT_TRACKER_DATA_START:
            return getShipmentTrackerDataStart(state, action);
        case actionTypes.GET_SHIPMENT_TRACKER_DATA_SUCCESS:
            return getShipmentTrackerDataSuccess(state, action);
        case actionTypes.GET_SHIPMENT_TRACKER_DATA_FAILED:
            return getShipmentTrackerDataFailed(state, action);
        case actionTypes.GET_CARRIERS_START:
            return getCarriersStart(state, action);
        case actionTypes.GET_CARRIERS_SUCCESS:
            return getCarriersSuccess(state, action);
        case actionTypes.GET_CARRIERS_FAILED:
            return getCarriersFailed(state, action);
        case actionTypes.GET_NOTIFICATIONS_START:
            return getNotificationsStart(state, action);
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return getNotificationsSuccess(state, action);
        case actionTypes.GET_NOTIFICATIONS_FAILED:
            return getNotificationsFailed(state, action);
        case actionTypes.GET_UNREAD_NOTIFICATIONS_START:
            return getUnreadNotificationsStart(state, action);
        case actionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS:
            return getUnreadNotificationsSuccess(state, action);
        case actionTypes.GET_UNREAD_NOTIFICATIONS_FAILED:
            return getUnreadNotificationsFailed(state, action);
        case actionTypes.SET_NOTIFICATION_READ_START:
            return setNotificationReadStart(state, action);
        case actionTypes.SET_NOTIFICATION_READ_SUCCESS:
            return setNotificationReadSuccess(state, action);
        case actionTypes.SET_NOTIFICATION_READ_FAILED:
            return setNotificationReadFailed(state, action);
        case actionTypes.SAVE_GEO_FENCE_START:
            return saveGeoFenceStart(state, action);
        case actionTypes.SAVE_GEO_FENCE_SUCCESS:
            return saveGeoFenceSuccess(state, action);
        case actionTypes.SAVE_GEO_FENCE_FAILED:
            return saveGeoFenceFailed(state, action);
        case actionTypes.DELETE_GEO_FENCE_START:
            return deleteGeoFenceStart(state, action);
        case actionTypes.DELETE_GEO_FENCE_SUCCESS:
            return deleteGeoFenceSuccess(state, action);
        case actionTypes.DELETE_GEO_FENCE_FAILED:
            return deleteGeoFenceFailed(state, action);
        case actionTypes.GET_SHIPMENT_REPORTS_START:
            return getShipmentReportsStart(state, action);
        case actionTypes.GET_SHIPMENT_REPORTS_SUCCESS:
            return getShipmentReportsSuccess(state, action);
        case actionTypes.GET_SHIPMENT_REPORTS_FAILED:
            return getShipmentReportsFailed(state, action);
        case actionTypes.CREATE_SHIPMENT_REPORT_START:
            return createShipmentReportStart(state, action);
        case actionTypes.CREATE_SHIPMENT_REPORT_SUCCESS:
            return createShipmentReportSuccess(state, action);
        case actionTypes.CREATE_SHIPMENT_REPORT_FAILED:
            return createShipmentReportFailed(state, action);
        case actionTypes.DELETE_SHIPMENT_REPORT_START:
            return deleteShipmentReportStart(state, action);
        case actionTypes.DELETE_SHIPMENT_REPORT_SUCCESS:
            return deleteShipmentReportSuccess(state, action);
        case actionTypes.DELETE_SHIPMENT_REPORT_FAILED:
            return deleteShipmentReportFailed(state, action);
        case actionTypes.ASSIGN_DEVICE_TO_SHIPMENT_START:
            return assignDeviceToShipmentStart(state, action);
        case actionTypes.ASSIGN_DEVICE_TO_SHIPMENT_SUCCESS:
            return assignDeviceToShipmentSuccess(state, action);
        case actionTypes.ASSIGN_DEVICE_TO_SHIPMENT_FAILED:
            return assignDeviceToShipmentFailed(state, action);
        default:
            return state;
    }
};
export default reducer;
