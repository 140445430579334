const headerLabels = ()=> {
    return {
        "visibility" : {
            "label" : "Visibility",
            "sub_menu" : {
                "active_shipment" :  "Active (Live Shipments)",
                "pdf_upload" : "Passive (PDF Upload)",
                "passive_insights" : "Passive Lane Insights",
                "active_insights" : "Active Lane Insights",
                "active_shipments_insghts" : "Active Shipments Insights",
                "asset_tracking" : "Asset Tracking"
            }
        },
        "risk" : {
            "label" : "Risk",
            "sub_menu" : {
                "risk_assessment" :  "Lane Risk Assessment",
                "risk_planning" :  "Risk Scenario Analysis",
                "live_risk" : "Live Shipment Risk Score"
            }
        },
        "config" : {
            "label" : "Configuration",
            "sub_menu" : {
                "shipment_config" :  "Active Shipment Configuration",
                "user_config" : "User Configuration",
                "parser_config" : "PDF Upload Configuration",
                "edit_templates" : "Edit Templates"
            }
        },
        "manage" : {
            "label" : "Manage",
            "sub_menu" : {
                "company" :  "Companies",
                "device" :  "Devices",
                "User" :  "Users",
            }
        },
        "help" : {
            "label" : "Help"
        }
    }
};
export default headerLabels();