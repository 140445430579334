import ReactGA from 'react-ga';
export const DASHBOARD = 'Dashboard'
export const GROUP_FILTER= 'Group Filter'
export const COMPANY_FILTER= 'Company Filter'
export const SHIPMENTS = 'Shipments'
export const SORT_BY = 'Sort By'
export const INSIGHTS_OVERVIEW_FILTER = 'Insights OverView Filter'
export const INSIGHTS_LOCATION_FILTER = 'Insights Location Filter'
export const INSIGHTS_EXCURSION = 'Insights Excursion'
export const COMPANIES = 'COMPANIES'
export const USERS = 'USERS'
export const DEVICES = 'DEVICES'
export const FEATURES = 'FEATURES'
export const SEARCH = 'SEARCH'
export const ASSIGNED = 'ASSIGNED'
export const CONFIGURATION = 'CONFIGURATION'
export const GEOFENCE = 'GEOFENCE'

let googleAnalyticActions = {};

googleAnalyticActions.initGoogleAnalytics = async (key) => {
    ReactGA.initialize(key);
};

googleAnalyticActions.addEvent = async (category, action) => {
    ReactGA.event({
        category: category,
        action: action,
    });
};
googleAnalyticActions.addPage = async (pathname, search) => {
    ReactGA.pageview('', '', '');
};

export { googleAnalyticActions };
