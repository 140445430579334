import * as actionTypes from './actionTypes';
import { AXIOS } from '../../utility/Axios';
import { sendLogout, sendNetworkError, sendInternalServerError } from './company';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;

export const getInsightsDataStart = () => {
    return {
        type: actionTypes.GET_INSIGHTS_DATA_START
    };
};

export const getInsightsDataStop = () => {
    return {
        type: actionTypes.GET_INSIGHTS_DATA_STOP
    };
};

export const getInsightsDataSuccess = (data) => {
    return {
        type: actionTypes.GET_INSIGHTS_DATA_SUCCESS,
        data
    };
};
export const getInsightsDataFailed = (error) => {
    return {
        type: actionTypes.GET_INSIGHTS_DATA_FAILED,
        error
    };
};

export const getInsights = data => async dispatch => {
    let params = {...data};
    delete(params.onComplete);
    let requestUrl = apis.insights.get_shipment_reports;
    dispatch(getInsightsDataStart());
    await AXIOS({
        url: requestUrl,
        responseType: 'json',
        Params: params.data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getInsightsDataFailed(response.response.data.error, {}));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getInsightsDataFailed(
                        response.response.data.errors
                    )
                );
            } else {
                dispatch(getInsightsDataFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                const D = {...response.data.data};
                const E = [];
                D.excursions.forEach((e, i) => {
                    if(e.createdDate.indexOf('1970-01')>=0) {
                        D.excursions.splice(i, 1);
                    } else {
                        e.createdDate = e.createdDate.replace(/T/, ' ').replace(/Z/, '').replace(/\.\d{0,}/, '');
                        E.push(e);
                    }                    
                });
                D.excursions = E;
                dispatch(getInsightsDataSuccess(D));
                if(params.data.showAll){
                    dispatch(getLocStatesSuccess([{label: 'All', id: ''}]));
                    dispatch(getLocCitiesSuccess([{label: 'All', id: ''}]));
                    dispatch(getLocRoutesSuccess([{label: 'All', id: ''}]));
                }
                //dispatch(getInsightsDataSuccess({...response.data.data}));
                if( data.onComplete && typeof(data.onComplete) === 'function' ) {
                    data.onComplete(D);
                }
                dispatch(getInsightsDataStop());
            } else {
                dispatch(getInsightsDataFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getLocCountriesStart = () => {
    return {
        type: actionTypes.GET_LOC_COUNTRIES_START
    };
};

export const getLocCountriesSuccess = (data) => {
    return {
        type: actionTypes.GET_LOC_COUNTRIES_SUCCESS,
        data
    };
};
export const getLocCountriesFailed = (error) => {
    return {
        type: actionTypes.GET_LOC_COUNTRIES_FAILED,
        error
    };
};

export const getLocCountries = data => async dispatch => {
    let params = {...data};
    delete(params.onComplete);
    let requestUrl = apis.insights.get_location_countries;
    if(params.data.companyId && params.data.companyId !== null && params.data.companyId !== '') {
        delete(params.data.groupId);
    } else {
        delete(params.data.companyId);
    }
    dispatch(getLocCountriesStart());
    await AXIOS({
        url: requestUrl,
        responseType: 'json',
        Params: params.data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLocCountriesFailed(response.response.data.error, {}));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLocCountriesFailed(
                        response.response.data.errors
                    )
                );
            } else {
                dispatch(getLocCountriesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                const COUNTRIES = response.data.data.map(c=> { return {label: c.name, id: c.id} });
                dispatch(getLocCountriesSuccess([{label: 'All', id: ''}, ...COUNTRIES]));
                dispatch(getLocStatesSuccess([{label: 'All', id: ''}]));
                dispatch(getLocCitiesSuccess([{label: 'All', id: ''}]));
                dispatch(getLocRoutesSuccess([{label: 'All', id: ''}]));
                if( data && data.onComplete && typeof(data.onComplete) === 'function' ) {
                    data.onComplete(response.data.data);
                }
                dispatch(getInsightsDataStop());
            } else {
                dispatch(getLocCountriesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getLocStatesStart = () => {
    return {
        type: actionTypes.GET_LOC_STATES_START
    };
};

export const getLocStatesSuccess = (data) => {
    return {
        type: actionTypes.GET_LOC_STATES_SUCCESS,
        data
    };
};
export const getLocStatesFailed = (error) => {
    return {
        type: actionTypes.GET_LOC_STATES_FAILED,
        error
    };
};

export const getLocStates = data => async dispatch => {
    let params = {...data};
    delete(params.onComplete);
    let requestUrl = apis.insights.get_country_states(data.data.countryId);
    if(params.data.companyId && params.data.companyId !== null && params.data.companyId !== '') {
        delete(params.data.groupId);
    } else {
        delete(params.data.companyId);
    }
    dispatch(getLocStatesStart());
    await AXIOS({
        url: requestUrl,
        responseType: 'json',
        Params: params.data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLocStatesFailed(response.response.data.error, {}));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLocStatesFailed(
                        response.response.data.errors
                    )
                );
            } else {
                dispatch(getLocStatesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                const STATES = response.data.data?.states.map(s=> { return {label: s.name, id: s.id} }) || [];
                dispatch(getLocStatesSuccess([{label: 'All', id: ''}, ...STATES]));
                dispatch(getLocCitiesSuccess([{label: 'All', id: ''}]));
                dispatch(getLocRoutesSuccess([{label: 'All', id: ''}]));
                //dispatch(getLocStatesSuccess([{label: 'All', id: ''}, {label: `${data.data.locCountry} State 1`, id: 1},{label: `${data.data.locCountry} State 2`, id: 2},{label: `${data.data.locCountry} State 3`, id: 3},{label: `${data.data.locCountry} State 4`, id: 4},{label: `${data.data.locCountry} State 5`, id: 5}]));
                if( data.onComplete && typeof(data.onComplete) === 'function' ) {
                    data.onComplete(response.data.data);
                }
                dispatch(getInsightsDataStop());
            } else {
                dispatch(getLocStatesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getLocCitiesStart = () => {
    return {
        type: actionTypes.GET_LOC_CITIES_START
    };
};

export const getLocCitiesSuccess = (data) => {
    return {
        type: actionTypes.GET_LOC_CITIES_SUCCESS,
        data
    };
};
export const getLocCitiesFailed = (error) => {
    return {
        type: actionTypes.GET_LOC_CITIES_FAILED,
        error
    };
};

export const getLocCities = data => async dispatch => {
    let params = {...data};
    delete(params.onComplete);
    let requestUrl = apis.insights.get_state_cities(data.data.countryId, data.data.stateId);
    if(params.data.companyId && params.data.companyId !== null && params.data.companyId !== '') {
        delete(params.data.groupId);
    } else {
        delete(params.data.companyId);
    }
    dispatch(getLocCitiesStart());
    await AXIOS({
        url: requestUrl,
        responseType: 'json',
        Params: params.data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLocCitiesFailed(response.response.data.error, {}));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLocCitiesFailed(
                        response.response.data.errors
                    )
                );
            } else {
                dispatch(getLocCitiesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                const CITIES = response.data.data?.states[0]?.cities.map(c=> { return {label: c.name, id: c.id} }) || [];
                dispatch(getLocCitiesSuccess([{label: 'All', id: ''}, ...CITIES]));
                dispatch(getLocRoutesSuccess([{label: 'All', id: ''}]));
                if( data.onComplete && typeof(data.onComplete) === 'function' ) {
                    data.onComplete(response.data.data);
                }
                dispatch(getInsightsDataStop());
            } else {
                dispatch(getLocCitiesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getLocRoutesStart = () => {
    return {
        type: actionTypes.GET_LOC_ROUTES_START
    };
};

export const getLocRoutesSuccess = (data) => {
    return {
        type: actionTypes.GET_LOC_ROUTES_SUCCESS,
        data
    };
};
export const getLocRoutesFailed = (error) => {
    return {
        type: actionTypes.GET_LOC_ROUTES_FAILED,
        error
    };
};

export const getLocRoutes = data => async dispatch => {
    let params = {...data};
    delete(params.onComplete);
    let requestUrl = apis.insights.get_location_routes;
    if(params.data.companyId && params.data.companyId !== null && params.data.companyId !== '') {
        delete(params.data.groupId);
    } else {
        delete(params.data.companyId);
    }
    dispatch(getLocRoutesStart());
    await AXIOS({
        url: requestUrl,
        responseType: 'json',
        Params: params.data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLocRoutesFailed(response.response.data.error, {}));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLocRoutesFailed(
                        response.response.data.errors
                    )
                );
            } else {
                dispatch(getLocRoutesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                const ROUTES = response.data.data.map(r=> { return {label: r.route_name, id: r.route_id} });
                dispatch(getLocRoutesSuccess([{label: 'All', id: ''}, ...ROUTES]));
                //dispatch(getLocRoutesSuccess([{label: 'All', id: ''}, {label: `${data.data.locState} City 1`, id: 1},{label: `${data.data.locState} City 2`, id: 2},{label: `${data.data.locState} City 3`, id: 3},{label: `${data.data.locState} City 4`, id: 4},{label: `${data.data.locState} City 5`, id: 5}]));
                if( data.onComplete && typeof(data.onComplete) === 'function' ) {
                    data.onComplete(response.data.data);
                }
                dispatch(getInsightsDataStop());
            } else {
                dispatch(getLocRoutesFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};