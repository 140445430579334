import { combineReducers } from 'redux';
import loginReducer from './reducers/login';
import dashboardReducer from './reducers/dashboard';
import companyReducer from './reducers/company';
import userReducer from './reducers/users';
import deviceReducer from './reducers/devices';
import configurationReducer from './reducers/configuration';
import insightsReducer from './reducers/insights';
import assetTrackingReducer from './reducers/assetTracking';
import pdfParserReducer from './reducers/pdf';
import riskIQReducer from './reducers/riskIQ';

export default combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    company: companyReducer,
    user: userReducer,
    device: deviceReducer,
    configuration: configurationReducer,
    insights: insightsReducer,
    assetTracking: assetTrackingReducer,
    pdfParser: pdfParserReducer,
    riskIQ: riskIQReducer
});
