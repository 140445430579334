export const googleGetPlacePredictions = async text =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject("Need valid text input")
    }

    // for use in things like GatsbyJS where the html is generated first
    if (typeof window === "undefined") {
      return reject("Need valid window object")
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { input: text },
        resolve
      )
    } catch (e) {
      reject(e)
    }
  });

export const googleGetPlaceDetails = async description => {
    const loc = {latitude: '', longitude: ''};
    await new window.google.maps.Geocoder().geocode({address: description})
    .then((result) => {
        const { results } = result;
        if(results.length) {
            loc.latitude = results[0].geometry.location.lat();
            loc.longitude = results[0].geometry.location.lng();
            return loc;
        }
        return loc;
    }).catch(e => {
        console.log('===========> ', e);
    });
    return loc;    
}