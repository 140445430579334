import * as actionTypes from './actionTypes';
import { AXIOS } from '../../utility/Axios';
import { getShipmentConfigurationStart, getShipmentConfigurationSuccess } from './dashboard';
import { sendLogout, sendNetworkError, sendInternalServerError } from './company';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;
//const deviceTypeId = 1;

export const updateProfileSuccess = (message) => {
    return {
        type: actionTypes.GET_USER_UPDATE_PROFILE_SUCCESS,
        message,
    };
};

export const getCompanyListSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_LIST_SUCCESS,
        data: data,
    };
};

export const getConfigurationProfileListStart = (data) => {
    return {
        type: actionTypes.GET_CONFIGURATION_PROFILE_LIST_START
    };
};

export const getConfigurationProfileListSuccess = (data, clear, deleteAction) => {
    return {
        type: actionTypes.GET_CONFIGURATION_PROFILE_LIST_SUCCESS,
        data,
        clear,
        deleteAction,
    };
};

export const getConfigurationProfileStart = (data) => {
    return {
        type: actionTypes.GET_CONFIGURATION_PROFILE_START,
    };
};

export const getConfigurationProfileSuccess = (data) => {
    return {
        type: actionTypes.GET_CONFIGURATION_PROFILE_SUCCESS,
        data,
    };
};

export const saveConfigurationProfileStart = (data) => {
    return {
        type: actionTypes.SAVE_CONFIGURATION_PROFILE_START,
    };
};

export const saveConfigurationProfileSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_CONFIGURATION_PROFILE_SUCCESS,
        message,
        error
    };
};

export const deleteConfigurationProfileSuccess = (message) => {
    return {
        type: actionTypes.DELETE_CONFIGURATION_PROFILE_SUCCESS,
        message,
    };
};

export const getAlertProfileListStart = (data) => {
    return {
        type: actionTypes.GET_ALERT_PROFILE_LIST_START
    };
};

export const getAlertProfileListSuccess = (data, clear, deleteAction) => {
    return {
        type: actionTypes.GET_ALERT_PROFILE_LIST_SUCCESS,
        data,
        clear,
        deleteAction,
    };
};

export const getAlertProfileStart = (data) => {
    return {
        type: actionTypes.GET_ALERT_PROFILE_START,
    };
};

export const getAlertProfileSuccess = (data) => {
    return {
        type: actionTypes.GET_ALERT_PROFILE_SUCCESS,
        data,
    };
};

export const saveAlertProfileStart = (data) => {
    return {
        type: actionTypes.SAVE_ALERT_PROFILE_START,
    };
};

export const saveAlertProfileSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_ALERT_PROFILE_SUCCESS,
        message,
        error
    };
};

export const deleteAlertProfileSuccess = (message) => {
    return {
        type: actionTypes.DELETE_ALERT_PROFILE_SUCCESS,
        message,
    };
};

export const updateProfile = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.update_profile;
    await AXIOS({
        method: 'POST',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
                dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(updateProfileSuccess(response.response.data.error));
            } else {
                dispatch(updateProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                dispatch(updateProfileSuccess('Updated Profile Successfully!'));
            }
        }
    });
};

export const getCompanyList = () =>
    async function (dispatch) {
        let requestUrl = apis.configuration.get_company_list;
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getCompanyListSuccess([]));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(getCompanyListSuccess(response.data.data));
                }
            }
        });
    };

export const getConfigurationProfileList = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_configuration_profile_list(data.companyId);
    dispatch(getConfigurationProfileListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getConfigurationProfileListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getConfigurationProfileListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getConfigurationProfileListSuccess([]));
            }
        }
    });
};

export const getConfigurationProfile = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_configuration_profile(data.profileID);
    if(data.forShipmentProfile) {
        dispatch(getShipmentConfigurationStart());
    } else {
        dispatch(getConfigurationProfileStart());
    }

    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if(data.forShipmentProfile) {
                dispatch(getShipmentConfigurationSuccess(response.response.data.error));
            } else {
                dispatch(getConfigurationProfileSuccess(response.response.data.error));
            }            
        } else {
            if (response.data && response.data.data) {
                const DATA = {...response.data.data};
                if(Array.isArray(DATA.thresholds)) {
                    const thresholds = {};
                    DATA.thresholds.forEach(t => {
                        const keys = Object.keys(t);
                        thresholds[keys[0]] = t[keys];
                    });
                    DATA.thresholds = thresholds || {};
                }
                if(data.forShipmentProfile) {
                    dispatch(getShipmentConfigurationSuccess(DATA));
                } else {
                    dispatch(getConfigurationProfileSuccess(DATA));
                }
                
                if(DATA && data.onComplete) {
                    data.onComplete(DATA);
                }
            }
                        
        }
    });
};

export const saveConfigurationProfile = (data) => async (dispatch) => {
    dispatch(saveConfigurationProfileStart());
    let requestUrl = apis.configuration.save_configuration_profile(data.configurationProfileDetails.id? `/${data.configurationProfileDetails.id}` : '');
    
    /** Remove profile ID from payload if its new **/
    const DATA = {...data.configurationProfileDetails};
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    // console.log('----> ',DATA);
    
    await AXIOS({
        method: data.configurationProfileDetails.id ? 'PUT' : 'POST',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log('---response------> ', response, '\n',response.response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response && response.response?.data) {
            dispatch(saveConfigurationProfileSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data?.success) {
                const msg = response.data.message;
                dispatch(saveConfigurationProfileSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(response.data);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveConfigurationProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteConfigurationProfile = (data) => 
    async function (dispatch) {
        dispatch(saveConfigurationProfileStart());
        let requestUrl = apis.configuration.delete_configuration_profile(data.profileID);
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_config_profiles_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteConfigurationProfileSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data?.success) {
                    dispatch(deleteConfigurationProfileSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };

export const getAlertProfileList = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_alert_profile_list(data.companyId);
    dispatch(getAlertProfileListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getAlertProfileListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getAlertProfileListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getAlertProfileListSuccess([]));
            }
        }
    });
};

export const getAlertProfile = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_alert_profile(data.id);
    if(data.forShipmentProfile) {
        dispatch(getShipmentConfigurationStart());
    } else {
        dispatch(getAlertProfileStart());
    }

    await AXIOS({
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getAlertProfileSuccess(response.response.data.error));
        } else {
            
            if (response.data && response.data.data) {
                const DATA = {...response.data.data};

                dispatch(getAlertProfileSuccess(DATA));
                
                if(DATA && data.onComplete) {
                    data.onComplete(DATA);
                }
            }
                        
        }
    });
};

export const saveAlertProfile = (data) => async (dispatch) => {
    dispatch(saveAlertProfileStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const requestUrl = apis.configuration.save_alert_profile;
    
    /** Remove profile ID from payload if its new **/
    const DATA = {...data.alertProfileDetails};
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    //console.log('----> ',DATA);
    
    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveAlertProfileSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(saveAlertProfileSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveAlertProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteAlertProfile = (data) => 
    async function (dispatch) {
        dispatch(saveAlertProfileStart());
        let requestUrl = apis.configuration.delete_alert_profile(data.id);
        
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteAlertProfileSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(deleteAlertProfileSuccess(response.data.data));
                    if(data.onComplete) {
                        data.onComplete(response.data.data);
                    }
                }
            }
        });
    };    


export const getProductProfileListStart = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_PROFILE_LIST_START
    };
};

export const getProductProfileListSuccess = (data, clear, updatedObj, status, deleteAction, productProfileListMeta) => {
    return {
        type: actionTypes.GET_PRODUCT_PROFILE_LIST_SUCCESS,
        data,
        clear,
        updatedObj, 
        status,
        deleteAction,
        productProfileListMeta
    };
};

export const getProductProfileStart = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_PROFILE_START,
    };
};

export const getProductProfileSuccess = (data, clear) => {
    return {
        type: actionTypes.GET_PRODUCT_PROFILE_SUCCESS,
        data,
        clear
    };
};

export const saveProductProfileStart = (data) => {
    return {
        type: actionTypes.SAVE_PRODUCT_PROFILE_START,
    };
};

export const saveProductProfileSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_PRODUCT_PROFILE_SUCCESS,
        message,
        error
    };
};

export const deleteProductProfileSuccess = (message) => {
    return {
        type: actionTypes.DELETE_PRODUCT_PROFILE_SUCCESS,
        message,
    };
};
export const getProductProfileList = (data) => async (dispatch) => {
    let params = '';
    if(data?.companyId){
        params+=`company_id=${data.companyId}&`;
    }
    if(data?.page){
        params+=`page=${data.page}&`;
    }
    if(data?.name){
        params+=`name=${data.name}`;
    }
    let requestUrl = apis.configuration.get_product_profile_list(params);
    dispatch(getProductProfileListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getProductProfileListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getProductProfileListSuccess(response.data.data, undefined, undefined, undefined, undefined, response.data.meta));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getProductProfileListSuccess([]));
            }
        }
    });
};

export const getProductProfile = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_product_profile(data.profileID);
    dispatch(getProductProfileStart());

    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getProductProfileSuccess(response.response.data.error));
        } else {
            
            if (response.data && response.data.data) {
                const DATA = {config: {...response.data.data}};

                dispatch(getProductProfileSuccess(DATA));
                
                if(DATA && data.onComplete) {
                    data.onComplete(DATA);
                }
            }
                        
        }
    });
};

export const saveProductProfile = (data) => async (dispatch) => {
    dispatch(saveProductProfileStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = {...data.productProfileDetails};
    const requestUrl = apis.configuration.save_product_profile(DATA.id ? `/${DATA.id}`: '');
    
    /** Remove profile ID from payload if its new **/    
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    //console.log('----> ',DATA);
    
    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveProductProfileSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(saveProductProfileSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveProductProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteProductProfile = (data) => 
    async function (dispatch) {
        dispatch(saveProductProfileStart());
        let requestUrl = apis.configuration.delete_product_profile(data.id);
        
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_config_profiles_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteProductProfileSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response && response.data) {
                    dispatch(deleteProductProfileSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };    


export const getProductTypeListStart = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_TYPE_LIST_START
    };
};

export const getProductTypeListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_PRODUCT_TYPE_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const saveProductTypeStart = (data) => {
    return {
        type: actionTypes.SAVE_PRODUCT_TYPE_START,
    };
};

export const saveProductTypeSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_PRODUCT_TYPE_SUCCESS,
        message,
        error
    };
};

export const deleteProductTypeSuccess = (message) => {
    return {
        type: actionTypes.DELETE_PRODUCT_TYPE_SUCCESS,
        message,
    };
};
export const getProductTypeList = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_product_type_list(data.companyId);
    dispatch(getProductTypeListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getProductTypeListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getProductTypeListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getProductTypeListSuccess([]));
            }
        }
    });
};

export const saveProductType = (data) => async (dispatch) => {
    dispatch(saveProductTypeStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = {...data.data};
    const requestUrl = apis.configuration.save_product_type(DATA.id ? `/${DATA.id}` : '');
    
    /** Remove profile ID from payload if its new **/
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    //console.log('----> ',DATA);
    
    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveProductTypeSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(saveProductTypeSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveProductTypeSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteProductType = (data) => 
    async function (dispatch) {
        dispatch(saveProductTypeStart());
        let requestUrl = apis.configuration.delete_product_type(data.id);
        
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_config_profiles_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteProductTypeSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deleteProductTypeSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getProductTypeStart = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_TYPE_START
    };
};

export const getProductTypeSuccess = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_TYPE_SUCCESS,
        data,
    };
};

export const getProductType = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_product_type(data.id);
    dispatch(getProductTypeStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getProductTypeSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getProductTypeListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getProductTypeListSuccess({}));
            }
        }
    });
};



export const getShipmentProfileListStart = (data) => {
    return {
        type: actionTypes.GET_SHIPMENT_PROFILE_LIST_START
    };
};

export const getShipmentProfileListSuccess = (data, clear, updatedObj, status, deleteAction) => {
    return {
        type: actionTypes.GET_SHIPMENT_PROFILE_LIST_SUCCESS,
        data,
        clear,
        updatedObj, 
        status,
        deleteAction,
    };
};

export const getShipmentProfileStart = (data) => {
    return {
        type: actionTypes.GET_SHIPMENT_PROFILE_START,
    };
};

export const getShipmentProfileSuccess = (data, clear) => {
    return {
        type: actionTypes.GET_SHIPMENT_PROFILE_SUCCESS,
        data,
        clear
    };
};

export const saveShipmentProfileStart = (data) => {
    return {
        type: actionTypes.SAVE_SHIPMENT_PROFILE_START,
    };
};

export const saveShipmentProfileSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_SHIPMENT_PROFILE_SUCCESS,
        message,
        error
    };
};

export const deleteShipmentProfileSuccess = (message) => {
    return {
        type: actionTypes.DELETE_SHIPMENT_PROFILE_SUCCESS,
        message,
    };
};
export const getShipmentProfileList = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_shipment_profile_list(data.companyId);
    dispatch(getShipmentProfileListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getShipmentProfileListSuccess([]));
            if(data.onComplete) {
                data.onComplete([]);
            }
        } else {
            if (response.data && response.data.data) {
                dispatch(getShipmentProfileListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getShipmentProfileListSuccess([]));
                if(data.onComplete) {
                    data.onComplete([]);
                }
            }
        }
    });
};

export const getShipmentProfile = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_shipment_profile(data.id);
    dispatch(getShipmentProfileStart());

    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getShipmentProfileSuccess(response.response.data.error));
        } else {
            
            if (response.data && response.data.data) {
                const DATA = {config: {...response.data.data}};

                dispatch(getShipmentProfileSuccess(DATA));
                
                if(DATA && data.onComplete) {
                    data.onComplete(DATA);
                }
            }
                        
        }
    });
};

export const saveShipmentProfile = (data) => async (dispatch) => {
    dispatch(saveShipmentProfileStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = {...data.shipmentProfileDetails};
    const requestUrl = apis.configuration.save_shipment_profile(DATA.id ? `/${DATA.id}`: '');
    
    /** Remove profile ID from payload if its new **/    
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    //console.log('-saveShipmentProfile---> ',DATA);
    
    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log('response:...... ', response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveShipmentProfileSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(saveShipmentProfileSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveShipmentProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteShipmentProfile = (data) => 
    async function (dispatch) {
        dispatch(saveShipmentProfileStart());
        let requestUrl = apis.configuration.delete_shipment_profile(data.id);
        
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_config_profiles_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteShipmentProfileSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response && response.data) {
                    dispatch(deleteShipmentProfileSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };

export const getCarrierTypeListStart = (data) => {
    return {
        type: actionTypes.GET_CARRIER_TYPE_LIST_START
    };
};

export const getCarrierTypeListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_CARRIER_TYPE_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const saveCarrierTypeStart = (data) => {
    return {
        type: actionTypes.SAVE_CARRIER_TYPE_START,
    };
};

export const saveCarrierTypeSuccess = (data, error) => {
    return {
        type: actionTypes.SAVE_CARRIER_TYPE_SUCCESS,
        data,
        error
    };
};

export const deleteCarrierTypeSuccess = (message) => {
    return {
        type: actionTypes.DELETE_CARRIER_TYPE_SUCCESS,
        message,
    };
};
export const getCarrierTypeList = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_carrier_type_list;
    dispatch(getCarrierTypeListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getProductTypeListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getCarrierTypeListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getCarrierTypeListSuccess([]));
            }
        }
    });
};

export const saveCarrierType = (data) => async (dispatch) => {
    dispatch(saveCarrierTypeStart());

    const DATA = {...data};
    const requestUrl = apis.configuration.save_carrier_type(DATA.id ? `/${DATA.id}` : '');
    
    /** Remove profile ID from payload if its new **/
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    //console.log('----> ',DATA);
    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log('-------------------> ', response, response.data, response.response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveCarrierTypeSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                if(msg.data.id){}
                else{
                    msg.data.id = DATA.id;
                }
                dispatch(saveCarrierTypeSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveCarrierTypeSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteCarrierType = (data) => 
    async function (dispatch) {
        dispatch(saveCarrierTypeStart());
        let requestUrl = apis.configuration.delete_carrier_type(data.id);
        
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_config_profiles_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteCarrierTypeSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deleteCarrierTypeSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getCarrierTypeStart = (data) => {
    return {
        type: actionTypes.GET_CARRIER_TYPE_START
    };
};

export const getCarrierTypeSuccess = (data) => {
    return {
        type: actionTypes.GET_CARRIER_TYPE_SUCCESS,
        data,
    };
};

export const getCarrierType = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_carrier_type(data.id);
    dispatch(getCarrierTypeStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getCarrierTypeSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getCarrierTypeListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getCarrierTypeListSuccess({}));
            }
        }
    });
};

export const getLaneIdListStart = (data) => {
    return {
        type: actionTypes.GET_LANE_ID_LIST_START
    };
};

export const getLaneIdListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_LANE_ID_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const saveLaneIdStart = (data) => {
    return {
        type: actionTypes.SAVE_LANE_ID_START,
    };
};

export const saveLaneIdSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_LANE_ID_SUCCESS,
        message,
        error
    };
};

export const deleteLaneIdSuccess = (message) => {
    return {
        type: actionTypes.DELETE_LANE_ID_SUCCESS,
        message,
    };
};
export const getLaneIdList = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_lane_id_list(data.companyId);
    dispatch(getLaneIdListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getLaneIdListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getLaneIdListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getLaneIdListSuccess([]));
            }
        }
    });
};

export const saveLaneId = (data) => async (dispatch) => {
    dispatch(saveLaneIdStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = {...data.data};
    const requestUrl = apis.configuration.save_lane_id(DATA.id ? `/${DATA.id}` : '');
    
    /** Remove profile ID from payload if its new **/
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    //console.log('----> ',DATA);
    
    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveLaneIdSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(saveLaneIdSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveLaneIdSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteLaneId = (data) => 
    async function (dispatch) {
        dispatch(saveLaneIdStart());
        let requestUrl = apis.configuration.delete_lane_id(data.id);
        
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_config_profiles_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteLaneIdSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deleteLaneIdSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getLaneIdStart = (data) => {
    return {
        type: actionTypes.GET_LANE_ID_START
    };
};

export const getLaneIdSuccess = (data) => {
    return {
        type: actionTypes.GET_LANE_ID_SUCCESS,
        data,
    };
};

export const getLaneId = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_lane_id(data.id);
    dispatch(getLaneIdStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getLaneIdSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getLaneIdListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getLaneIdListSuccess({}));
            }
        }
    });
};

export const getPackageTypeListStart = (data) => {
    return {
        type: actionTypes.GET_PACKAGE_TYPE_LIST_START
    };
};

export const getPackageTypeListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_PACKAGE_TYPE_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const savePackageTypeStart = (data) => {
    return {
        type: actionTypes.SAVE_PACKAGE_TYPE_START,
    };
};

export const savePackageTypeSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_PACKAGE_TYPE_SUCCESS,
        message,
        error
    };
};

export const deletePackageTypeSuccess = (message) => {
    return {
        type: actionTypes.DELETE_PACKAGE_TYPE_SUCCESS,
        message,
    };
};
export const getPackageTypeList = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_package_type_list(data.companyId);
    dispatch(getPackageTypeListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPackageTypeListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPackageTypeListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPackageTypeListSuccess([]));
            }
        }
    });
};

export const savePackageType = (data) => async (dispatch) => {
    dispatch(savePackageTypeStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = {...data.data};
    const requestUrl = apis.configuration.save_package_type(DATA.id ? `/${DATA.id}` : '');
    
    /** Remove profile ID from payload if its new **/
    if(DATA.id){}
    else {
        delete(DATA.id);
    }
    
    //console.log('----> ',DATA);
    
    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(savePackageTypeSuccess(response.response.data, true));
            if(data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(savePackageTypeSuccess(msg));
                if(data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(savePackageTypeSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deletePackageType = (data) => 
    async function (dispatch) {
        dispatch(savePackageTypeStart());
        let requestUrl = apis.configuration.delete_package_type(data.id);
        
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_config_profiles_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deletePackageTypeSuccess(response.response.data.message));
                if(data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deletePackageTypeSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getPackageTypeStart = (data) => {
    return {
        type: actionTypes.GET_PACKAGE_TYPE_START
    };
};

export const getPackageTypeSuccess = (data) => {
    return {
        type: actionTypes.GET_PACKAGE_TYPE_SUCCESS,
        data,
    };
};

export const getPackageType = (data) => async (dispatch) => {
    let requestUrl = apis.configuration.get_package_type(data.id);
    dispatch(getPackageTypeStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_config_profiles_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPackageTypeSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPackageTypeListSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPackageTypeListSuccess({}));
            }
        }
    });
};
