import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const intialState = {
    users: [],
    loading: true,
    error: false,
    selectedUser: null,
    searchedUser: null,
    profileMessage: '',
    disableMessage: '',
    shipmentMessage: '',
    activateMessage: '',
    enableDisableMessage: '',
    errors: {},
};

const getAllUsersListStart = state => {
    return updateObject(state, {
        loading: true,
        error: false,
    });
};
const getAllUsersListSuccess = (state, action) => {
    return updateObject(state, {
        loading: action.flag,
        users: action.list,
    });
};
const getAllUsersListFailed = state => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const createUserStart = state => {
    return updateObject(state, {
        loading: true,
        error: false,
    });
};
const createUserSuccess = (state, action) => {
    const data = Object.assign({}, action.data);
    return updateObject(state, {
        loading: false,
        data,
    });
};
const createUserFailed = state => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};
const updateUserStart = state => {
    return updateObject(state, {
        loading: true,
        error: false,
    });
};
const updateUserSuccess = (state, action) => {
    let users_temporary = state.users.map(usr => {
        if (action.user.id === usr.id) {
            return { ...action.user };
        }
        return usr;
    });
    return updateObject(state, {
        loading: false,
        selectedUser: action.user,
        users: Array.from(users_temporary),
    });
};
const updateUserFailed = state => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};
// const searchUserStart = (state) => {
//     return updateObject(state, {
//         loading: true,
//         error: false,
//     });
// };
// const searchUserSuccess = (state, action) => {
//     let search_user = state.users.map((usr) => {
//         if (action.user.id === usr.id) {
//             return usr;
//         }
//     });
//     return updateObject(state, {
//         loading: false,
//         searchedUser: search_user,
//     });
// };

// const searchUserFailed = (state, action) => {
//     return updateObject(state, {
//         loading: false,
//         error: true,
//     });
// };
const disableUserStart = state => {
    return updateObject(state, {
        loading: true,
        error: false,
    });
};
const disableUserSuccess = (state, action) => {
    return updateObject(state, {
        disableMessage: action.message,
    });
};
const disableUserFailed = state => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};

const bulkUploadUsersStart = state => {
    return updateObject(state, {
        loading: true,
        error: false,
    });
};

const multiDisableUsersStart = state => {
    return updateObject(state, {
        loading: true,
        error: false,
    });
};

const multiDisableUsersSuccess = (state, action) => {
    const data = Object.assign({}, action.data);
    return updateObject(state, {
        loading: false,
        data,
    });
};

const multiDisableUsersFailed = state => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};
const bulkUploadUsersSuccess = (state, action) => {
    const data = Object.assign({}, action.data);
    return updateObject(state, { loading: false, data });
};
const bulkUploadUsersFailed = state => {
    return updateObject(state, {
        loading: false,
        error: true,
    });
};
const sendUserProfileSuccess = (state, action) => {
    return updateObject(state, { profileMessage: action.message, errors: action.errors });
};
const sendShipmentStatisticsMessage = (state, action) => {
    return updateObject(state, { shipmentMessage: action.message });
};
const sendActivateUserSuccess = (state, action) => {
    return updateObject(state, { activateMessage: action.message });
};
const sendEnableDisableUserSuccess = (state, action) => {
    return updateObject(state, { enableDisableMessage: action.message });
};
const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_USERS_LIST_START:
            return getAllUsersListStart(state, action);
        case actionTypes.GET_ALL_USERS_LIST_SUCCESS:
            return getAllUsersListSuccess(state, action);
        case actionTypes.GET_ALL_USERS_LIST_FAILED:
            return getAllUsersListFailed(state, action);
        case actionTypes.CREATE_USER_START:
            return createUserStart(state, action);
        case actionTypes.CREATE_USER_SUCCESS:
            return createUserSuccess(state, action);
        case actionTypes.CREATE_USER_FAILED:
            return createUserFailed(state, action);
        case actionTypes.UPDATE_USER_START:
            return updateUserStart(state, action);
        case actionTypes.UPDATE_USER_SUCCESS:
            return updateUserSuccess(state, action);
        case actionTypes.UPDATE_USER_FAILED:
            return updateUserFailed(state, action);
        case actionTypes.DISABLE_USER_START:
            return disableUserStart(state, action);
        case actionTypes.DISABLE_USER_SUCCESS:
            return disableUserSuccess(state, action);
        case actionTypes.DISABLE_USER_FAILED:
            return disableUserFailed(state, action);
        case actionTypes.BULK_UPLOAD_USERS_START:
            return bulkUploadUsersStart(state, action);
        case actionTypes.BULK_UPLOAD_USERS_SUCCESS:
            return bulkUploadUsersSuccess(state, action);
        case actionTypes.BULK_UPLOAD_USERS_FAILED:
            return bulkUploadUsersFailed(state, action);
        case actionTypes.MULTI_DISABLE_USERS_START:
            return multiDisableUsersStart(state, action);
        case actionTypes.MULTI_DISABLE_USERS_SUCCESS:
            return multiDisableUsersSuccess(state, action);
        case actionTypes.MULTI_DISABLE_USERS_FAILED:
            return multiDisableUsersFailed(state, action);
        case actionTypes.GET_USER_UPDATE_PROFILE_SUCCESS:
            return sendUserProfileSuccess(state, action);
        case actionTypes.USER_SHIPMENT_STATISTICS:
            return sendShipmentStatisticsMessage(state, action);
        case actionTypes.ACTIVATE_USER_SUCCESS:
            return sendActivateUserSuccess(state, action);
        case actionTypes.ENABLE_DISABLE_USER_SUCCESS:
            return sendEnableDisableUserSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
