import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const intialState = {    
    assetListLoading: false,
    assetList: [],
    assetDetailsLoading: false,
    assetDetails: [],
    geofenceListLoading: false,
    geofenceList: [],
    updateDevice: {},
    updateGeofences: {},
    productTypesLoading: false,
    productTypes: [],
};
const getCompanyDevicesStart = (state, action) => {
    return updateObject(state, { assetListLoading: true });
};
const getCompanyDevicesSuccess = (state, action) => {
    const assetList = [...action.data];
    const DUP_DATA = state.assetListData;
    return updateObject(state, { 
        assetListLoading: false, 
        assetList, 
        assetListData: action.clear ? DUP_DATA : [...assetList] });
};
const getCompanyDevicesStatusSuccess = (state, action) => {
    const assetListData = [...state.assetListData];
    const assetList = [...state.assetList];
    const assetDetails = [...state.assetDetails];
    assetList.forEach(device => {
        if(device.deviceName && action.data[device.deviceName]) {
            device.status = action.data[device.deviceName];
        } else {
            device.status = 'disconnected';
        }
    });
    assetListData.forEach(device => {
        if(device.deviceName && action.data[device.deviceName]) {
            device.status = action.data[device.deviceName];
        } else {
            device.status = 'disconnected';
        }
    });
    //console.log('action.refenceTimestamp: ', action.refenceTimestamp);
    /*START: CLEARING OLDER EVENTS*/
    const olderEventsIndex = assetDetails.findIndex(event => {
        if(event.timestamp < action.refenceTimestamp) {
            return true;
        }
    });
    
    if(olderEventsIndex >= 0) {
        assetDetails.splice(olderEventsIndex);
    }
    /*END: CLEARING OLDER EVENTS*/
    return updateObject(state, { 
        assetList: assetList, 
        assetListData,
        assetDetails
    });
};
const updateAssetLiveData = (state, action) => {
    const assetListData = [...state.assetListData];
    const assetList = [...state.assetList];

    assetListData.forEach((device, deviceIndex) => {
        const deviceIndexInList = assetList.findIndex(device => device.deviceName === action.data.deviceName);
        if(device.deviceName === action.data.deviceName) {
            assetListData[deviceIndex].status = action.data.statuses[device.deviceName].status || 'connected';
            if(deviceIndexInList >= 0){
                assetList[deviceIndexInList].status = action.data.statuses[device.deviceName].status || 'connected';
            }
            assetListData[deviceIndex].battery = action.data.statuses[device.deviceName].battery || 0;
            if(deviceIndexInList >= 0){
                assetList[deviceIndexInList].battery = action.data.statuses[device.deviceName].battery || 0;
            }

            if(action.data.timestamp) {
                assetListData[deviceIndex].timestamp = action.data.timestamp;
                if(deviceIndexInList >= 0){
                    assetList[deviceIndexInList].timestamp = action.data.timestamp;
                }
            }
            if(action.data.placeName) {
                assetListData[deviceIndex].placeName = action.data.placeName;
                if(deviceIndexInList >= 0){
                    assetList[deviceIndexInList].placeName = action.data.placeName;
                }
            }
            if(action.data.shortText) {
                assetListData[deviceIndex].shortText = action.data.shortText;
                if(deviceIndexInList >= 0){
                    assetList[deviceIndexInList].shortText = action.data.shortText;
                }
            }
            if(action.data.latitude) {
                assetListData[deviceIndex].latitude = action.data.latitude;
                if(deviceIndexInList >= 0){
                    assetList[deviceIndexInList].latitude = action.data.latitude;
                }
            }
            if(action.data.longitude) {
                assetListData[deviceIndex].longitude = action.data.longitude;
                if(deviceIndexInList >= 0){
                    assetList[deviceIndexInList].longitude = action.data.longitude;
                }
            }
        } else {
            const nonActiveDeviceIndex = assetList.findIndex(d => d.deviceName === device.deviceName);
            if(action.data.statuses[device.deviceName]) {
                assetListData[deviceIndex].status = action.data.statuses[device.deviceName].status || 'disconnected';
                if(nonActiveDeviceIndex >= 0){
                    assetList[nonActiveDeviceIndex].status = action.data.statuses[device.deviceName].status || 'disconnected';
                }
                assetListData[deviceIndex].battery = action.data.statuses[device.deviceName].battery || 'disconnected';
                if(nonActiveDeviceIndex >= 0){
                    assetList[nonActiveDeviceIndex].battery = action.data.statuses[device.deviceName].battery || 'disconnected';
                }
            } else {
                assetListData[deviceIndex].status = 'disconnected';
                if(nonActiveDeviceIndex >= 0){
                    assetList[nonActiveDeviceIndex].status = 'disconnected';
                }
                assetListData[deviceIndex].battery = 100;
                if(nonActiveDeviceIndex >= 0){
                    assetList[nonActiveDeviceIndex].battery = 100;
                }
            }
        }        
    });

    /*START: CLEARING OLDER EVENTS*/
    const assetDetails = [...state.assetDetails];
    //assetDetails.unshift(action.data);
 
    const olderEventsIndex = assetDetails.findIndex(event => {
        if(event.timestamp < action.refenceTimestamp) {
            return true;
        }
    });

    if(olderEventsIndex >= 0) {
        assetDetails.splice(olderEventsIndex);
    }
    /*END: CLEARING OLDER EVENTS*/

    return updateObject(state, { 
        assetList, 
        assetListData,
        assetDetails
    });
};
const getDeviceDetailsStart = (state, action) => {
    return updateObject(state, { assetDetailsLoading: true });
};
const getDeviceDetailsSuccess = (state, action) => {
    const assetDetails = Object.values(action.data);
    return updateObject(state, { assetDetailsLoading: false, assetDetails });
};
const getCompanyGeofencesStart = (state, action) => {
    return updateObject(state, { geofenceListLoading: true });
};
const getCompanyGeofencesSuccess = (state, action) => {
    return updateObject(state, { geofenceListLoading: false, geofenceList: action.data });
};
const updateDeviceDetailsStart = (state, action) => {
    return updateObject(state, { updateDeviceLoading: true });
};
const updateDeviceDetailsSuccess = (state, action) => {
    return updateObject(state, { updateDeviceLoading: false, updateDevice: action.data });
};
const updateCompanyGeofencesStart = (state, action) => {
    return updateObject(state, { updateGeofencesLoading: true });
};
const updateCompanyGeofencesSuccess = (state, action) => {
    return updateObject(state, { updateGeofencesLoading: false, geofenceList: action.data });
};
const getProductTypesStart = (state, action) => {
    return updateObject(state, { productTypesLoading: true });
};
const getProductTypesSuccess = (state, action) => {
    return updateObject(state, { productTypesLoading: false, productTypes: action.data });
};
const updateProductTypeStart = (state, action) => {
    return updateObject(state, { updateProductTypeLoading: true });
};
const updateProductTypeSuccess = (state, action) => {
    return updateObject(state, { updateProductTypeLoading: false, productTypes: action.data });
};


const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_DEVICES_TRACKING_START:
            return getCompanyDevicesStart(state, action);
        case actionTypes.GET_COMPANY_DEVICES_TRACKING_SUCCESS:
            return getCompanyDevicesSuccess(state, action);
        case actionTypes.GET_COMPANY_DEVICES_TRACKING_STATUS_SUCCESS:
            return getCompanyDevicesStatusSuccess(state, action);
        case actionTypes.GET_DEVICE_DETAILS_START:
            return getDeviceDetailsStart(state, action);
        case actionTypes.GET_DEVICE_DETAILS_SUCCESS:
            return getDeviceDetailsSuccess(state, action);
        case actionTypes.GET_COMPANY_GEOFENCES_START:
            return getCompanyGeofencesStart(state, action);
        case actionTypes.GET_COMPANY_GEOFENCES_SUCCESS:
            return getCompanyGeofencesSuccess(state, action);
        case actionTypes.UPDATE_DEVICE_DETAILS_START:
            return updateDeviceDetailsStart(state, action);
        case actionTypes.UPDATE_DEVICE_DETAILS_SUCCESS:
            return updateDeviceDetailsSuccess(state, action);
        case actionTypes.UPDATE_COMPANY_GEOFENCES_START:
            return updateCompanyGeofencesStart(state, action);
        case actionTypes.UPDATE_ASSET_LIVE_DATA:
            return updateAssetLiveData(state, action);
        case actionTypes.UPDATE_COMPANY_GEOFENCES_SUCCESS:
            return updateCompanyGeofencesSuccess(state, action);
        case actionTypes.GET_PRODUCT_TYPES_START:
            return getProductTypesStart(state, action);
        case actionTypes.GET_PRODUCT_TYPES_SUCCESS:
            return getProductTypesSuccess(state, action);
        case actionTypes.UPDATE_PRODUCT_TYPE_START:
            return updateProductTypeStart(state, action);
        case actionTypes.UPDATE_PRODUCT_TYPE_SUCCESS:
            return updateProductTypeSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
