import React, { useState, useEffect, useRef } from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from '../ScrollBarHOC/scrollBarHOC';

const SelectBox = (props) => {

    const ref = useRef(null);
    const [state, setState] = useState({show: false, value: props.value, label: ''});
    const list = props.list || [];
    const disable = props.disable;

    useEffect((a) => {
        const handleClickOutside = (event) => {
            // console.log('props.value:.........>>>>>>>>> ', a, props.value);
            if (ref.current && !ref.current.contains(event.target)) {
                const selectedValue = list.filter(l => String(l.id) === String(state.value));
                const label = selectedValue[0]?.label || 'Select';
                setState({ show: false, label });
                if (props.onChange && typeof props.onChange === 'function' && props.multiple) {
                    props.onChange(props.value, true);
                }
            }
        };
        if (state.show) {
            document.addEventListener('click', handleClickOutside, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [state.show]);

    const selectClick = async (e) => {
        if(typeof (props.selectBoxClick) === 'function' && props.selectBoxClick){
            props.selectBoxClick();
        }
        e.stopPropagation();
        if(disable) return false;
        const targetClassName = e.target.className;
        //console.log('dataset: ', e.target.dataset);
        //console.log('targetClassName: ', targetClassName);
        //console.log('show...', state.show);
        //debugger;
        if (targetClassName.indexOf('sel-value')===0) {
            //console.log('clicked on select box value...', state.show);
            const show = state.show;
            if (show) {
                //console.log('already showing........');
                // document.body.removeEventListener('click', handleBodyClick);
            } else {
                const SEL_BOX = e.target?.closest('.sel-box');
                // document.body.addEventListener('click', handleBodyClick);
                await setState({ show: true, label: '' });

                if(props.list?.length > 4 || props.apiSearch){
                    const SEL_IN = SEL_BOX?.querySelectorAll('.auto-s');
                    if(SEL_IN?.length){
                        SEL_IN[0].focus();
                    }
                }              
            }
        } else if ((targetClassName.indexOf('sel-item')>=0 && targetClassName.indexOf('grp-hdr')===-1 && (props.multiple ? props.closeOnSelect : true)) || (props.multiple && targetClassName.indexOf('sel-item')===0)) {
            //console.log('clicked on select box item...');
            //console.log(e.target,  e.target.dataset);
            //console.log(list);
            const index = list.filter(item => String(item.id) === e.target.dataset.id);
            const obj = {
                id: e.target.dataset.id,
                text: e.target.innerText,
                obj: index[0],
            };
            setState({ ...state, show: props.multiple&&!props.closeOnSelect?true:false, value: obj.id, label: props.multiple?'':obj.text });
            if (props.onChange && typeof props.onChange === 'function' && !obj.obj.disable) {
                props.onChange(obj);
            }
            //document.body.addEventListener('click', handleBodyClick);
        } else {
            return false;
        }
    };

    //console.log('VALUE: ', props, state);
    let index = 0;
    if(props.useProps) {
        if(props.multiple && Array.isArray(props.value)) {
            index = list.filter(l => props.value.indexOf(l.id) >= 0 ).map(s => s.label).join('-|-')
        } else {
            index = list.findIndex((d) => {
                return d.hasOwnProperty('id') && props.hasOwnProperty('value') && (d.id+'').toLowerCase() === ((props.value+'').toLowerCase());
            });
        }
        
    } else {
        if(props.multiple && Array.isArray(state.value)) {
            index = list.filter(l => state.value.indexOf(l.id) >= 0 ).map(s => s.label).join('-|-')
        } else {
            index = list.findIndex((d) => {
                return d.hasOwnProperty('id') && state.hasOwnProperty('value') && d.id.toString().toLowerCase() === (state.value.toString().toLowerCase());
            });
        }
        
    }

    const defaultValue = isNaN(index) && index.indexOf('-|-') ? index.replace('-|-', ',') : index >= 0 ? list[index]?.label : (props.defaultLabel||'Select');
    const LIST = list.filter(l => l.label?.toLowerCase().indexOf(state.label?.toLowerCase())>=0);
    if(props.apiSearch && props.apiResultsLoading){
        LIST.unshift({label: 'Loading...', id: 'none-0'});
    } else if(props.apiSearch && props.list?.length === 0) {
        LIST.push({label: 'No options found...', id: 'none-0'});
    } else {
        if(props.list?.length && props.list?.length > 4 && LIST?.length === 0) {
            LIST.push({label: 'No options found...', id: 'none-0'});
        }
    }
    const uid = `sel_box_${new Date().getTime()}`;

    let groupName = null;
    let newGroup = null;

    return (
        <>
            <div ref={ref} className={`sel-box ${props.css}${props.disable ? ' disable' : ''}${state.show ? ' open': ''}`} style={props.style} onClick={selectClick}>
                {props.loading ? <div className="loading abs" style={{top: '-2px'}}/> : <></>}
                {(props.list?.length > 4 || props.apiSearch) && state.show ?
                <div htmlFor={uid} className="sel-value redesign-srch" title={props.showTitle?defaultValue:''}>
                    <input 
                        id={uid}
                        type="text"
                        className={'auto-s'} 
                        value={state.label === null || state.label === undefined ? defaultValue : state.label}
                        onChange={e => {
                            if (props.searchOnChange && typeof props.searchOnChange === 'function') {
                                props.searchOnChange(e.target.value);
                            }
                            setState({ ...state, label: e.target.value, show: true});
                        }}
                        placeholder={defaultValue}
                        disabled={props.disable}
                    />
                </div>
                :
                <div className="sel-value" title={props.showTitle?defaultValue:''}>{defaultValue}</div>
                }
                {state.show ? (
                    <div className={`sel-box-pop${props.popCss ?` ${props.popCss}`: ''}${LIST?.length > 6 ? ' h-200' : ''}`}>
                        <PerfectScrollbar>
                        {
                            props.apiResultsLoading ?
                            <div className='loading slb-h'></div>
                            :
                            <></> 
                        }
                        {LIST.map((l, i) => {
                            //console.log(props.value === l.id, props.value, l.id);
                            if(props.groupBy) {
                                if(!groupName || groupName !== l[props.groupBy]) {
                                    groupName = l[props.groupBy];
                                    newGroup = true;
                                } else {
                                    newGroup = false;
                                }
                            }
                            return (
                                l.hasOwnProperty('label') && l.hasOwnProperty('id')?
                                <>
                                {
                                    props.groupBy && newGroup ?
                                    <div
                                        className={`sel-item n-o grp-hdr`}
                                        data-id={`g-${l.id}`}
                                        data-obj={l}
                                        data-index={i}
                                        data-test-id={groupName}
                                        key={`sel-box-group-${l.label}-${l.id}-${i}`}
                                        title={props.showTitle && groupName?.length>30?groupName:''}
                                    >
                                        {groupName}
                                    </div>
                                    : <></>
                                }
                                <div
                                    className={`sel-item${(props.multiple && Array.isArray(props.value) ? props.value.indexOf(l.id) >= 0 : (props.useProps ? String(props.value) === String(l.id) : String(l.id) === String(state.value))) ? ` check` : ''}${l.id === 'none-0'||l.disable ? ' n-o': ''}${props.groupBy ? ' grp-itm': ''}`}
                                    data-id={l.id}
                                    data-obj={l}
                                    data-index={i}
                                    data-test-id={l.label}
                                    key={`sel-box-${l.label}-${l.label}-${i}`}
                                    data-label={l.label}
                                    title={props.showTitle && l.label?.length>10?l.label:''}
                                >
                                    {l.label}
                                </div>
                                </>
                                : ''
                            );
                        })}
                        </PerfectScrollbar>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
}

export default SelectBox;