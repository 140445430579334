import React from 'react';
const SessionExpired = (props) => {
    return (
        <>
        <div className="n-w-icn"></div>
        <div className="e-s">
            <div className="r-h4">Oops!</div>
            <div className="r-h4">Session Expired</div>
        </div>

        <div className="r-b-small">We're sorry, but your session has expired, please login again.</div>

        <div className='df-sb'>
            <div 
                className={`svr-err-btn n-b db-btn`}
                onClick={e => {
                    e.stopPropagation();
                    props.onChange();
                }}
                style={{marginTop:'20%',display:'inline-block'}}
            >Logout</div>
        </div> 
        </>
    )
};
export default SessionExpired;