import * as actionTypes from './actionTypes';
import { AXIOS } from '../../utility/Axios';
import { getNewToken } from './assetTracking';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;
const ENV = parseInt(process.env.REACT_APP_CUSTOMER_ID,10);
export const getCompanyListSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANIES_SUCCESS,
        data,
    };
};

export const getCompanyListStart = () => {
    return {
        type: actionTypes.GET_COMPANIES_START,
    };
};

export const getCompanyUserListStart = () => {
    return {
        type: actionTypes.GET_COMPANY_USER_LIST_START,
    };
};

export const getCompanyUsersSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_USERS_SUCCESS,
        data,
    };
};

export const addCompanyStart = () => {
    return {
        type: actionTypes.ADD_NEW_COMPANY_START,
    };
};

export const addCompanySuccess = (errors, flag, message) => {
    return {
        type: actionTypes.ADD_NEW_COMPANY_SUCCESS,
        errors,
        flag,
        message        
    };
};

export const getCompanyDevicesListstart = () => {
    return {
        type: actionTypes.GET_COMPANY_DEVICE_LIST_START,
    };
};

export const getCompanyDevicesListSuccess = (list, flag) => {
    return {
        type: actionTypes.GET_COMPANY_DEVICES_SUCCESS,
        list,
        flag,
    };
};

export const companyShipmentCountSuccess = (message) => {
    return {
        type: actionTypes.GET_COMPANY_SHIPMENT_COUNT_SUCCESS,
        message,
    };
};
export const deleteCompanySuccess = (message) => {
    return {
        type: actionTypes.DELETE_COMPANY_SUCCESS,
        message,
    };
};
export const sendLogout = (flag) => {
    return {
        type: actionTypes.USER_LOGOUT,
        flag,
    };
};
export const sendNetworkError = (flag) => {
    return {
        type: actionTypes.NO_NETWORK_CONNECTION,
        flag,
    };
};
export const sendInternalServerError = (flag) => {
    return {
        type: actionTypes.INTERNAL_SERVER_ERROR,
        flag,
    };
};

export const companyDetailsSuccess = (loading, data, message) => {
    return {
        type: actionTypes.GET_COMPANY_DETAILS_SUCCESS,
        loading,
        data,
        message,
    };
};
export const companyDetailsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_DETAILS_START,
    };
};
export const getGroupListSuccess = (data) => {
    return {
        type: actionTypes.GET_GROUPS_LIST_SUCCESS,
        data,
    };
};

export const getGroupListStart = () => {
    return {
        type: actionTypes.GET_GROUPS_LIST_START,
    };
};
export const getGroupUserListStart = () => {
    return {
        type: actionTypes.GET_GROUPS_USERS_LIST_START,
    };
};

export const getGroupUsersSuccess = (data) => {
    return {
        type: actionTypes.GET_GROUPS_USERS_SUCCESS,
        data,
    };
};

export const addGroupStart = () => {
    return {
        type: actionTypes.ADD_NEW_GROUP_START,
    };
};

export const addGroupSuccess = (errors, flag, message) => {
    return {
        type: actionTypes.ADD_NEW_GROUP_SUCCESS,
        errors,
        flag,
        message,
    };
};

export const getGroupDevicesListstart = () => {
    return {
        type: actionTypes.GET_GROUPS_DEVICES_LIST_START,
    };
};

export const getGroupDevicesListSuccess = (list, flag) => {
    return {
        type: actionTypes.GET_GROUPS_DEVICES_LIST_SUCCESS,
        list,
        flag,
    };
};

export const getGroupCompaniesListStart = () => {
    return {
        type: actionTypes.GET_GROUPS_COMPANIES_LIST_START,
    };
};

export const getGroupCompaniesListSuccess = (list, flag) => {
    return {
        type: actionTypes.GET_GROUPS_COMPANIES_LIST_SUCCESS,
        list,
        flag,
    };
};

export const groupShipmentCountSuccess = (message) => {
    return {
        type: actionTypes.GET_GROUP_SHIPMENT_COUNT_SUCCESS,
        message,
    };
};
export const deleteGroupSuccess = (message) => {
    return {
        type: actionTypes.DELETE_GROUP_SUCCESS,
        message,
    };
};

export const viewGroupStart = () =>{
    return{
        type:actionTypes.VIEW_GROUP_START
    }
}

export const viewGroupSuccess = (data) =>{
    return{
        type:actionTypes.VIEW_GROUP_SUCCESS,
        data
    }
}
export const assignCompaniesToGroupSuccess = (message)=>{
    return{
        type:actionTypes.ASSIGN_COMPANIES_TO_GROUP_SUCCESS,
        message
    }
}
export const removeAssignCompaniesSuccess = (message)=>{
    return{
        type:actionTypes.REMOVE_ASSIGNED_COMPANIES_SUCCESS,
        message
    }
}
export const makeGroupAdminSuccess = (message)=>{
    return{
        type:actionTypes.MAKE_GROUP_ADMIN_SUCCESS,
        message
    }
}
export const getCompanyFeaturesStart = ()=>{
    return{
        type:actionTypes.GET_COMPANY_FEATURES_START
    }
}
export const getCompanyFeaturesSuccess = (data)=>{
    return{
        type:actionTypes.GET_COMPANY_FEATURES_SUCCESS,
        data
    }
}
export const updateCompanyFeaturesStart = ()=>{
    return{
        type:actionTypes.UPDATE_COMPANY_FEATURES_START
    }
}
export const updateCompanyFeaturesSuccess = (message)=>{
    return{
        type:actionTypes.UPDATE_COMPANY_FEATURES_SUCCESS,
        message
    }
}
export const getCompanyList = (data) =>
    async function (dispatch) {
        dispatch(getCompanyListStart());
        let requestUrl = apis.company.get_company_list(data&&data.detailResponse?'&detailResponse=1':'');
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getCompanyListSuccess([]));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(getCompanyListSuccess(response.data.data));
                    if(data?.onComplete && typeof(data.onComplete) ==='function'){
                        data.onComplete(response.data.data);
                    }
                }
            }
        });
    };

export const getCompanyUserList = (name) =>
    async function (dispatch) {
        dispatch(getCompanyUserListStart());
        let requestUrl = apis.company.get_company_users_list(name);
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getCompanyUsersSuccess([]));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(getCompanyUsersSuccess(response.data.data));
                }
            }
        });
    };

export const addNewCompany = (data) => async (dispatch) => {
    dispatch(addCompanyStart());
    const requestUrl = apis.company.add_new_company;
    await AXIOS({
        method: 'post',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response && response.response.data && response.response.data.errors) {
                dispatch(addCompanySuccess(response.response.data.errors, true, ''));
            } else {
                dispatch(addCompanySuccess({}, true, `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                dispatch(addCompanySuccess({}, false, response.data.message||response.data.data.message, {...response.data.data, ...data}));
            }
        }
    });
};

export const addNewSsoCompany = (data) => async (dispatch) => {
    dispatch(addCompanyStart());
    const requestUrl = apis.company.add_new_sso_company;
    await AXIOS({
        method: 'post',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response && response.response.data && response.response.data.errors) {
                dispatch(addCompanySuccess(response.response.data.errors, true, ''));
            } else {
                dispatch(addCompanySuccess({}, true, `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                dispatch(addCompanySuccess({}, false, response.data.message||response.data.data.message, {...response.data.data, ...data}));
            }
        }
    });
};

export const updateCompany = (data) => async (dispatch) => {
    dispatch(addCompanyStart());
    const requestUrl = apis.company.update_company(data.id);
    await AXIOS({
        method: ENV === 0 ? 'post' : 'put',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response && response.response.data && response.response.data.errors) {
                dispatch(addCompanySuccess(response.response.data.errors, true, ''));
            } else {
                dispatch(addCompanySuccess({}, true, `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                dispatch(addCompanySuccess({}, false, response.data.message||response.data.data));
            } else {
                dispatch(addCompanySuccess({}, true, `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getDevicesByCompany = (company) => async (dispatch) => {
    const requestUrl = apis.company.get_devices_by_company(company);
    dispatch(getCompanyDevicesListstart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getCompanyDevicesListSuccess([], false));
        } else {
            if (response.data && response.data.data) {
                dispatch(getCompanyDevicesListSuccess(response.data.data, false));
            }
        }
    });
};

export const getShipmentCountByCompany = (id) => async (dispatch) => {
    const requestUrl = apis.company.get_shipment_count_by_company(id);
    await AXIOS({
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(companyShipmentCountSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            if (
                parseInt(response.data.data[0].new || 0, 10) ||
                parseInt(response.data.data[0].intransit || 0, 10) ||
                parseInt(response.data.data[0].destination || 0, 10)
            ) {
                dispatch(
                    companyShipmentCountSuccess(
                        'There are active Shipments associated with this Company, please complete the shipments!'
                    )
                );
            } else {
                dispatch(companyShipmentCountSuccess(''));
            }
        }
    });
};

export const deleteCompany = (id) => async (dispatch) => {
    const requestUrl = apis.company.delete_company(id);
    await AXIOS({
        method: 'delete',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(deleteCompanySuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            dispatch(deleteCompanySuccess(''));
        }
    });
};

export const getCompanyDetails = (id) => async (dispatch) => {
    dispatch(companyDetailsStart());
    const requestUrl = apis.company.get_company_details(id);
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(companyDetailsSuccess(false, {}, 'Error while fetching company details'));
        } else {
            if (response.data && response.data.data) {
                dispatch(companyDetailsSuccess(false, response.data.data, ''));
            } else {
                dispatch(companyDetailsSuccess(false, {}, 'Error while fetching company details'));
            }
        }
    });
};
export const getGroupList = () =>
    async function (dispatch) {
        dispatch(getGroupListStart());
        let requestUrl = apis.company.get_group_list;
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                if(window.location.pathname.indexOf('/assets') >= 0) {
                    dispatch(getNewToken({
                        onComplete: () => {
                            dispatch(getGroupList());
                        }
                    }));
                } else {
                    dispatch(sendLogout(true));
                }
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getGroupListSuccess([]));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(getGroupListSuccess(response.data.data));
                }
            }
        });
    };
    export const getGroupUserList = (name) =>
    async function (dispatch) {
        dispatch(getGroupUserListStart());
        let requestUrl = apis.company.get_group_users_list(name);
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getGroupUsersSuccess([]));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(getGroupUsersSuccess(response.data.data));
                }
            }
        });
    };
    export const getCompaniesByGroup = (name) =>
    async function (dispatch) {
        dispatch(getGroupCompaniesListStart());
        let requestUrl = apis.company.get_companies_by_group(name);
        // let requestUrl = 'company/index?limit=50000';
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getGroupCompaniesListSuccess([]));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(getGroupCompaniesListSuccess(response.data.data));                    
                }
            }
        });
    };

export const addNewGroup = (data) => async (dispatch) => {
    dispatch(addGroupStart());
    const requestUrl = apis.company.add_new_group;
    await AXIOS({
        method: 'post',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if ((response.response && response.response.data && (response.response.data.errors||!response.response.data.success))) {
                dispatch(addGroupSuccess(response.response.data.errors||response.response.data.data, true, ''));
            } else {
                dispatch(addGroupSuccess({}, true, `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                //console.log('response.data: ', response.data);
                dispatch(addGroupSuccess({}, false, response.data.message||response.data.data));
            } else if (response.data && response.data.errors) {
                dispatch(addGroupSuccess(response.data.errors, true, ''));
            }
        }
    });
};

export const updateGroup = (data) => async (dispatch) => {
    dispatch(addGroupStart());
    let requestUrl = apis.company.update_group(data.groupId);
    await AXIOS({
        method: ENV===0?'post':'put',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response && response.response.data && response.response.data.errors) {
                dispatch(addGroupSuccess(response.response.data.errors, true, ''));
            } else {
                dispatch(addGroupSuccess({}, true, `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data && response.data.data) {
                dispatch(addGroupSuccess({}, false, response.data.data));
            } else {
                dispatch(addGroupSuccess(response.data.errors, true, ''));
            }
        }
    });
};

export const getDevicesByGroup = (group) => async (dispatch) => {
    const requestUrl = apis.company.get_devices_by_group(group);
    dispatch(getGroupDevicesListstart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getGroupDevicesListSuccess([], false));
        } else {
            if (response.data && response.data.data) {
                dispatch(getGroupDevicesListSuccess(response.data.data, false));
            }
        }
    });
};

export const getShipmentCountByGroup = (id) => async (dispatch) => {
    const requestUrl = apis.company.get_shipment_count_by_group(id);
    await AXIOS({
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(groupShipmentCountSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            if (
                parseInt(response.data.data[0].new || 0, 10) ||
                parseInt(response.data.data[0].intransit || 0, 10) ||
                parseInt(response.data.data[0].destination || 0, 10)
            ) {
                dispatch(
                    groupShipmentCountSuccess(
                        'There are active Shipments associated with this Company, please complete the shipments!'
                    )
                );
            } else {
                dispatch(groupShipmentCountSuccess(''));
            }
        }
    });
};

export const deleteGroup = (id) => async (dispatch) => {
    console.log("ID.........",id)
    const requestUrl = apis.company.delete_group(id);
    await AXIOS({
        method: 'delete',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(deleteGroupSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            dispatch(deleteGroupSuccess(''));
        }
    });
};

export const viewGroup = (groupId) => async (dispatch)=>{
    const requestUrl = apis.company.view_group(groupId);
    dispatch(viewGroupStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(viewGroupSuccess([], false));
        } else {
            if (response.data && response.data.data) {
                dispatch(viewGroupSuccess(response.data.data));
            }
        }
    });
}

export const assignCompaniesToGroup = (data) => async (dispatch)=>{
    let requestUrl = apis.company.assign_company_to_group(data.companyID);
    await AXIOS({
        method: 'POST',
        url: requestUrl,
        data:data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log('response: ', response.message, response.response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if(response.response.data && response.response.data.error) {
                dispatch(assignCompaniesToGroupSuccess(response.response.data.error));
            } else {
                dispatch(assignCompaniesToGroupSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
            
        } else {
            if (response.data && response.data.data) {
                dispatch(assignCompaniesToGroupSuccess('Removed companies successfully from group!'));
            }
        }
    });
}

export const removeAssignedCompany = (data) => async (dispatch)=>{
    let requestUrl = apis.company.remove_company_from_group(data.companyID);
    await AXIOS({
        method: 'DELETE',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        console.log('response: ', response.message, response.response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if(response.response.data && response.response.data.error) {
                dispatch(removeAssignCompaniesSuccess(response.response.data.error));
            } else {
                dispatch(removeAssignCompaniesSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
            
        } else {
            if (response.data && response.data.data) {
                dispatch(removeAssignCompaniesSuccess('Removed companies successfully from group!'));
            }
        }
    });
}

export const makeGroupAdmin = (data) => async (dispatch)=>{
    let requestUrl = apis.company.make_as_group_admin(data.group_id);
    await AXIOS({
        method: 'PUT',
        url: requestUrl,
        data: data,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(makeGroupAdminSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
        } else {
            if (response.data && response.data.errors) {
                dispatch(makeGroupAdminSuccess(response.data.errors.groupAdmin));
            } else
            if (response.data && response.data.data) {
                dispatch(makeGroupAdminSuccess(''));
            }
        }
    });
}

export const getCompanyFeatures = (data) =>
    async function (dispatch) {
        dispatch(getCompanyFeaturesStart());
        let requestUrl = apis.company.get_company_features(data);
        
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getCompanyFeaturesSuccess([]));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(getCompanyFeaturesSuccess(response.data.data));
                }
            }
        });
    };

    export const updateCompanyFeatures = (data) =>
    async function (dispatch) {
        dispatch(updateCompanyFeaturesStart());
        let requestUrl = apis.company.update_company_features(data.data.companyId);
        await AXIOS({
            method: ENV === 0 ? 'POST' : 'PUT',
            url: requestUrl,
            data: ENV===0?data.data:data.data.features,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(updateCompanyFeaturesSuccess(response.response.data?.message||{}));
                if(data.onComplete) {
                    data.onComplete(response.response.data?.message, true);
                }
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(updateCompanyFeaturesSuccess(response.data.message||response.data.data));
                    if(data.onComplete) {
                        data.onComplete(response.data.message||response.data.data, false);
                    }
                }
            }
        });
    };

export const getCompanyProductStatusTemplatesStart = ()=>{
    return{
        type:actionTypes.GET_COMPANY_PRODUCT_STATUS_TEMPLATES_START
    }
}
export const getCompanyProductStatusTemplatesSuccess = (data, clear)=>{
    return{
        type:actionTypes.GET_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS,
        data,
        clear
    }
}
export const updateCompanyProductStatusTemplatesStart = ()=>{
    return{
        type:actionTypes.UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_START
    }
}
export const updateCompanyProductStatusTemplatesSuccess = (message)=>{
    return{
        type:actionTypes.UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS,
        message
    }
}    

export const getCompanyProductStatusTemplates = (data) =>
    async function (dispatch) {
        if(data.clear) {
            dispatch(getCompanyProductStatusTemplatesSuccess({}, true));
            return false;
        }
        dispatch(getCompanyProductStatusTemplatesStart());
        let requestUrl = apis.company.get_company_product_status_template(data.companyId);
        await AXIOS({
            method: 'GET',
            url: requestUrl,
            //Params: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response) {
                dispatch(getCompanyProductStatusTemplatesSuccess({}));
            } else {
                if (response && response.data && response.data.data) {
                    const DATA = response.data.data;
                    dispatch(getCompanyProductStatusTemplatesSuccess(DATA));
                }
            }
        });
    };

export const updateCompanyProductStatusTemplates = (data) =>
    async function (dispatch) {
        dispatch(updateCompanyProductStatusTemplatesStart());
        let requestUrl = apis.company.update_company_product_status_template(data.APPROVE.id ? '/0' : '');
        const DATA = {...data};
        if(DATA.APPROVE?.help_line_number){

        }
        await AXIOS({
            method: data.APPROVE.id ? 'PUT' : 'POST',
            url: requestUrl,
            data: data,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if(response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data) {
                dispatch(updateCompanyProductStatusTemplatesSuccess(response.response.data));
            } else {
                if (response && response.data && response.data.data) {
                    dispatch(updateCompanyProductStatusTemplatesSuccess(response.data));
                    if(data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };    
