import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const intialState = {
    lanesListLoading: true,
    lanesListError: false,
    lanesList: [],
    lanesListMeta: {},
    laneDetailsLoading: false,
    laneDetailsError: false,
    laneDetails: {stability: [], routes:[]},
    laneStabilityLoading: false,
    laneStabilityError: false,
    laneStability: [],
    routeDetailsLoading: false,
    routeDetailsError: false,
    routeDetails: {},
    routeStabilityLoading: false,
    routeStabilityError: false,
    routeStability: [],
    legStabilityLoading: false,
    legStabilityError: false,
    legStability: [],
    laneInsights: [],
    routeInsights: {},
    legInsights: {},
    legInsightsLoading: false,
    insightsMapping: {},
};
let stability = { lane: 0, route: 0, leg: 0,};
const getLanesListStart = state => {
    return updateObject(state, {
        lanesListLoading: true,
        lanesListError: false,
        laneDetails: {stability: [], routes:[]},
    });
};
const getLanesListSuccess = (state, action) => {
    return updateObject(state, {
        lanesListLoading: false,
        lanesList: action.data.data,
        lanesListMeta: action.data.meta,
    });
};
const getLanesListFailed = state => {
    return updateObject(state, {
        lanesListLoading: false,
        lanesListError: true,
    });
};

const getLaneDetailsStart = state => {
    return updateObject(state, {
        laneDetailsLoading: true,
        laneDetailsError: false,
        laneDetails: {stability: [], routes:[]},
    });
};
const getLaneDetailsSuccess = (state, action) => {
    //console.log('--------------lane details: ', action);
    //console.log('1: ', {...state.laneDetails, ...action.data}.routes.map(r=>r.id));
    const lanesList = [...state.lanesList];
    const laneIndex = lanesList.findIndex(l=>l.id === action.data.id);
    if(action.updateToList) {
        const newLaneDetails = {...action.data};

        newLaneDetails.routes.map((route, rIndex) => {
            route.legs.map((leg, lIndex) => {
                leg.lane_name = newLaneDetails.lane_name;
                if(leg.leg_name && leg.leg_name.indexOf('Origin')>=0 && newLaneDetails.origin) {
                    leg.origin = newLaneDetails.origin;
                    leg.leg_name = leg.leg_name.replace('Origin', newLaneDetails.origin);
                }
                if (leg.leg_name && leg.leg_name.indexOf('Destination')>=0 && newLaneDetails.destination) {
                    leg.destination = newLaneDetails.destination;
                    leg.leg_name = leg.leg_name.replace('Destination', newLaneDetails.destination);
                }
            });
        });
        lanesList[laneIndex].lane_details = newLaneDetails;
        
        return updateObject(state, {
            laneDetailsLoading: false,
            lanesList,
        });
    } else {
        /*order legs*/
        const LaneDetails = {...state.laneDetails, ...action.data};
        let RouteDetails = state.routeDetails?.id ? {...state.routeDetails} : {...LaneDetails.routes[0]};
        LaneDetails.routes.map((route, rIndex) => {
            route.legs.map((leg, lIndex) => {
                leg.lane_name = LaneDetails.lane_name;
                if(leg.leg_name && leg.leg_name.indexOf('Origin')>=0 && LaneDetails.origin) {
                    leg.origin = LaneDetails.origin;
                    leg.leg_name = leg.leg_name.replace('Origin', LaneDetails.origin);
                }
                if (leg.leg_name && leg.leg_name.indexOf('Destination')>=0 && LaneDetails.destination) {
                    leg.destination = LaneDetails.destination;
                    leg.leg_name = leg.leg_name.replace('Destination', LaneDetails.destination);
                }
            });
        });

        RouteDetails = LaneDetails.routes[0];

        if(laneIndex>=0 && lanesList[laneIndex]){
            lanesList[laneIndex].lane_details = LaneDetails;
        }
        
        return updateObject(state, {
            laneDetailsLoading: false,
            lanesList,
            laneDetails: {...LaneDetails},
            routeDetails: RouteDetails,
        });
    }
};
const getLaneDetailsFailed = state => {
    return updateObject(state, {
        laneDetailsLoading: false,
        laneDetailsError: true,
    });
};

const getLaneStabilityStart = state => {
    stability.lane++;
    return updateObject(state, {
        laneStabilityLoading: true,
        laneStabilityError: false,
    });
};
const getLaneStabilitySuccess = (state, action) => {
    stability.lane--;
    return updateObject(state, {
        laneStabilityLoading: stability.lane===0?false:true,
        laneStability: action.data,
    });
};
const getLaneStabilityFailed = state => {
    return updateObject(state, {
        laneStabilityLoading: false,
        laneStabilityError: true,
    });
};

const getRouteDetailsStart = state => {
    return updateObject(state, {
        routeDetailsLoading: true,
        routeDetailsError: false,
    });
};
const getRouteDetailsSuccess = (state, action) => {
    let {laneDetails, routeDetails} = state;
    let routeIndex = laneDetails.routes.findIndex(r => Number(r.id) === Number(action.id));
    routeDetails = {...laneDetails.routes[routeIndex]};
    routeIndex = routeIndex >=0 ? routeIndex : 0;
    // console.log('-------------------');
    console.log('route Index:........ ', routeIndex, ' | routes: ', laneDetails.routes, ' | action: ... ', action);
    // console.log('-------------------');
    laneDetails.routes[routeIndex] = { ...laneDetails.routes[routeIndex], ...action.data};
    routeDetails = {...state.routeDetails, ...action.data};
    
    return updateObject(state, {
        routeDetailsLoading: false,
        laneDetails,
        routeDetails,
    });
};
const getRouteDetailsFailed = state => {
    return updateObject(state, {
        routeDetailsLoading: false,
        routeDetailsError: true,
    });
};

const getRouteStabilityStart = state => {
    stability.route++;
    return updateObject(state, {
        routeStabilityLoading: true,
        routeStabilityError: false,
    });
};
const getRouteStabilitySuccess = (state, action) => {
    stability.route--;
    return updateObject(state, {
        routeStabilityLoading: stability.route===0?false:true,
        routeStability: action.data
    });
};
const getRouteStabilityFailed = state => {
    return updateObject(state, {
        routeStabilityLoading: false,
        routeStabilityError: true,
    });
};

const getLegStabilityStart = state => {
    stability.leg++;
    return updateObject(state, {
        legStabilityLoading: true,
        legStabilityError: false,
    });
};
const getLegStabilitySuccess = (state, action) => {
    stability.leg--;
    return updateObject(state, {
        legStabilityLoading: stability.leg===0?false:true,
    });
};
const getLegStabilityFailed = state => {
    return updateObject(state, {
        legStabilityLoading: false,
        legStabilityError: true,
    });
};

const getLaneInsightsStart = state => {
    return updateObject(state, {
        laneInsightsLoading: true,
        laneInsightsError: false,
    });
};
const getLaneInsightsSuccess = (state, action) => {
    const LaneInsights = {...state.laneInsights};
    if(action.del) {
        delete(LaneInsights[action.id]);
    } else {
        LaneInsights[action.id] = action.data.insights;
    }
    console.log('LaneInsights----reducer----------> ', LaneInsights);

    return updateObject(state, {
        laneInsightsLoading: false,
        laneInsightsError: false,
        laneInsights: LaneInsights,
        insightsMapping: action.data.insights_mapping || state.insightsMapping,
    });
};
const getLaneInsightsFailed = state => {
    return updateObject(state, {
        laneInsightsLoading: false,
        laneInsightsError: true,
    });
};

const getRouteInsightsStart = state => {
    return updateObject(state, {
        routeInsightsLoading: true,
        routeInsightsError: false,
    });
};

const getRouteInsightsSuccess = (state, action) => {
    const RouteInsights = {...state.routeInsights};
    if(action.del) {
        delete(RouteInsights[action.id]);
    } else {
        RouteInsights[action.id] = action.data.insights;
        delete(RouteInsights[action.id].dynamic_insights);
    }
    // if(action.del) {
    //     delete(RouteInsights[action.id]);
    // } else {
    //     const keys = Object.keys(RouteInsights);
    //     keys.forEach((key, i) => {
    //         if(action.id !== key) {
    //             delete(RouteInsights[key]);
    //         }
    //     });
    //     RouteInsights[action.id] = action.data;
    // }
    // console.log('RouteInsights----reducer----------> ', RouteInsights);
    return updateObject(state, {
        routeInsightsLoading: false,
        routeInsightsError: false,
        routeInsights: RouteInsights,
        insightsMapping: action.data.insights_mapping || state.insightsMapping,
    });
};
const getRouteInsightsFailed = state => {
    return updateObject(state, {
        routeInsightsLoading: false,
        routeInsightsError: true,
    });
};

const getLegInsightsStart = state => {
    return updateObject(state, {
        legInsightsLoading: true,
        legInsightsError: false,
    });
};
const getLegInsightsSuccess = (state, action) => {
    const LegInsights = {...state.legInsights};
    // const selectedLegIds = action.selectedLegs.map(leg=>leg.uid);
    // Object.keys(LegInsights).forEach(key => {
    //     if(selectedLegIds.includes(key)){}
    //     else{
    //         delete(LegInsights[key]);
    //     }
    // });
    if(action.del) {
        delete(LegInsights[action.id]);
    } else {
        LegInsights[action.id] = action.data.insights;
        LegInsights[action.id].routing_risk = {...LegInsights[action.id].routing_risk, ...LegInsights[action.id]['in-transit_delays']};
        delete(LegInsights[action.id].dynamic_insights);
    }
    //console.log('============reducer leg insights=====>>>>>>>>>>>>>>');
    return updateObject(state, {
        legInsightsLoading: false,
        legInsightsError: false,
        legInsights: LegInsights,
        insightsMapping: action.data.insights_mapping || state.insightsMapping,
    });
};
const getLegInsightsFailed = state => {
    return updateObject(state, {
        legInsightsLoading: false,
        legInsightsError: true,
    });
};

const riskIQ = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANES_LIST_START:
            return getLanesListStart(state, action);
        case actionTypes.GET_LANES_LIST_SUCCESS:
            return getLanesListSuccess(state, action);
        case actionTypes.GET_LANES_LIST_FAILED:
            return getLanesListFailed(state, action);
        case actionTypes.GET_LANE_DETAILS_START:
            return getLaneDetailsStart(state, action);
        case actionTypes.GET_LANE_DETAILS_SUCCESS:
            return getLaneDetailsSuccess(state, action);
        case actionTypes.GET_LANE_DETAILS_FAILED:
            return getLaneDetailsFailed(state, action);
        case actionTypes.GET_LANE_STABILITY_START:
            return getLaneStabilityStart(state, action);
        case actionTypes.GET_LANE_STABILITY_SUCCESS:
            return getLaneStabilitySuccess(state, action);
        case actionTypes.GET_LANE_STABILITY_FAILED:
            return getLaneStabilityFailed(state, action);
        case actionTypes.GET_ROUTE_DETAILS_START:
            return getRouteDetailsStart(state, action);
        case actionTypes.GET_ROUTE_DETAILS_SUCCESS:
            return getRouteDetailsSuccess(state, action);
        case actionTypes.GET_ROUTE_DETAILS_FAILED:
            return getRouteDetailsFailed(state, action);
        case actionTypes.GET_ROUTE_STABILITY_START:
            return getRouteStabilityStart(state, action);
        case actionTypes.GET_ROUTE_STABILITY_SUCCESS:
            return getRouteStabilitySuccess(state, action);
        case actionTypes.GET_ROUTE_STABILITY_FAILED:
            return getRouteStabilityFailed(state, action);
        case actionTypes.GET_LEG_STABILITY_START:
            return getLegStabilityStart(state, action);
        case actionTypes.GET_LEG_STABILITY_SUCCESS:
            return getLegStabilitySuccess(state, action);
        case actionTypes.GET_LEG_STABILITY_FAILED:
            return getLegStabilityFailed(state, action);
        case actionTypes.GET_LANE_INSIGHTS_START:
            return getLaneInsightsStart(state, action);
        case actionTypes.GET_LANE_INSIGHTS_SUCCESS:
            return getLaneInsightsSuccess(state, action);
        case actionTypes.GET_LANE_INSIGHTS_FAILED:
            return getLaneInsightsFailed(state, action);
        case actionTypes.GET_ROUTE_INSIGHTS_START:
            return getRouteInsightsStart(state, action);
        case actionTypes.GET_ROUTE_INSIGHTS_SUCCESS:
            return getRouteInsightsSuccess(state, action);
        case actionTypes.GET_ROUTE_INSIGHTS_FAILED:
            return getRouteInsightsFailed(state, action);
        case actionTypes.GET_LEG_INSIGHTS_START:
            return getLegInsightsStart(state, action);
        case actionTypes.GET_LEG_INSIGHTS_SUCCESS:
            return getLegInsightsSuccess(state, action);
        case actionTypes.GET_LEG_INSIGHTS_FAILED:
            return getLegInsightsFailed(state, action);
        default:
            return state;
    }
};

export default riskIQ;