import React, { useState, useEffect } from 'react';
import { DatePicker } from 'rc-datepicker';

const DateTimePickerHOC = (props) => {
    const dateTimeStamp = new Date(props.date).getTime();
    const duration = Number(props.duration || ((new Date().getTime()-dateTimeStamp+45000)/1000)  || 0);
    const newDateTimeStamp = dateTimeStamp + (duration*1000);
    const anticipatedDate = new Date(newDateTimeStamp);

    useEffect(() => {
        const stopIndex = document.getElementById(`_address_loc_${props.index}_`);
        stopIndex.scrollIntoView();
    });

    const stringDateTime = anticipatedDate.toString().split(' ');
    const time = stringDateTime[4].split(':');
    const [date, setDate] = useState({
                                date: anticipatedDate, 
                                hours: time[0], 
                                minutes: time[1], 
                                month: anticipatedDate.getMonth()+1, 
                                monthName: stringDateTime[1], 
                                day: stringDateTime[2], 
                                year: stringDateTime[3],
                                error: props.duration && new Date().getTime() > anticipatedDate.getTime() ? true : false
                            });

    const closePop = () => {
        if(props.onClose && typeof(props.onClose) === 'function') {
            props.onClose();
        }
    }
    const handleArrowKeys = (e, val, type) => {
        const maxValue = {hours: 23, minutes: 59};
        let activeTime = val || 0;
        const TODAY = new Date();
        if((Number(e.which) === 38 || e.key === 'ArrowUp' || Number(e.keyCode) === 38)) {
            e.preventDefault();
            if(activeTime <= maxValue[type]-1) activeTime++;
            const newDate = {...date};
            if(type === 'hours'){
                newDate.hours = activeTime;
                newDate.date.setHours(newDate.hours);
            } else if(type === 'minutes') {
                newDate.minutes = activeTime;
                newDate.date.setMinutes(newDate.minutes);
            }
            if(
                new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() < new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime() || 
                (
                    new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() === new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime()
                    && (
                        (type === 'hours' && Number(activeTime) < TODAY.getHours()) 
                        || (type === 'minutes' && Number(newDate.hours) <= TODAY.getHours() && Number(activeTime) < TODAY.getMinutes())
                        )
                )
            ){
                newDate.error = true;
            } else {
                newDate.error = false;
            }            
            setDate(newDate);
        } else if((Number(e.which) === 40 || e.key === 'ArrowDown' || Number(e.keyCode) === 40)) {
            e.preventDefault();
            const newDate = {...date};
            if(
                new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() < new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime() || 
                (
                    new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() === new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime()
                    && (
                        (type === 'hours' && Number(activeTime) <= TODAY.getHours()) 
                        || (type === 'minutes' && Number(newDate.hours) <= TODAY.getHours() && Number(activeTime) <= TODAY.getMinutes())
                        )
                )
            ){
                newDate.error = true;
            } else {
                if(activeTime >= 1){
                    activeTime--;
                }
                newDate.error = false;
            }            
            if(type === 'hours'){
                newDate.hours = activeTime;
                newDate.date.setHours(newDate.hours);
            } else if(type === 'minutes') {
                newDate.minutes = activeTime;
                newDate.date.setMinutes(newDate.minutes);
            }
            setDate(newDate);
        }
        
    }
    return(
        <div className="d-t-p-container" id={`d_t_p_container_${props.hasOwnProperty('index')?props.index:''}`}>
            <div className="d-t-p-shadow">
            <div className="d-t-p-bdy">
                <DatePicker 
                    minDate={new Date()}
                    value={date.date}
                    onChange={(e) => {
                        const D = e.toString().split(' ');
                        const newDate = {...date};
                        //newDate.date = e;
                        newDate.month = new Date(e).getMonth()+1;
                        newDate.monthName = D[1];
                        newDate.day = D[2];
                        newDate.year = D[3];
                        
                        const selectedDate = new Date(e);
                        selectedDate.setHours(newDate.hours);
                        selectedDate.setMinutes(newDate.minutes);
                        newDate.date = selectedDate;
                        const TODAY = new Date();
                        if(
                            new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() < new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime() ||
                            (
                                new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() === new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime()
                                && ((Number(newDate.hours) < TODAY.getHours()) || (Number(newDate.hours) <= TODAY.getHours() && Number(newDate.minutes) < TODAY.getMinutes()))
                            )
                            
                            ){
                            newDate.error = true;
                        } else {
                            newDate.error = false;
                        }  

                        setDate(newDate);
                    }}
                    className='cx-d-p'
                    // onShow={()=>{
                    //     console.log("on show: ", props);
                    // }}
                    // onHide={()=>{
                    //     console.log("on hide: ", props);
                    // }}
                />
                <div className="d-t-p-r-sec">
                    <div className="sel-date"><span className="sel-day">{date.day}</span> {date.monthName} {date.year}</div>
                    <div className={`ip-box${date.error ? ' e' : ''}`}>
                    <input 
                        type="text" 
                        className="i-fld" 
                        maxLength="2"
                        placeholder="HH"
                        autoComplete="off"
                        value={date.hours}
                        id={"d_t_p_h"}
                        onKeyDown={(e)=>handleArrowKeys(e, date.hours, 'hours')}
                        onChange={(e) => {
                            const val = e.target.value;
                            const activeTime = (val+'').match(/^(2[0-3]|1[0-9]|0[0-9]|[0-9])$/) || val === '' ? val : date.hours;
                            const newDate = {...date}
                            newDate.hours = activeTime;
                            const selectedDate = newDate.date;
                            selectedDate.setHours(newDate.hours||0);
                            newDate.date = selectedDate;
                            const TODAY = new Date();
                            if(
                                new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() < new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime() ||
                                (
                                    new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() === new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime()
                                    && (
                                        Number(newDate.hours) < TODAY.getHours() 
                                        || (
                                            Number(newDate.hours) <= TODAY.getHours() 
                                            && Number(newDate.minutes) < TODAY.getMinutes()
                                            )
                                        )
                                )                                
                            ){
                                newDate.error = true;
                            } else {
                                newDate.error = false;
                            }
                            setDate(newDate);
                            if(activeTime.match(/^(2[0-3]|1[0-9]|0[0-9]|[3-9])$/)) {
                                document.getElementById('d_t_p_m').focus();
                            }
                        }}
                    />
                    <div>:</div>
                    <input 
                        type="text" 
                        className="i-fld" 
                        maxLength="2"
                        placeholder="MM"
                        autoComplete="off"
                        value={date.minutes}
                        id={"d_t_p_m"}
                        onKeyDown={(e)=>handleArrowKeys(e, date.minutes, 'minutes')}
                        onChange={(e) => {
                            const val = e.target.value;
                            const activeTime = (val+'').match(/^[0-5]?[0-9]$/) || val === '' ? val : date.minutes;
                            const newDate = {...date}
                            newDate.minutes = activeTime;
                            const selectedDate = newDate.date;
                            selectedDate.setMinutes(newDate.minutes||0);
                            newDate.date = selectedDate;
                            const TODAY = new Date();
                            if(
                                new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() < new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime() ||
                                (
                                    new Date(`${newDate.month}/${newDate.day}/${newDate.year}`).getTime() === new Date(`${TODAY.getMonth()+1}/${TODAY.getDate()}/${TODAY.getFullYear()}`).getTime()
                                    && Number(newDate.hours) <= TODAY.getHours() 
                                    && Number(newDate.minutes) < TODAY.getMinutes()
                                )
                            ){
                                newDate.error = true;
                            } else {
                                newDate.error = false;
                            }
                            setDate(newDate);
                            if(activeTime === '' && !(date.hours+'').match(/^(2[0-3]|1[0-9]|0[0-9]|[3-9])$/)) {
                                document.getElementById('d_t_p_h').focus();
                            }
                        }}
                    />
                    </div>
                    {
                        date.error ?
                        <div className="error_msg" style={{position:'unset',marginTop:'4px',textAlign:'left',fontSize:'10px'}}>*Can not set <br />past date and time</div>
                        : ''
                    }
                </div>
            </div>
            <div className="d-t-p-ftr">
                <div 
                    className="c-btn s-m cancel"
                    onClick={(e) => {
                        e.stopPropagation();
                        closePop();
                    }}
                >Cancel</div>
                <div 
                    className={`c-btn s-m ok${date.error ? ' n-a': ''}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        if(date.error) return false;
                        if(props.onChange && typeof(props.onChange) === 'function') {
                            props.onChange(date);
                        }
                        closePop();
                        const stopIndex = document.getElementById(`_address_loc_${props.index}_`);
                        stopIndex.scrollIntoView();
                    }}
                >Ok</div>
            </div>
            </div>
        </div>
    );
}

export default DateTimePickerHOC;