import * as actionTypes from './actionTypes';
import axios from 'axios';
import { AXIOS } from 'utility/Axios';
import { sendLogout } from './company';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;
const ENV = parseInt(process.env.REACT_APP_CUSTOMER_ID,10);
const Axios = axios.create({
    baseURL: `${process.env.REACT_APP_api_domain}/api/`,
});
export const getActiveUserStart = () => {
    return {
        type: actionTypes.GET_ACTIVE_USER_START,
    };
};
export const getUserNotFound = error => {
    return {
        type: actionTypes.GET_USER_NOT_FOUND,
        error,
    };
};
export const getActiveUserSuccess = (data, role, user) => {
    return {
        type: actionTypes.GET_ACTIVE_USER_SUCCESS,
        data,
        role,
        user,
    };
};
export const getUpdateUserProfileSuccess = user => {
    return {
        type: actionTypes.GET_USER_UPDATE_PROFILE_SUCCESS,
        user,
    };
};
export const sendEmail = message => {
    return {
        type: actionTypes.SEND_EMAIL_ID,
        message,
    };
};
export const getActiveUserFailed = (error, email, serverError, networkError, maintenanceMode) => {
    return {
        type: actionTypes.GET_ACTIVE_USER_FAILED,
        error,
        email,
        serverError,
        networkError,
        maintenanceMode
    };
};
export const getUserDetailsFailed = (error, email, serverError, networkError, maintenanceMode) => {
    return {
        type: actionTypes.GET_USER_DETAILS_FAILED,
        error,
        email,
        serverError,
        networkError,
        maintenanceMode
    };
};
export const getUpdateUserFailed = (error,errorObj) => {
    return {
        type: actionTypes.GET_UPDATE_USER_FAILED,
        error,
        errorObj
    };
};
export const setPassword = (flag, message) => {
    return {
        type: actionTypes.SET_PASSWORD,
        flag,
        message,
    };
};
export const updatePasswordState = (flag, message, error) => {
    return {
        type: actionTypes.UPDATE_PASSWORD,
        flag,
        message,
        error,
    };
};
export const resetPasswordState = (flag, message, error) => {
    return {
        type: actionTypes.RESET_PASSWORD,
        flag,
        message,
        error,
    };
};
export const getRegistrationStatus = (message, mail) => {
    return {
        type: actionTypes.GET_REGISTER,
        message,
        mail,
    };
};
export const getCompanyName = company => {
    return {
        type: actionTypes.GET_COMPANY_NAME,
        company,
    };
};
export const getCodeStatus = (verificationCodeStatus) => {
    return {
        type: actionTypes.GET_VERIFICATION_CODE,
        verificationCodeStatus,
    };
};
export const sendVerifyCode = () => {
    return {
        type: actionTypes.SEND_VERIFICATION_CODE,
    };
};
export const sendTCData = (content, updatedAt) => {
    return {
        type: actionTypes.GET_TC_DATA,
        content,
        updatedAt,
    };
};

export const getActiveUser = user =>
    async function(dispatch) {
        if(window.navigator.onLine){
            const requestUrl = apis.login.login;
            let e1 = {
                msg: '',
                forcePassword: 0,
            };
            // dispatch(getActiveUserStart());
            await Axios({
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'crossDomain': true,
                    'Access-Control-Allow-Origin': '*',
                    'x-api-key': process.env.REACT_APP_X_API_KEY,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                url: requestUrl,
                responseType: 'json',
                data: user,
                onInternalServerError: () => {
                    dispatch(getActiveUserFailed(false, false, true));
                }
            })
                .then(response => {
                    if(response && response.data && response.data.maintenanceMode) {
                        window.localStorage.setItem('maintenance', true);
                        dispatch(getActiveUserFailed(false, false, false, false, true));
                    } else if (
                        response.data &&
                        response.data.data &&
                        response.data.data.user &&
                        (ENV === 0 ? response.data.data.user.verified : true)
                    ) {
                        window.localStorage.removeItem('maintenance');
                        window.localStorage.setItem('access_token', response.data.data.access_token);
                        window.localStorage.setItem('refresh_token', response.data.data.refresh_token);
                        window.localStorage.setItem('id', response.data.data.user.id);
                        window.localStorage.setItem('email', response.data.data.user.email);
                        window.localStorage.setItem('companyId', response.data.data.user.companyId);
                        window.localStorage.setItem('groupId', response.data.data.user.groupId);
                        window.localStorage.setItem('roles', response.data.data.user.roles);
                        window.localStorage.setItem('company', response.data.data.user.company);
                        //window.localStorage.setItem('permissions', response.data.data.user.permissions);
                        window.localStorage.setItem('name', `${response.data.data.user.firstname} ${response.data.data.user.lastname}`);
                        window.localStorage.setItem('userDetails', JSON.stringify(response.data.data.user));
                        window.localStorage.setItem('assetTracking', response.data.data.user.asset_tracking);
                        window.localStorage.setItem('carrierTracking', response.data.data.user.carrier_tracking);
                        window.localStorage.setItem('remaningStabilityBudget', response.data.data.user.remaning_stability_budget);
                        window.localStorage.setItem('pdfParser', response.data.data.user.pdf_parser);
                        window.localStorage.setItem('riskIQ', response.data.data.user.risk_iq);
                        window.localStorage.setItem('landingPage', response.data.data.user.landing_page);
                        window.localStorage.setItem('accept_terms', response.data.data.user.accept_terms_and_conditions);
                        dispatch(
                            getActiveUserSuccess(response.data, response.data.data.user.roles, response.data.data.user)
                        );
                        //dispatch(getUpdateUserProfileSuccess(response.data.data.user));
                        //e1.forcePassword = response.data.data.user.forcePasswordChange;
                    } else if (response.data && response.data.error) {
                        dispatch(getActiveUserFailed(response.data.error, user.email));
                        e1.msg = response.data.error;
                    } else {
                        // empty
                    }
                    //  callback(response.data[0]);
                })
                .catch(error => {
                    if(error.response?.status === 503 || error.response?.data?.data?.message === "Service Unavailable") {
                        window.localStorage.setItem('maintenance', true);
                        window.localStorage.setItem('maintenanceModeData', JSON.stringify(error.response?.data?.data));
                        dispatch(getActiveUserFailed(false, false, false, false, true));
                    } else if (error.response && error.response.data && error.response.data.error) {
                        dispatch(getActiveUserFailed(error.response.data.error, user.email));
                        e1.msg = error.response.data.error;
                    } else {
                        if(error.message && error.message.toLowerCase() === 'network error') {
                            dispatch(
                                getActiveUserFailed(false, false, true)
                            );
                        } else {
                            dispatch(
                                getActiveUserFailed(`Could not process request. Please contact ${apis.help.api_help_email}`, user.email)
                            );
                        }
                        
                        e1.msg = `Could not process request. Please contact ${apis.help.api_help_email}`;
                    }
                });
            return e1;
        }
        else {            
            dispatch(getActiveUserFailed(false, false, false, true));
        }        
    };
export const updateUserProfile = data =>
    async function(dispatch) {
        let e1 = '';
        let requestUrl = apis.login.update_profile(data.id);
        await AXIOS({
            method: 'PUT',
            url: requestUrl,
            data: data,
        }).then(response => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if (response.response) {
                if(ENV === 0){
                    dispatch(getUpdateUserFailed(response.response.data.message,response.response.data.errors));
                }else{
                    dispatch(getUpdateUserFailed(response.response.data.message,response.response.data.data));
                }
                e1 = 'Unsuccessfull!';
            } else {
                dispatch(getUpdateUserProfileSuccess('User profile updated successfully'));
                e1 = 'User profile updated successfully';
            }
            return e1;
        });
    };
export const getUserDetails = (token,callback) =>
async function(dispatch) {
    if(window.navigator.onLine){
        const requestUrl = apis.login.get_user_details(token);
        let e1 = {
            msg: '',
            forcePassword: 0,
        };
        await AXIOS({
            url: requestUrl,
        }).then(response => {
                if(response === undefined) {
                    dispatch(getUserDetailsFailed(false, false, true, false, false));
                    if(callback){
                        callback(true);
                    }
                }else if (response && response.data && response.data.maintenanceMode) {
                    window.localStorage.setItem('maintenance', true);
                    dispatch(getUserDetailsFailed(false, false, false, false, true));
                } else if (
                    response.data &&
                    response.data.data &&
                    (ENV === 0 ? response.data.data.verified : true)
                ) {
                    window.localStorage.removeItem('maintenance');
                    window.localStorage.setItem('device_token', response.data.data.deviceToken);
                    window.localStorage.setItem('refresh_token', response.data.data.refresh_token);
                    window.localStorage.setItem('id', response.data.data.id);
                    window.localStorage.setItem('email', response.data.data.email);
                    window.localStorage.setItem('groupId', response.data.data.groupId);
                    window.localStorage.setItem('companyId', response.data.data.company_id);
                    window.localStorage.setItem('roles', response.data.data.roles);
                    window.localStorage.setItem('company', response.data.data.company);
                    //window.localStorage.setItem('permissions', response.data.data.user.permissions);
                    window.localStorage.setItem('name', `${response.data.data.firstname} ${response.data.data.lastname}`);
                    window.localStorage.setItem('userDetails', JSON.stringify(response.data.data));
                    window.localStorage.setItem('assetTracking', response.data.data.asset_tracking);
                    window.localStorage.setItem('carrierTracking', response.data.data.carrier_tracking);
                    window.localStorage.setItem('remaningStabilityBudget', response.data.data.remaning_stability_budget);
                    window.localStorage.setItem('pdfParser', response.data.data.pdf_parser);
                    window.localStorage.setItem('riskIQ', response.data.data.risk_iq);
                    window.localStorage.setItem('landingPage', response.data.data.landing_page);
                    window.localStorage.setItem('accept_terms', response.data.data.accept_terms_and_conditions);
                    window.localStorage.setItem('sso_user', response.data.data.sso);
                    window.localStorage.setItem('connection_name', response.data.data.connection_name);
                    // dispatch(getUpdateUserProfileSuccess(response.data.data));
                    // e1.forcePassword = response.data.data.forcePasswordChange;
                    if(callback){
                        callback(false);
                    }
                } else if (response.data && response.data.error) {
                    dispatch(getUserDetailsFailed(response.data.error, response.data.data.email));
                    e1.msg = response.data.error;
                } else {
                    dispatch(getUserDetailsFailed(false, false, true, false, false));
                    if(callback){
                        callback(true);
                    }
                }
                //  callback(response.data[0]);
            })
            .catch(error => {
                // Catch Body
            });
        
    }
    else {            
        dispatch(getActiveUserFailed(false, false, false, true));
    }        
};
export const doesEmailExists = email =>
    async function(dispatch) {
        let obj = {
            e1: '',
            company: '',
            obj1: '',
        };
        const requestUrl = apis.login.does_user_exists;
        await Axios({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'crossDomain': true,
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            params: {
                email: email,
            },
            responseType: 'json',
            url: requestUrl,
        })
            .then(response => {
                if (response.data.data.exists) {
                    dispatch(getUserNotFound(''));
                    obj.e1 = '* Email already exists';
                } else {
                    dispatch(getUserNotFound('User not found'));
                    obj.obj1 = 'User not found';
                }
                obj.exists = response.data.data.exists;
                obj.company = response.data.data.company;
                dispatch(getCompanyName(response.data.data.company));
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                dispatch(getUserNotFound(`Could not process request. Please contact ${apis.help.api_help_email}`));
                dispatch(getCompanyName(''));
                obj.e1 = `Could not process request. Please contact ${apis.help.api_help_email}`;
                obj.obj1 = `Could not process request. Please contact ${apis.help.api_help_email}`;
            });
        return obj;
    };
export const sendEmailVerification = email =>
    async function(dispatch) {
        let e1 = '';
        const requestUrl = apis.login.password_create;
        await Axios({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'crossDomain': true,
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            url: requestUrl,
            responseType: 'json',
            data: {
                email: email,
            },
        })
            .then(response => {
                if (response.data) {
                    dispatch(sendEmail(''));
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                if (error && error.response && error.response.data) {
                    dispatch(sendEmail(error.response.data.errors.email[0]));
                    e1 = error.response.data.errors.email[0];
                } else {
                    dispatch(sendEmail(`Could not process request. Please contact ${apis.help.api_help_email}`));
                    e1 = `Could not process request. Please contact ${apis.help.api_help_email}`;
                }
            });
        return e1;
    };
export const forgotPasswordDetails = data =>
    async function(dispatch) {
        if(window.navigator.onLine){
            let e1 = '';
            const requestUrl = apis.login.password_reset;
            await Axios({
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'crossDomain': true,
                    'Access-Control-Allow-Origin': '*',
                    'x-api-key': process.env.REACT_APP_X_API_KEY,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                },
                url: requestUrl,
                responseType: 'json',
                data: data
            })
            .then(response => {
                if(response && response.data && response.data.maintenanceMode) {
                    window.localStorage.setItem('maintenance', true);
                    dispatch(getActiveUserFailed(false, false, false, false, true));
                } else if (response.data) {
                    window.localStorage.removeItem('maintenance');
                    dispatch(setPassword(true, ENV===0?response.data.data:response.data.message));
                }
            })
            .catch(error => {
                if (error && error.response && error.response.data) {
                    dispatch(setPassword(false, error.response.data.message));
                    e1 = error.response.data.message;
                } else {
                    if(error.message && error.message.toLowerCase() === 'network error') {
                        dispatch(getActiveUserFailed(false, false, true));
                    } else {
                        dispatch(setPassword(false, `Could not process request. Please contact ${apis.help.api_help_email}`));
                    }
                    e1 = `Could not process request. Please contact ${apis.help.api_help_email}`;
                }
            });
            return e1;
        } else {
            dispatch(getActiveUserFailed(false, false, false, true));
        }
    };
export const verifySecurityCode = data =>
async function(dispatch) {
    if(window.navigator.onLine){
        let e1 = '';
        const requestUrl = apis.login.password_token_verification(data.token);
        await Axios({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'crossDomain': true,
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            url: requestUrl,
            responseType: 'json',
        })
        .then(response => {
            if(response && response.data && response.data.maintenanceMode) {
                window.localStorage.setItem('maintenance', true);
                dispatch(getActiveUserFailed(false, false, false, false, true));
            } else if (response.data) {
                window.localStorage.removeItem('maintenance');
                dispatch(setPassword(true, response.data.data));
            }
        })
        .catch(error => {
            if (error && error.response && error.response.data) {
                dispatch(setPassword(false, error.response.data));
                e1 = error.response.data.message;
            } else {
                if(error.message && error.message.toLowerCase() === 'network error') {
                    dispatch(getActiveUserFailed(false, false, true));
                } else {
                    dispatch(setPassword(false, `Could not process request. Please contact ${apis.help.api_help_email}`));
                }
                e1 = `Could not process request. Please contact ${apis.help.api_help_email}`;
            }
        });
        return e1;
    } else {
        dispatch(getActiveUserFailed(false, false, false, true));
    }
};
export const RegisterUser = user =>
    async function(dispatch) {
        let e1 = '';
        const requestUrl = apis.login.user_register;
        await AXIOS({
            method: 'POST',
            url: requestUrl,
            data: user,
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                if(response === undefined){
                    dispatch(sendLogout(true));
                }
                else if(response.response){
                    if (response.response.data && (response.response.data?.errors || !response.response.data.success)) {
                        dispatch(getRegistrationStatus(response.response.data?.errors||response.response.data?.data, ''));
                        e1 = response.response.data?.errors||response.response.data?.data;
                    } else {
                        dispatch(getRegistrationStatus(`Could not process request. Please contact ${apis.help.api_help_email}`, ''));
                        e1 = `Could not process request. Please contact ${apis.help.api_help_email}`;
                    }
                }
                else{
                dispatch(getRegistrationStatus('', user.email));
                }
            })
            // .catch(error => {
            //     if (error.response.data && error.response.data.errors) {
            //         if (error.response.data.errors.phone_number && error.response.data.errors.phone_number[0]) {
            //             dispatch(getRegistrationStatus(error.response.data.errors.phone_number[0], ''));
            //         } else if (error.response.data.errors.email && error.response.data.errors.email[0]) {
            //             dispatch(getRegistrationStatus(error.response.data.errors.email[0], ''));
            //         }
            //         e1 = error.response.data.errors.phone_number;
            //     } else {
            //         dispatch(getRegistrationStatus(`Could not process request. Please contact ${apis.help.api_help_email}`, ''));
            //         e1 = `Could not process request. Please contact ${apis.help.api_help_email}`;
            //     }
            // });
        return e1;
    };

export const checkCompany = company =>
    async function(dispatch) {
        const requestUrl = apis.login.check_user_company;
        await Axios({
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'crossDomain': true,
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            url: requestUrl,
            responseType: 'json',
            data: company,
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {})
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
                dispatch(getCompanyName(company));
            });
    };
export const getTokenVerification = user =>
    async function(dispatch) {
        let e1 = {
            verificationCodeError: '',
            forcePassword: 0,
            token: '',
        };
        await Axios({
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'crossDomain': true,
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            url: apis.login.validate_user_code,
            responseType: 'json',
            data: user,
        })
        .then(response => {
            e1.forcePassword = response.data.data.force_password_change
                ? response.data.data.force_password_change
                : 0;
            e1.token = response.data.data.token ? response.data.data.token : '';
            dispatch(getCodeStatus(response.data));
        })
        .catch(error => {
            console.log(error.response);
            if (error.response?.data)
                dispatch(getCodeStatus(error.response.data));
            else {
                dispatch(getCodeStatus({data: {token: [`Could not process request. Please contact ${apis.help.api_help_email}`]}, success: false}));
            }
        });
    return e1;
};
export const sendResetPasswordCode = email =>
    async function(dispatch) {
        await Axios({
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'crossDomain': true,
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            url: apis.login.resend_password_code,
            responseType: 'json',
            params: {
                email: email,
            },
        })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
                dispatch(sendVerifyCode());
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {});
    };
export const sendActivationCode = email =>
async function(dispatch) {
    await Axios({
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'crossDomain': true,
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        },
        url: apis.login.resend_user_code,
        responseType: 'json',
        data: {
            email: email,
        },
    })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
            dispatch(sendVerifyCode());
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {});
};
export const updatePassword = obj =>
    async function(dispatch) {
        dispatch(updatePasswordState(false, '', [{oldPassword: '', newPassword: ''}]));
        let message = '';
        let requestUrl = apis.login.update_password(obj.id);
        await AXIOS({
            method: ENV === 0?'post':'PUT',
            url: requestUrl,
            data: obj,
        }).then(response => {
            if (response === undefined) {
                //setIsAuthenticated(false);
                /*HERE WE need to redirect to login*/
                dispatch(sendLogout(true));
                message = 'Unauthenticated';
            } else if (response.response) {
                const res = response.response;
                if (res.status === 422) {
                    dispatch(updatePasswordState(false, res.data.message, res.data.data));
                    message = res.data.message;
                }else if(res.status === 401) {
                    dispatch(updatePasswordState(false, res.data.error));
                    message = res.data.error;
                }
            } else if (response.data) {
                dispatch(updatePasswordState(true, response.data.data));
                message = response.data.data;
            } else if (response.data.error) {
                dispatch(updatePasswordState(false, response.data.error));
                message = response.data.error;
            }
        });

        return message;
    };
export const resetPassword = obj =>
    async function(dispatch) {
        let message = '';
        let requestUrl = apis.login.password_reset();
        await AXIOS({
            method: "post",
            url: requestUrl,
            data: obj,
        }).then(response => {
            if (response === undefined) {
                //setIsAuthenticated(false);
                /*HERE WE need to redirect to login*/
                dispatch(sendLogout(true));
                message = 'Unauthenticated';
            } else if (response.response) {
                const res = response.response;
                if (res.status === 401 || res.status === 422 || res.status === 404) {
                    dispatch(resetPasswordState(false, res.data));
                    message = res.data.message;
                }
            } else if (response.data) {
                dispatch(resetPasswordState(true, response.data));
                message = response.data.data;
            } else if (response.data.data) {
                dispatch(resetPasswordState(false, response.data));
                message = response.data.data;
            }
        });

        return message;
};

export const getData = id =>
    async function(dispatch) {
        dispatch(sendTCData('', ''));
        const requestUrl = apis.login.get_pages_data(id);
        await AXIOS({
            method: 'get',
            url: requestUrl,
        }).then(response => {
            if (response.data && response.data.data) {
                dispatch(sendTCData(response.data.data.content, response.data.data.updated_at));
            }
        });
    };
export const acceptTermsSuccess = (data) => {
    return {
        type: actionTypes.ACCEPT_TERMS_SUCCESS,
        data,
    };
};
export const acceptTermsFailed = (error) => {
    return {
        type: actionTypes.ACCEPT_TERMS_FAILED,
        error,
    };
};
export const acceptTermsOfService = data =>
    async function(dispatch) {
        if(window.navigator.onLine){
            let e1 = {
                msg: '',
                forcePassword: 0,
            };
            const requestUrl = apis.login.accept_terms();
            await AXIOS({
                method: 'put',
                url: requestUrl,
                data: data,
            }).then(response => {
                if(response && response.data && response.data.maintenanceMode) {
                    window.localStorage.setItem('maintenance', true);
                    dispatch(getActiveUserFailed(false, false, false, false, true));
                } else if (
                    response.data &&
                    response.data.success
                ) {
                    window.localStorage.removeItem('maintenance');
                    window.localStorage.setItem('accept_terms', 1);
                    dispatch(acceptTermsSuccess(true));
                    
                } else if ((response.data?.message) && !response.data.success) {
                    dispatch(acceptTermsFailed(response.data));
                    e1.msg = response.data.error;
                } else {
                    // empty
                }
                //  callback(response.data[0]);
            })
            .catch(error => {
                if (error.response && error.response.data && !error.response.data.success) {
                    dispatch(acceptTermsFailed(error.response.data));
                    e1.msg = error.response.data?.data;
                } else {
                    if(error.message && error.message.toLowerCase() === 'network error') {
                        dispatch(acceptTermsFailed(false));
                    } else {
                        dispatch(
                            acceptTermsFailed({data: {terms: `Could not process request. Please contact ${apis.help.api_help_email}`}})
                        );
                    }
                    
                    // e1.msg = error.response?.data;
                }
            });
            //return e1;
        }
        else {            
            dispatch(getActiveUserFailed(false, false, false, true));
        }        
    };
    
