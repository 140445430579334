import React, { Component } from 'react';
import { connect } from 'react-redux';
import ShipmentProfile from 'components/Configuration/ShipmentProfile';
import { getDate, /*formatPhone, roundOff,*/ getFullTime, getSplitTimeConfig } from 'utility/utility';
import * as actions from 'redux-store/actions';
//import ShipmentProfile from 'components/SelectBox/SelectBox';
import SweetAlert from '../SweetAlert/SweetAlert';
import PerfectScrollbar from 'components/ScrollBarHOC/scrollBarHOC';
//import GeofenceLocationIcon from 'assets/images/geo_fence_loc';

class ShipmentConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            shipment_id: this.props.shipment_id,
            status: this.props.status,
            profileName: this.props.profileName || 'Profile 1',
            id: this.props.id,
            newProfileID: null,
            company_id: this.props.company_id,
            updated_by: this.props.updated_by || '--',
            updated_at: this.props.updated_at || '--',

            showSaveConfirmation: false,
            saveConfirmationTitle: 'Are you sure you want to update profile configuration for this shipment?',
            shipmentProfile: {},
            configurationProfileDetails: {},
            productProfileDetails: {},
        };
    }
    componentDidMount() {
        //console.log(this.state);
        if(this.props.shipment_id) {
            this.props.onGetShipmentConfiguration({
                shipment_id: this.props.shipment_id.replace('PX', ''),
                onComplete: (data) => {
                    this.setConfigurationProfile(data);
                }
            });
        } else if(this.props.shipment_profile_id) {
            // this.props.onGetShipmentProfile({
            //     id: this.props.shipment_profile_id,
            //     onComplete: async (data) => {
            //         if(data) {
                        this.setShipmentProfile(this.props.SHIPMENT_PROFILE_DETAILS, true);
            //         }
                    
            //     }
            // });            
        }
        
    }

    componentWillUnmount() {
        // empty
        
    }

    componentDidUpdate() {
        // empty
        /* eslint-disable */
        //console.log('componentDidUpdate..............');
    }

    setConfigurationProfile = (data, productProfile) => {
        const configurationProfile = JSON.parse(JSON.stringify(data));
        if(productProfile) {
            configurationProfile.product_profile_config = this.props.productProfile.config.product_profile_config;
            configurationProfile.productType = this.props.productProfile.config.product_type;
            configurationProfile.temperatureRepresentation = this.props.productProfile.config.representation;
            configurationProfile.totalShelfLifeDuration = this.props.productProfile.config.total_shelf_life_duration;
        }

        configurationProfile.profile_name = configurationProfile.profileName ? configurationProfile.profile_name : this.state.profile_name;
        if(configurationProfile?.thresholds?.TEMPERATURE?.above_th_duration) {
            configurationProfile.thresholds.TEMPERATURE.timeAboveThreshold = getSplitTimeConfig(configurationProfile.thresholds.TEMPERATURE.above_th_duration);
        } else {
            if(configurationProfile?.thresholds?.TEMPERATURE) {
                configurationProfile.thresholds.TEMPERATURE.above_th_duration = 0;
                configurationProfile.thresholds.TEMPERATURE.timeAboveThreshold = {d: 0, h: 0, m: 0};
            }
        }
        if(configurationProfile?.thresholds?.TEMPERATURE?.below_th_duration) {
            configurationProfile.thresholds.TEMPERATURE.timeBelowThreshold = getSplitTimeConfig(configurationProfile.thresholds.TEMPERATURE.below_th_duration);
        } else {
            if(configurationProfile?.thresholds?.TEMPERATURE) {
                configurationProfile.thresholds.TEMPERATURE.below_th_duration = 0;
                configurationProfile.thresholds.TEMPERATURE.timeBelowThreshold = {d: 0, h: 0, m: 0};
            }
        }

        if(configurationProfile.product_profile_config) {
            configurationProfile.product_profile_config.map(pp => {
                pp.threshold_duration = Math.round(pp.threshold_duration / 60);
            });
        }

        if(configurationProfile.stops?.length) {
            configurationProfile.stops.forEach((stop, index) => {
                if(stop.dueDate) {
                    stop.dueDate = getSplitTimeConfig(stop.dueDate);
                }
            })
        }

        if(configurationProfile.dueDate) {
            configurationProfile.dueDate = getSplitTimeConfig(configurationProfile.dueDate);
        }

        this.setState({configurationProfile});
    }

    setShipmentProfile = async (details, update, returnData) => {
        const shipmentProfile = {...details};

        if(shipmentProfile.auto_complete) {
            shipmentProfile.x_duration = getSplitTimeConfig(shipmentProfile.x_duration || 0);
        } else {
            shipmentProfile.x_duration = getSplitTimeConfig(0);
        }

        shipmentProfile.legs?.map((leg) => {
            if(leg.leg_type !== 'origin') {
                leg.due_date = getSplitTimeConfig(leg.due_date || 0);
            }
            if(this.props.carrierTypeList.length && leg.carrier) {
                const carrierIndex = this.props.carrierTypeList.findIndex(c => Number(c.id) === Number(leg.carrier));
                if(carrierIndex >= 0) {
                    leg.carrier_name = this.props.carrierTypeList[carrierIndex].carrier_name;
                }
            }
        });

        if(returnData) {
            return shipmentProfile;
        }
        await this.setState({
            shipmentProfile, 
            showSaveConfirmation: false, 
            showModifyAlert: false, 
            nextProfileID: null, 
            editProfileName: null
        });
        
        const fieldSection = document.querySelectorAll('.fld-sec');
        if(fieldSection.length) {
            fieldSection[0].scrollIntoView();
        }        
        
    };

    isValidConfiguration = async () => {
        let isValidProfile = 0;
        const {configurationProfile} = this.state;
        if (configurationProfile.thresholds.TEMPERATURE) {
            if (configurationProfile.thresholds.TEMPERATURE.threshold === '') {
                configurationProfile.thresholds.TEMPERATURE.threshold = 0;
            }
            if (configurationProfile.thresholds.TEMPERATURE.representation) {}
            else{
                configurationProfile.thresholds.TEMPERATURE.representationError = '*Representation is mandatory';
            }
        }
        if (configurationProfile.thresholds.HUMIDITY) {
            if (configurationProfile.thresholds.HUMIDITY.threshold === '') {
                configurationProfile.thresholds.HUMIDITY.threshold = 0;
            }
        }
        
        if(configurationProfile.thresholds.LID) {
            if (!configurationProfile.thresholds.LID.maxLimit || Number(configurationProfile.thresholds.LID?.maxLimit||66001) > 66000) {
                configurationProfile.thresholds.LID.maxError = '*Light Threshold value should be 0-66000!';
                isValidProfile++;
            } else {
                configurationProfile.thresholds.LID.maxError = '';
            }
        }
        if (configurationProfile.thresholds.SHOCK) {
            if (configurationProfile.thresholds.SHOCK.value === '') {
                configurationProfile.thresholds.SHOCK.valueError = '*Shock Threshold can not be empty!';
                isValidProfile++;
            } else if (Number(configurationProfile.thresholds.SHOCK.value) > 16 || Number(configurationProfile.thresholds.SHOCK.value) < 1) {
                configurationProfile.thresholds.SHOCK.valueError = '*Shock Threshold value should be 1.0-16!';
                isValidProfile++;
            } else {
                configurationProfile.thresholds.SHOCK.valueError = '';
            }
        }
        if (configurationProfile.thresholds.CONNECTIVITYCYCLEINMOTION) {
            if (configurationProfile.thresholds.CONNECTIVITYCYCLEINMOTION.value === '') {
                configurationProfile.thresholds.CONNECTIVITYCYCLEINMOTION.valueError = '*Please select Communication interval inmotion!';
                isValidProfile++;
            } else {
                configurationProfile.thresholds.CONNECTIVITYCYCLEINMOTION.valueError = '';
            }
        }
        if (configurationProfile.thresholds.CONNECTIVITYCYCLENOMOTION) {
            if (configurationProfile.thresholds.CONNECTIVITYCYCLENOMOTION.value === '') {
                configurationProfile.thresholds.CONNECTIVITYCYCLENOMOTION.valueError = '*Please select Communication interval inmotion!';
                isValidProfile++;
            } else {
                configurationProfile.thresholds.CONNECTIVITYCYCLENOMOTION.valueError = '';
            }
        }
        if (configurationProfile.thresholds.CONNECTIVITYCYCLEMONITORPERIOD) {
            if (configurationProfile.thresholds.CONNECTIVITYCYCLEMONITORPERIOD.value === '') {
                configurationProfile.thresholds.CONNECTIVITYCYCLEMONITORPERIOD.valueError = '*Please select Sampling period!';
                isValidProfile++;
            } else {
                configurationProfile.thresholds.CONNECTIVITYCYCLEMONITORPERIOD.valueError = '';
            }
        }
        if (configurationProfile.thresholds.CONNECTIVITYCYCLECOMMSYNC) {
            if (configurationProfile.thresholds.CONNECTIVITYCYCLECOMMSYNC.value === '') {
                configurationProfile.thresholds.CONNECTIVITYCYCLECOMMSYNC.valueError = '*Please select Reporting period!';
                isValidProfile++;
            } else {
                configurationProfile.thresholds.CONNECTIVITYCYCLEMONITORPERIOD.valueError = '';
            }
        }

        if(configurationProfile.thresholds.CONNECTIVITYCYCLECOMMSYNC && configurationProfile.thresholds.CONNECTIVITYCYCLEMONITORPERIOD) {
            if(configurationProfile.thresholds.CONNECTIVITYCYCLECOMMSYNC.value < configurationProfile.thresholds.CONNECTIVITYCYCLEMONITORPERIOD.value) {
                configurationProfile.thresholds.CONNECTIVITYCYCLECOMMSYNC.valueError = '*Reporting period should be greater than Sampling!';
                isValidProfile++;
            } else {}
        }
        if(configurationProfile.thresholds.TEMPERATURE.temperatureRepresentation) {
            configurationProfile.temperatureRepresentation = configurationProfile.thresholds.TEMPERATURE.temperatureRepresentation;
        }

        await this.setState({configurationProfile});
        return isValidProfile === 0 ? true : false;
    };

    saveShipmentConfigurationConfirm = async () => {
        const configurationProfile = {...this.state.configurationProfile};
        configurationProfile.shipmentID = this.props.shipmentID.replace('PX', '');
        let isProfileChanged = false;
        if(this.state.newProfileID) {
            configurationProfile.newProfileID = this.state.newProfileID;
            configurationProfile.profileID = this.props.profileID;
            isProfileChanged = true;
        }
        
        if(configurationProfile.thresholds?.TEMPERATURE?.timeAboveThreshold) {
            configurationProfile.thresholds.TEMPERATURE.above_th_duration = getFullTime(configurationProfile.thresholds.TEMPERATURE.timeAboveThreshold);
        }
        if(configurationProfile.thresholds?.TEMPERATURE?.timeBelowThreshold) {
            configurationProfile.thresholds.TEMPERATURE.below_th_duration = getFullTime(configurationProfile.thresholds.TEMPERATURE.timeBelowThreshold);
        }
        if(configurationProfile.thresholds.GEOLOCATION) {
            delete(configurationProfile.thresholds.GEOLOCATION);
        }
        if(configurationProfile.product_profile_config) {
            configurationProfile.product_profile_config = JSON.parse(JSON.stringify(configurationProfile.product_profile_config));
            configurationProfile.product_profile_config.forEach(c => {
                c.threshold_duration = c.threshold_duration * 60;
            });

            if(configurationProfile.thresholds.TEMPERATURE.temperatureRepresentation) {
                configurationProfile.temperatureRepresentation = configurationProfile.thresholds.TEMPERATURE.temperatureRepresentation;
            }
        }
        //console.log('configurationProfile: ', configurationProfile);
        await this.props.onUpdateShipmentConfiguration({
            ...configurationProfile,
            onComplete: (data) => {
                //console.log(data);
                //console.log(this.props.configurationProfileError);
                if(data.success) {
                    this.props.closeShipmentConfiguration({
                        isProfileChanged: isProfileChanged,
                        message: data.message || 'Shipment configuration updated successfully',
                        shipmentID: configurationProfile.shipmentID
                    });
                } else if(data?.data && !data.success) {

                    configurationProfile.product_profile_config.forEach((pp, i) => {
                        delete(configurationProfile.product_profile_config[i].error);
                    });
                    for(let e in data.data) {
                        const error_obj = e.split('.');
                        configurationProfile.product_profile_config[error_obj[1]].error = data.data[e];
                    }

                    if(configurationProfile.product_profile_config) {
                        configurationProfile.product_profile_config = JSON.parse(JSON.stringify(configurationProfile.product_profile_config));
                        configurationProfile.product_profile_config.forEach(c => {
                            c.threshold_duration = c.threshold_duration / 60;
                        });
                    }
                    
                    this.setState({
                        configurationProfile
                    });
                    
                    // this.props.closeShipmentConfiguration({
                    //     isProfileChanged: false,
                    //     error: data.message
                    // }); 
                } else {
                    console.log('...ERROR....: ', data);
                }
                
            }
        });     
        if(this.props.updateShipmentConfigurationFailed) {
            this.props.closeShipmentConfiguration({
                isProfileChanged: false,
                error: this.props.updateShipmentConfigurationFailed
            }); 
        }
    }

    setConfigurationProfileDetails = (details) => {
        let configurationProfileDetails = {...details};
        configurationProfileDetails.apply_changes_to_active_shipments = false;
        
        configurationProfileDetails.id = details.id;
        configurationProfileDetails.profile_name = details.profile_name;
        configurationProfileDetails.description = details.description;
        configurationProfileDetails.updated_by = details.updated_by;
        configurationProfileDetails.updated_at = details.updated_at;
        //console.log('------> ', configurationProfileDetails.id);
        
        if(configurationProfileDetails.thresholds.hasOwnProperty('TEMPERATURE')){
            //if(configurationProfileDetails.thresholds.TEMPERATURE.timeAbove) {
                configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold = getSplitTimeConfig(configurationProfileDetails.thresholds.TEMPERATURE.above_th_duration);
            //}
            //if(configurationProfileDetails.thresholds.TEMPERATURE.timeBelow) {
                configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold = getSplitTimeConfig(configurationProfileDetails.thresholds.TEMPERATURE.below_th_duration);
            //}
            if(configurationProfileDetails.product_profile_id){
                configurationProfileDetails.thresholds.TEMPERATURE.product_profile_id = configurationProfileDetails.product_profile_id;
            }
        }
        else{
            configurationProfileDetails.thresholds.TEMPERATURE = {
                representation: '',
                min_limit: 0,
                max_limit: 0,
                threshold: 0,
                above_th_duration: 0,
                below_th_duration: 0,
                timeAboveThreshold: {d: 0, h: 0, m: 0},
                timeBelowThreshold: {d: 0, h: 0, m: 0}
            };
        }
        if(configurationProfileDetails.thresholds.hasOwnProperty('HUMIDITY')){}
        else{
            configurationProfileDetails.thresholds.HUMIDITY = {
                min_limit: 0,
                max_limit: 0,
                threshold: 0
            };
        }
        
        if(configurationProfileDetails.thresholds.LID) {
            if(configurationProfileDetails.thresholds.LID.value){}
            else {
                configurationProfileDetails.thresholds.LID.value = configurationProfileDetails.thresholds.LID.max_limit;
            }
            if(configurationProfileDetails.thresholds.LID.max_limit === 80) {
                configurationProfileDetails.thresholds.LID.isLumenDefault = true;
            } else {
                configurationProfileDetails.thresholds.LID.isLumenDefault = false;
            }
        } else {
            configurationProfileDetails.thresholds.LID = {
                value: 0,
                max_limit: 0,
                isLumenDefault: false
            };
        }
        if(configurationProfileDetails.thresholds.SHOCK) {
            if( configurationProfileDetails.thresholds.SHOCK.value === 1.5 ||
                configurationProfileDetails.thresholds.SHOCK.value === 3 ||
                configurationProfileDetails.thresholds.SHOCK.value === 5
                ) {
                configurationProfileDetails.thresholds.SHOCK.isShockDefault = true;
            } else {
                configurationProfileDetails.thresholds.SHOCK.isShockDefault = false;
            }   
        } else {
            configurationProfileDetails.thresholds.SHOCK = {
                value: 0,
                isShockDefault: false
            };
        }
        configurationProfileDetails.show = true;

        this.setState({configurationProfileDetails});
    };
    
    setProductProfileDetails = (details) => {
        let productProfileDetails = {...details};
        const {configurationProfileDetails} = this.state;

        if(productProfileDetails.config.hasOwnProperty('total_shelf_life_duration')){
            configurationProfileDetails.total_shelf_life_duration = productProfileDetails.config.total_shelf_life_duration || '';
        }

        if(productProfileDetails.config.product_profile_config) {
            configurationProfileDetails.product_profile_config = productProfileDetails.config.product_profile_config;
            configurationProfileDetails.product_profile_config.map(pp => {
                pp.threshold_duration = Math.round(pp.threshold_duration / 60);
            });
        }
        if(productProfileDetails.config.product_type){
            configurationProfileDetails.product_type = JSON.parse(JSON.stringify(productProfileDetails.config.product_type));
        }
        configurationProfileDetails.thresholds.temperature_representation = productProfileDetails?.config?.representation || 'C';
        this.setState({configurationProfileDetails});
    };

    getConfigurationProfileDetails = (data) => {
        if(data.id) {
            this.props.onGetConfigurationProfileDetails({
                profileID: data.id,
                onComplete: (configurationProfileDetails) => {
                    this.setConfigurationProfileDetails(configurationProfileDetails);
                    if(configurationProfileDetails.product_profile_id) {
                        this.props.onGetProductProfileDetails({
                            profileID: configurationProfileDetails.product_profile_id,
                            onComplete: (productProfileDetails) => {
                                this.setProductProfileDetails(productProfileDetails);
                            }
                        });
                    }
                }
            })
        } else {
            const {configurationProfileDetails} = this.state;
            configurationProfileDetails.show = false;
            this.setState({configurationProfileDetails});
        }
        
    }

    render () {
        const SHIPMENT_PROFILE_DETAILS = this.state.shipmentProfile || {};
        //console.log('--------ship-config---------> ', SHIPMENT_PROFILE_DETAILS);
        return (
            <>
            <div className="overlay">
                <div className="geo-modal config config-modal">
                    <div className="geo-header">
                        <div className="g-h-txt">{this.state.status == 'New' ? 'Edit ' : '' } Shipment Profile</div>
                        <div
                            className="material-icons geo-close-icn"
                            onClick={() => {
                                this.props.closeShipmentProfileConfiguration();
                            }}
                        >clear</div>
                    </div>
                    <div className="p-l-e" style={{position:'absolute', textAlign: 'right', top: '26px', right: '52px'}}>
                        <div style={{fontSize:'10px'}}>Last updated by</div>
                        <div style={{fontSize:'12px', fontWeight: '600'}}>{SHIPMENT_PROFILE_DETAILS.config?.updated_by || '--'}</div>
                        <div style={{fontSize:'12px', fontWeight: '500'}}>{SHIPMENT_PROFILE_DETAILS.config?.updated_at ? getDate((SHIPMENT_PROFILE_DETAILS.config?.updated_at), false, false, true) : '--'}</div>
                    </div>
                    <div className="geo-body form-sec s-c">
                        <PerfectScrollbar>
                        {this.props.shipmentProfileLoading || this.props.shipmentProfileSaving || this.props.shipmentProfileConfigLoading? <div className="loading abs" /> : ''}
                        <div className="n-form ship-det">
                            {
                                SHIPMENT_PROFILE_DETAILS.config?.legs ?
                                <ShipmentProfile 
                                    key={`SHIPMENT_PROFILE_DETAILS_${SHIPMENT_PROFILE_DETAILS.id}_${this.state.configurationProfileDetails.id}_${this.state.productProfileDetails}`}
                                    SHIPMENT_PROFILE_DETAILS={SHIPMENT_PROFILE_DETAILS}
                                    disableEdit={this.props.shipment_profile_id}
                                    configurationProfileList={this.props.configurationProfileList}
                                    carrierTypeList={this.props.carrierTypeList}
                                    configurationProfileLoading={this.props.configurationProfileLoading}
                                    CONFIGURATION_PROFILE_DETAILS={this.state.configurationProfileDetails}
                                    getConfigurationProfileDetails={this.getConfigurationProfileDetails}
                                />
                                : <></>
                            }
                        </div>
                        </PerfectScrollbar>
                    </div>
                    <div className="geo-ftr">
                        <div 
                            className="c-btn g"
                            onClick={(e)=>{
                                this.props.closeShipmentProfileConfiguration();
                            }}
                        >{this.state.status === 'New' || this.state.status === 'In-Transit' ? 'Cancel' : 'Close'}</div>
                        {this.state.status === 'New' || this.state.status === 'In-Transit' && this.props.editConfiguration ?
                        <div 
                            className="c-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                if(this.props.shipmentProfileSaving) {} 
                                else {
                                    this.isValidConfiguration().then((resolve, reject) => {
                                        if(resolve) {
                                            if(this.state.newProfileID) {
                                                this.setState({showSaveConfirmation: true});
                                            } else {
                                                this.saveShipmentConfigurationConfirm();
                                            }                                            
                                        }
                                    });
                                }                             
                            }}
                        >Update</div>
                        : ''}
                    </div>
                </div>
            </div>
            <SweetAlert
                show={this.state.showSaveConfirmation}
                title={this.state.saveConfirmationTitle}
                className="save-warning"
                status={'warning'}
                showCancelBtn={true}
                cancelBtnText="NO"
                confirmBtnText="YES"
                onCancelClick={() => {
                    this.setState({showSaveConfirmation: false});
                }}
                onConfirmClick={async () => {
                    if(this.props.configurationProfileSaving) {}
                    else{
                        this.saveShipmentConfigurationConfirm();
                    }
                }}
            />
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        shipmentProfileLoading: state.configuration.shipmentProfileLoading,
        shipmentProfile: state.configuration.shipmentProfile,
        shipmentProfileSaving: state.configuration.shipmentProfileSaving,
        shipmentProfileError: state.configuration.shipmentProfileError,
        configurationProfileLoading: state.configuration.configurationProfileLoading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onGetShipmentConfiguration: (obj) => dispatch(actions.getShipmentConfiguration(obj)),
        onUpdateShipmentConfiguration: (obj) => dispatch(actions.updateShipmentConfiguration(obj)),
        onGetShipmentProfile: (obj) => dispatch(actions.getShipmentProfile(obj)),
        onGetConfigurationProfileDetails: (obj) => dispatch(actions.getConfigurationProfile(obj)),
        onGetProductProfileDetails: (obj) => dispatch(actions.getProductProfile(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentConfiguration);