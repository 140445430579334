import React from 'react';
class SweetAlert extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            if (this.props.show) {
                this.handleOpen();
                if (!this.props.onConfirmClick) {
                    setTimeout(() => {
                        this.props.onClose();
                    }, (this.props.displayDuration || 2000));
                }
            } else {
                this.closeModal();
            }
        }
    }
    handleOpen = () => {
        document.getElementById(`my-alert${this.props.className ? ` ${this.props.className}` : ''}`).style.display =
            'block';
    };
    closeModal = () => {
        document.getElementById(`my-alert${this.props.className ? ` ${this.props.className}` : ''}`).style.display =
            'none';
    };
    handleClick = () => {
        this.props.onConfirmClick();
    };
    render() {
        return (
            <div id={`my-alert${this.props.className ? ` ${this.props.className}` : ''}`} className="alert" style={{display: this.props.show ? 'block' : 'none'}}>
                <div className={'alert-content'}>
                    {/* <div className="alert-header"></div> */}
                    <div className={`alert-body ${this.props.onConfirmClick ? '' : 'bdr'}`}>
                        <div>
                        {this.props.status === 'success' ? (
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle
                                    className="path circle"
                                    fill="none"
                                    stroke="#28a745"
                                    strokeWidth="6"
                                    strokeMiterlimit="10"
                                    cx="65.1"
                                    cy="65.1"
                                    r="62.1"
                                />
                                <polyline
                                    className="path check"
                                    fill="none"
                                    stroke="#28a745"
                                    strokeWidth="6"
                                    strokeLinecap="round"
                                    strokeMiterlimit="10"
                                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                />
                            </svg>
                        ) : this.props.status === 'warning' ? (
                            <div className="warning">!</div>
                        ) : (
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                <circle
                                    className="path circle"
                                    fill="none"
                                    stroke="#dc3545"
                                    strokeWidth="6"
                                    strokeMiterlimit="10"
                                    cx="65.1"
                                    cy="65.1"
                                    r="62.1"
                                />
                                <line
                                    className="path line"
                                    fill="none"
                                    stroke="#dc3545"
                                    strokeWidth="6"
                                    strokeLinecap="round"
                                    strokeMiterlimit="10"
                                    x1="34.4"
                                    y1="37.9"
                                    x2="95.8"
                                    y2="92.3"
                                />
                                <line
                                    className="path line"
                                    fill="none"
                                    stroke="#dc3545"
                                    strokeWidth="6"
                                    strokeLinecap="round"
                                    strokeMiterlimit="10"
                                    x1="95.8"
                                    y1="38"
                                    x2="34.4"
                                    y2="92.2"
                                />
                            </svg>
                        )}
                        </div>
                        <div className="alert-text">{this.props.title||'---'}</div>
                    </div>
                    {this.props.onConfirmClick ? (
                        <div className="alert-footer">
                            <div style={{ height: '10px' }}>
                                {this.props.loading ? <div className="loading" /> : ''}
                            </div>
                            <div className="alert-btns">
                                {this.props.showExtCancelBtn ? (
                                    <button
                                        className="alert-button cancel ext"
                                        onClick={() => {
                                            this.props.onExtCancelClick();
                                        }}
                                    >
                                        {this.props.extCancelBtnText ? this.props.extCancelBtnText : 'CANCEL'}
                                    </button>
                                ) : (
                                    ''
                                )}
                                {this.props.showCancelBtn ? (
                                    <button
                                        className="alert-button cancel"
                                        onClick={() => {
                                            this.props.onCancelClick();
                                        }}
                                    >
                                        {this.props.cancelBtnText ? this.props.cancelBtnText : 'CANCEL'}
                                    </button>
                                ) : (
                                    ''
                                )}
                                <button
                                    className="alert-button"
                                    onClick={this.handleClick}
                                    disabled={this.props.loading ? this.props.loading : false}
                                >
                                    {this.props.confirmBtnText ? this.props.confirmBtnText : 'OK'}
                                </button>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}

export default SweetAlert;
