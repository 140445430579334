import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';
//import { viewGroup } from 'redux-store/actions/company';
const intialState = {
    companies: [],
    loading: true,
    userLoading: false,
    companyUserList: [],
    errors: {},
    companyFlag: false,
    error_message: '',
    deviceLoading: false,
    companyDeviceList: [],
    shipmentCountMessage: '',
    deleteMessage: '',
    logout: false,
    company_details: {},
    detailsMessage: '',
    detailsLoading: true,
    groups:[],
    groupUserList:[],
    groupDeviceList:[],
    groupCompanyList:[],
    groupFlag:false,
    groupShipmentCountMessage:'',
    groupDeleteMessage:'',
    groupCompanyLoading:false,
    groupUserLoading:false,
    groupLoading: true,
    groupDeviceLoading:false,
    viewGroup:[],
    viewGroupLoading:false,
    assignMessage:'',
    makeGroupAdminMessage:'',
    featuresLoading: false,
    features: [],
    productStatusTemplatesLoading: false,
    productStatusTemplates: {},
    productStatusTemplatesMessage: {}
};
const sendCompanyList = (state, action) => {
    return updateObject(state, { companies: action.data, loading: false });
};
const companyListStart = (state, action) => {
    return updateObject(state, { loading: true, companies: [] });
};
const companyUserListStart = (state, action) => {
    return updateObject(state, { userLoading: true });
};
const sendCompanyUserList = (state, action) => {
    return updateObject(state, { companyUserList: action.data, userLoading: false });
};

const sendNewCompanyStart = (state, action) => {
    return updateObject(state, { errors: {}, companyFlag: false, error_message: '' });
};

const sendNewCompanySuccess = (state, action) => {    
    return updateObject(state, { errors: action.errors, companyFlag: action.flag, error_message: action.message});
};

const companyDeviceListStart = (state, action) => {
    return updateObject(state, { companyDeviceList: [], deviceLoading: true });
};

const sendCompanyDevicesList = (state, action) => {
    return updateObject(state, { companyDeviceList: action.list, deviceLoading: action.flag });
};

const sendCompanyShipmentCountSuccess = (state, action) => {
    return updateObject(state, { shipmentCountMessage: action.message });
};
const sendDeleteCompanySuccess = (state, action) => {
    return updateObject(state, { deleteMessage: action.message });
};
const confirmLogout = (state, action) => {
    return updateObject(state, { logout: action.flag });
};
const setNetworkConnectionError = (state, action) => {
    return updateObject(state, { networkError: action.flag });
};
const setInternalServerError = (state, action) => {
    return updateObject(state, { serverError: action.flag });
};
const sendCompanyDetails = (state, action) => {
    return updateObject(state, {
        detailsLoading: action.loading,
        company_details: action.data,
        detailsMessage: action.message,
    });
};
const companyDetailsStart = (state, action) => {
    return updateObject(state, { detailsLoading: true });
};
const sendGroupList = (state, action) => {
    const groups = [...action.data]; 
    groups.sort((a,b) => {
        if (a.name?.toUpperCase() < b.name?.toUpperCase()) {
            return -1;
        }
        if (b.name?.toUpperCase() < a.name?.toUpperCase()) {
            return 1;
        }
        return 0;
    });
    return updateObject(state, { groups, groupLoading: false });
};
const groupListStart = (state, action) => {
    return updateObject(state, { groupLoading: true, groups: [] });
};
const groupUserListStart = (state, action) => {
    return updateObject(state, { groupUserLoading: true });
};
const sendGroupUserList = (state, action) => {
    return updateObject(state, { groupUserList: action.data, groupUserLoading: false });
};

const sendNewGroupStart = (state, action) => {
    return updateObject(state, { errors: {}, groupFlag: false, error_message: '' });
};

const sendNewGroupSuccess = (state, action) => {
    return updateObject(state, { errors: action.errors, groupFlag: action.flag, save_group_message: action.message });
};

const groupDeviceListStart = (state, action) => {
    return updateObject(state, { groupDeviceList: [], groupDeviceLoading: true });
};

const sendGroupDevicesList = (state, action) => {
    return updateObject(state, { groupDeviceList: action.list, groupDeviceLoading: action.flag });
};
const groupCompanyListStart = (state, action) => {
    return updateObject(state, { groupCompanyList: [], groupCompanyLoading: true });
};

const sendGroupCompanyList = (state, action) => {
    return updateObject(state, { groupCompanyList: action.list, groupCompanyLoading: action.flag });
};

const sendGroupShipmentCountSuccess = (state, action) => {
    return updateObject(state, { groupShipmentCountMessage: action.message });
};
const sendDeleteGroupSuccess = (state, action) => {
    return updateObject(state, { groupDeleteMessage: action.message });
};
const viewGroupStart = (state,action)=>{
    return updateObject(state,{viewGroup:[],viewGroupLoading:true});
}
const viewGroupSuccess = (state,action)=>{
    return updateObject(state,{viewGroup:action.data,viewGroupLoading:false})
}
const assignCompaniesToGroupSuccess = (state,action)=>{
    return updateObject(state,{assignMessage:action.message})
}
const removeAssignedCompaniesSuccess = (state,action)=>{
    return updateObject(state,{assignMessage:action.message})
}
const makeGroupAdminSucess = (state,action) =>{
    return updateObject(state,{makeGroupAdminMessage:action.message});
}
const getCompanyFeaturesStart = (state,action) =>{
    return updateObject(state,{featuresLoading: true});
}
const getCompanyFeaturesSuccess = (state,action) =>{
    return updateObject(state,{features: action.data, featuresLoading: false});
}
const updateCompanyFeaturesStart = (state,action) =>{
    return updateObject(state,{updateFeaturesLoading: true});
}
const updateCompanyFeaturesSuccess = (state,action) =>{
    return updateObject(state,{features: action.data, updateFeaturesLoading: false});
}
const getCompanyProductStatusTemplatesStart = (state,action) =>{
    return updateObject(state,{productStatusTemplates: {APPROVE: {},REJECT: {}, ASSESS: {}}, productStatusTemplatesLoading: true});
}
const getCompanyProductStatusTemplatesSuccess = (state,action) =>{
    return updateObject(state,{productStatusTemplates: action.data, productStatusTemplatesLoading: false});
}
const updateCompanyProductStatusTemplatesStart = (state,action) =>{
    return updateObject(state,{productStatusTemplatesLoading: true});
}
const updateCompanyProductStatusTemplatesSuccess = (state,action) =>{
    return updateObject(state,{productStatusTemplatesMessage: action.message, productStatusTemplatesLoading: false});
}
const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANIES_SUCCESS:
            return sendCompanyList(state, action);
        case actionTypes.GET_COMPANIES_START:
            return companyListStart(state, action);
        case actionTypes.GET_COMPANY_USER_LIST_START:
            return companyUserListStart(state, action);
        case actionTypes.GET_COMPANY_USERS_SUCCESS:
            return sendCompanyUserList(state, action);
        case actionTypes.ADD_NEW_COMPANY_START:
            return sendNewCompanyStart(state, action);
        case actionTypes.ADD_NEW_COMPANY_SUCCESS:
            return sendNewCompanySuccess(state, action);
        case actionTypes.GET_COMPANY_DEVICE_LIST_START:
            return companyDeviceListStart(state, action);
        case actionTypes.GET_COMPANY_DEVICES_SUCCESS:
            return sendCompanyDevicesList(state, action);
        case actionTypes.GET_COMPANY_SHIPMENT_COUNT_SUCCESS:
            return sendCompanyShipmentCountSuccess(state, action);
        case actionTypes.DELETE_COMPANY_SUCCESS:
            return sendDeleteCompanySuccess(state, action);
        case actionTypes.USER_LOGOUT:
            return confirmLogout(state, action);
        case actionTypes.NO_NETWORK_CONNECTION:
            return setNetworkConnectionError(state, action);
        case actionTypes.INTERNAL_SERVER_ERROR:
            return setInternalServerError(state, action);
        case actionTypes.GET_COMPANY_DETAILS_SUCCESS:
            return sendCompanyDetails(state, action);
        case actionTypes.GET_COMPANY_DETAILS_START:
            return companyDetailsStart(state, action);
        case actionTypes.GET_GROUPS_LIST_SUCCESS:
            return sendGroupList(state, action);
        case actionTypes.GET_GROUPS_LIST_START:
            return groupListStart(state, action);
        case actionTypes.GET_GROUPS_USERS_LIST_START:
            return groupUserListStart(state, action);
        case actionTypes.GET_GROUPS_USERS_SUCCESS:
            return sendGroupUserList(state, action);
        case actionTypes.ADD_NEW_GROUP_START:
            return sendNewGroupStart(state, action);
        case actionTypes.ADD_NEW_GROUP_SUCCESS:
            return sendNewGroupSuccess(state, action);
        case actionTypes.GET_GROUPS_DEVICES_LIST_START:
            return groupDeviceListStart(state, action);
        case actionTypes.GET_GROUPS_DEVICES_LIST_SUCCESS:
            return sendGroupDevicesList(state, action);
        case actionTypes.GET_GROUPS_COMPANIES_LIST_START:
            return groupCompanyListStart(state, action);
        case actionTypes.GET_GROUPS_COMPANIES_LIST_SUCCESS:
            return sendGroupCompanyList(state, action);
        case actionTypes.GET_GROUP_SHIPMENT_COUNT_SUCCESS:
            return sendGroupShipmentCountSuccess(state, action);
        case actionTypes.DELETE_GROUP_SUCCESS:
            return sendDeleteGroupSuccess(state, action);
        case actionTypes.VIEW_GROUP_START:
            return viewGroupStart(state,action);
        case actionTypes.VIEW_GROUP_SUCCESS:
            return viewGroupSuccess(state,action);
        case actionTypes.ASSIGN_COMPANIES_TO_GROUP_SUCCESS:
            return assignCompaniesToGroupSuccess(state,action);
        case actionTypes.REMOVE_ASSIGNED_COMPANIES_SUCCESS:
            return removeAssignedCompaniesSuccess(state,action);
        case actionTypes.MAKE_GROUP_ADMIN_SUCCESS:
            return makeGroupAdminSucess(state,action);
        case actionTypes.GET_COMPANY_FEATURES_START:
            return getCompanyFeaturesStart(state,action);
        case actionTypes.GET_COMPANY_FEATURES_SUCCESS:
            return getCompanyFeaturesSuccess(state,action);
        case actionTypes.UPDATE_COMPANY_FEATURES_START:
            return updateCompanyFeaturesStart(state,action);
        case actionTypes.UPDATE_COMPANY_FEATURES_SUCCESS:
            return updateCompanyFeaturesSuccess(state,action);
        case actionTypes.GET_COMPANY_PRODUCT_STATUS_TEMPLATES_START:
            return getCompanyProductStatusTemplatesStart(state,action);
        case actionTypes.GET_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS:
            return getCompanyProductStatusTemplatesSuccess(state,action);
        case actionTypes.UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_START:
            return updateCompanyProductStatusTemplatesStart(state,action);
        case actionTypes.UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS:
            return updateCompanyProductStatusTemplatesSuccess(state,action);
        default:
            return state;
    }
};

export default reducer;
