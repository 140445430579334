import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from 'react-redux';
import * as actions from '../../../redux-store/actions';
import { getDate } from '../../../utility/utility';
import CreateShipment from '../CreateShipment';
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import SweetAlert from '../../SweetAlert/SweetAlert';
import ReachedDestinationIcon from 'assets/images/ReachedDestination.png';
import ShipmentCreatedIcon from 'assets/images/ShipmentCreated.svg';
import TemperatureIcon from 'assets/images/temperature.svg';
import StopEntryIcon from 'assets/images/entry.svg';
import StopExitIcon from 'assets/images/exit.svg';
import HumiditySvgTimelineIcon from 'assets/images/humidity_timeline_icon.svg';
import CompletedIcon from 'assets/images/Completed.png';
import BatteryIcon from 'assets/images/battery.svg';
import OriginIcon from 'assets/images/Origin.png';
import PackedIcon from 'assets/images/Packed.png';
import Deviation from 'assets/images/Route-Deviation.png';
import Shock from 'assets/images/shock.png';
import LidOpened from 'assets/images/LidOpened.svg';
import heatDeviationIcon from "assets/images/t_heat_deviation.svg";
import heatWarningIcon from "assets/images/t_heat_warning.svg";
import coldDeviationIcon from "assets/images/t_cold_deviation.svg";
import coldWarningIcon from "assets/images/t_cold_warning.svg";
import dryDeviationIcon from 'assets/images/h_dry_deviation.svg';
import dryWarningIcon from 'assets/images/h_dry_warning.svg';
import wetDeviationIcon from 'assets/images/h_wet_deviation.svg';
import wetWarningIcon from 'assets/images/h_wet_warning.svg';
//import { messaging } from "init-fcm.js";
import ReactTimeAgo from 'react-time-ago'
import JavascriptTimeAgo from 'javascript-time-ago'
import temperatureTimeAboveIcon from "assets/images/t_time_above.svg";
import temperatureTimeBelowIcon from "assets/images/t_time_below.svg";
import shipmentDelayedIcon from "assets/images/shipment_delayed.svg";
// The desired locales.
import en from 'javascript-time-ago/locale/en';
import PerfectScrollbar from 'components/ScrollBarHOC/scrollBarHOC';
import { googleAnalyticActions } from '../../../init-ga';
import Spin from 'assets/images/icons8-iphone-spinner.gif';
import Echo from 'laravel-echo';
import socketio from 'socket.io-client';
import SessionExpired from 'components/Auth/SessionExpired';
import { getNotifications, getNotificationsSuccess, getUnreadNotificationsCount, setNotificationRead, getShipmentReportsSuccess, sendLogout } from "../../../redux-store/actions";

const headerLabels = require(`../../../config/0/headerLabels.js`).default;

let PRIVATE = null;
let SESSTION_TIMEOUT = null;

const MobileConfig = {
    mobile: window.screen.width < 1024 ? true : false
}
const ARROW_COLOR = {
    'TEMPERATURE WARNING': 'warning',
    'TEMPERATURE ALARM': 'danger',
    'HUMIDITY WARNING': 'warning',
    'HUMIDITY ALARM': 'danger',
    'HEAT WARNING': 'h-warning',
    'HEAT DEVIATION': 'h-danger',
    'HEAT ALERT': 'h-danger',
    'COLD WARNING': 'c-warning',
    'COLD DEVIATION': 'c-danger',
    'COLD ALERT': 'c-danger',
    'DRY WARNING': 'd-warning',
    'DRY DEVIATION': 'd-danger',
    'DRY ALERT': 'd-danger',
    'WET WARNING': 'w-warning',
    'WET DEVIATION': 'w-danger',
    'WET ALERT': 'w-danger',
    'MOISTURE WARNING': 'w-warning',
    'MOISTURE DEVIATION': 'w-danger',
    'MOISTURE ALERT': 'w-danger',
    'BATTERY LOW': 'warning',
    'BATTERY CRITICAL': 'danger',
};
const ColorsMap = {
    'TEMPERATURE-MINOR': 'none',
    'TEMPERATURE-MAJOR': 'none',
    'HEAT WARNING': 'none',
    'HEAT ALARM': 'none',
    'HEAT DEVIATION': 'none',
    'COLD ALARM': 'none',
    'COLD DEVIATION': 'none',
    'COLD WARNING': 'none',
    'DRY WARNING': 'none',
    'DRY ALARM': 'none',
    'DRY DEVIATION': 'none',
    'MOISTURE ALARM': 'none',
    'MOISTURE DEVIATION': 'none',
    'MOISTURE WARNING': 'none',
    'TIME ABOVE HIGH TEMPERATURE THRESHOLD': 'none',
    'TIME BELOW LOW TEMPERATURE THRESHOLD': 'none',
    'MINOR-TEMPERATURE': 'warning',
    'MAJOR-TEMPERATURE': 'danger',
    'MINOR-HUMIDITY': 'warning',
    'MAJOR-HUMIDITY': 'danger',
    'SHOCK-MINOR': 'warning',
    'SHOCK-NORMAL': 'warning',
    'SHOCK-MAJOR': 'danger',
    'LOCATION DEVIATION-0': 'warning',
    'CREATED-CREATED': 'primary',
    'COMPLETED-COMPLETED': 'success',
    'PACKED-PACKED': 'primary',
    'ORIGIN-ORIGIN': 'primary',
    'DESTINATION-DESTINATION': 'success',
    'LID-MAJOR': 'danger',
    'LID-NORMAL': 'warning',
    'LID-MINOR': 'warning',
    'BATTERY-NORMAL': 'success',
    'BATTERY-MAJOR': 'danger',
    'BATTERY-MINOR': 'warning',
    'TRACKING-NORMAL': 'success',
    'TRACKING-MAJOR': 'danger',
    'TRACKING-MINOR': 'warning',
    'STOP-ENTRY': 'success',
    'STOP-EXIT': 'success',
    'GEOFENCE-ENTRY': 'success',
    'GEOFENCE-EXIT': 'success',
    'SHIPMENT DELAYED': 'danger',
    'AT_ENTRY-AT_ENTRY': 'success',
    'AT_EXIT-AT_EXIT': 'success'
};
const IconsMap = {
    'TEMPERATURE': <img src={TemperatureIcon} alt="temperature icon" />,
    'HUMIDITY': <img src={HumiditySvgTimelineIcon} alt="humidity icon" />,
    'HEAT WARNING': <img src={heatWarningIcon} alt="Heat warning icon" />,
    'HEAT ALARM': <img src={heatDeviationIcon} alt="Heat deviation icon" />,
    'HEAT DEVIATION': <img src={heatDeviationIcon} alt="Heat deviation icon" />,
    'COLD ALARM': <img src={coldDeviationIcon} alt="Cold deviation icon" />,
    'COLD DEVIATION': <img src={coldDeviationIcon} alt="Cold deviation icon" />,
    'COLD WARNING': <img src={coldWarningIcon} alt="Cold warning icon" />,
    'DRY WARNING': <img src={dryWarningIcon} alt="Dry warning icon" />,
    'DRY ALARM': <img src={dryDeviationIcon} alt="Dry deviation icon" />,
    'DRY DEVIATION': <img src={dryDeviationIcon} alt="Dry deviation icon" />,
    'WET ALARM': <img src={wetDeviationIcon} alt="Wet deviation icon" />,
    'WET DEVIATION': <img src={wetDeviationIcon} alt="Wet deviation icon" />,
    'WET WARNING': <img src={wetWarningIcon} alt="Wet warning icon" />,
    'MOISTURE ALARM': <img src={wetDeviationIcon} alt="Wet deviation icon" />,
    'MOISTURE DEVIATION': <img src={wetDeviationIcon} alt="Wet deviation icon" />,
    'MOISTURE WARNING': <img src={wetWarningIcon} alt="Wet warning icon" />,
    'LOCATION DEVIATION': <img src={Deviation} alt="reached destination icon" />,
    'CREATED': <img src={ShipmentCreatedIcon} alt="shipment created icon" />,
    'SHOCK': <img src={Shock} alt="shipment created icon" />,
    'COMPLETED': <img src={CompletedIcon} alt="shipment completed icon" />,
    'PACKED': <img src={PackedIcon} alt="shipment completed icon" />,
    'ORIGIN': <img src={OriginIcon} alt="shipment completed icon" />,
    'DESTINATION': <img src={ReachedDestinationIcon} alt="reached destination icon" />,
    'LID': <img src={LidOpened} alt="lid opened" />,
    'BATTERY': <img src={BatteryIcon} alt="Battery" />,
    'STOP-ENTRY': <img src={StopEntryIcon} alt="Stop Entry" />,
    'STOP-EXIT': <img src={StopExitIcon} alt="Stop Exit" />,
    'GEOFENCE-ENTRY': <img src={StopEntryIcon} alt="Geofence Entry" />,
    'GEOFENCE-EXIT': <img src={StopExitIcon} alt="Geofence Exit" />,
    'AT_ENTRY': <img src={StopEntryIcon} alt="Geofence Entry" />,
    'AT_EXIT': <img src={StopExitIcon} alt="Geofence Exit" />,
    'TIME ABOVE HIGH TEMPERATURE THRESHOLD': <img src={temperatureTimeAboveIcon} alt="Temperature time above threshold" />,
    'TIME BELOW LOW TEMPERATURE THRESHOLD': <img src={temperatureTimeBelowIcon} alt="Temperature time below threshold" />,
    'SHIPMENT DELAYED': <img src={shipmentDelayedIcon} alt="Shipment Delayed" />
};

const TITLE_MAPPING = {
    'shipments_active/' : headerLabels.visibility.sub_menu.active_shipment,
    'shipments_active' : headerLabels.visibility.sub_menu.active_shipment,
    'pdfparser' : headerLabels.visibility.sub_menu.pdf_upload,
    'insights' : headerLabels.visibility.sub_menu.active_shipments_insghts,
    'temperature_dashboard' : headerLabels.visibility.sub_menu.passive_insights,
    'new_passive_dashboard' : headerLabels.visibility.sub_menu.passive_insights,
    'active_dashboard' : headerLabels.visibility.sub_menu.active_insights,
    'new_active_dashboard' : headerLabels.visibility.sub_menu.active_insights,
    'assets' : headerLabels.visibility.sub_menu.asset_tracking,
    'riskiq' : headerLabels.risk.sub_menu.risk_assessment,
    'riskplanning' : headerLabels.risk.sub_menu.risk_planning,
    'liveriskiq' : headerLabels.risk.sub_menu.live_risk,
    'config' : headerLabels.config.sub_menu.shipment_config,
    'config_userConfig' : headerLabels.config.sub_menu.user_config,
    'config_parserConfig' : headerLabels.config.sub_menu.parser_config,
    'config_templates' : headerLabels.config.sub_menu.edit_templates,
    'companies' : headerLabels.manage.sub_menu.company,
    'users' : headerLabels.manage.sub_menu.User,
    'devices' : headerLabels.manage.sub_menu.device,
    'help' : headerLabels.help.label,
    'profile' : 'User Profile',
    'dashboard' : 'Dashboard',
    'undefined' : 'CONTXT'
}
//let fcm_token = { called: false, token: null };
//const getToken = localStorage.getItem('access_token');
JavascriptTimeAgo.locale(en);

let currentPage = 1;
let lastPage = 1;

let notifications = [];
const ROLES = localStorage.getItem('roles');
const userId = localStorage.getItem('id');
const companyId = localStorage.getItem('companyId');
const companyName = localStorage.getItem('company')?.toLowerCase();

window.onbeforeunload = () => {
    console.log('------->before unload operations...HEADER..');
    if (window.location.pathname.indexOf('riskiq') === -1) {
        localStorage.removeItem('lane_id');
    }
    if (window.Echo && window.Echo.disconnect) {
        console.log('-------> unload operations...HEADER..');
        window.Echo.disconnect();
        console.log(`disconnected...socket connection:`);
    }
};


const Header = (props) => {

    const dispatch = useDispatch();
    const ud = JSON.parse(localStorage.getItem('userDetails'));

    const onGetNotifications = (data) => dispatch(getNotifications(data));
    const onGetNotificationsSuccess = (data) => dispatch(getNotificationsSuccess(data));
    const onGetUnreadNotificationsCount = (data) => dispatch(getUnreadNotificationsCount(data));
    const onSetNotificationRead = (data) => dispatch(setNotificationRead(data));
    const onGetShipmentReportsSuccess = (data) => dispatch(getShipmentReportsSuccess(data));
    const setSessionExpire = (data) => dispatch(sendLogout(data));

    const dashboard = useSelector(state => state.dashboard);
    const checkLogout = useSelector(state => state.company.logout);
    const serverError = useSelector(state => state.company.serverError);
    const networkError = useSelector(state => state.company.networkError);

    const [initials, setInitials] = useState(`${ud?.firstname[0]}${ud?.lastname[0]}`);
    const [company, setCompany] = useState(ud?.company);
    const [showNotifications, setShowNotifications] = useState(false);
    const [addShipment, SetAddShipment] = useState(false);
    const [notificationMessages, setNotificationMessages] = useState([]);
    const [checkTailwind, setCheckTailwind] = useState(false);
    const { logout } = useAuth0();

    const access_token = localStorage.getItem('access_token');

    useEffect(() => {
        const URL = window.location.pathname.substring(1).replace('/', '_');
        document.title = TITLE_MAPPING[URL];

        if(["temperature_dashboard", "active_dashboard"].some((word) => window.location.href.includes(word))){
            setCheckTailwind(true);
        }
        
    }, []);

    useEffect(async () => {
        console.log('Preparing connection for Echo..........: ', process.env.REACT_APP_redis_host);
        window.Echo = new Echo({
            host: process.env.REACT_APP_redis_host,
            broadcaster: 'socket.io',
            transports: ['websocket', 'polling', 'flashsocket'],
            client: socketio,
            authEndpoint: "/api/broadcasting/auth",
            auth: {
                headers: {
                    Authorization: "Bearer " + access_token,
                    'x-api-key': process.env.REACT_APP_X_API_KEY
                }
            },
            activityTimeout: 60000
        });
        //localStorage.debug = '*';

        // window.Echo.connector.socket.on('connect', (e) => {
        //     console.log('connection established........APP..... ', e);
        // });
        // // localStorage.debug = '*';

        // window.Echo.connector.socket.on('connect_error', (param) => {
        //     console.log('connection connect_error_........APP..... ', param);
        // });

        // window.Echo.connector.socket.on('connect_error', (param) => {
        //     console.log('connection connect_error_........APP..... ', param);
        // });

        // window.Echo.connector.socket.on('leave', (param) => {
        //     console.log('connection leave..........APP.... ', param);
        // });

        // window.Echo.connector.socket.on('disconnect', (param, param2) => {
        //     console.log('connection disconnected..........APP.... ', param, param2);
        // });

        // window.Echo.connector.socket.on('ping', (param, param2) => {
        //     console.log('connection ping..........APP.... : ', param, param2);
        // });

        // window.Echo.connector.socket.on('pong', (param, param2) => {
        //     console.log('connection pong..........APP.... : ', param, param2);
        // });

        // window.Echo.connector.socket.on('reconnecting', (param, param2) => {
        //     console.log('connection reconnecting..........APP.... : ', param, param2);
        // });

        // window.Echo.connector.socket.on('reconnect', (param, param2) => {
        //     console.log('connection reconnect..........APP.... : ', param, param2);
        // });

        // window.Echo.connector.socket.on('reconnect_attempt', (param, param2) => {
        //     console.log('connection reconnect_attempt..........APP.... : ', param, param2);
        // });

        // window.Echo.connector.socket.on('reconnect_error', (param, param2) => {
        //     console.log('connection reconnect_error..........APP.... : ', param, param2);
        // });

        // window.Echo.connector.socket.on('reconnect_failed', (param, param2) => {
        //     console.log('connection reconnect_failed..........APP.... : ', param, param2);
        // });

        if (onGetUnreadNotificationsCount) {
            await onGetUnreadNotificationsCount();
        }
        
        PRIVATE = window.Echo.private(`App.User.${userId}`);
        console.log('connecting to channel: ', `App.User.${userId}`);
        
        //setTimeout(() => {
        PRIVATE.listen('ShipmentReport', async ev => {
            //console.log('-------------->ev====> ', ev);
            if(ev.config && Number(ev.userId) === Number(userId)) {
                
                console.log('-ShipmentReport--ev---> ', ev);
                const {shipmentReports} = dashboard;
                shipmentReports.forEach((report, index) => {
                    if(Number(report.id) === Number(ev.config.id)) {
                        shipmentReports[index].report_link = ev.config.report_link;
                        shipmentReports[index].report_status = ev.config.report_status;
                    }
                });
                onGetShipmentReportsSuccess(shipmentReports);
                if((ev.config.report_status === 'COMPLETED' || ev.config.report_status === 'FAILED')){
                    if(notificationMessages?.length && (ev.config.id === notificationMessages[notificationMessages.length-1]?.id && ev.config.report_status === notificationMessages[notificationMessages.length-1]?.report_status)) {}
                    else {
                        notificationMessages.push(ev.config);
                        if(notificationMessages.length>2) {
                            notificationMessages.splice(0,1);
                        }
                        setNotificationMessages(notificationMessages);
                        setTimeout(() => {
                            notificationMessages.splice(0,1);
                            setNotificationMessages(notificationMessages);
                        }, 5000);
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        currentPage = 1;
        lastPage = 1;

        if (PRIVATE) {
            window.Echo.leave(`App.User.${userId}`);
            PRIVATE.stopListening('ShipmentReport');
            //window.Echo.disconnect();
        }
    }, [userId]);

    useEffect(() => {
        if(access_token) {
            const loc = window.location.href; 
            if(loc.indexOf('sid') > 0 && loc.indexOf('rid') > 0) {
                sessionStorage.setItem('lvu', loc);
            }
        }
        else {
            const loc = window.location.href;
            if(loc.indexOf('sid') > 0 && loc.indexOf('rid') > 0) {
                sessionStorage.setItem('lvu', loc);
            }
            if(localStorage.getItem('sso')){
                window.location.replace('/loggedOut');
            } else {
                window.location.replace('/login');
            }
        }

        /*Clearing session timeout*/
        if(SESSTION_TIMEOUT) clearTimeout(SESSTION_TIMEOUT);
        /*Verify if token expired*/
        const J = jwtDecode(access_token);
        const remainingTime = J.exp * 1000 - new Date().getTime();
        SESSTION_TIMEOUT = setTimeout(() => {
            /*Redirect to Session Timeout id expires*/
            setSessionExpire(true);
            clearTimeout(SESSTION_TIMEOUT);
        }, remainingTime>=0?remainingTime:0);

    }, []);

    useEffect(() => {
        onGetUnreadNotificationsCount();
    }, [dashboard.setNotificationRead, dashboard.setNotificationReadLoading]);

    const onLogout = () => {
        // e.stopPropagation();
        let email_p = '';
        let password_p = '';
        if (localStorage.getItem('email_paxafe')) {
            email_p = localStorage.getItem('email_paxafe');
        }
        if (localStorage.getItem('password_paxafe')) {
            password_p = localStorage.getItem('password_paxafe');
        }
        localStorage.clear();
        if (email_p) {
            localStorage.setItem('email_paxafe', email_p);
        }
        if (password_p) {
            localStorage.setItem('password_paxafe', password_p);
        }
        window.location.href = '/login';
    };

    const logoutWithRedirect = (clickedLogout) => {
        localStorage.clear();
        clearCookies();
        sessionStorage.clear();
        const loc = window.location.href;
        if(clickedLogout && loc.indexOf('sid') > 0 && loc.indexOf('rid') > 0) {
            sessionStorage.setItem('lvu', loc);
        }
        const logoutParams = {
            returnTo: ud.sso ? process.env.REACT_APP_AUTH0_LOGOUT_URL : process.env.REACT_APP_AUTH0_CALLBACK_URL,
            // returnTo: 'http://localhost:3000',
        };
        logout({logoutParams});
    }

    const clearCookies = () => {
        var allCookies = document.cookie.split(';');
        for (var i = 0; i < allCookies.length; i++) {
            document.cookie = allCookies[i] + "=; expires=" + new Date(0).toUTCString();
        }
    }

    const closeNotifications = (e, close) => {
        if (!e.target.closest('div.notifi-pop') || close) {
            setShowNotifications(false);
            document.body.removeEventListener('click', closeNotifications);
        }
    };

    const markReadAllNotifications = (e) => {
        e.stopPropagation();
        markReadNotifications({ all: true });
        closeNotifications(e, 'force close');
    };

    const markReadNotifications = (data, clear) => {
        onSetNotificationRead({ ...data });
    };

    const toggleNotifications = (e) => {
        e.stopPropagation();
        if (dashboard.unreadNotifications !== 0) {
            setShowNotifications(true);
            onGetNotifications(1);
            document.body.addEventListener('click', closeNotifications);
        }
    };

    const responsive = () => {
        var x = document.getElementById('nav-links');
        if (x.classList.length == 2) {
            x.classList.add('responsive');

        }
        else {
            x.classList.remove('responsive');
        }
    };

    const shipmentsSelect = () => {
        //   this.setState({childNodes:!this.state.childNodes})
        var x = document.getElementById('shipment-child-nodes');
        if (x.classList.length == 1) {
            x.classList.add('show');
        }
        else {
            x.classList.remove('show');
        }
    };

    const toggleAddShipment = (e) => {
        if(e) e.stopPropagation();
        SetAddShipment(!addShipment);
    };

    notifications = dashboard && dashboard.notifications ? dashboard.notifications : [];
    currentPage = dashboard && dashboard.notificationsMeta ? dashboard.notificationsMeta.current_page : 1;
    lastPage = dashboard && dashboard.notificationsMeta ? dashboard.notificationsMeta.last_page : 1;
    const pathname = window.location.pathname;

    return (
        <>
            <div className="hdr">
                <a href="/" onClick={()=>{
                    googleAnalyticActions.addEvent('Dashboard', 'Contxt Homepage');
                }} className="logo" data-testid="contxt-logo"></a>

                <div className="nav-links l-sp-175" id="nav-links">
                    {/* <div className="h-menu-icon" onClick={this.responsive}>
                        <span className="menu-icn"></span>
                    </div> */}
                    <ul className="nav-list">
                        <li id="shipments-select" data-testid="shipments-lnk" className={`has-child-nodes${['shipments','pdfparser','temperature_dashboard','active_insights','insights','assets','active_dashboard'].some((s) => pathname.includes(s)) ? ' a' : ''} ${checkTailwind ? 't-overide' : ''}`}>
                            {window.screen.width < 1024 ?
                                <a onClick={shipmentsSelect}>{headerLabels.visibility.label}</a>
                                :
                                <a href="/shipments/active">{headerLabels.visibility.label}</a>
                            }
                            <ul className="child-nodes" id="shipment-child-nodes">
                                {/* <li onClick={this.toggleAddShipment}>{headerLabels.visibility.sub_menu.add_shipment}</li> */}
                                <li data-testid="active-shipments" data-id="ACTIVE_SHIPMENTS">
                                    <a href="/shipments/active">{headerLabels.visibility.sub_menu.active_shipment}</a>
                                </li>
                                {
                                parseInt(localStorage.getItem('pdfParser')) ?
                                <li data-testid="pdf-parser" data-id="PDF_PARSER" className='has-grand-child-nodes'>
                                    <a href="/pdfparser" className='justify-settings'>{headerLabels.visibility.sub_menu.pdf_upload}</a>
                                </li>
                                : ''
                                }
                                <li data-testid="active-shipment-insights" data-id="ACTIVE_INSIGHTS">
                                    <a href="/insights">{headerLabels.visibility.sub_menu.active_shipments_insghts}</a>
                                </li>
                                {
                                parseInt(localStorage.getItem('pdfParser')) ?
                                <li data-testid="temperature-dashboard" data-id="TEMPERATURE_DASHBOARD" className='has-grand-child-nodes'>
                                    <a href="/temperature_dashboard" className='justify-settings'>{headerLabels.visibility.sub_menu.passive_insights}</a>
                                </li>
                                : ''
                                }
                                {
                                parseInt(localStorage.getItem('riskIQ')) ?
                                <li data-testid="active-dashboard" data-id="ACTIVE_DASHBOARD" className='has-grand-child-nodes'>
                                    <a href="/active_dashboard" className='justify-settings'>{headerLabels.visibility.sub_menu.active_insights}</a>
                                </li>
                                : ''
                                }
                                {
                                parseInt(localStorage.getItem('assetTracking')) ?
                                <li data-testid="asset-tracking" data-id="ASSET_TRACKING">
                                    <a href="/assets">{headerLabels.visibility.sub_menu.asset_tracking}</a>
                                </li>
                                : ''
                                }
                            </ul>
                        </li>
                        {
                        parseInt(localStorage.getItem('riskIQ')) ?
                        <li data-testid="risk-lnk" className={`has-child-nodes${['riskiq','riskplanning','liveriskiq','athena'].some((s) => pathname.includes(s)) ? ' a' : ''} ${checkTailwind ? 't-overide' : ''}`}><a href='/riskiq'>{headerLabels.risk.label}</a>
                            <ul className="child-nodes" id="risk-child-nodes" style={{width:'250px'}}>
                                <li data-testid="risk-assessment" data-id="RISK_ASSESSMENT">
                                    <a href="/riskiq">{headerLabels.risk.sub_menu.risk_assessment}</a>
                                </li>
                                <li data-testid="risk-planning" data-id="RISK_PLANNING">
                                    <a href="/riskplanning">{headerLabels.risk.sub_menu.risk_planning}</a>
                                </li>
                                {(process.env.REACT_APP_ENV === 'PRODUCTION' ? (companyId === '75' || companyId === '5') : companyName === 'paxafe') ?
                                    <li data-testid="live-risk-iq" data-id="LIVE_RISK_IQ">
                                        <a href="/liveriskiq">{headerLabels.risk.sub_menu.live_risk}</a>
                                    </li>
                                    : 
                                    <></>
                                }
                            </ul>
                        </li>
                        :
                        ''
                        }
                        {ROLES === 'super admin' || ROLES === 'company admin' || ROLES === 'group admin' ?
                            <>
                                <li data-testid="config-lnk" className={`has-child-nodes${pathname.includes('config') ? ' a' : ''} ${checkTailwind ? 't-overide' : ''}`}>
                                    <a href='/config'>{headerLabels.config.label}</a>
                                    <ul className="child-nodes" id="config-child-nodes">
                                        <li data-testid="shipment-configuration" data-id="SHIPMENT_CONFIGURATION">
                                            <a href="/config">{headerLabels.config.sub_menu.shipment_config}</a>
                                        </li>
                                        <li data-testid="user-configuration" data-id="USER_CONFIGURATION">
                                            <a href="/config/userConfig">{headerLabels.config.sub_menu.user_config}</a>
                                        </li>
                                        {parseInt(localStorage.getItem('pdfParser')) ?
                                        <li data-testid="parser-configuration" data-id="PARSER_CONFIGURATION">
                                            <a href="/config/parserConfig">{headerLabels.config.sub_menu.parser_config}</a>
                                        </li>
                                        : ''
                                        }
                                        {ROLES !== 'super admin' ? 
                                        <>
                                        {parseInt(localStorage.getItem('remaningStabilityBudget')) ?
                                        <li data-testid="edit-temapltes" data-id="EDIT_TEMPLATES">
                                            <a href="/config/templates">{headerLabels.config.sub_menu.edit_templates}</a>
                                        </li>
                                        : ''
                                        }
                                        </>
                                        : ''}
                                    </ul>                                            
                                </li>
                                <li data-testid="manage-lnk" className={`has-child-nodes${['companies','users','devices'].some((s) => pathname.includes(s)) ? ' a' : ''} ${checkTailwind ? 't-overide' : ''}`}><a href={`/${ROLES === 'super admin' || ROLES === 'group admin' ? "companies" : "users"}`}>{headerLabels.manage.label}</a>
                                    <ul className="child-nodes" id="risk-child-nodes" style={{width:'150px'}}>
                                        {ROLES === 'super admin' || ROLES === 'group admin' ?
                                        <li data-testid="companies-lnk">
                                            <a href="/companies">{headerLabels.manage.sub_menu.company}</a>
                                        </li>
                                        : ''
                                        }
                                        {ROLES === 'company admin' ?
                                        <li data-testid="users-lnk">
                                            <a href="/users">{headerLabels.manage.sub_menu.User}</a>
                                        </li>
                                        : ''
                                        }
                                        {ROLES === 'super admin' || ROLES === 'company admin' || ROLES === 'group admin' ?
                                        <li data-testid="risk-planning" data-id="RISK_PLANNING">
                                            <a href="/devices">{headerLabels.manage.sub_menu.device}</a>
                                        </li>
                                        : ''
                                        }
                                    </ul>
                                </li>
                            </>
                            :
                            ''
                        }
                        <li data-testid="help-lnk" className={`${pathname.indexOf('help') > 0 ? ' a' : ''} help ${checkTailwind ? 't-overide' : ''}`}><a href='/help'>{headerLabels.help.label}</a></li>
                        <span style={{ cursor:'pointer', marginTop: "8px", marginLeft: "0px" }} className="icon" onClick={responsive}>
                            <span className="menu-icn"></span>
                        </span>
                        {ROLES !== 'super admin' && ROLES !== 'company admin' && ROLES !== 'group admin' ?
                            <li className="shipper-li" style={{ width: '442px' }}></li> : ''
                        }
                    </ul>
                </div>

                <div className="auth-links">
                    <span className="comp-name l-sp-171" data-testid="comp-lbl">
                        {company}
                    </span>
                    <span className={`user-name l-sp-07 has-child-nodes ${checkTailwind ? 't-overide' : ''}`} data-testid="user-lbl">
                        <span>{initials}</span>
                        <ul className="child-nodes profile-pop">
                            <li><a href='/profile'>Profile</a></li>
                            <li className="with-icn" onClick={(e) => logoutWithRedirect(true)}>
                                <span className="material-icons">logout</span>
                                <span>Logout</span>
                            </li>
                        </ul>
                    </span>
                    <span className="notify-sec l-sp-09" data-testid="notify-lbl" onClick={toggleNotifications}>
                        <span>{dashboard.unreadNotifications || 0}</span>
                        {
                            showNotifications ?
                                <div className="notifi-pop">

                                    <div style={{ height: '3px' }}>
                                        {
                                            dashboard &&
                                                dashboard.notificationsLoading ?
                                                <div className="loading" />
                                                : ''
                                        }
                                    </div>
                                    {notifications.length ?
                                        <div className="clear-all"><span style={{ color: "#4e97c2", letterSpacing: "0.33px", cursor: "pointer" }} onClick={markReadAllNotifications}>Clear All</span></div>
                                        :
                                        <div className="clear-all"><span style={{ cursor: "not-allowed" }} onClick={(e) => { e.stopPropagation() }}>Clear All</span></div>}

                                    <div className="notifi-items">
                                        <PerfectScrollbar id="notifications_scroll">
                                            {
                                                notifications.map((notification, i) => {
                                                    let shipmentDelayedBody = notification.body;
                                                    if(notification.title.toUpperCase() === 'SHIPMENT DELAYED') {
                                                        const dateTimePattern = /\d{2}-\d{2}-\d{4}\s\d{2}:\d{2}/;
                                                        let shipmentDelayedTime = notification.body.match(dateTimePattern)[0];
                                                        shipmentDelayedTime = moment(new Date(shipmentDelayedTime).getTime() - (new Date().getTimezoneOffset()*60*1000)).format('YYYY-MM-DD HH:mm');
                                                        shipmentDelayedBody = shipmentDelayedBody.replace(dateTimePattern, shipmentDelayedTime);
                                                    }                                                        
                                                    return (
                                                        <div
                                                        className="notifi-item"
                                                        key={`notification-box-${i}`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            markReadNotifications({ ids: notification.id });
                                                            const N = notifications.slice(0, notifications.length);
                                                            const index = N.findIndex(i => i.id === notification.id);
                                                            if (N[index].read) {
                                                                //empty
                                                            }
                                                            else {
                                                                N[index].read = true;
                                                                onGetNotificationsSuccess({ data: N, meta: dashboard.notificationsMeta, unreadNotifications: dashboard.unreadNotifications - 1 });
                                                            }

                                                            if (e.target.className === 'notifi-close') {
                                                                let a = N.slice();
                                                                N.splice(index, 1);
                                                                onGetNotificationsSuccess({ data: N, meta: dashboard.notificationsMeta, unreadNotifications: a[index].read ? dashboard.unreadNotifications : dashboard.unreadNotifications - 1 });
                                                            }
                                                            const shipmentId = notification?.shipmentID;
                                                            const status = 'statusFromNotication';
                                                            if (props.goToShipment && window.location.hash !== '') {
                                                                window.location.hash = `nship=${shipmentId}`;
                                                                props.goToShipment(shipmentId, status);
                                                                } else {
                                                                window.location.href = `/shipments/active#nship=${shipmentId}#ns`;
                                                                }
                                                        }}
                                                    >
                                                        <div className="notifi-bdy">
                                                            <div className={`notifi-icon ${ColorsMap[notification.title.match(/temperature|humidity|moisture|cold|heat|dry|shipment\sdelayed/gi) ? (`${notification.title.toUpperCase()}`) : (`${notification.eventName === 'ENTRY' || notification.eventName === 'EXIT' ? notification.eventType?.toUpperCase() : notification.eventName}-${notification.eventStatus ? notification.eventStatus.toUpperCase() : ''}`)]}`}>
                                                                {(notification.eventName.match(/temperature|humidity/gi) && (!notification.title.match(/temperature|humidity/gi) || notification.title.match(/temperature/gi))) || notification.title.match(/shipment\sdelayed/gi) ? IconsMap[`${notification.title.toUpperCase()}`] : IconsMap.hasOwnProperty(notification.eventName) ? IconsMap[notification.eventName] : IconsMap.hasOwnProperty(`${notification.eventType?.toUpperCase()}-${notification.eventStatus}`) ? IconsMap[`${notification.eventType?.toUpperCase()}-${notification.eventStatus}`] : ''}
                                                            </div>
                                                            <div
                                                                className="notifi-box"
                                                            >
                                                                <div className="notifi-title">
                                                                    {notification.title}
                                                                    <span className={`time-ago${notification.read ? "" : ' unread'}`} title={getDate(notification.createdDate)}>
                                                                        <ReactTimeAgo
                                                                            date={getDate(notification.createdDate, true)}
                                                                            tooltip={false}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className="notifi-txt">
                                                                    {
                                                                        /*notification.title.match(/time (above|below) (high|low) (temperature threshold)/gi) ?
                                                                        (() => {
                                                                            return (
                                                                                <>
                                                                                {notification.shipmentID}&nbsp;
                                                                                {
                                                                                    <>Duration {notification.title.match(/above/gi) ? <>&#8250;</> : <>&#8249;</>} {notification.threshold} for {getSplitTime(notification.duration, false, false, true)}</>
                                                                                }
                                                                                </>
                                                                            )
                                                                        })()
                                                                        :*/
                                                                        notification.eventName.toUpperCase() === "TEMPERATURE" || notification.eventName.toUpperCase() === "HUMIDITY" || notification.eventName.toUpperCase() === "BATTERY" ?
                                                                        (() => {
                                                                            return (
                                                                                <>
                                                                                    {
                                                                                        notification.body.indexOf('-LIMIT') ?
                                                                                        <div style={{marginBottom: '-6px'}}>{notification.body.split(/PX\d{0,}/)[0]}</div>
                                                                                        : ''
                                                                                    }
                                                                                    {notification.shipmentID}&nbsp;
                                                                                    {    notification.symbol.toUpperCase().trim() === "UPWARD" ?
                                                                                        <span className={'notifi-arrow color-' + ARROW_COLOR[`${notification.title.toUpperCase()}`]}>&uarr;</span> :
                                                                                        <span className={'notifi-arrow color-' + ARROW_COLOR[`${notification.title.toUpperCase()}`]}>&darr;</span>
                                                                                    }
                                                                                    {notification.hasOwnProperty('deviation') &&
                                                                                        isNaN(notification.deviation) &&
                                                                                        notification.deviation.includes("°") ?
                                                                                        parseFloat(notification.deviation, 10).toFixed(1) + (notification.deviation + '').replace(/[0-9.]/g, '') :
                                                                                        (notification.deviation+'').indexOf('%RH') >=0 ? notification.deviation.replace('%RH', '% RH') :
                                                                                        notification.deviation || '0'
                                                                                    }
                                                                                    {notification.eventName.toUpperCase() === "BATTERY" ? '%' : ''}
                                                                                </>);
                                                                        })()
                                                                        :
                                                                        notification.title.toUpperCase() === 'SHIPMENT DELAYED' ?
                                                                        shipmentDelayedBody
                                                                        :
                                                                        notification.body
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="notifi-close"
                                                                key="close"
                                                                aria-label="Close"
                                                            // onClick={() => {
                                                            //     this.markReadNotifications({ids: notification.id}, true);
                                                            // }}
                                                            >
                                                                X
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                                )

                                            }
                                        </PerfectScrollbar>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {currentPage > 1 ?
                                            <div><a style={{ cursor: "pointer", padding: "10px", color: "#52bbeb8c" }} onClick={(e) => { e.stopPropagation(); onGetNotifications(currentPage - 1); document.getElementById('notifications_scroll').scrollTo(0,0);}}>Previous</a></div>
                                            :
                                            <div><a style={{ cursor: "not-allowed", padding: "10px" }} onClick={() => { }}>Previous</a></div>}
                                        {currentPage !== lastPage ?
                                            <div><a style={{ cursor: "pointer", padding: "10px", color: "#52bbeb8c" }} onClick={(e) => { e.stopPropagation(); onGetNotifications(currentPage + 1); document.getElementById('notifications_scroll').scrollTo(0,0); }}>Next</a></div>
                                            :
                                            <div><a style={{ cursor: "not-allowed", padding: "10px" }} onClick={(e) => { e.stopPropagation() }}>Next</a></div>}
                                    </div>

                                </div>
                                :
                                ''
                        }

                    </span>
                </div>
            </div>
            {checkLogout ? (
                <>
                <div className="hdr">
                    <a href="/" className="logo" data-testid="contxt-logo"></a>
                </div>
                <div className="cntxt-overlay extended">
                    <div className="cntxt-err-pop grey">
                        <SessionExpired 
                            onChange={() => logoutWithRedirect(true)}
                        />
                    </div>
                </div>
                </>
            ) : <></>
            }
            {
                serverError ? 
                    <div className="cntxt-overlay s">
                        <div className="cntxt-err-pop">
                            <div className="h1">Error 500</div>
                            <div className="b">
                                Sorry, the page or resource you've requested could not be processed. If you feel you have reached this message in error please contact support@paxafe.com.
                            </div>
                            <div className="f"><a href="/dashboard" className="c-btn">Home</a></div>
                        </div>
                    </div>
                : 
                networkError ? 
                    <div className="cntxt-overlay s">
                        <div className="cntxt-err-pop">
                            <div className="h1">No Connectivity!</div>
                            <div className="b">Please check your internet connection.</div>
                            <div className="f"><a href={window.location.href} className="c-btn">Reload</a></div>
                        </div>
                    </div>
                    : ''
            }
            {
            (pathname.indexOf('shipments') > 0 && localStorage.getItem('shipmentDetails') === "false") || props.isShipmentList ?
            <div className="plus-link new-ship" onClick={toggleAddShipment}>
                <span className="material-icons-round">add</span>
            </div>
            : ''
            }
            {
                addShipment ?
                <CreateShipment 
                    goToShipment={props.goToShipment}
                    toggleAddShipment={toggleAddShipment}
                />
                : 
                <></>
            }
            {

            }
            <div className="notify-box">
                {
                    notificationMessages.map((notify, index) => {
                        return(
                            <div className="notify-item" key={`user-report-notify-${index}-${notify.shipment_id}`}>
                            {
                                notify.report_status === 'FAILED' ?
                                <>
                                <span className="material-icons n-icn">warning</span>
                                <div className="n-msg">{`PX${notify.shipment_id} Report generation failed`}</div>
                                </>
                                :
                                notify.report_status === 'COMPLETED' ? 
                                <>
                                <span className="material-icons n-icn">check_circle_outline</span>
                                <div className="n-msg">{`PX${notify.shipment_id} Report ready to download`}</div>
                                </>
                                :
                                notify.report_status === 'SCHEDULED' && false ? 
                                <>
                                <span className="material-icons-outlined n-icn">schedule</span>
                                <div className="n-msg">{`PX${notify.shipment_id} Report is scheduled`}</div>
                                </>
                                :
                                (notify.report_status === 'INPROGRESS' || notify.report_status === 'IN PROGRESS') && false ? 
                                <>
                                <img src={Spin} className="act-icns n-icn" />
                                <div className="n-msg">{`PX${notify.shipment_id} Report generation In Progress`}</div>
                                </>
                                : ''
                            }                                
                            <span className="material-icons c-icn">close</span>
                            </div>
                        )
                    })
                }
            </div>
        </>
    );

}
export default Header;