import * as actionTypes from './actionTypes';
import { AXIOS } from '../../utility/Axios';
import { sendLogout, sendNetworkError, sendInternalServerError } from './company';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;

export const uploadPDFStart = (message, errors) => {
    return {
        type: actionTypes.UPLOAD_PDF_START
    };
};
export const uploadPDFSuccess = data => {
    return {
        type: actionTypes.UPLOAD_PDF_SUCCESS,
        data,
    };
};
export const uploadPDFFailed = data => {
    return {
        type: actionTypes.UPLOAD_PDF_FAILED,
        data,
    };
};

export const uploadPDF = data => async dispatch => {
    dispatch(uploadPDFStart());
    let requestUrl = apis.pdf.upload_document;
    await AXIOS({
        method: 'POST',
        url: requestUrl,
        data: data,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data?.errors) {
                dispatch(uploadPDFFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    uploadPDFFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(uploadPDFFailed(response.response.data || `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data) {
                dispatch(uploadPDFSuccess(response.data));
            } else {
                dispatch(uploadPDFFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getPDFsListStart = (message, errors) => {
    return {
        type: actionTypes.GET_PDF_LIST_START
    };
};
export const getPDFsListSuccess = (data) => {
    return {
        type: actionTypes.GET_PDF_LIST_SUCCESS,
        data,
    };
};
export const getPDFsListFailed = data => {
    return {
        type: actionTypes.GET_PDF_LIST_FAILED,
        data,
    };
};

export const getPDFsList = data => async dispatch => {
    dispatch(getPDFsListStart());
    const Params = { ...data };
    delete (Params.companyId);
    delete (Params.company_id);
    delete (Params.onComplete);
    if (Params.ptr_alarm === 3) {
        delete (Params.ptr_alarm);
        Params['ptr_alarm[>]'] = 0;
    }
    let requestUrl = apis.pdf.get_pdf_list(data.companyId ? `company_id=${data.companyId}` : '');
    await AXIOS({
        url: requestUrl,
        Params,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getPDFsListFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getPDFsListFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getPDFsListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            //if (response.data?.stats) {
                dispatch(getPDFsListSuccess({ ...response.data }));
                if (data.onComplete && typeof (data.onComplete) === 'function') {
                    data.onComplete(response.data);
                }
            // } else {
            //     dispatch(getPDFsListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            // }
        }
    });
};

export const getPDFDetailsStart = (message, errors) => {
    return {
        type: actionTypes.GET_PDF_DETAILS_START
    };
};
export const getPDFDetailsSuccess = data => {
    return {
        type: actionTypes.GET_PDF_DETAILS_SUCCESS,
        data,
    };
};
export const getPDFDetailsFailed = data => {
    return {
        type: actionTypes.GET_PDF_DETAILS_FAILED,
        data,
    };
};

export const getPDFDetails = data => async dispatch => {
    dispatch(getPDFDetailsStart());
    let requestUrl = apis.pdf.get_pdf_details(data.id);
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getPDFDetailsFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getPDFDetailsFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getPDFDetailsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data?.data) {
                dispatch(getPDFDetailsSuccess(response.data));
            } else {
                dispatch(getPDFDetailsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getFiltersListStart = (message, errors) => {
    return {
        type: actionTypes.GET_FILTERS_LIST_START
    };
};
export const getFiltersListSuccess = (data) => {
    return {
        type: actionTypes.GET_FILTERS_LIST_SUCCESS,
        data,
    };
};
export const getFiltersListFailed = data => {
    return {
        type: actionTypes.GET_FILTERS_LIST_FAILED,
        data,
    };
};

export const getFiltersList = data => async dispatch => {
    dispatch(getFiltersListStart());
    let requestUrl = apis.pdf.get_filters_list(data.companyId ? `company_id=${data.companyId}` : '');
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getFiltersListFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getFiltersListFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getFiltersListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data?.data) {
                dispatch(getFiltersListSuccess({ data: response.data.data }));
                if (data.onComplete && typeof (data.onComplete) === 'function') {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getFiltersListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const bulkUploadPDFStart = (message, errors) => {
    return {
        type: actionTypes.BULK_UPLOAD_PDF_START
    };
};
export const bulkUploadPDFSuccess = data => {
    return {
        type: actionTypes.BULK_UPLOAD_PDF_SUCCESS,
        data,
    };
};
export const bulkUploadPDFFailed = data => {
    return {
        type: actionTypes.BULK_UPLOAD_PDF_FAILED,
        data,
    };
};

export const bulkUploadPDF = data => async dispatch => {
    dispatch(bulkUploadPDFStart());
    let requestUrl = apis.pdf.bulk_upload_documents;
    await AXIOS({
        method: 'POST',
        url: requestUrl,
        data: data,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data?.errors) {
                dispatch(bulkUploadPDFFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    bulkUploadPDFFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(bulkUploadPDFFailed(response.response.data || `Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data) {
                dispatch(bulkUploadPDFSuccess(response.data));
            } else {
                dispatch(bulkUploadPDFFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getBulkPDFsListStart = (message, errors) => {
    return {
        type: actionTypes.GET_BULK_PDF_LIST_START
    };
};
export const getBulkPDFsListSuccess = (data) => {
    return {
        type: actionTypes.GET_BULK_PDF_LIST_SUCCESS,
        data,
    };
};
export const getBulkPDFsListFailed = data => {
    return {
        type: actionTypes.GET_BULK_PDF_LIST_FAILED,
        data,
    };
};

export const getBulkPDFsList = data => async dispatch => {
    dispatch(getBulkPDFsListStart());
    const Params = { ...data };
    delete (Params.companyId);
    delete (Params.company_id);
    delete (Params.onComplete);

    let requestUrl = apis.pdf.get_bulk_pdfs_list(data.companyId ? `company_id=${data.companyId}` : '');
    await AXIOS({
        url: requestUrl,
        Params,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getBulkPDFsListFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getBulkPDFsListFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getBulkPDFsListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data) {
                dispatch(getBulkPDFsListSuccess({ ...response.data }));
                if (data.onComplete && typeof (data.onComplete) === 'function') {
                    data.onComplete(response.data);
                }
            } else {
                dispatch(getBulkPDFsListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getZipPDFsList = data => async dispatch => {
    dispatch(getPDFsListStart());
    const Params = { ...data };
    // delete (Params.companyId
    // delete (Params.company_id);
    delete (Params.onComplete);

    let requestUrl = apis.pdf.get_zip_pdfs_list(data.id);
    await AXIOS({
        url: requestUrl,
        Params,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getPDFsListFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getPDFsListFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getPDFsListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            if (response.data) {
                dispatch(getPDFsListSuccess({ ...response.data }));
                if (data.onComplete && typeof (data.onComplete) === 'function') {
                    data.onComplete(response.data);
                }
            } else {
                dispatch(getPDFsListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        }
    });
};

export const getPdfParserProfileListStart = (data) => {
    return {
        type: actionTypes.GET_PDF_PARSER_PROFILE_LIST_START
    };
};

export const getPdfParserProfileListSuccess = (data, clear, updatedObj, status) => {
    return {
        type: actionTypes.GET_PDF_PARSER_PROFILE_LIST_SUCCESS,
        data,
        clear,
        updatedObj,
        status,
    };
};

export const getPdfParserProfileStart = (data) => {
    return {
        type: actionTypes.GET_PDF_PARSER_PROFILE_START,
    };
};

export const getPdfParserProfileSuccess = (data, clear) => {
    return {
        type: actionTypes.GET_PDF_PARSER_PROFILE_SUCCESS,
        data,
        clear
    };
};

export const savePdfParserProfileStart = (data) => {
    return {
        type: actionTypes.SAVE_PDF_PARSER_PROFILE_START,
    };
};

export const savePdfParserProfileSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_PDF_PARSER_PROFILE_SUCCESS,
        message,
        error
    };
};

export const deletePdfParserProfileSuccess = (message) => {
    return {
        type: actionTypes.DELETE_PDF_PARSER_PROFILE_SUCCESS,
        message,
    };
};
export const getPdfParserProfileList = (data) => async (dispatch) => {
    // let requestUrl = `pdfparser/api/pdf-parser-profile`;&size=500
    let requestUrl = apis.pdf.get_pdf_parser_profile_list(data.companyId);

    // let requestUrl = `pdfparser/api/pdf-parser-profile?company_id=${data.companyId}&q=${data.profileID}&size=500`;
    dispatch(getPdfParserProfileListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfParserProfileListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPdfParserProfileListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPdfParserProfileListSuccess([]));
            }
        }
    });
};

export const getPdfParserProfile = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_pdf_parser_profile(data.profileID);
    dispatch(getPdfParserProfileStart());

    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfParserProfileSuccess(response.response.data.error));
        } else {

            if (response.data && response.data.data) {
                const DATA = { config: { ...response.data.data } };

                dispatch(getPdfParserProfileSuccess(DATA));

                if (DATA && data.onComplete) {
                    data.onComplete(DATA);
                }
            }

        }
    });
};

export const savePdfParserProfile = (data) => async (dispatch) => {
    dispatch(savePdfParserProfileStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = { ...data.parserProfileDetails };
    const requestUrl = apis.pdf.save_pdf_parser_profile(DATA.id ? `/${DATA.id}` : '');

    /** Remove profile ID from payload if its new **/
    if (DATA.id) { }
    else {
        delete (DATA.id);
    }

    //console.log('----> ',DATA);

    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(savePdfParserProfileSuccess(response.response.data, true));
            if (data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(savePdfParserProfileSuccess(msg));
                if (data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(savePdfParserProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deletePdfParserProfile = (data) =>
    async function (dispatch) {
        dispatch(savePdfParserProfileStart());
        let requestUrl = apis.pdf.delete_pdf_parser_profile(data.id);

        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_pdf_parser_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if (response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deletePdfParserProfileSuccess(response.response.data.message));
                if (data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response && response.data) {
                    dispatch(deletePdfParserProfileSuccess(response.data));
                    if (data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getPdfProductProfileListStart = (data) => {
    return {
        type: actionTypes.GET_PDF_PRODUCT_PROFILE_LIST_START
    };
};

export const getPdfProductProfileListSuccess = (data, clear, updatedObj, status) => {
    return {
        type: actionTypes.GET_PDF_PRODUCT_PROFILE_LIST_SUCCESS,
        data,
        clear,
        updatedObj,
        status,
    };
};

export const getPdfProductProfileStart = (data) => {
    return {
        type: actionTypes.GET_PDF_PRODUCT_PROFILE_START,
    };
};

export const getPdfProductProfileSuccess = (data, clear) => {
    return {
        type: actionTypes.GET_PDF_PRODUCT_PROFILE_SUCCESS,
        data,
        clear
    };
};

export const savePdfProductProfileStart = (data) => {
    return {
        type: actionTypes.SAVE_PDF_PRODUCT_PROFILE_START,
    };
};

export const savePdfProductProfileSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_PDF_PRODUCT_PROFILE_SUCCESS,
        message,
        error
    };
};

export const deletePdfProductProfileSuccess = (message) => {
    return {
        type: actionTypes.DELETE_PDF_PRODUCT_PROFILE_SUCCESS,
        message,
    };
};
export const getPdfProductProfileList = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_pdf_product_profile_list(data.companyId);
    dispatch(getPdfProductProfileListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfProductProfileListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPdfProductProfileListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPdfProductProfileListSuccess([]));
            }
        }
    });
};

export const getPdfProductProfile = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_pdf_product_profile(data.profileID);
    dispatch(getPdfProductProfileStart());

    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfProductProfileSuccess(response.response.data.error));
        } else {

            if (response.data && response.data.data) {
                const DATA = { config: { ...response.data.data } };

                dispatch(getPdfProductProfileSuccess(DATA));

                if (DATA && data.onComplete) {
                    data.onComplete(DATA);
                }
            }

        }
    });
};

export const savePdfProductProfile = (data) => async (dispatch) => {
    dispatch(savePdfProductProfileStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = { ...data.pdfProductProfileDetails };
    const requestUrl = apis.pdf.save_pdf_product_profile(DATA.id ? `/${DATA.id}` : '');

    /** Remove profile ID from payload if its new **/
    if (DATA.id) { }
    else {
        delete (DATA.id);
    }

    //console.log('----> ',DATA);

    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(savePdfProductProfileSuccess(response.response.data, true));
            if (data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(savePdfProductProfileSuccess(msg));
                if (data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(savePdfProductProfileSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deletePdfProductProfile = (data) =>
    async function (dispatch) {
        dispatch(savePdfProductProfileStart());
        let requestUrl = apis.pdf.delete_pdf_product_profile(data.id);
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_pdf_parser_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if (response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deletePdfProductProfileSuccess(response.response.data.message));
                if (data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response && response.data) {
                    dispatch(deletePdfProductProfileSuccess(response.data));
                    if (data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };

export const getActiveIngredientListStart = (data) => {
    return {
        type: actionTypes.GET_ACTIVE_INGREDIENT_LIST_START
    };
};

export const getActiveIngredientListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_ACTIVE_INGREDIENT_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const saveActiveIngredientStart = (data) => {
    return {
        type: actionTypes.SAVE_ACTIVE_INGREDIENT_START,
    };
};

export const saveActiveIngredientSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_ACTIVE_INGREDIENT_SUCCESS,
        message,
        error
    };
};

export const deleteActiveIngredientSuccess = (message) => {
    return {
        type: actionTypes.DELETE_ACTIVE_INGREDIENT_SUCCESS,
        message,
    };
};
export const getActiveIngredientList = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_active_ingredient_list(data.companyId);
    dispatch(getActiveIngredientListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getActiveIngredientListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getActiveIngredientListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getActiveIngredientListSuccess([]));
            }
        }
    });
};

export const saveActiveIngredient = (data) => async (dispatch) => {
    dispatch(saveActiveIngredientStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = { ...data.data };
    const requestUrl = apis.pdf.save_active_ingredient_list(DATA.id ? `/${DATA.id}` : '');

    /** Remove profile ID from payload if its new **/
    if (DATA.id) { }
    else {
        delete (DATA.id);
    }

    //console.log('----> ',DATA);

    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveActiveIngredientSuccess(response.response.data, true));
            if (data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(saveActiveIngredientSuccess(msg));
                if (data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveActiveIngredientSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteActiveIngredient = (data) =>
    async function (dispatch) {
        dispatch(saveActiveIngredientStart());
        let requestUrl = apis.pdf.delete_active_ingredient_list(data.id);
        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_pdf_parser_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if (response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteActiveIngredientSuccess(response.response.data.message));
                if (data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deleteActiveIngredientSuccess(response.data));
                    if (data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getActiveIngredientStart = (data) => {
    return {
        type: actionTypes.GET_ACTIVE_INGREDIENT_START
    };
};

export const getActiveIngredientSuccess = (data) => {
    return {
        type: actionTypes.GET_ACTIVE_INGREDIENT_SUCCESS,
        data,
    };
};

export const getActiveIngredient = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_active_ingredient(data.id);
    dispatch(getActiveIngredientStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getActiveIngredientSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getActiveIngredientListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getActiveIngredientListSuccess({}));
            }
        }
    });
};

export const getStrengthListStart = (data) => {
    return {
        type: actionTypes.GET_STRENGTH_LIST_START
    };
};

export const getStrengthListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_STRENGTH_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const saveStrengthStart = (data) => {
    return {
        type: actionTypes.SAVE_STRENGTH_START,
    };
};

export const saveStrengthSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_STRENGTH_SUCCESS,
        message,
        error
    };
};

export const deleteStrengthSuccess = (message) => {
    return {
        type: actionTypes.DELETE_STRENGTH_SUCCESS,
        message,
    };
};
export const getStrengthList = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_strength_list(data.companyId);
    dispatch(getStrengthListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getStrengthListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getStrengthListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getStrengthListSuccess([]));
            }
        }
    });
};

export const saveStrength = (data) => async (dispatch) => {
    dispatch(saveStrengthStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = { ...data.data };
    const requestUrl = apis.pdf.save_strength(DATA.id ? `/${DATA.id}` : '');

    /** Remove profile ID from payload if its new **/
    if (DATA.id) { }
    else {
        delete (DATA.id);
    }

    //console.log('----> ',DATA);

    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(saveStrengthSuccess(response.response.data, true));
            if (data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(saveStrengthSuccess(msg));
                if (data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(saveStrengthSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deleteStrength = (data) =>
    async function (dispatch) {
        dispatch(saveStrengthStart());
        let requestUrl = apis.pdf.delete_strength(data.id);

        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_pdf_parser_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if (response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deleteStrengthSuccess(response.response.data.message));
                if (data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deleteStrengthSuccess(response.data));
                    if (data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getStrengthStart = (data) => {
    return {
        type: actionTypes.GET_STRENGTH_START
    };
};

export const getStrengthSuccess = (data) => {
    return {
        type: actionTypes.GET_STRENGTH_SUCCESS,
        data,
    };
};

export const getStrength = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_strength(data.id);
    dispatch(getStrengthStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getStrengthSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getStrengthListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getStrengthListSuccess({}));
            }
        }
    });
};
export const getPdfLaneIdListStart = (data) => {
    return {
        type: actionTypes.GET_PDF_LANE_ID_LIST_START
    };
};

export const getPdfLaneIdListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_PDF_LANE_ID_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const savePdfLaneIdStart = (data) => {
    return {
        type: actionTypes.SAVE_PDF_LANE_ID_START,
    };
};

export const savePdfLaneIdSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_PDF_LANE_ID_SUCCESS,
        message,
        error
    };
};

export const deletePdfLaneIdSuccess = (message) => {
    return {
        type: actionTypes.DELETE_PDF_LANE_ID_SUCCESS,
        message,
    };
};
export const getPdfLaneIdList = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_pdf_lane_id_list(data.companyId);
    dispatch(getPdfLaneIdListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfLaneIdListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPdfLaneIdListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPdfLaneIdListSuccess([]));
            }
        }
    });
};

export const savePdfLaneId = (data) => async (dispatch) => {
    dispatch(savePdfLaneIdStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = { ...data.data };
    const requestUrl = apis.pdf.save_pdf_lane_id(DATA.id ? `/${DATA.id}` : '');

    /** Remove profile ID from payload if its new **/
    if (DATA.id) { }
    else {
        delete (DATA.id);
    }

    //console.log('----> ',DATA);

    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(savePdfLaneIdSuccess(response.response.data, true));
            if (data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(savePdfLaneIdSuccess(msg));
                if (data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(savePdfLaneIdSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deletePdfLaneId = (data) =>
    async function (dispatch) {
        dispatch(savePdfLaneIdStart());
        let requestUrl = apis.pdf.delete_pdf_lane_id(data.id);

        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_pdf_parser_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if (response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deletePdfLaneIdSuccess(response.response.data.message));
                if (data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deletePdfLaneIdSuccess(response.data));
                    if (data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getPdfLaneIdStart = (data) => {
    return {
        type: actionTypes.GET_PDF_LANE_ID_START
    };
};

export const getPdfLaneIdSuccess = (data) => {
    return {
        type: actionTypes.GET_PDF_LANE_ID_SUCCESS,
        data,
    };
};

export const getPdfLaneId = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_pdf_lane_id(data.id);
    dispatch(getPdfLaneIdStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfLaneIdSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPdfLaneIdListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPdfLaneIdListSuccess({}));
            }
        }
    });
};

export const getPdfPackageTypeListStart = (data) => {
    return {
        type: actionTypes.GET_PDF_PACKAGE_TYPE_LIST_START
    };
};

export const getPdfPackageTypeListSuccess = (data, clear, index) => {
    return {
        type: actionTypes.GET_PDF_PACKAGE_TYPE_LIST_SUCCESS,
        data,
        clear,
        index
    };
};

export const savePdfPackageTypeStart = (data) => {
    return {
        type: actionTypes.SAVE_PDF_PACKAGE_TYPE_START,
    };
};

export const savePdfPackageTypeSuccess = (message, error) => {
    return {
        type: actionTypes.SAVE_PDF_PACKAGE_TYPE_SUCCESS,
        message,
        error
    };
};

export const deletePdfPackageTypeSuccess = (message) => {
    return {
        type: actionTypes.DELETE_PDF_PACKAGE_TYPE_SUCCESS,
        message,
    };
};
export const getPdfPackageTypeList = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_pdf_package_type_list(data.companyId);
    dispatch(getPdfPackageTypeListStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfPackageTypeListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPdfPackageTypeListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPdfPackageTypeListSuccess([]));
            }
        }
    });
};

export const savePdfPackageType = (data) => async (dispatch) => {
    dispatch(savePdfPackageTypeStart());
    //const requestUrl = `alertprofiles/${data.alertProfileDetails?.profileID ? 'update' : 'save'}`;
    const DATA = { ...data.data };
    const requestUrl = apis.pdf.save_pdf_package_type(DATA.id ? `/${DATA.id}` : '');

    /** Remove profile ID from payload if its new **/
    if (DATA.id) { }
    else {
        delete (DATA.id);
    }

    //console.log('----> ',DATA);

    await AXIOS({
        method: DATA.id ? 'put' : 'post',
        url: requestUrl,
        data: DATA,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        //console.log(response);
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response && response.response.data) {
            dispatch(savePdfPackageTypeSuccess(response.response.data, true));
            if (data.onComplete) {
                data.onComplete(response.response.data, true);
            }
        } else {
            if (response.data) {
                const msg = response.data;
                dispatch(savePdfPackageTypeSuccess(msg));
                if (data.onComplete) {
                    data.onComplete(msg);
                }
            }
        }
    }).catch(err => {
        //console.log(err);
        dispatch(savePdfPackageTypeSuccess(`Could not process request. Please contact ${apis.help.api_help_email}`));
    });
};

export const deletePdfPackageType = (data) =>
    async function (dispatch) {
        dispatch(savePdfPackageTypeStart());
        let requestUrl = apis.pdf.delete_pdf_package_type(data.id);

        await AXIOS({
            method: 'delete',
            url: requestUrl,
            data: data,
            service: process.env.REACT_APP_pdf_parser_api,
            onInternalServerError: () => {
                dispatch(sendInternalServerError(true));
            }
        }).then((response) => {
            if (response === undefined) {
                dispatch(sendLogout(true));
            } else if (response.networkError) {
                dispatch(sendNetworkError(true));
            } else if (response.response && response.response.data.message) {
                dispatch(deletePdfPackageTypeSuccess(response.response.data.message));
                if (data.onComplete) {
                    data.onComplete(response.response.data, true);
                }
            } else {
                if (response?.data) {
                    dispatch(deletePdfPackageTypeSuccess(response.data));
                    if (data.onComplete) {
                        data.onComplete(response.data);
                    }
                }
            }
        });
    };


export const getPdfPackageTypeStart = (data) => {
    return {
        type: actionTypes.GET_PDF_PACKAGE_TYPE_START
    };
};

export const getPdfPackageTypeSuccess = (data) => {
    return {
        type: actionTypes.GET_PDF_PACKAGE_TYPE_SUCCESS,
        data,
    };
};

export const getPdfPackageType = (data) => async (dispatch) => {
    let requestUrl = apis.pdf.get_pdf_package_type(data.id);
    dispatch(getPdfPackageTypeStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        service: process.env.REACT_APP_pdf_parser_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getPdfPackageTypeSuccess({}));
        } else {
            if (response.data && response.data.data) {
                dispatch(getPdfPackageTypeListSuccess(response.data.data));
                if (response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getPdfPackageTypeListSuccess({}));
            }
        }
    });
};
