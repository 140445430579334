import React from 'react';
import SimpleBarReact from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

function ScrollBar(props){
    if(window.screen.width<1024) {
        return(
            <>{props.children}</>
        );
    }
    else{
        return(
            <SimpleBarReact 
                id={props.id||''} 
                style={{ height: "100%", outline: 0 }}
            >
            {props.children}
            </SimpleBarReact>
        );
    
    }
}

export default ScrollBar;