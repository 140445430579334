import React, { lazy } from 'react';
import * as actionTypes from './actionTypes';
import { AXIOS } from '../../utility/Axios';
import { sendLogout, sendNetworkError, sendInternalServerError } from './company';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;
//const ad = ( async () => { return await import(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`)})();

//import decode from 'jwt-decode';
let previousRefreshToken = Date.now()/1000;
export const getNewToken = (data) => async (dispatch) => {
    if((localStorage.getItem('access_token'))) {
        if((Date.now() / 1000)-previousRefreshToken < 1) {
            if(data && data.hasOwnProperty('onComplete') && typeof(data.onComplete === 'function')) {
                data.onComplete();
            }
        } else {
            const requestUrl = apis.asset_tracking.refresh_token;
            previousRefreshToken = Date.now() / 1000;
            await AXIOS({
                method: 'POST',
                url: requestUrl,
                data: {
                    refreshToken: localStorage.getItem('refresh_token'),
                    email: localStorage.getItem('email')
                },
                onInternalServerError: () => {
                    dispatch(sendInternalServerError(true));
                }
            }).then((response) => {
                if(response.networkError) {
                    dispatch(sendNetworkError(true));
                } else if (response.data?.data?.access_token) {
                    window.localStorage.setItem('access_token', response.data.data.access_token);
                    window.localStorage.setItem('refresh_token', response.data.data.refresh_token);
                    window.localStorage.setItem('name', `${response.data.data.user.firstname} ${response.data.data.user.lastname}`);
                    window.localStorage.setItem('userDetails', JSON.stringify(response.data.data.user));
                    window.localStorage.setItem('assetTracking', response.data.data.user.assetTracking);
                    window.localStorage.setItem('carrierTracking', response.data.data.user.carrierTracking);
                    if(data && data.hasOwnProperty('onComplete') && typeof(data.onComplete === 'function')) {
                        data.onComplete();
                    }
                }
            });
        }
    } else {
        dispatch(sendLogout(true));
    }
};

export const getCompanyDeviceListStart = (data) => {
    return {
        type: actionTypes.GET_COMPANY_DEVICES_TRACKING_START
    };
};

export const getCompanyDeviceListSuccess = (data, clear) => {
    return {
        type: actionTypes.GET_COMPANY_DEVICES_TRACKING_SUCCESS,
        data,
        clear
    };
};
export const getCompanyDeviceListStatusSuccess = (data, clear, refenceTimestamp) => {
    return {
        type: actionTypes.GET_COMPANY_DEVICES_TRACKING_STATUS_SUCCESS,
        data,
        clear,
        refenceTimestamp
    };
};

export const getCompanyDeviceList = (data) => async (dispatch) => {
    let requestUrl = apis.asset_tracking.get_company_device_list(data.companyId);
    if(data.status) {
    } else {
        dispatch(getCompanyDeviceListStart());
    }
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(getCompanyDeviceList(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getCompanyDeviceListSuccess([]));
        } else {
            if (response.data && response.data.data) {
                if(data.status) {
                    dispatch(getCompanyDeviceListStatusSuccess(response.data.data, false, data.duration));
                    if(response.data.data && data.onComplete) {
                        data.onComplete(response.data.data);
                    }
                } else {
                    const DATA = response.data.data;
                    // DATA.push({PK: "COMPANY#5",
                    // SK: "DEVICE#STATUS#M4194800142SM",
                    // accuracy: 0,
                    // dataType: "Location",
                    // deviceName: "M4194800142SM",
                    // deviceType: "BAT-XL",
                    // battery:30,
                    // device_id: 390,
                    // device_name: "TM005",
                    // latitude: 29.7293,
                    // longitude: -95.1235,
                    // placeName: "5600 Hwy 225, La Porte, Texas 77536, United States",
                    // product_description: "none",
                    // product_type: "",
                    // received: 1633590602782,
                    // recordType: "T3",
                    // shortText: "Hwy 225",
                    // show_status: 1,
                    // status: "disconnected",
                    // timestamp: 1633024280000,
                    // ttl: 1633590663,
                    // },
                    // {PK: "COMPANY#5",
                    // SK: "DEVICE#STATUS#M4194800142SM",
                    // accuracy: 0,
                    // dataType: "Location",
                    // deviceName: "M4194800142SM",
                    // deviceType: "BAT-XL",
                    // battery:10,
                    // device_id: 390,
                    // device_name: "TM007",
                    // latitude: 29.7293,
                    // longitude: -95.1235,
                    // placeName: "5600 Hwy 225, La Porte, Texas 77536, United States",
                    // product_description: "none",
                    // product_type: "",
                    // received: 1633590602782,
                    // recordType: "T3",
                    // shortText: "Hwy 225",
                    // show_status: 1,
                    // status: "disconnected",
                    // timestamp: 1633024280000,
                    // ttl: 1633590663,
                    // },
                    // {PK: "COMPANY#5",
                    // SK: "DEVICE#STATUS#M4194800142SM",
                    // accuracy: 0,
                    // dataType: "Location",
                    // deviceName: "M4194800142SM",
                    // deviceType: "BAT-XL",
                    // battery:60,
                    // device_id: 390,
                    // device_name: "TM008",
                    // latitude: 29.7293,
                    // longitude: -95.1235,
                    // placeName: "5600 Hwy 225, La Porte, Texas 77536, United States",
                    // product_description: "none",
                    // product_type: "",
                    // received: 1633590602782,
                    // recordType: "T3",
                    // shortText: "Hwy 225",
                    // show_status: 1,
                    // status: "disconnected",
                    // timestamp: 1633024280000,
                    // ttl: 1633590663,
                    // });
                    dispatch(getCompanyDeviceListSuccess(DATA));
                    if(response.data.data && data.onComplete) {
                        data.onComplete(DATA);
                    }
                }
                
            } else {
                dispatch(getCompanyDeviceListSuccess([]));
            }
        }
    });
};


export const getCompanyDeviceListStatus = (data) => async (dispatch) => {
    let requestUrl = apis.asset_tracking.get_company_device_statuses(data.companyId);
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(getCompanyDeviceListStatus(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else {
            if (response.data && response.data.data) {
                dispatch(getCompanyDeviceListStatusSuccess(response.data.data, false, data.duration));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }                
            }
        }
    });
};

export const getDeviceDetailsStart = (data) => {
    return {
        type: actionTypes.GET_DEVICE_DETAILS_START
    };
};

export const getDeviceDetailsSuccess = (data) => {
    return {
        type: actionTypes.GET_DEVICE_DETAILS_SUCCESS,
        data
    };
};

export const updateAssetLiveData = (data, refenceTimestamp) => {
    return {
        type: actionTypes.UPDATE_ASSET_LIVE_DATA,
        data,
        refenceTimestamp
    };
};

export const getDeviceDetails = (data) => async (dispatch) => {
    let requestUrl = apis.asset_tracking.get_device_details(data.companyId, data.deviceName, data.startDateTime);
    dispatch(getDeviceDetailsStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(getDeviceDetails(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getDeviceDetailsSuccess([]));
        } else {
            if (response.data && response.data.data) {
                dispatch(getDeviceDetailsSuccess(response.data.data));
                if(response.data.data && data.onComplete) {
                    data.onComplete(response.data.data);
                }
            } else {
                dispatch(getDeviceDetailsSuccess([]));
            }
        }
    });
};

export const updateDeviceDetailsStart = (data) => {
    return {
        type: actionTypes.UPDATE_DEVICE_DETAILS_START
    };
};

export const updateDeviceDetailsSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_DEVICE_DETAILS_SUCCESS,
        data
    };
};

export const updateDeviceDetails = (data) => async (dispatch) => {
    let requestUrl = apis.asset_tracking.update_device_details(data.companyId, data.device.deviceName);
    dispatch(updateDeviceDetailsStart());
    const device = {...data.device};
    delete(device.deviceName);
    await AXIOS({
        method: 'PUT',
        url: requestUrl,
        data: device,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(updateDeviceDetails(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(updateDeviceDetailsSuccess({}));
        } else {
            if (response.data) {
                dispatch(updateDeviceDetailsSuccess(response.data));
                if(response.data && data.onComplete) {
                    data.onComplete(response.data);
                }
            } else {
                dispatch(updateDeviceDetailsSuccess({}));
            }
        }
    });
};

export const getCompanyGeofencesStart = (data) => {
    return {
        type: actionTypes.GET_COMPANY_GEOFENCES_START
    };
};

export const getCompanyGeofencesSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_GEOFENCES_SUCCESS,
        data
    };
};

export const getCompanyGeofences = (data) => async (dispatch) => {
    let requestUrl = apis.asset_tracking.get_company_geofences(data.companyId);
    dispatch(getCompanyGeofencesStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(getCompanyGeofences(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getCompanyGeofencesSuccess([]));
        } else {
            if (response.data) {
                dispatch(getCompanyGeofencesSuccess(response.data));
                if(response.data && data.onComplete) {
                    data.onComplete(response.data);
                }
            } else {
                dispatch(getCompanyGeofencesSuccess([]));
            }
        }
    });
};

export const updateCompanyGeofencesStart = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_GEOFENCES_START
    };
};

export const updateCompanyGeofencesSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_GEOFENCES_SUCCESS,
        data
    };
};

export const updateCompanyGeofences = (data) => async (dispatch) => {
    let requestUrl = apis.asset_tracking.update_company_geofences(data.companyId);
    dispatch(updateCompanyGeofencesStart());
    const geofences = data.data;
    await AXIOS({
        method: 'POST',
        url: requestUrl,
        data: geofences,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(updateCompanyGeofences(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(updateCompanyGeofencesSuccess([]));
        } else {
            if (response.data) {
                dispatch(updateCompanyGeofencesSuccess(response.data || []));
                if(data.onComplete) {
                    data.onComplete(response.data);
                }
            } else {
                dispatch(updateCompanyGeofencesSuccess([]));
            }
        }
    });
};

export const getProductTypesStart = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_TYPES_START
    };
};

export const getProductTypesSuccess = (data) => {
    return {
        type: actionTypes.GET_PRODUCT_TYPES_SUCCESS,
        data
    };
};

export const getProductTypes = (data) => async (dispatch) => {
    let requestUrl = apis.asset_tracking.get_product_types(data.companyId);
    dispatch(getProductTypesStart());
    await AXIOS({
        method: 'GET',
        url: requestUrl,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(getCompanyGeofences(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(getProductTypesSuccess([]));
        } else {
            if (response.data) {
                let DATA = response.data;
                dispatch(getProductTypesSuccess(DATA));
                if(response.data && data.onComplete) {
                    data.onComplete(DATA);
                }
            } else {
                dispatch(getProductTypesSuccess([]));
            }
        }
    });
};

export const updateProductTypeStart = (data) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_TYPE_START
    };
};

export const updateProductTypeSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_PRODUCT_TYPE_SUCCESS,
        data
    };
};

export const updateProductType = (data) => async (dispatch) => {    
    let requestUrl = apis.asset_tracking.update_product_type(data.companyId);
    dispatch(updateProductTypeStart());
    const DATA = data.data;
    await AXIOS({
        method: 'POST',
        url: requestUrl,
        data: DATA,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then((response) => {
        if (response === undefined) {
            //dispatch(sendLogout(true));
            dispatch(getNewToken({
                onComplete: () => {
                    dispatch(updateProductType(data));
                }
            }));
        } else if(response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            dispatch(updateProductTypeSuccess([]));
        } else {
            if (response.data) {
                dispatch(updateProductTypeSuccess(response.data || []));
                if(data.onComplete) {
                    data.onComplete(response.data);
                }
            } else {
                dispatch(updateProductTypeSuccess([]));
            }
        }
    });
};