import React from 'react';
import moment from 'moment';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...JSON.parse(JSON.stringify(oldObject)),
        ...updatedProperties,
    };
};

export const getUTCDate = (t) => {
    return moment.utc(Number(t)).format('MM/DD/YYYY at HH:mm').replace(/amt|pmt/, 'at');
};

export const getDate = (t, returnDate, dontConvert, dontUseDefaultDate, onlyDate, showSeconds, monthAsString, dateFormat) => {
    let d = t;
    if(t && (t).toString().indexOf('GMT') >= 0 ) {
    } else if(t){
        d = (t).toString().indexOf('-') >=0 ? t.replace(/-/g, '/') : t
    }
    
    const T = d
        ? new Date(new Date(isNaN(d) ? d : Number(d)).getTime() - (dontConvert ? 0 : new Date().getTimezoneOffset() * 60 * 1000))
        : dontUseDefaultDate ? 0 : new Date();
        
    if(isNaN(new Date(T).getTime()) || T === 0) {
        return '--';
    }
    if (returnDate) {
        return T;
    }    
    //console.log('======================\n',t, '\n',T);
    const day = T.getDate();
    const month = T.getMonth();
    const year = T.getFullYear();
    const today = new Date();
    let connectingString = 'at';
    //console.log(T.getTime(),' | ', today.getTime());
    if (T.getTime() > today.getTime()) {
        connectingString = 'by';
    }

    if(onlyDate) {
        return moment(T).format('MM/DD/YYYY');
    }
    if (day === today.getDate() && month === today.getMonth() && year === today.getFullYear()) {
        return `Today ${connectingString} ${moment(T).format(`HH:mm${showSeconds?':ss':''}`)}`;
    }
    const format = dateFormat || `MM/DD/YYYY at HH:mm${showSeconds?':ss':''}`;
    let date = moment(T)
    .format(format)
    .replace(/amt|pmt/, connectingString);
    if(monthAsString) {
        date = date.replace(/\/\d\d\//, `/${T.toLocaleString('default', { month: 'short' })}/`);
    }
    return date;    
};
// eslint-disable-next-line no-extend-native
String.prototype.format = function(hash) {
    let string = this,
        key;
    for (key in hash) {
        string = string.replace(new RegExp('\\{' + key + '\\}', 'gm'), hash[key]);
    }
    return string;
};
export const formatDate = d => {
    try {
        if (d === '' || d === null || d === undefined) return '--';
        return moment(d).format('MMM DD, YYYY') + ' ET';
    } catch (e) {
        // console.log('ERROR while creating date: ', e);
    }
};
export const formatPhone1 = v => {
    if (v === undefined || v === null) return '';
    const V = v + '';
    let val = V.replace(/[()\s]/g, '');
    if (isNaN(val)) return '';
    val = val.substr(0, 10);
    val = `(${val.substr(0, 3)}) ${val.substr(3, 3)} ${val.substr(6, 4)}`.trim().replace(/[)]$/, '');
    return val.length === 1 ? '' : val;
};
export const formatPhone = text => {
    let cleaned = ('' + text).replace(/\D/g, '').substr(0,19);
    // console.log(text);
    // console.log(cleaned);
    let splitCleaned = [];
    if (cleaned && cleaned.length > 10) {
        splitCleaned = [cleaned.slice(0, 10), cleaned.slice(10)];

        cleaned = splitCleaned[0];
    }

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{1,4})$/) || cleaned.match(/^(\d{3})(\d{1,3})(\d{0,4})$/);
    if (match) {
        var intlCode = `(${match[1]}) ${match[2]}${match[3]?`-${match[3]}`:''}`;
        return `${intlCode}${splitCleaned && splitCleaned.length > 0 ? splitCleaned[1] : ''}`;
    }

    return cleaned;
};
export const formatTemperature = (T, r) => {
    const rep = r || 'C';
    let t = T + '';
    if (!t || t === '' || t === undefined || t === null) return '';
    if(rep && (rep === 'F' || rep === 'f')) {
        t = toFahrenheit(t);
    }
    t = `${t}°${rep}`;
    if (t.includes('°')) {
        const temp = t.split('°');
        return `${parseFloat(temp[0], 10)
            .toFixed(1)
            .replace(/\.?0+$/, '')}°${temp[1]}`;
    } else if (t.includes(' ')) {
        const temp = t.split(' ');
        return `${parseFloat(temp[0], 10)
            .toFixed(1)
            .replace(/\.?0+$/, '')} ${temp[1]}`;
    } else {
        return `${parseFloat(t, 10)
            .toFixed(1)
            .replace(/\.?0+$/, '')}°${rep}`;
    }
};

export const toFahrenheit = t => {
    return Number(((Number(t) * 9) / 5 + 32).toFixed(2));
};
export const toCelsius = t => {
    return Number(((Number(t) - 32) * (5 / 9)).toFixed(2));
};

export const getFormattedDuration = MS => {
    //console.log('================: ', ms);
    if(MS === "NA" || MS === null || MS === undefined || isNaN(MS)) return 'NA';
    const ms = Math.abs(MS);
    const diff = moment.duration(ms)._data;
    //console.log('ms: ', ms, diff, t, diff.seconds);
    let str = '';
    if (diff.years > 0) {
        str = `${diff.years} year${diff.years !== 1 ? 's' : ''} ${diff.months > 0 ? `and ${diff.months} month${diff.months !== 1 ? 's' : ''}` : ''}`;
    } else if (diff.months > 0) {
        str = `${diff.months} month${diff.months !== 1 ? 's' : ''} ${diff.days > 0 ? `and ${diff.days} day${diff.days !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.days > 0) {
        str = `${diff.days} day${diff.days !== 1 ? 's' : ''} ${diff.hours > 0 ? `and ${diff.hours + (diff.minutes>30 ? 1 : 0)} hour${diff.hours !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.hours > 0) {
        str = `${diff.hours} hour${diff.hours !== 1 ? 's' : ''} ${diff.minutes > 0 ? `and ${diff.minutes} min${diff.minutes !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.minutes > 0) {
        str = `${diff.minutes} min${diff.minutes !== 1 ? 's' : ''}`;
    } else {
        str = `${diff.seconds > 0 ? '1 min' : '0 mins'}`;
    }
    //console.log('==================|', ms, '<><><>|',str,'|');
    return <span title={`${diff.years>0?`${diff.years}year${diff.years===1?'':'s'}`:''}${diff.months>0?` ${diff.months}month${diff.months===1?'':'s'}`:''}${diff.days>0?` ${diff.days}day${diff.days===1?'':'s'}`:''}${diff.hours>0?` ${diff.hours}hour${diff.hours===1?'':'s'}`:''}${diff.minutes>0?` ${diff.minutes}minute${diff.minutes===1?'':'s'}`:''}${diff.seconds>0?` ${diff.seconds}second${diff.seconds===1?'':'s'}`:''}`}>{str}</span>;
};
export const getFormattedDurationShort = (MS, toolTip) => {
    if(MS === "NA" || MS === null || MS === undefined || isNaN(MS)) return 'NA';
    const ms = Math.abs(MS);
    //const diff = moment.duration(ms)._data;
    const diff = {years:0,months:0,days:0,hours:0,minutes:0,seconds:0};
    const TIME_OBJ = {year: 12*30*24*60*60*1000, month: 30*24*60*60*1000, day: 24*60*60*1000, hour: 60*60*1000, minute: 60*1000, second: 1000, millisecond: 1};
    diff.years = Math.floor(ms/(TIME_OBJ.year));
    diff.months = Math.floor(ms%(TIME_OBJ.year)/TIME_OBJ.month);
    diff.days = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month/(TIME_OBJ.day));
    diff.hours = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month%(TIME_OBJ.day)/(TIME_OBJ.hour));
    diff.minutes = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month%(TIME_OBJ.day)%(TIME_OBJ.hour)/(TIME_OBJ.minute));
    diff.seconds = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month%(TIME_OBJ.day)%(TIME_OBJ.hour)%(TIME_OBJ.minute)/(TIME_OBJ.second));
    diff.milliseconds = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month%(TIME_OBJ.day)%(TIME_OBJ.hour)%(TIME_OBJ.minute)%(TIME_OBJ.second)/(TIME_OBJ.millisecond));
    //console.log('ms: ', ms, diff, diff.seconds);
    let str = '';
    if (diff.years > 0) {
        str = `${diff.years}y${diff.months > 0 ? ` ${diff.months}m` : ''}`;
    } else if (diff.months > 0) {
        str = `${diff.months}m${diff.days > 0 ? ` ${diff.days}day`: ''}`;
    } else if (diff.days > 0) {
        str = `${diff.days+(diff.hours>22&&diff.minutes>30?1:0)}d${diff.hours > 0 ? ` ${diff.hours>22&&diff.minutes>30?'':`${diff.hours + (diff.minutes>30 ? 1 : 0)}h`}`: ''}`;
    } else if (diff.hours > 0) {
        str = `${diff.hours}h ${diff.minutes > 0 ? ` ${diff.minutes}m`: ''}`;
    } else if (diff.minutes > 0) {
        str = `${diff.minutes}min${diff.minutes !== 1 ? 's' : ''}`;
    } else {
        str = `${diff.seconds > 0 || diff.milliseconds > 0 ? '1min' : '0mins'}`;
    }
    //console.log('==================|', ms, '<><><>|',str,'|');
    if(toolTip){
        return <span className='t-tip t' style={{color: '#000'}}>{str}<span className='t-txt'>{diff.years>0?`${diff.years}y`:''}{diff.months>0?` ${diff.months}m`:''}{diff.days>0?` ${diff.days}d`:''}{diff.hours>0?` ${diff.hours}h`:''}{diff.minutes>0?` ${diff.minutes}mins`:''}{diff.seconds>0?` ${diff.seconds}s`:''}</span></span>;
    }else{
        return <span title={`${diff.years>0?`${diff.years}year${diff.years===1?'':'s'}`:''}${diff.months>0?` ${diff.months}month${diff.months===1?'':'s'}`:''}${diff.days>0?` ${diff.days}day${diff.days===1?'':'s'}`:''}${diff.hours>0?` ${diff.hours}hour${diff.hours===1?'':'s'}`:''}${diff.minutes>0?` ${diff.minutes}minute${diff.minutes===1?'':'s'}`:''}${diff.seconds>0?` ${diff.seconds}second${diff.seconds===1?'':'s'}`:''}`}>{str}</span>;
    }
};
export const excludeZipCountry = i => {
    if (i && typeof i.toLowerCase() === 'string') return `${i}`.replace(/(?:(\s\d{1,},.[^,]+$)|(,[^,])$)/gi, '').replace(/(\s{0,},\s{0,},)/,',');
    return i;
};
export const excludeDoubleComma = i => {
    if (i && typeof i.toLowerCase() === 'string') return `${i}`.replace(/(\s{0,},\s{0,},)/,',');
    return i;
};


export const enableETA = () => {
    const R = localStorage.getItem('roles');
    const isCarrierTracking = localStorage.getItem('carrierTracking') === '1';
    return R && (R.toLowerCase() === 'super admin' || isCarrierTracking) ? true : false;
}
export const roundOff = (n, p) => {
    const P = isNaN(p) ? 0 : p;
    return +(Math.round(Number(n) + "e+" + P)  + "e-" + P)
}
/*

export const formatDateDDMM = (d, dontConvert) => {
    try {
        if (d === '' || d === null || d === undefined) return '';
        if(dontConvert) {
            return moment(d).format('MMM DD, YYYY @ hh:mm A') + ' ET';
        }
        return moment.tz(moment.utc(d), 'America/New_York').format('MMM DD, YYYY @ hh:mm A') + ' ET';

    } catch (e) {

    }
};
export const formatDateNotifications =(d) => {
    try {
        if (d === '' || d === null || d === undefined) return '';
        return moment.tz(moment.utc(d), 'America/New_York').format('MMM DD, YYYY @ hh:mm A');

    } catch (e) {

    }
};
export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


export const splitAt = i => x => [x.slice(0, i), x.slice(i)];

Date.prototype.getWeek = function () {
    var onejan = new Date(this.getFullYear(), 0, 1);
    var today = new Date(this.getFullYear(), this.getMonth(), this.getDate());
    var dayOfYear = ((today - onejan + 86400000) / 86400000);
    return Math.ceil(dayOfYear / 7)
};



export const getUTCESTOffset = (type, z) => {
    const zone = z || 'America/New_York';
    const DATE = moment.utc(new Date()).format('YYYY-MM-DD HH:MM');
    const ET_DATE = moment.tz(moment.utc(DATE), zone).format('YYYY-MM-DD HH:MM');
    const DATE_TIME = new Date(DATE).getTime();
    const ET_DATE_TIME = new Date(ET_DATE).getTime();
    let diff = DATE_TIME - ET_DATE_TIME;
    if(type === 'S' || type === 's') diff = diff / 1000;
    if(type === 'M' || type === 'm') diff = diff / 60;
    if(type === 'H' || type === 'h') diff = diff / 60;
    if(type === 'offset') {
        diff = moment.tz(moment.utc(DATE), zone).format('YYYY-MM-DD HH:MM Z').match(/\d{1,}:\d{1,}$/)[0];
    }
    return diff;
}

export const getRelativeDate = (d, days) => {
    const init = new Date(d);
    const newDate = init.getTime() + (days * 24 * 60 * 60 * 1000);
    return new Date(newDate);
}*/

const timeMap = {
    0: 'morning',
    1: 'afternoon',
    2: 'evening',
};
export const setTimeOfDay = () => {
    const D = new Date().getHours();
    const index = [12, 16, 24].findIndex(t => D < t);
    this.setState({ timeOfDay: timeMap[index] });
};


export const getSplitTime = (timeInSeconds, returnParam, returnHTML, returnString) => {
    const splitTime = {days: 0, hours: 0, minutes: 0};

    splitTime.days = parseInt(timeInSeconds / (24 * 60 * 60));
    const leftOverTimeAfterDaysSplit = timeInSeconds - ( splitTime.days * 24 * 60 * 60);

    splitTime.hours = parseInt(leftOverTimeAfterDaysSplit / (60 * 60));
    const leftOverTimeAfterHoursSplit = timeInSeconds - ((splitTime.days * 24* 60 * 60) + (splitTime.hours*60*60));

    splitTime.minutes = parseInt(leftOverTimeAfterHoursSplit / (60));

    splitTime.seconds = timeInSeconds - ((splitTime.days * 24 * 60 * 60) + (splitTime.hours*60*60) + (splitTime.minutes*60));

    if(returnParam) {
        return splitTime[returnParam];
    } else if (returnHTML) {
        return (
        <>
        {splitTime.days > 0 ?
            <span className={`ex-time d${splitTime.days===1?' o':''}`}>{splitTime.days}</span>
        : '' }
        {splitTime.hours > 0 ?
            <span className={`ex-time h${splitTime.hours===1?' o':''}`}>{splitTime.hours}</span>
        : '' }
        {((splitTime.days === 0 || splitTime.hours === 0) && splitTime.minutes > 0) ?
            <span className={`ex-time m${splitTime.minutes===1?' o':''}`}>{splitTime.minutes}</span>
        : ''}
        {splitTime.days === 0 && splitTime.hours === 0 && splitTime.minutes === 0 && splitTime.seconds > 0 ?
            <span className={`ex-time s${splitTime.seconds===1?' o':''}`}>{splitTime.seconds}</span>
        : ''}
        {splitTime.days === 0 && splitTime.hours === 0 && splitTime.minutes === 0 && splitTime.seconds === 0 ?
            <span className={`ex-time m`}>{splitTime.minutes}</span>
        : ''}
        </>
        );
    } else if (returnString) {
        const obj = [];
        if(splitTime.days > 0) {
            obj.push( `${splitTime.days}day${splitTime.days > 1 ? 's' : ''}` );
        }
        if(splitTime.hours > 0) {
            obj.push( `${splitTime.hours}hour${splitTime.hours > 1 ? 's' : ''}` );
        }
        if((splitTime.days === 0 || splitTime.hours === 0) && splitTime.minutes > 0) {
            obj.push( `${splitTime.minutes}minute${splitTime.minutes > 1 ? 's' : ''}` );
        }
        if((splitTime.days === 0 && splitTime.hours === 0 && splitTime.minutes === 0) && splitTime.seconds > 0) {
            obj.push( `${splitTime.seconds}second${splitTime.seconds > 1 ? 's' : ''}` );
        }
        return obj.join(' ');
    }

    return splitTime;
};

export const getFullTime = (splitTimeObj) => {
    let fullTime = 0;
    if(splitTimeObj?.mo && !isNaN(splitTimeObj.mo)) {
        fullTime = parseInt(splitTimeObj.mo, 10) * (30 * 24 * 60 * 60);
    }
    if(splitTimeObj?.d && !isNaN(splitTimeObj.d)) {
        fullTime += parseInt(splitTimeObj.d, 10) * (24 * 60 * 60);
    }
    if(splitTimeObj?.h && !isNaN(splitTimeObj.h)) {
        fullTime += parseInt(splitTimeObj.h, 10) * (60 * 60);
    }
    if(splitTimeObj?.m && !isNaN(splitTimeObj.m)) {
        fullTime += parseInt(splitTimeObj.m, 10) * (60);
    }
    if(!isNaN(splitTimeObj.s)) {
        fullTime += parseInt(splitTimeObj.s, 10);
    }
    return fullTime;
}

export const getSplitTimeConfig = (timeInSec, returnType) => {
    const timeInSeconds = timeInSec; /* || ((1*23*60*60) + (5*60*60) + (20*60) + (59))*/

    const splitTime = {mo: 0, d: 0, h: 0, m: 0, s: 0};

    splitTime.mo = parseInt(timeInSeconds / (30 * 24 * 60 * 60));
    const leftOverTimeAfterMonthsSplit = timeInSeconds - ( splitTime.mo * 30 * 24 * 60 * 60);

    splitTime.d = parseInt(leftOverTimeAfterMonthsSplit / (24 * 60 * 60));
    const leftOverTimeAfterDaysSplit = timeInSeconds - (( splitTime.mo * 30 * 24 * 60 * 60) + ( splitTime.d * 24 * 60 * 60));

    splitTime.h = parseInt(leftOverTimeAfterDaysSplit / (60 * 60));
    const leftOverTimeAfterHoursSplit = timeInSeconds - (( splitTime.mo * 30 * 24 * 60 * 60) + (splitTime.d * 24* 60 * 60) + (splitTime.h*60*60));

    splitTime.m = parseInt(leftOverTimeAfterHoursSplit / (60));

    splitTime.s = timeInSeconds - (( splitTime.mo * 30 * 24 * 60 * 60) + (splitTime.d * 24 * 60 * 60) + (splitTime.h*60*60) + (splitTime.m*60));
    
    return returnType ? splitTime[returnType] : splitTime;
};

export const getDateFromDurationTimeline = (obj) => {
    const dateTimeStamp = getDate(obj.date, true).getTime();
    const duration_num = Number(obj.duration);
    const durationDateTimeStamp = dateTimeStamp + (duration_num * 1000);
    const durationDateTime = moment(durationDateTimeStamp).format("MM-DD-YYYY HH:mm")

    return durationDateTime;

}

export const getDateFromDuration = (date, duration, dontConvert) => {
    const createdDate = getDate(date, true, dontConvert).getTime();
    const T = new Date(createdDate + (Number(duration) * 1000));
    const today = new Date();
    let connectingString = 'at';
    const day = T.getDate();
    const month = T.getMonth();
    const year = T.getFullYear();

    if (T.getTime() > today.getTime()) {
        connectingString = 'by';
        if (day === today.getDate() && month === today.getMonth() && year === today.getFullYear()) {
            return `Today ${connectingString} ${moment(T).format('HH:mm')}`;
        }
    }

    return moment(T)
        .format('MM/DD/YYYY at HH:mm')
        .replace(/amt|pmt/, connectingString);
}

export const getShortCode = (locationObj) => {
    let locationShortCode = '';
    let city = '';
    let state = '';
    //console.log('locationObj: ', locationObj);
    if (locationObj && locationObj.context) {
        for (let index = 0; index < locationObj.context.length; index++) {
            const element = locationObj.context[index];
            if (element.id && element.id.includes('place')) {
                city = element.text;
            } else if (element.id && element.id.includes('region')) {
                state = element.text;
            }
            if (element.short_code) {
                //console.log('short code of city -->', element.short_code);
                // locationShortCode = element.short_code;
                let split_shortCode = '';
                if (element.short_code.includes('-')) {
                    split_shortCode = element.short_code.split('-');
                    locationShortCode = split_shortCode[0];
                    console.log('short code of contains -->', locationShortCode);
                } else {
                    locationShortCode = element.short_code;
                }
            }
        }
    } else {
        console.log(
            'short code of contains -->',
            'Context object is not available',
        );
    }
    if (locationShortCode === '') {
        locationShortCode = locationObj.properties?.short_code || '';
    }
    
    let locToString = '';
    if (city.length !== 0 && state.length !== 0) {
        locToString = city + ', ' + state;
    } else if (city.length === 0 && state.length !== 0) {
        locToString = locationObj.text + ', ' + state;
    } else {
        locToString = locationObj.text + ', ' + locationShortCode;
    }
    console.log('final Short Code: ', locToString);
    return locToString;
}

export const checkValidRelevance = (locations) => {
    let isRelevantLocation = false;
    for(let loc=0; loc<locations.length; loc++) {
        if(locations[loc].relevance >= 1) {
            isRelevantLocation = true;
            break;
        }
    }
    return isRelevantLocation;
}

export const dataToCsvURI = (data, name) => {
    const csvContent = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(data.map(row =>  row.join(',')).join(`\n `))}`;
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    if(link.remove) link.remove();
}

export const getDecimalNumber = (number, decimal, returnIfNaN) => {
    const d = decimal || 1;
    if(isNaN(number)) if(returnIfNaN) return number; else number = 0;
    return number % 1 === 0 ? parseInt(number,10) : Number(number).toFixed(d);
};

export const showDottedText = (txt, len=8) => {
    if(txt && txt.length>len) {
        return { txt: `${txt.substring(0,2)}...${txt.substring(txt.length-4)}`, clipped: true};
    }
    return {txt:txt?txt:'---', clipped: false};
};

export const getTotalMonths = (sDate, eDate) => {
    let endDate = new Date(eDate||'');
    let startDate = new Date(sDate||'');
    return ((endDate.getMonth() - startDate.getMonth()) + (12 * (endDate.getFullYear() - startDate.getFullYear()))) || 1;
};

export const generateRiskInsightsObj = ({Insights, insightsMapping, showInsights, duration, risk_factor, start_at, updated_at, compareLanesObj, insightsRangeFilter, transport_modes}) => {
    const formattedInsights = {};
    console.log('=====================================');
    console.log('Insights............---------------: ', Insights);
    console.log('duration............---------------: ', duration);
    console.log('=====================================');
    let durationRange = '-- — --';
    const durationRanges = {all:null, year:null, halfYear:null, quarter:null, month:null};
    //let durationPreviousRange = '-- — --';
    let comparisonString = '--';
    let lastSyncMonth = updated_at?new Date(updated_at*1000).getMonth():new Date().getMonth();
    //console.log('>>>updated_at: ', updated_at, '::::: ', updated_at?new Date(updated_at*1000).getMonth():new Date().getMonth());
    let lastSyncYear = updated_at?new Date(updated_at*1000).getFullYear():new Date().getFullYear();
    let rangeFilter = {month: false, quarter: false, halfYear: false, year: false};
    const ZERO_PARAM_LIST = ['avg_excursion_count_per_shipment','avg_excursion_duration_per_shipment','shipment_count', 'excursion_shipment_count', 'in-transit_delays', "delayed_shipment_count", "delayed_shipment_count_transport_mode"];
    if(compareLanesObj) {
        for(let lane_r_or_l in Insights) {
            //console.log('compareLanesObj, lane_r_or_l=======> ',compareLanesObj, lane_r_or_l);
            let loopIndex = 0;
            if(showInsights === 'lane') {
                loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(lane_r_or_l));
            } else if (showInsights === 'route') {
                const obj = lane_r_or_l.split('_');
                loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(obj[0]) && Number(lane.route_id) === Number(obj[1]));
            } else if (showInsights === 'waypoint') {
                const obj = lane_r_or_l.split('_');
                loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(obj[0]) && Number(lane.route_id) === Number(obj[1]) && Number(lane.leg_id) === Number(obj[2]));
            }
            const u_at = compareLanesObj[loopIndex]?.obj.updated_at||null;
            lastSyncMonth = u_at?new Date(u_at*1000).getMonth():new Date().getMonth();
            lastSyncYear = u_at?new Date(u_at*1000).getFullYear():new Date().getFullYear();
        }
    }

    let activeYear = lastSyncYear-1;
    let deviationYear = activeYear-1;
    if(start_at&&updated_at) durationRanges.all = {start: new Date(`${getDate((start_at || ''), false, true, false, true, false, true)}`).getTime(), end: new Date(`${getDate((updated_at*1000 || ''), false, true, false, true, false, true)}`).getTime()};
    if(duration === 'ALL' && start_at && updated_at) {
        durationRange = `${getDate((start_at || ''), false, true, false, true, false, true)} — ${getDate((updated_at*1000 || ''), false, true, false, true, false, true)}`
        comparisonString = 'No comparison is displayed when all data is selected.';
    }
    /*360D*/
    durationRanges.year = {start: new Date(`01/Jan/${activeYear}`).getTime(), end: new Date(`31/Dec/${activeYear}`).getTime()};
    if(duration === '360D') {
        durationRange = `01/Jan/${activeYear} — 31/Dec/${activeYear}`;
        comparisonString = `Now comparing Jan - Dec ${activeYear} (current year) with Jan - Dec ${deviationYear} (previous year)`;
    }
    /*180D*/
    activeYear = lastSyncMonth>5?lastSyncYear:lastSyncYear-1;
    const activeHalfYear = lastSyncMonth>5?'h1':'h2';
    const deviationHalfYear = activeHalfYear==='h1'?'h2':'h1';
    deviationYear = activeHalfYear==="h1"?activeYear-1:activeYear;
    const durationMonthMapping = {h1: 'Jan - Jun', h2: 'Jul - Dec', q1: 'Jan - Mar', q2: 'Apr - Jun', q3: 'Jul - Sept', q4: 'Oct - Dec'};

    durationRanges.halfYear = {start: new Date(`01/${activeHalfYear==="h1"?'Jan':'Jul'}/${activeYear}`).getTime(), end: new Date(`${activeHalfYear==="h1"?'30':'31'}/${activeHalfYear==="h1"?'Jun':'Dec'}/${activeYear}`).getTime()};
    if(duration === '180D') {
        durationRange = `01/${activeHalfYear==="h1"?'Jan':'Jul'}/${activeYear} — ${activeHalfYear==="h1"?'30':'31'}/${activeHalfYear==="h1"?'Jun':'Dec'}/${activeYear}`;
        comparisonString = `Now comparing ${durationMonthMapping[activeHalfYear]} ${activeYear} (${activeHalfYear.toUpperCase()} ${activeYear}) with previous ${durationMonthMapping[deviationHalfYear]} ${deviationYear} (${deviationHalfYear.toUpperCase()} ${deviationYear})`;
    }
    /*90D*/
    activeYear = lastSyncMonth<3?lastSyncYear-1:lastSyncYear;
    const activeQuarterYear = lastSyncMonth>=9?"q3":lastSyncMonth>=6?"q2":lastSyncMonth>=3?"q1":"q4";
    const deviationQuarterYear = activeQuarterYear==="q1"?"q4":activeQuarterYear==="q2"?"q1":activeQuarterYear==="q3"?"q2":"q3";
    durationRanges.quarter = {start: new Date(`01/${activeQuarterYear==='q1'?'Jan':activeQuarterYear==='q2'?'Apr':activeQuarterYear==='q3'?'Jul':'Oct'}/${activeYear}`).getTime(), end: new Date(`${activeQuarterYear==='q1'?'31':activeQuarterYear==='q2'?'30':activeQuarterYear==='q3'?'30':'31'}/${activeQuarterYear==='q1'?'Mar':activeQuarterYear==='q2'?'Jun':activeQuarterYear==='q3'?'Sept':'Dec'}/${activeYear}`).getTime()};
    if(duration === '90D') {
        durationRange = `01/${activeQuarterYear==='q1'?'Jan':activeQuarterYear==='q2'?'Apr':activeQuarterYear==='q3'?'Jul':'Oct'}/${activeYear} — ${activeQuarterYear==='q1'?'31':activeQuarterYear==='q2'?'30':activeQuarterYear==='q3'?'30':'31'}/${activeQuarterYear==='q1'?'Mar':activeQuarterYear==='q2'?'Jun':activeQuarterYear==='q3'?'Sept':'Dec'}/${activeYear}`;
        comparisonString = `Now comparing ${durationMonthMapping[activeQuarterYear]} ${activeYear} (${activeQuarterYear.toUpperCase()} ${activeYear}) with previous quarter ${durationMonthMapping[deviationQuarterYear]} ${activeQuarterYear==='q1'?activeYear-1:activeYear} (${deviationQuarterYear.toUpperCase()} ${activeQuarterYear==='q1'?activeYear-1:activeYear})`;
    }
    /*30D*/
    activeYear = lastSyncMonth?lastSyncYear:lastSyncYear-1;
    const activeMonth = lastSyncMonth||12;
    const deviationMonth = activeMonth-1||12;
    deviationYear = deviationMonth==12?activeYear-1:activeYear;
    durationRanges.month = {start: new Date(`01/${getShortMonth(activeMonth)}/${activeYear}`).getTime(), end: new Date(`${new Date(activeYear, activeMonth, 0).getDate()}/${getShortMonth(activeMonth)}/${activeYear}`).getTime()};
    if(duration === '30D') {
        durationRange = `01/${getShortMonth(activeMonth)}/${activeYear} — ${new Date(activeYear, activeMonth, 0).getDate()}/${getShortMonth(activeMonth)}/${activeYear}`;
        comparisonString = `Now comparing ${getShortMonth(activeMonth)} ${activeYear} (current month) with ${getShortMonth(activeMonth===1?12:activeMonth-1)} ${deviationYear} (previous month)`;
    }

    if(Insights && Object.values(Insights) && Object.values(Insights)[0] && Object.keys(Object.values(Insights).flat())?.length) {
        for(let lane_r_or_l in Insights) {
            if(compareLanesObj) {
                //console.log('compareLanesObj, lane_r_or_l=======> ',compareLanesObj, lane_r_or_l);
                let loopIndex = 0;
                if(showInsights === 'lane') {
                    loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(lane_r_or_l));
                } else if (showInsights === 'route') {
                    const obj = lane_r_or_l.split('_');
                    loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(obj[0]) && Number(lane.route_id) === Number(obj[1]));
                } else if (showInsights === 'waypoint') {
                    const obj = lane_r_or_l.split('_');
                    loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(obj[0]) && Number(lane.route_id) === Number(obj[1]) && Number(lane.leg_id) === Number(obj[2]));
                }
                const u_at = compareLanesObj[loopIndex]?.obj.updated_at||null;
                lastSyncMonth = u_at?new Date(u_at*1000).getMonth():new Date().getMonth();
                lastSyncYear = u_at?new Date(u_at*1000).getFullYear():new Date().getFullYear();
            }
            
            formattedInsights[lane_r_or_l] = {};
            if(lane_r_or_l){} else return {};
            Object.keys(Insights[lane_r_or_l]).forEach(riskFactor => {
                const compareObj = showInsights === 'lane' ? 'overview' : riskFactor;
                if(risk_factor === '' || risk_factor === riskFactor) {
                    if(Insights[lane_r_or_l][compareObj]?.all_time) {
                        for(let key in Insights[lane_r_or_l][compareObj].all_time) {
                            if(duration === 'ALL') {
                                if(insightsMapping[key]) {
                                    formattedInsights[lane_r_or_l][insightsMapping[key]] = Insights[lane_r_or_l][compareObj].all_time[key];
                                    if(Number(key)===7 && showInsights === 'waypoint') {
                                        formattedInsights[lane_r_or_l][insightsMapping[9]] = Insights[lane_r_or_l][compareObj].all_time[key];
                                        formattedInsights[lane_r_or_l]['transport_mode'] = transport_modes[lane_r_or_l];
                                    }
                                } else {
                                    formattedInsights[lane_r_or_l][key] = Insights[lane_r_or_l][compareObj].all_time[key];
                                }
                            }
                        }
                    }
                    if(Insights[lane_r_or_l][compareObj]) {
                        /*RANGE: 360D*/
                        let activeYear = lastSyncYear-1;
                        let deviationYear = activeYear-1;
                        for(let key in Insights[lane_r_or_l][compareObj][activeYear]||[]) {
                            if(duration === '360D') {
                                if(insightsMapping[key]) {
                                    formattedInsights[lane_r_or_l][insightsMapping[key]] = Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]:null;
                                    formattedInsights[lane_r_or_l][`${insightsMapping[key]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]?Insights[lane_r_or_l][compareObj][deviationYear][key]:null;
                                    if(Number(key)===7 && showInsights === 'waypoint') {
                                        formattedInsights[lane_r_or_l][insightsMapping[9]] = Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]:null;
                                        formattedInsights[lane_r_or_l][`${insightsMapping[9]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]?Insights[lane_r_or_l][compareObj][deviationYear][key]:null;
                                        formattedInsights[lane_r_or_l]['transport_mode'] = transport_modes[lane_r_or_l];
                                    }
                                    if(!rangeFilter.year && duration==='ALL') {
                                        if(Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]!=="null"&&Insights[lane_r_or_l][compareObj][activeYear][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][key])?false:true):true):false) {
                                            rangeFilter.year = true;
                                        }
                                    }
                                } else {
                                    formattedInsights[lane_r_or_l][key] = Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]:null;
                                }
                            } else {
                                if(!rangeFilter.year && duration==='ALL') {
                                    if(Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]!=="null"&&Insights[lane_r_or_l][compareObj][activeYear][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][key])?false:true):true):false) {
                                        rangeFilter.year = true;
                                    }
                                }
                            }
                        }
    
                        /*RANGE: 180D*/                
                        activeYear = lastSyncMonth>5?lastSyncYear:lastSyncYear-1;
                        const activeHalfYear = lastSyncMonth>5?'h1':'h2';
                        const deviationHalfYear = activeHalfYear==='h1'?'h2':'h1';
                        deviationYear = activeHalfYear==="h1"?activeYear-1:activeYear;
                        if(Insights[lane_r_or_l][compareObj][activeYear]) {
                            for(let key in Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear]||[]) {
                                if(duration === '180D') {
                                    if(insightsMapping[key]) {
                                        formattedInsights[lane_r_or_l][insightsMapping[key]] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear]?Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key]:null;
                                        formattedInsights[lane_r_or_l][`${insightsMapping[key]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]&&Insights[lane_r_or_l][compareObj][deviationYear][deviationHalfYear]?Insights[lane_r_or_l][compareObj][deviationYear][deviationHalfYear][key]:null;
                                        if(Number(key)===7 && showInsights === 'waypoint') {
                                            formattedInsights[lane_r_or_l][insightsMapping[9]] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear]?Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key]:null;
                                            formattedInsights[lane_r_or_l][`${insightsMapping[9]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]&&Insights[lane_r_or_l][compareObj][deviationYear][deviationHalfYear]?Insights[lane_r_or_l][compareObj][deviationYear][deviationHalfYear][key]:null;
                                            formattedInsights[lane_r_or_l]['transport_mode'] = transport_modes[lane_r_or_l];
                                        }
                                        if(!rangeFilter.halfYear && duration==='ALL') {
                                            if(Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear]?Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key]!=='null'&&Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key])?false:true):true):false) {
                                                rangeFilter.halfYear = true;
                                            }
                                        }
                                    } else {
                                        formattedInsights[lane_r_or_l][key] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear]?Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key]:null;
                                    }
                                } else {
                                    if(!rangeFilter.halfYear && duration==='ALL') {
                                        if(Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear]?Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key]!=='null'&&Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][activeHalfYear][key])?false:true):true):false) {
                                            rangeFilter.halfYear = true;
                                        }
                                    }
                                    // console.log('half year >>> ', insightsMapping[key], ' : ', Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]:'---', ' | rangeFilter: ', rangeFilter);
                                }
                            }
                        }
                        
                        /*RANGE: 900D*/
                        activeYear = lastSyncMonth<3?lastSyncYear-1:lastSyncYear;
                        const activeQuarterYear = lastSyncMonth>=9?"q3":lastSyncMonth>=6?"q2":lastSyncMonth>=3?"q1":"q4";
                        const deviationQuarterYear = activeQuarterYear==="q1"?"q4":activeQuarterYear==="q2"?"q1":activeQuarterYear==="q3"?"q2":"q3";
                        deviationYear = activeQuarterYear==="q1"?activeYear-1:activeYear;
                        if(Insights[lane_r_or_l][compareObj][activeYear]) {
                            for(let key in Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear]) {
                                if(duration === '90D'){
                                    if(insightsMapping[key]) {
                                        formattedInsights[lane_r_or_l][insightsMapping[key]] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear]?Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key]:null;
                                        formattedInsights[lane_r_or_l][`${insightsMapping[key]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]&&Insights[lane_r_or_l][compareObj][deviationYear][deviationQuarterYear]?Insights[lane_r_or_l][compareObj][deviationYear][deviationQuarterYear][key]:null;
                                        if(Number(key)===7 && showInsights === 'waypoint') {
                                            formattedInsights[lane_r_or_l][insightsMapping[9]] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear]?Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key]:null;
                                            formattedInsights[lane_r_or_l][`${insightsMapping[9]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]&&Insights[lane_r_or_l][compareObj][deviationYear][deviationQuarterYear]?Insights[lane_r_or_l][compareObj][deviationYear][deviationQuarterYear][key]:null;
                                            formattedInsights[lane_r_or_l]['transport_mode'] = transport_modes[lane_r_or_l];
                                        }
                                        if(!rangeFilter.quarter && duration==='ALL') {
                                            if(Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear]?Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key]!=='null'&&Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key])?false:true):true):false) {
                                                rangeFilter.quarter = true;
                                            }
                                        }
                                    } else {
                                        formattedInsights[lane_r_or_l][key] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear]?Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key]:null;
                                    }
                                } else {
                                    if(!rangeFilter.quarter && duration==='ALL') {
                                        if(Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear]?Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key]!=='null'&&Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][activeQuarterYear][key])?false:true):true):false) {
                                            rangeFilter.quarter = true;
                                        }
                                    }
                                    // console.log('quarter >>> ', insightsMapping[key], ' : ', Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]:'---', ' | rangeFilter: ', rangeFilter);
                                }
                            }
                        }
                    
                        /*RANGE: 30D*/
                        activeYear = lastSyncMonth?lastSyncYear:lastSyncYear-1;
                        const activeMonth = lastSyncMonth||12;
                        const deviationMonth = activeMonth-1||12;
                        deviationYear = deviationMonth==12?activeYear-1:activeYear;
                        if(Insights[lane_r_or_l][compareObj][activeYear]) {
                            for(let key in Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`]) {
                                if(duration === '30D'){
                                    if(insightsMapping[key]) {
                                        formattedInsights[lane_r_or_l][insightsMapping[key]] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`]?Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key]:null;
                                        formattedInsights[lane_r_or_l][`${insightsMapping[key]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]&&Insights[lane_r_or_l][compareObj][deviationYear][`month_${deviationMonth}`]?Insights[lane_r_or_l][compareObj][deviationYear][`month_${deviationMonth}`][key]:null;
                                        if(Number(key)===7 && showInsights === 'waypoint') {
                                            formattedInsights[lane_r_or_l][insightsMapping[9]] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`]?Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key]:null;
                                            formattedInsights[lane_r_or_l][`${insightsMapping[9]}_deviation`] = Insights[lane_r_or_l][compareObj][deviationYear]&&Insights[lane_r_or_l][compareObj][deviationYear][`month_${deviationMonth}`]?Insights[lane_r_or_l][compareObj][deviationYear][`month_${deviationMonth}`][key]:null;
                                            formattedInsights[lane_r_or_l]['transport_mode'] = transport_modes[lane_r_or_l];
                                        }
                                        if(!rangeFilter.month && duration==='ALL') {
                                            if(Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`]?Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key]!=='null'&&Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key])?false:true):true):false) {
                                                rangeFilter.month = true;
                                            }
                                        }
                                    } else {
                                        formattedInsights[lane_r_or_l][key] = Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`]?Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key]:null;
                                    }
                                } else {
                                    if(!rangeFilter.month && duration==='ALL') {
                                        if(Insights[lane_r_or_l][compareObj][activeYear]&&Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`]?Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key]!=='null'&&Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key]!==undefined&&(ZERO_PARAM_LIST.includes(insightsMapping[key])?(isNaN(Insights[lane_r_or_l][compareObj][activeYear][`month_${activeMonth}`][key])?false:true):true):false) {
                                            rangeFilter.month = true;
                                        }
                                    }
                                    //console.log('month >>> ', insightsMapping[key], ' : ', Insights[lane_r_or_l][compareObj][activeYear]?Insights[lane_r_or_l][compareObj][activeYear][key]:'---', ' | rangeFilter: ', rangeFilter);
                                }
                            }
                        
                        }
                    }
                }
            });
        }    
    }
        
    return {insights: formattedInsights, durationRange,  rangeFilter:duration==='ALL'?rangeFilter:insightsRangeFilter, comparisonString, durationRanges};
};
export const validateInsightValue = (obj, key, validateDeviation, checkIsEqual, consider0AsValid) => {
    let isValid = true;
    if(((consider0AsValid?(obj[key]===undefined||obj[key]===null||obj[key]==='null'):(!obj[key] || obj[key] === 'null')) || (validateDeviation ? (consider0AsValid?(obj[`${key}_deviation`]===undefined||obj[`${key}_deviation`]===null||obj[`${key}_deviation`]==='null'):(!obj[`${key}_deviation`] || obj[`${key}_deviation`] === 'null')): false) || (checkIsEqual?obj[key]!==obj[`${key}_deviation`]:false))) isValid = false;
    return isValid;
}
export const getShortMonth = (m) => {
    return new Date(`${m}/1/${new Date().getFullYear()}`).toLocaleString('default', { month: 'short' })
}