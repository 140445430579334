import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import rootReducer from './redux-store/reducers';
import { googleAnalyticActions } from './init-ga';
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
const Header = require(`./components/Header/${process.env.REACT_APP_CUSTOMER_ID}/Header`).default;
require(`./config/${process.env.REACT_APP_CUSTOMER_ID}/theme.css`);
require(`./index.css`);

const App = React.lazy(() => import('./App'));
const Company = React.lazy(() => import('./Company'));
const Login = React.lazy(() => import('./components/Auth/Login'));
const ForgotPasswordPage = React.lazy(() => import('./components/Auth/ForgotPasswordPage'));
const Auth0Login = React.lazy(() => import('./components/Auth/Auth0Login'));
const LogoutScreen = React.lazy(() => import('./components/Auth/LogoutScreen'));
const Logout = React.lazy(() => import('./components/Auth/Logout'));
const SetPassword = React.lazy(() => import('./components/Auth/SetPassword'));
const VerificationCode = React.lazy(() => import('./components/Auth/VerificationCode'));
const UserProfile = React.lazy(() => import('./components/UserProfile/UserProfile'));
const Help = React.lazy(() => import('./components/Help/HelpNew'));
const Configuration = React.lazy(() => import('./components/Configuration/Configuration'));
const EditTempaltes = React.lazy(() => import('./components/Configuration/EditTempaltes'));
const AlertProfilePage = React.lazy(() => import('./components/Configuration/AlertProfilePage'));
const AssetTracking = React.lazy(() => import('./components/AssetTracking/AssetTracking'));
const PdfParser = React.lazy(() => import('./components/Pdf/PdfParser'));
const RiskIQ = React.lazy(() => import('./components/Shipments/RiskIQ/RiskIQ'));
const RiskAssessment = React.lazy(() => import('./components/Shipments/RiskIQ/RiskAssessment'));
const RiskPlanning = React.lazy(() => import('./components/Shipments/RiskIQ/RiskPlanning'));
const LiveRiskIQ = React.lazy(() => import('./components/Shipments/LiveRiskIQ/LiveRiskIQ'));
const TermsOfService = React.lazy(() => import('./components/Auth/TermsOfService'));

//const RiskDetailView = React.lazy(() => import('components/Shipments/RiskIQ/Risk_datailview'));
const TemperatureDashboard = React.lazy(() => import('./components/Pdf/TemperatureDashboard'));
const ParserConfiguraton = React.lazy(() => import('components/Pdf/ParserConfiguration'));
const Users = React.lazy(() => import('./Users'));
const Devices = React.lazy(() => import('./Devices'));
const Insights = React.lazy(() => import('./Insights'));
const StaticPages = React.lazy(() => import('./static-pages/App'));
const ActiveDashboard = React.lazy(() => import('./components/Dashboard/ActiveDashboard'));
const PassiveDashboard = React.lazy(() => import('./components/Dashboard/PassiveDashboard'));
const RiskProtoType = React.lazy(() => import('./components/Shipments/RiskIQ/RiskProtoType'));

const container = document.getElementById('root');
const root = createRoot(container);


const initialState = window.__INITIAL_STATE__;
delete window.__INITIAL_STATE__;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(thunk)
));
const hist = createBrowserHistory();
const ROLE = localStorage.getItem('roles');
const companyId = localStorage.getItem('companyId');
const ENV = parseInt(process.env.REACT_APP_CUSTOMER_ID,10);
if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js', { scope: './' })
        .then(function(registration) {
            console.log('Registration successful, scope is...: ', registration.scope);
        })
        .catch(function(err) {
            console.log('Service worker registration failed, error...: ', err);
        });
}
const wordsToCheck = ["login", "forgotPassword", "updatePassword", "verificationCode",''];

const onRedirectCallback = (appState) => {
    hist.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};

const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    onRedirectCallback,
    cacheLocation: 'localstorage',
    authorizationParams: {
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        // redirect_uri: 'http://localhost:3000',
        ...(process.env.REACT_APP_AUDIENCE ? { audience: process.env.REACT_APP_AUDIENCE } : null),
    },
};

Sentry.init({
    environment: "preprod",
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["https://api-preprod.paxafe.com","https://echoserver-preprod.paxafe.com"],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});  

root.render(
    <Auth0Provider {...providerConfig}>
        <Provider store={store}>
            <Suspense fallback={wordsToCheck.some((word) => window.location.href.includes(word)) ? <div className='loading'/> : <><Header /><div className='loading' style={{top: '70px'}}/></> }>
            <BrowserRouter history={hist}>
                <Routes>
                    {ROLE === 'super admin' || ROLE === 'group admin'? <Route path="/companies" element={<Company/>} /> : ''}
                    {ROLE === 'company admin' ? <Route path="/users" element={<Users/>} /> : ''}
                    <Route path="/login" element={<Auth0Login/>} />
                    <Route path="/pages/" element={<StaticPages />} />
                    <Route path="/pages/index.html/?" element={<StaticPages />} />
                    <Route path="/forgotPassword/:encrypt" element={<SetPassword/>} />
                    <Route path="/forgotPassword" element={<ForgotPasswordPage/>} />
                    <Route path="/loggedOut" element={<LogoutScreen/>} />
                    <Route path="/signOut" element={<Logout/>} />
                    <Route path="/termsofservice" element={<TermsOfService/>} />
                    <Route path="/riskrevamp" element={<RiskProtoType/>} />
                    {ENV === 0 ? <Route path="/help" element={<Help/>} /> : ( '' )}
                    {
                    (ROLE === 'company admin' || ROLE === 'group admin') && parseInt(localStorage.getItem('remaningStabilityBudget')) && ENV === 0 ? (
                        <Route path="/config/templates" element={<EditTempaltes/>} />
                    ) : ( '' )}
                    {ENV === 0?<Route path="/config/userConfig" element={<AlertProfilePage/>} />:( '' )}
                    {parseInt(localStorage.getItem('pdfParser')) ? <Route path="/config/parserConfig" element={<ParserConfiguraton/>} /> : ( '' )}
                    {ENV === 0?<Route path="/config" element={<Configuration/>} />:( '' )}
                    {ENV === 0?<Route path="/assets" element={<AssetTracking/>} />:( '' )}
                    <Route path="/verificationCode/:encrypt" element={<VerificationCode/>} />
                    {ENV === 0?<Route path="/dashboard" element={<App/>} />: ( '' )}
                    <Route path="/profile" element={<UserProfile/>} />
                    {ENV === 0 ? <Route path="/insights" element={<Insights/>} /> : ( '' )}
                    {ENV === 0 && (ROLE === 'super admin' || ROLE === 'company admin' || ROLE === 'group admin') ? (
                        <Route path="/devices" element={<Devices/>} />
                    ) : ( '' )}
                    {parseInt(localStorage.getItem('pdfParser')) ? (
                        <Route path="/pdfParser" element={<PdfParser/>} />
                    ) : ( '' )}
                    {parseInt(localStorage.getItem('pdfParser')) ? (
                        <Route path="/temperature_dashboard" element={<PassiveDashboard/>} />
                    ) : ( '' )}
                    {parseInt(localStorage.getItem('riskIQ')) ? (
                        <Route path="/active_dashboard" element={<ActiveDashboard/>} />
                    ) : ( '' )}
                    {parseInt(localStorage.getItem('riskIQ')) ? (
                        <Route path="/riskiq/:laneId?" element={<RiskAssessment/>} />
                    ) : ( '' )}
                    {parseInt(localStorage.getItem('riskIQ')) ? (
                        <Route path="/riskplanning" element={<RiskPlanning/>} />
                    ) : ( '' )}
                    {
                        (ENV === 0 && process.env.REACT_APP_ENV === 'PRODUCTION' ? (companyId === '75' || companyId === '5') : localStorage.getItem('company')?.toLowerCase() === 'paxafe') ?
                        (<Route path="/liveriskiq" element={<LiveRiskIQ/>} />
                        ) : 
                        ('')
                    }
                    {ENV === 0 ? <Route path="/shipments/:pageType?" element={<App/>} /> : ( '' ) }
                    {
                        ENV === 0 ?
                        <Route path="/" element={<Auth0Login/>} /> :
                        <Route path="/" element={<RiskAssessment/>} />
                    }
                    {
                        ENV === 0 ?
                        <Route element={<Auth0Login/>} /> :
                        <Route element={<RiskAssessment/>} />
                    }
                </Routes>
            </BrowserRouter>
            </Suspense>
        </Provider>
    </Auth0Provider>
);
if(process.env.REACT_APP_ENV === 'PRODUCTION') {
    window.console.log = () => {};
}
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        if(localStorage.getItem('access_token')) {
            if(window.location.pathname === '/login'){
                window.location.replace('/dashboard');
            }
        } else {
            console.log('NO access_token, logging out......');
            if(localStorage.getItem('sso')){
                window.location.replace('/loggedOut');
            } else {
                window.location.replace('/login');
            }
        }
    } else {
        console.log('This page was loaded normally.');
    }
});
googleAnalyticActions.initGoogleAnalytics(process.env.REACT_APP_GA_KEY);
