import React from 'react';

const GeoFenceLocIcon = (props) => {
    return (
        <svg
			version="1.1" 
			width="512" 
			height="512"
			x="0" 
			y="0" 
			viewBox="0 0 96 96" 
			style={{enableBackground:'new 0 0 512 512'}}
			className={props?.className || ''}>
			<g><path xmlns="http://www.w3.org/2000/svg" id="Location" d="m48 92c-19.878 0-40-4.122-40-12 0-5.732 9.882-8.721 18.172-10.218 1.092-.207 2.128.525 2.323 1.612.196 1.087-.525 2.127-1.612 2.323-10.842 1.96-14.883 4.893-14.883 6.283 0 2.715 12.707 8 36 8s36-5.285 36-8c0-1.39-4.041-4.323-14.883-6.282-1.087-.196-1.809-1.236-1.612-2.323.195-1.087 1.237-1.815 2.323-1.612 8.29 1.496 18.172 4.485 18.172 10.217 0 7.878-20.122 12-40 12zm32-56c0 24.785-29.629 44.823-30.891 45.664-.336.224-.722.336-1.109.336s-.773-.112-1.109-.336c-1.262-.841-30.891-20.879-30.891-45.664 0-17.645 14.355-32 32-32s32 14.355 32 32zm-18 0c0-7.732-6.268-14-14-14s-14 6.268-14 14 6.268 14 14 14 14-6.268 14-14z" fill={props?.fill || '#fff'} data-original="#000000"/></g>
		</svg>
    )
}

export default GeoFenceLocIcon;
