import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import Slider from 'rc-slider';
import SelectBox from 'components/SelectBox/SelectBox';
import { toCelsius, toFahrenheit } from 'utility/utility';
import 'rc-slider/assets/index.css';
import ShipIcon from 'assets/images/box-ultrathin-outline.svg';
import defaultProfile from 'assets/json/defaultProfile.json';
import SweetAlert from '../SweetAlert/SweetAlert';
import { getSplitTimeConfig} from 'utility/utility';
import INMOTION from 'assets/json/Inmotion.json';
import NOMOTION from 'assets/json/Nomotion.json';
import REPORTING from 'assets/json/Reporting.json';
import SAMPLING from 'assets/json/Sampling.json';
import {
    getProductProfileList
} from '../../redux-store/actions/configuration';

const CLASSIFICATION = [
    {label: 'Assess', id: 'ASSESS'},
    {label: 'Approve', id: 'APPROVE'},
    {label: 'Reject', id: 'REJECT'}
];
const SEVERITY = [
    {label: 'Warning', id: 'WARNING'},
    {label: 'Alarm', id: 'ALARM'}
];
const DURATION_TYPE = [
    {label: 'Cumulative', id: 'CUMULATIVE'},
    {label: 'Continuous', id: 'CONTINUOUS'}
];
const TEMPERATURE_LIMITS = {min: -40, max: 125};
class MetricConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showEmptyProductsMessage: false
        }        
    }

    componentDidMount = () => {
        console.log('-----DID MOUNT------');
        this.setState({companyId: this.props.companyId });
    }

    checkTemperatureThresholds = (value, direction, tempType) => {
        const temp = tempType === 'F' ? toCelsius(value) : value;
        if(direction) {
            if((direction === 'm' && temp <= TEMPERATURE_LIMITS.min) || (direction === 'p' && temp >= TEMPERATURE_LIMITS.max)) {
                return true;
            }
        } else {
            if(temp < TEMPERATURE_LIMITS.min || temp > TEMPERATURE_LIMITS.max) {
                return true;
            }
        }
        return false;
    };

    stepChangeValue = (e, index, category, direction) => {
        const configurationProfileDetails = {...this.props.CONFIGURATION_PROFILE_DETAILS};
        let value = configurationProfileDetails.product_profile_config[index][category] || 0;

        if(
            (category === 'threshold_duration' && value >= 999999 && direction === 'p') || 
            (category === 'threshold_duration' && value <= 0 && direction === 'm') ||
            (category === 'threshold_value' && this.checkTemperatureThresholds(value, direction, configurationProfileDetails.thresholds.temperature_representation))
        ){}
        else {
            if(direction === 'p') {
                value++;
            } else if(direction === 'm') {
                value--;
            }
        }
        configurationProfileDetails.product_profile_config[index][category] = (value+'').indexOf('.') >= 0 ? Number(value).toFixed(1): value;
        configurationProfileDetails.thresholds.updated = true;
        this.setState({configurationProfileDetails});
    }
   
    render () {
        //console.log('this.props.CONFIGURATION_PROFILE_DETAILS: ', this.props.CONFIGURATION_PROFILE_DETAILS, this.props);
        const CONFIGURATION_PROFILE_DETAILS = {...defaultProfile, ...this.props.CONFIGURATION_PROFILE_DETAILS};
        const TOTAL_SHELF_LIFE = getSplitTimeConfig(CONFIGURATION_PROFILE_DETAILS.total_shelf_life_duration || 0);
        
        return (
            <>
            <SweetAlert
                show={this.state.showEmptyProductsMessage}
                title={'No active product profile available. Create a product profile to enable this feature'}
                status={'warning'}
                className="empty-products-warning"
                onClose={() => {
                    this.setState({ showEmptyProductsMessage: false });
                }}
            />
            {
                this.props.isShipmentConfiguration && CONFIGURATION_PROFILE_DETAILS.product_profile_config && this.props.isProductProfileShipment ?
                <>
                <div className="fld-sec" style={{display: 'flex'}}>
                    <div className="fld-lbl" style={{width: "240px"}}>Product Type</div>
                    <div className="fld-lbl-r-o p-t" style={{background: CONFIGURATION_PROFILE_DETAILS.product_type?.color || '#96d796'}}>{CONFIGURATION_PROFILE_DETAILS.product_type?.name || 'none'}</div>
                </div>
                <div className="fld-sec">
                    <div className="i-sec" style={{display:'flex', alignItems: "center"}}>
                        <div className={`status-bar${CONFIGURATION_PROFILE_DETAILS.total_shelf_life_duration ? ' a' : ''} d`}
                        ></div>
                        <div className="fld-lbl" style={{marginLeft: '20px', width: '156px'}}>Total Shelf Life <i className="material-icons t-tip">info<span className="t-txt">Product shelf-life duration. If the total shipment duration exceeds the shelf life provided, the product status indicator will auto classify to Reject status</span></i></div>
                        <div className="time-sec">
                            <div className="i-flds" style={{marginBottom: '-10px'}}>
                                <div className="ip D">
                                    <input
                                        className="tol dhm"
                                        id="temp_time_a_t_d_ip"
                                        type="text"
                                        maxLength="3"
                                        placeholder="-"
                                        autoComplete="off"
                                        value={TOTAL_SHELF_LIFE.d || ''}
                                        disabled={true}
                                    />
                                    <label className="metrics-label" htmlFor="temp_time_a_t_d_ip">
                                        Days
                                    </label>
                                </div>
                                <div className="ip H">
                                    <input
                                        className="tol dhm"
                                        id="temp_time_a_t_h_ip"
                                        type="text"
                                        maxLength="2"
                                        placeholder="-"
                                        autoComplete="off"
                                        value={TOTAL_SHELF_LIFE.h || ''}
                                        disabled={true}
                                    />
                                    <label className="metrics-label" htmlFor="temp_time_a_t_h_ip">
                                        Hours
                                    </label>
                                </div>
                                <div className="ip M">
                                    <input
                                        className="tol dhm"
                                        id="temp_time_a_t_m_ip"
                                        type="text"
                                        maxLength="2"
                                        placeholder="-"
                                        autoComplete="off"
                                        value={TOTAL_SHELF_LIFE.m || ''}
                                        disabled={true}
                                    />
                                    <label className="metrics-label" htmlFor="temp_time_a_t_m_ip">
                                        Minutes
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                :
                ''
            }
            <div className="fld-sec slider-sec" key={`_${CONFIGURATION_PROFILE_DETAILS.profile_id}_`}>
                <div className="fld-lbl">
                    Temperature {CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id ? '' : <i className="material-icons t-tip">info<span className="t-txt">Use sliders to set thresholds for temperature limits. Set tolerance value for secondary alarm. </span></i>}
                </div>
                {
                    this.props.showProductProfile ?
                    <>
                    <div className="i-sec p-t-20" style={{display:'flex', alignItems: "center"}}>
                        <div className={`status-bar${CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id ? ' a' : ''}`}
                            onClick={ (e) => {
                                e.stopPropagation();
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                let showEmptyProductsMessage = false;
                                if(this.props.productProfileList?.length) {
                                    configurationProfileDetails.thresholds.TEMPERATURE.product_profile_id = CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id ? '' : (CONFIGURATION_PROFILE_DETAILS.product_profile_id ? CONFIGURATION_PROFILE_DETAILS.product_profile_id : true);
                                } else {
                                    showEmptyProductsMessage = true;
                                }                               

                                this.setState({ configurationProfileDetails, showEmptyProductsMessage });
                            }}
                        ></div>
                        <div className="fld-lbl" style={{marginLeft: '20px', width: '156px'}}>Product Profile <i className="material-icons t-tip">info<span className="t-txt">Enable this feature to apply product based temperature profile</span></i></div>
                        {
                            CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id ?
                            <>
                            <SelectBox
                                popCss={`left`}
                                style={{ width: '160px' }}
                                css={'sort-lbl'}
                                useProps
                                list={this.props.productProfileList?.map(pp => { return {label: pp.name, id: pp.id}}) || []}
                                value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id || ''}
                                apiSearch={true}
                                apiResultsLoading={this.props.productProfileListLoading}
                                searchOnChange={debounce((str) => {
                                    this.props.getProductProfileList({
                                        companyId: this.state.companyId,
                                        name : str
                                    })
                                }, 500)}
                                onChange={o => {
                                    const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                    configurationProfileDetails.thresholds.updated = true;
                                    configurationProfileDetails.thresholds.TEMPERATURE.product_profile_id = o.id;
                                    this.setState({configurationProfileDetails});
                                }}
                                disable={this.props.disableEdit}
                            />
                            <span
                                className="material-icons-outlined visibility"
                                style={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id ? {marginLeft: '20px', cursor: 'pointer', fontSize: '20px'}: {marginLeft: '20px', cursor: 'pointer', fontSize: '20px', opacity: 0.4, cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if(CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id){
                                        this.props.showProductProfileConfiguration(CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id);
                                    }
                                }}
                            >
                                visibility
                            </span>
                            </>
                            : ''
                        }                        
                    </div>
                    {CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_idError?
                        <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_idError}</div>
                    :""}
                    </>
                    : ''
                }
                {
                CONFIGURATION_PROFILE_DETAILS.product_profile_config?.length && this.props.isShipmentConfiguration && this.props.isProductProfileShipment ?   
                <>
                <div 
                    className="fld-sec" key={`_${0}_`}
                    style={{padding: "20px 0 0", borderWidth: '0'}}
                >
                    <div className="fld-lbl">
                        <input
                            type="radio"
                            value="C"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.temperature_representation === 'C' || false}
                            onChange={async e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                if(configurationProfileDetails.thresholds.temperature_representation === 'F') {
                                    configurationProfileDetails.product_profile_config.map(ppc => {
                                        ppc.threshold_value = toCelsius(ppc.threshold_value);
                                    });
                                }
                                configurationProfileDetails.temperature_representation = e.target.value;
                                configurationProfileDetails.thresholds.temperature_representation = e.target.value;
                                configurationProfileDetails.thresholds.TEMPERATURE.temperature_representation = e.target.value;
                                console.log('-----configurationProfileDetails------->>>>> ', configurationProfileDetails);
                                await this.setState({ configurationProfileDetails });
                            }}
                            className="metrics-checkbox"
                            id="metric1"
                            disabled={this.props.disableEdit}
                        />
                        <label style={{ marginLeft: '20px' }} className="metrics-label" htmlFor="metric1">
                            &#176; C
                        </label>
                        <input
                            type="radio"
                            value="F"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.temperature_representation === 'F' || false}
                            onChange={async e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                if(configurationProfileDetails.thresholds.temperature_representation === 'C') {
                                    configurationProfileDetails.product_profile_config.map(ppc => {
                                        ppc.threshold_value = toFahrenheit(ppc.threshold_value);
                                    });
                                }
                                configurationProfileDetails.temperature_representation = e.target.value;
                                configurationProfileDetails.thresholds.temperature_representation = e.target.value;
                                configurationProfileDetails.thresholds.TEMPERATURE.temperature_representation = e.target.value;
                                
                                await this.setState({ configurationProfileDetails });
                            }}
                            className="metrics-checkbox"
                            id="metric2"
                            disabled={this.props.disableEdit}
                        />
                        <label style={{ marginLeft: '20px' }} className="metrics-label" htmlFor="metric2">
                            &#176; F
                        </label>
                        <br></br>
                    </div>                 
                    <div style={{}}>
                        <div className="list-t-r r-h">
                            <div className="c-0">Limit</div>
                            <div className="c-1">Threshold Value <span className="material-icons t-tip">info<span className="t-txt" style={{minWidth:'68px'}}><div>min: -40°C</div><div>max: 125°C</div></span></span></div>
                            <div className="c-2">Severity <span className="material-icons t-tip">info<span className="t-txt" style={{minWidth:'120px'}}>Alert Classification</span></span></div>
                            <div className="c-3">Duration (<span style={{fontSize:'12px'}}>Min</span>)<span className="material-icons t-tip">info
                                <span className="t-txt" style={{minWidth:'98px'}}><div>12Hr = 720Min</div><div>24Hr = 1440Min</div><div>36Hr = 2160Min</div><div>48Hr = 2880Min</div></span>
                                </span>
                            </div>
                            <div className="c-4">Duration Type<span className="material-icons t-tip">info<span className="t-txt" style={{minWidth:'200px'}}><div>Continuous: The excursion timer resets for every event</div><div>Cumulative: The excursion timer will be the sum of all events duration</div></span></span>
                            </div>
                            <div className="c-5">Classification</div>                        
                        </div>
                        {
                        CONFIGURATION_PROFILE_DETAILS.product_profile_config?.length ?
                        CONFIGURATION_PROFILE_DETAILS.product_profile_config.map((t, i) => {
                            return (
                                <>
                                <div className="list-t-r" key={`${t.limit_type}_th-${i}`}>
                                    <div className="c-0">
                                        <div className="sel-box" style={{width: "92%"}}><div className="sel-value n">{t.limit_type.replace('-',' ')}</div></div>
                                    </div>
                                    <div className="c-1">
                                        <div className="ip-p-m">
                                            <div 
                                                className={`ip-m${CONFIGURATION_PROFILE_DETAILS?.product_profile_config[i]?.threshold_value <= TEMPERATURE_LIMITS.min || this.props.disableEdit ? ' d': ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if(this.props.disableEdit){}
                                                    else{this.stepChangeValue(e, i, 'threshold_value', 'm');}                                                    
                                                }}
                                            >-</div>
                                            <input 
                                                className="ip" 
                                                placeholder="-" 
                                                value={t.threshold_value}
                                                maxLength={5}
                                                onChange={(e) => {
                                                    const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                                    const val = e.target.value;                                                    
                                                    configurationProfileDetails.product_profile_config[i].threshold_value = !isNaN(val) && !this.checkTemperatureThresholds(val, false, configurationProfileDetails.thresholds.temperature_representation)  ? val : (val === '-' ? val : configurationProfileDetails.product_profile_config[i].threshold_value);
                                                    configurationProfileDetails.thresholds.updated = true;
                                                    this.setState({ configurationProfileDetails });
                                                }}
                                                disabled={this.props.disableEdit}
                                            />
                                            <div 
                                                className={`ip-p${CONFIGURATION_PROFILE_DETAILS?.product_profile_config[i]?.threshold_value >= TEMPERATURE_LIMITS.max || this.props.disableEdit ? ' d': ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if(this.props.disableEdit){}
                                                    else{this.stepChangeValue(e, i, 'threshold_value', 'p');}                                                    
                                                }}
                                            >+</div>
                                        </div>
                                    </div>
                                    <div className="c-2">
                                        <SelectBox
                                            popCss={'left'}
                                            style={{width: "80%"}}
                                            list={SEVERITY}
                                            value={t.severity || ''}
                                            onChange={(e) => {
                                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                                const val = e.id;
                                                configurationProfileDetails.product_profile_config[i].severity = val;
                                                configurationProfileDetails.thresholds.updated = true;
                                                this.setState({ configurationProfileDetails });
                                            }}
                                            key={`severity_${i}_${t.severity}`}
                                            disable={this.props.disableEdit}
                                        ></SelectBox>
                                    </div>
                                    <div className="c-3">
                                        <div className="ip-p-m">
                                            <div 
                                                className={`ip-m${CONFIGURATION_PROFILE_DETAILS?.product_profile_config[i]?.threshold_duration <= 0 || this.props.disableEdit? ' d': ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if(this.props.disableEdit){}
                                                    else{this.stepChangeValue(e, i, 'threshold_duration', 'm');}
                                                }}
                                            >-</div>
                                            <input 
                                                className="ip" 
                                                placeholder="-" 
                                                value={t.threshold_duration} 
                                                maxLength={6}
                                                onChange={(e) => {
                                                    const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                                    const val = e.target.value;
                                                    configurationProfileDetails.product_profile_config[i].threshold_duration = isNaN(val) || val < 0 || val === '-0' ? configurationProfileDetails.product_profile_config[i].threshold_duration : val;
                                                    configurationProfileDetails.thresholds.updated = true;
                                                    this.setState({ configurationProfileDetails });
                                                }}
                                                disabled={this.props.disableEdit}
                                            />
                                            <div 
                                                className={`ip-p${CONFIGURATION_PROFILE_DETAILS?.product_profile_config[i]?.threshold_duration >= 999999 || this.props.disableEdit ? ' d': ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if(this.props.disableEdit){}
                                                    else{this.stepChangeValue(e, i, 'threshold_duration', 'p');}
                                                }}
                                            >+</div>
                                        </div>
                                    </div>
                                    <div className="c-4">
                                        <SelectBox
                                            popCss={'left'}
                                            style={{width: "80%"}}
                                            list={DURATION_TYPE}
                                            value={t.stability_budget || ''}
                                            key={`stability_budget_${i}_${t.stability_budget}`}
                                            onChange={(e) => {
                                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                                const val = e.id;
                                                configurationProfileDetails.product_profile_config[i].stability_budget = val;
                                                configurationProfileDetails.thresholds.updated = true;
                                                this.setState({ configurationProfileDetails });
                                            }} 
                                            disable={this.props.disableEdit}
                                        ></SelectBox>
                                    </div>
                                    <div className="c-5">
                                        <SelectBox
                                            popCss={'left'}
                                            style={{width: "60%"}}
                                            list={CLASSIFICATION}
                                            value={t.classification || ''}
                                            key={`classification_${i}_${t.classification}`}
                                            onChange={(e) => {
                                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                                const val = e.id;
                                                configurationProfileDetails.product_profile_config[i].classification = val;
                                                configurationProfileDetails.thresholds.updated = true;
                                                this.setState({ configurationProfileDetails });    
                                            }} 
                                            disable={this.props.disableEdit}
                                        ></SelectBox>
                                        {/*
                                            PRODUCT_PROFILE_DETAILS.config.config_type === 'A' && PRODUCT_PROFILE_DETAILS.config.product_profile_config?.length > 2 && t.limit_type !== 'LOWER-LIMIT-1' && t.limit_type !== 'UPPER-LIMIT-1' ?
                                            <span 
                                                className="material-icons close-icn" 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const productProfileDetails = {...PRODUCT_PROFILE_DETAILS};
                                                    productProfileDetails.config.product_profile_config.splice(i, 1);
                                                    productProfileDetails.config.updated = true;
                                                    this.setState({ productProfileDetails });
                                                }}>
                                            close
                                            </span>
                                            : ''
                                        */}
                                    </div>
                                </div>
                                {
                                    CONFIGURATION_PROFILE_DETAILS.errors?.product_profile_config?.[i] ?
                                    <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.errors.product_profile_config[i]}</div>
                                    : ''
                                }
                                
                                </>
                            )
                        })
                        : 
                        <div className="no-data">No configurations added..</div>
                    }
                    </div>
                    
                </div> 
                </>
                :
                <>
                {
                    (!this.props.showProductProfile) || (this.props.isShipmentConfiguration) || !(CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.product_profile_id) ? 
                    <>
                    <div style={{paddingTop:'20px'}}>
                        <input
                            type="radio"
                            value="C"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.representation === 'C'}
                            onChange={async e => {

                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                const temp = configurationProfileDetails.thresholds.TEMPERATURE;
                                temp.representation = e.target.value;
                                temp.min_limit = ((temp.min_limit - 32) * 5/9) < -40 ? -40 : ((temp.min_limit - 32) * 5/9);
                                temp.max_limit = ((temp.max_limit - 32) * 5/9) > 85 ? 85 : ((temp.max_limit - 32) * 5/9);
                                const tolerance = (((configurationProfileDetails.thresholds.TEMPERATURE.max_limit-configurationProfileDetails.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0);
                                configurationProfileDetails.thresholds.TEMPERATURE.threshold = Number(tolerance) === -1 ?  '0' : Number(configurationProfileDetails.thresholds.TEMPERATURE.threshold) > Number(tolerance) ? tolerance : configurationProfileDetails.thresholds.TEMPERATURE.threshold;
                                await this.setState({ configurationProfileDetails });
                                //this.setState({ updateTemperature: true });
                            }}
                            className="metrics-checkbox"
                            id="metric1"
                            disabled={this.props.disableEdit}
                        />
                        <label className="metrics-label" htmlFor="metric1">
                            &#176; C
                        </label>
                        <input
                            type="radio"
                            value="F"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.representation === 'F'}
                            onChange={async e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                const temp = configurationProfileDetails.thresholds.TEMPERATURE;
                                temp.representation = e.target.value;
                                temp.min_limit = (temp.min_limit * 9/5) + 32 < -40 ? -40 : (temp.min_limit * 9/5) + 32;
                                temp.max_limit = (temp.max_limit * 9/5) + 32 > 185 ? 185 : (temp.max_limit * 9/5) + 32;
                                const tolerance = (((configurationProfileDetails.thresholds.TEMPERATURE.max_limit-configurationProfileDetails.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0);
                                configurationProfileDetails.thresholds.TEMPERATURE.threshold = Number(tolerance) === -1 ?  '0' : Number(configurationProfileDetails.thresholds.TEMPERATURE.threshold) > Number(tolerance) ? tolerance : configurationProfileDetails.thresholds.TEMPERATURE.threshold;
                                await this.setState({ configurationProfileDetails });
                                //this.setState({ configurationProfileDetails });
                            }}
                            className="metrics-checkbox"
                            id="metric2"
                            disabled={this.props.disableEdit}
                        />
                        <label style={{ marginLeft: '20px' }} className="metrics-label" htmlFor="metric2">
                            &#176; F
                        </label>
                        <br></br>
                    </div>
                    {/*CONFIGURATION_PROFILE_DETAILS.errors.TEMPERATURE?
                    <span className="error_msg" style={{marginLeft:"120px"}}>{CONFIGURATION_PROFILE_DETAILS.errors.TEMPERATURE}</span>
                        :""*/}
                    <div className="temperature-details">
                        <div className="temp-slider" key={`R_${CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.representation}_`}>
                            <Slider
                                className="temperature-slider"
                                range
                                min={-40}
                                max={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.representation === 'F' ? 185 : 85}
                                value={[
                                    CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.min_limit,
                                    CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.max_limit,
                                ]}
                                tipFormatter={value =>
                                    `${value}${CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.representation ? `°${CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.representation}`: ''}`
                                }
                                tipProps={{
                                    placement: 'bottom',
                                    visible: true,
                                }}
                                allowCross={false}
                                onChange={async e => {
                                    const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                    configurationProfileDetails.thresholds.updated = true;
                                    configurationProfileDetails.thresholds.TEMPERATURE.min_limit = e[0];
                                    configurationProfileDetails.thresholds.TEMPERATURE.max_limit = e[1];
                                    const tolerance = (((configurationProfileDetails.thresholds.TEMPERATURE.max_limit-configurationProfileDetails.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0);
                                    configurationProfileDetails.thresholds.TEMPERATURE.threshold = Number(tolerance) === -1 ?  '0' : Number(configurationProfileDetails.thresholds.TEMPERATURE.threshold) > Number(tolerance) ? tolerance : configurationProfileDetails.thresholds.TEMPERATURE.threshold;
                                    this.setState({ configurationProfileDetails });
                                }}
                                disabled={this.props.disableEdit}
                            />
                                                    
                        </div>
                        <div className="tolerance" style={{ display: 'inline-block', marginTop: '8px', marginLeft: '46px' }}>
                            <div> Tolerance </div>
                            <div>
                                <input
                                    className="tol"
                                    disabled={this.props.disableEdit}
                                    type="text"
                                    autoComplete="off"
                                    maxLength={(((CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.max_limit-CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0).length}
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.threshold}
                                    onChange={(e) => {
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        const tolerance = (((configurationProfileDetails.thresholds.TEMPERATURE.max_limit-configurationProfileDetails.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0);
                                        let mexp = tolerance.length === 1 ? `${tolerance}|[0-${tolerance[0]-1}]?` : tolerance[0] === '1' ? `${tolerance}|${tolerance[0]}[0-${tolerance[1]}]?|[0-9]?` : `${tolerance}|${tolerance[0]}[0-${tolerance[1]}]|[1-${tolerance[0]-1}]?[0-9]`;
                                        let val = e.target.value.match(new RegExp(mexp));
                                        if(isNaN(val)){
                                            val = '';
                                        }
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.TEMPERATURE.threshold = val;
                                        this.setState({ configurationProfileDetails });                                                        
                                    }}
                                />{' '}
                                
                            <br></br>
                            <span style={{ color: '#919191', fontSize: '10px' }}>{`(max ${(((CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.max_limit-CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0) === '-1' ? '0' : (((CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.max_limit-CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.min_limit)/2)-1).toFixed(0)} °${CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.representation})`}</span>
                            </div>
                            {CONFIGURATION_PROFILE_DETAILS.errors?.TEMPERATURE ?
                            <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.errors.TEMPERATURE}</div>
                            :""}
                        </div>
                    </div>                
                    <div className="time-sec p-t-20">
                        <div className="i-flds m-0">
                            <div className="lbl">Time above threshold: </div>
                            <div className="ip D">
                                <input
                                    className="tol dhm"
                                    id="temp_time_a_t_d_ip"
                                    type="text"
                                    maxLength="3"
                                    placeholder="-"
                                    autoComplete="off"
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.timeAboveThreshold.d}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold.d = (val+'').match(/^(36[0-5]|3[0-5]?[0-9]?|[0-2]?[0-9]?[0-9]?)$/) || val === '' ? val : configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold.d;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disabled={this.props.disableEdit}
                                />
                                <label className="metrics-label" htmlFor="temp_time_a_t_d_ip">
                                    Days
                                </label>
                            </div>
                            <div className="ip H">
                                <input
                                    className="tol dhm"
                                    id="temp_time_a_t_h_ip"
                                    type="text"
                                    maxLength="2"
                                    placeholder="-"
                                    autoComplete="off"
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.timeAboveThreshold.h}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold.h = (val+'').match(/^(2[0-3]|1[0-9]|0[0-9]|[0-9])$/) || val === '' ? val : configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold.h;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disabled={this.props.disableEdit}
                                />
                                <label className="metrics-label" htmlFor="temp_time_a_t_h_ip">
                                    Hours
                                </label>
                            </div>
                            <div className="ip M">
                                <input
                                    className="tol dhm"
                                    id="temp_time_a_t_m_ip"
                                    type="text"
                                    maxLength="2"
                                    placeholder="-"
                                    autoComplete="off"
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.timeAboveThreshold.m}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold.m = (val+'').match(/^[0-5]?[0-9]$/) || val === '' ? val : configurationProfileDetails.thresholds.TEMPERATURE.timeAboveThreshold.m;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disabled={this.props.disableEdit}
                                />
                                <label className="metrics-label" htmlFor="temp_time_a_t_m_ip">
                                    Minutes
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="time-sec p-t-20">
                        <div className="i-flds m-0">
                            <div className="lbl">Time below threshold: </div>
                            <div className="ip D">
                                <input
                                    className="tol dhm"
                                    id="temp_time_b_t_d_ip"
                                    type="text"
                                    maxLength="3"
                                    placeholder="-"
                                    autoComplete="off"
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.timeBelowThreshold.d}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold.d = (val+'').match(/^(36[0-5]|3[0-5]?[0-9]?|[0-2]?[0-9]?[0-9]?)$/) || val === '' ? val : configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold.d;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disabled={this.props.disableEdit}
                                />
                                <label className="metrics-label" htmlFor="temp_time_b_t_d_ip">
                                    Days
                                </label>
                            </div>
                            <div className="ip H">
                                <input
                                    className="tol dhm"
                                    id="temp_time_b_t_h_ip"
                                    type="text"
                                    maxLength="2"
                                    placeholder="-"
                                    autoComplete="off"
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.timeBelowThreshold.h}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold.h = (val+'').match(/^(2[0-3]|1[0-9]|0[0-9]|[0-9])$/) || val === '' ? val : configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold.h;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disabled={this.props.disableEdit}
                                />
                                <label className="metrics-label" htmlFor="temp_time_b_t_h_ip">
                                    Hours
                                </label>
                            </div>
                            <div className="ip M">
                                <input
                                    className="tol dhm"
                                    id="temp_time_b_t_m_ip"
                                    type="text"
                                    maxLength="2"
                                    placeholder="-"
                                    autoComplete="off"
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.TEMPERATURE.timeBelowThreshold.m}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold.m = (val+'').match(/^[0-5]?[0-9]$/) || val === '' ? val : configurationProfileDetails.thresholds.TEMPERATURE.timeBelowThreshold.m;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disabled={this.props.disableEdit}
                                />
                                <label className="metrics-label" htmlFor="temp_time_b_t_m_ip">
                                    Minutes
                                </label>
                            </div>
                        </div>
                    </div>
                    </>
                    
                    : ''
                }
                </>
                }
            </div>   
            <div className="fld-sec slider-sec humidity">
                <div className="fld-lbl">Humidity <span>(%RH)</span> 
                    <br></br>
                </div>
                <div className="temperature-details">
                    <div className="temp-slider">
                        <Slider
                            className="temperature-slider"
                            range
                            min={0}
                            max={100}
                            value={[
                                CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.min_limit,
                                CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.max_limit,
                            ]}
                            tipFormatter={value =>
                                `${value}% RH`
                            }
                            tipProps={{
                                placement: 'bottom',
                                visible: true,
                            }}
                            allowCross={false}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                configurationProfileDetails.thresholds.HUMIDITY.min_limit = e[0];
                                configurationProfileDetails.thresholds.HUMIDITY.max_limit = e[1];
                                const tolerance = (((configurationProfileDetails.thresholds.HUMIDITY.max_limit-configurationProfileDetails.thresholds.HUMIDITY.min_limit)/2)-1).toFixed(0);
                                configurationProfileDetails.thresholds.HUMIDITY.threshold = Number(tolerance) === -1 ?  '0' : Number(configurationProfileDetails.thresholds.HUMIDITY.threshold) > Number(tolerance) ? tolerance : configurationProfileDetails.thresholds.HUMIDITY.threshold;
                                this.setState({ configurationProfileDetails });
                            }}
                            disabled={this.props.disableEdit}
                        />
                    </div>
                    <div className="tolerance" style={{ display: 'inline-block', marginTop: '8px', marginLeft: '46px' }}>
                        <div> Tolerance </div>
                        <div>
                            <input
                                className="tol"
                                type="text"
                                autoComplete="off"
                                maxLength={(((CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.max_limit-CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.min_limit)/2)-1).toFixed(0).length}
                                value={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.threshold}
                                onChange={(e) => {
                                    const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                    const tolerance = (((configurationProfileDetails.thresholds.HUMIDITY.max_limit-configurationProfileDetails.thresholds.HUMIDITY.min_limit)/2)-1).toFixed(0);
                                    let mexp = tolerance.length === 1 ? `${tolerance}|[0-${tolerance[0]-1}]?` : tolerance[0] === '1' ? `${tolerance}|${tolerance[0]}[0-${tolerance[1]}]?|[0-9]?` : `${tolerance}|${tolerance[0]}[0-${tolerance[1]}]|[1-${tolerance[0]-1}]?[0-9]`;
                                    let val = e.target.value.match(new RegExp(mexp));
                                    if(isNaN(val)){
                                        val = '';
                                    }
                                    configurationProfileDetails.thresholds.updated = true;
                                    configurationProfileDetails.thresholds.HUMIDITY.threshold = val;
                                    this.setState({ configurationProfileDetails });
                                }}
                                disabled={this.props.disableEdit}
                            />{' '}
                            <br></br>
                            <span style={{ color: '#919191', fontSize: '10px' }}>{`(max ${(((CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.max_limit-CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.min_limit)/2)-1).toFixed(0) === '-1' ? '0' : (((CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.max_limit-CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.min_limit)/2)-1).toFixed(0)} %RH)`}</span>
                        </div>
                        {CONFIGURATION_PROFILE_DETAILS.errors?.HUMIDITY ?
                        <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.errors.HUMIDITY}</div>
                        :""}
                    </div>
                </div>
                {/*
                <div className="time-sec p-t-20">
                    <div className="lbl">Time above threshold: </div>
                    <div className="ip D">
                        <input
                            type="checkbox"
                            value="D"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.d.active}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.d.active = !configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.d.active;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="humidity_time_a_t_d"
                        />
                        <label className="metrics-label" htmlFor="humidity_time_a_t_d">
                            Days
                        </label>
                        <input
                            className="tol"
                            type="text"
                            autoComplete="off"
                            maxLength="2"
                            value={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.d.value}
                            onChange={(e) => {
                                const val = e.target.value;
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.d.value = (val+'').match(/^[0-9]{1,2}$/) || val === '' ? val : configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.d.value;
                                this.setState({configurationProfileDetails});
                            }}
                            readOnly={!CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.d.active}
                        />
                    </div>
                    <div className="ip H">
                        <input
                            type="checkbox"
                            value="H"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.h.active}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.h.active = !configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.h.active;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="humidity_time_a_t_h"
                        />
                        <label className="metrics-label" htmlFor="humidity_time_a_t_h">
                            Hours
                        </label>
                        <input
                            className="tol"
                            type="text"
                            autoComplete="off"
                            maxLength="2"
                            value={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.h.value}
                            onChange={(e) => {
                                const val = e.target.value;
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.h.value = (val+'').match(/^[0-9]{1,2}$/) || val === '' ? val : configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.h.value;
                                this.setState({configurationProfileDetails});
                            }}
                            readOnly={!CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.h.active}
                        />
                    </div>
                    <div className="ip M">
                        <input
                            type="radio"
                            value="M"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.m.active}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.m.active = !configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.m.active;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="humidity_time_a_t_m"
                        />
                        <label className="metrics-label" htmlFor="humidity_time_a_t_m">
                            Minutes
                        </label>
                        <input
                            className="tol"
                            type="text"
                            autoComplete="off"
                            maxLength="2"
                            value={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.m.value}
                            onChange={(e) => {
                                const val = e.target.value;
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.m.value = (val+'').match(/^[0-9]{1,2}$/) || val === '' ? val : configurationProfileDetails.thresholds.HUMIDITY.timeAboveThreshold.m.value;
                                this.setState({configurationProfileDetails});
                            }}
                            readOnly={!CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.m.active}
                        />
                    </div>
                </div>
                <div className="time-sec p-t-20">
                    <div className="lbl">Time below threshold: </div>
                    <div className="ip D">
                        <input
                            type="checkbox"
                            value="D"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.d.active}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.d.active = !configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.d.active;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="humidity_time_b_t_d"
                        />
                        <label className="metrics-label" htmlFor="humidity_time_b_t_d">
                            Days
                        </label>
                        <input
                            className="tol"
                            type="text"
                            autoComplete="off"
                            maxLength="2"
                            value={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.d.value}
                            onChange={(e) => {
                                const val = e.target.value;
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.d.value = (val+'').match(/^[0-9]{1,2}$/) || val === '' ? val : configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.d.value;
                                this.setState({configurationProfileDetails});
                            }}
                            readOnly={!CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeAboveThreshold.d.active}
                        />
                    </div>
                    <div className="ip H">
                        <input
                            type="checkbox"
                            value="H"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.h.active}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.h.active = !configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.h.active;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="humidity_time_b_t_h"
                        />
                        <label className="metrics-label" htmlFor="humidity_time_b_t_h">
                            Hours
                        </label>
                        <input
                            className="tol"
                            type="text"
                            autoComplete="off"
                            maxLength="2"
                            value={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.h.value}
                            onChange={(e) => {
                                const val = e.target.value;
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.h.value = (val+'').match(/^[0-9]{1,2}$/) || val === '' ? val : configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.h.value;
                                this.setState({configurationProfileDetails});
                            }}
                            readOnly={!CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.h.active}
                        />
                    </div>
                    <div className="ip M">
                        <input
                            type="radio"
                            value="M"
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.m.active}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.m.active = !configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.m.active;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="humidity_time_b_t_m"
                        />
                        <label className="metrics-label" htmlFor="humidity_time_b_t_m">
                            Minutes
                        </label>
                        <input
                            className="tol"
                            type="text"
                            autoComplete="off"
                            maxLength="2"
                            value={CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.m.value}
                            onChange={(e) => {
                                const val = e.target.value;
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.m.value = (val+'').match(/^[0-9]{1,2}$/) || val === '' ? val : configurationProfileDetails.thresholds.HUMIDITY.timeBelowThreshold.m.value;
                                this.setState({configurationProfileDetails});
                            }}
                            readOnly={!CONFIGURATION_PROFILE_DETAILS.thresholds.HUMIDITY.timeBelowThreshold.m.active}
                        />
                    </div>
                </div>
                */}
            </div>  
            {/* 
            <div className="fld-sec slider-sec">
                <div className="fld-lbl">
                    Pressure <span>(hPa)</span>
                    <br></br>
                </div>
                <div className="temperature-details">
                    <div className="temp-slider">
                        <Range
                            className="temperature-slider"
                            min={300}
                            max={1100}
                            value={[
                                CONFIGURATION_PROFILE_DETAILS.thresholds.PRESSURE.minLimit,
                                CONFIGURATION_PROFILE_DETAILS.thresholds.PRESSURE.maxLimit,
                            ]}
                            tipFormatter={value =>
                                `${value} hPa`
                            }
                            tipProps={{
                                placement: 'bottom',
                                visible: true,
                            }}
                            allowCross={false}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.PRESSURE.minLimit = e[0];
                                configurationProfileDetails.thresholds.PRESSURE.maxLimit = e[1];
                                this.setState({ configurationProfileDetails });
                            }}
                        />
                    </div>
                    <div className="tolerance" style={{ display: 'inline-block', marginTop: '8px', marginLeft: '46px' }}>
                        <div> Tolerance </div>
                        <div>
                            <input
                                className="tol"
                                type="text"
                                autoComplete="off"
                                maxLength="3"
                                value={CONFIGURATION_PROFILE_DETAILS.thresholds.PRESSURE.threshold}
                                onChange={(e) => {
                                    let val = e.target.value;
                                    const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                    if(isNaN(val)){
                                        val = '';
                                    }
                                    configurationProfileDetails.thresholds.PRESSURE.threshold = val;
                                    this.setState({ configurationProfileDetails });
                                }}
                            />{' '}
                        </div>
                    </div>
                </div>
            </div>
            */}
            <div className="fld-sec">
                <div className="fld-lbl">Light <i className="material-icons t-tip">info<span className="t-txt">Ambient light threshold in Lumens</span></i></div>
                <div className="lumen-details" style={{ display: 'flex'}}>
                    <div style={{ marginTop: '16px'}}>
                        <input
                            type="radio"
                            value={80}
                            checked={CONFIGURATION_PROFILE_DETAILS.thresholds.LID.isLumenDefault}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                configurationProfileDetails.thresholds.LID.value = 80;
                                configurationProfileDetails.thresholds.LID.max_limit = 80;
                                configurationProfileDetails.thresholds.LID.isLumenDefault = true;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="metricLumen"
                            name="metricLumen"
                            disabled={this.props.disableEdit}
                        />
                        <label className="metrics-label" htmlFor="metricLumen">
                            80 Lumens
                        </label>{' '}
                        <br></br>
                        <label style={{ marginLeft: '25px', display: 'flex' }}>Package open/close &nbsp; <img src={ShipIcon} alt="" /></label>
                    </div>
                    <div className="check-sec" style={{ marginTop: '16px' }}>
                        <input                                            
                            type="radio"
                            value="custom"
                            checked={!CONFIGURATION_PROFILE_DETAILS.thresholds.LID.isLumenDefault}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                configurationProfileDetails.thresholds.LID.value = '';
                                configurationProfileDetails.thresholds.LID.max_limit = '';
                                configurationProfileDetails.thresholds.LID.isLumenDefault = false;
                                this.setState({configurationProfileDetails});
                                document.getElementById('custom_lid').focus();
                            }}
                            className="metrics-checkbox"
                            id="metricLumenCustom"
                            name="metricLumen"
                            disabled={this.props.disableEdit}
                        />
                        <label className="metrics-label" htmlFor="metricLumenCustom">
                            <input
                                className="custom"
                                style={{border:"1px solid #979797",borderWidth:"0 0 1px 0",outline:"none",fontSize:"18px",padding:"0 4px",fontWeight:"500"}}
                                value={CONFIGURATION_PROFILE_DETAILS.thresholds.LID.isLumenDefault ? '' : (CONFIGURATION_PROFILE_DETAILS.thresholds.LID.value||CONFIGURATION_PROFILE_DETAILS.thresholds.LID.max_limit)}
                                onChange={e => {
                                    let val = e.target.value;
                                    val = parseInt(val,10);
                                    if(isNaN(val)){
                                        val = '';
                                    }
                                    else{
                                        val = val + '';
                                        val = val.substr(0,5);
                                    }
                                    const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                    configurationProfileDetails.thresholds.updated = true;
                                    configurationProfileDetails.thresholds.LID.value = val;
                                    configurationProfileDetails.thresholds.LID.max_limit = val;
                                    this.setState({configurationProfileDetails});
                                    
                                }}
                                type="text"
                                autoComplete="off"
                                readOnly={CONFIGURATION_PROFILE_DETAILS.thresholds.LID.isLumenDefault}
                                disabled={this.props.disableEdit}
                                id={'custom_lid'}
                            />
                        </label>{' '}
                        <br></br>
                        <label style={{ marginLeft: '25px' }}>Custom Value</label>{' '}
                        <span style={{ color: '#919191', fontSize: '10px' }}>(max 66,000 lumen)</span><br></br>
                        {CONFIGURATION_PROFILE_DETAILS.errors?.LID ?
                        <span className="error_msg" style={{marginLeft:"24px"}}>{CONFIGURATION_PROFILE_DETAILS.errors.LID}</span>
                        :""}
                    </div>
                    
                </div>
            </div>
            <div className="fld-sec">
                <div className="fld-lbl">Shock <i className="material-icons t-tip">info<span className="t-txt">The G value suggests threshold for shock experienced by the shipment</span></i></div>
                <div
                    className="shock-details"
                    style={{
                        display: 'flex',
                        marginTop: '16px',
                    }}
                >
                    <div>
                        <input
                            type="radio"
                            value="1.5"
                            checked={Number(CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.value) === 1.5 && CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.isShockDefault}
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                configurationProfileDetails.thresholds.SHOCK.value = parseFloat(e.target.value);
                                configurationProfileDetails.thresholds.SHOCK.isShockDefault = true;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="metricShock1.5"
                            name="metricShock"
                            disabled={this.props.disableEdit}
                        />
                        <label className="metrics-label" htmlFor="metricShock1.5">
                            1.5 G
                        </label>{' '}
                        <br></br>
                        <label style={{ marginLeft: '25px' }}>High Sensitivity</label>
                    </div>
                    <div className="check-sec">
                        <input
                            type="radio"
                            value="3"
                            checked={
                                (Number(CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.value) === 3) && CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.isShockDefault
                            }
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                configurationProfileDetails.thresholds.SHOCK.value = parseFloat(e.target.value);
                                configurationProfileDetails.thresholds.SHOCK.isShockDefault = true;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="metricShock3"
                            name="metricShock"
                            disabled={this.props.disableEdit}
                        />
                        <label className="metrics-label" htmlFor="metricShock3">
                            3 G
                        </label>{' '}
                        <br></br>
                        <label style={{ marginLeft: '25px' }}>Moderate Sensitivity</label>
                    </div>
                    <div className="check-sec">
                        <input
                            type="radio"
                            value="5"
                            checked={
                                (Number(CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.value) === 5) && CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.isShockDefault
                            }
                            onChange={e => {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                configurationProfileDetails.thresholds.SHOCK.value = parseFloat(e.target.value);
                                configurationProfileDetails.thresholds.SHOCK.isShockDefault = true;
                                this.setState({configurationProfileDetails});
                            }}
                            className="metrics-checkbox"
                            id="metricShock5"
                            name="metricShock"
                            disabled={this.props.disableEdit}
                        />
                        <label className="metrics-label" htmlFor="metricShock5">
                            5 G
                        </label>{' '}
                        <br></br>
                        <label style={{ marginLeft: '25px' }}>Low Sensitivity</label>
                    </div>
                    <div className="check-sec">
                        <input
                            type="radio"
                            value="custom"
                            checked={!CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.isShockDefault}
                            onChange={()=>{
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.updated = true;
                                configurationProfileDetails.thresholds.SHOCK.value = '';
                                configurationProfileDetails.thresholds.SHOCK.isShockDefault = false;
                                this.setState({configurationProfileDetails});  
                                document.getElementById('custom_shock').focus();
                            }}
                            className="metrics-checkbox"
                            id="metricShockCustom"
                            name="metricShock"
                            disabled={this.props.disableEdit}
                        />
                        <label className="metrics-label" htmlFor="metricShockCustom">
                            <input
                                className="custom"
                                maxLength={3}
                                style={{border:"1px solid #979797",borderWidth:"0 0 1px 0",outline:"none",fontSize:"18px",padding:"0 4px",fontWeight:"500"}}
                                value={CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.isShockDefault ? '' : CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.value}
                                onChange={(e) => {
                                    let val = e.target.value;
                                    if((val+'').match(/\.\d{1,}$/)){
                                        val = (Math.floor(val * 100) / 100).toFixed(1);
                                    }
                                    if((val+'').match(/\.\.$/)){
                                        val = val.replace('..', '.');
                                    }
                                    if((val+'').match(/^\d{1,2}\.{0,}\d{0,1}$/) || val === '') {
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.SHOCK.value = val;
                                        this.setState({configurationProfileDetails}); 
                                    }
                                    
                                }}
                                type="text"
                                autoComplete="off"
                                readOnly={CONFIGURATION_PROFILE_DETAILS.thresholds.SHOCK.isShockDefault}
                                disabled={this.props.disableEdit}
                                id={'custom_shock'}
                            />
                        </label>{' '}
                        <br></br>
                        <label style={{ marginLeft: '25px' }}>Custom Value</label>{' '}
                        <span style={{ color: '#919191', fontSize: '10px' }}>(max 16 G)</span><br></br>
                        {CONFIGURATION_PROFILE_DETAILS.errors?.SHOCK?
                        <span className="error_msg" style={{marginLeft:'24px'}}>{CONFIGURATION_PROFILE_DETAILS.errors.SHOCK}</span>
                        :""}
                    </div>
                    
                </div>
            </div>  
            {/*
            <div className="fld-sec">
                <div style={{display: 'flex'}}>
                    <label htmlFor="profile_name" className="fld-lbl" style={{marginRight: '20px', position: 'unset', width: 'unset'}}>Connectivity cycles <i className="material-icons t-tip">info<span className="t-txt">Number of failed device communication cycles before failure alert. </span></i></label>
                    <input
                        id="connectivityCycles"
                        type="text"
                        autoComplete="off"
                        className="i-fld"
                        placeholder=" "
                        value={CONFIGURATION_PROFILE_DETAILS.thresholds.CONNECTIVITY_CYCLES.value}
                        onChange={(e) => {
                            let val = e.target.value;
                            if((val+'').match(/^[0-9]{1,2}$/) || val === '') {
                                const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                configurationProfileDetails.thresholds.CONNECTIVITY_CYCLES.value = val;
                                this.setState({configurationProfileDetails}); 
                            }
                        }}
                        name="connectivityCycles"
                        maxLength="32"
                        style={{width: '100px'}}
                    />
                </div>
                <div className="error_msg">{this.state.connectivityCyclesError}</div>
            </div>
            */}
            <div className="fld-sec">
                <div className="i-sec">
                    <div htmlFor="profile_name" className="fld-lbl" style={{marginRight: '20px', position: 'unset', width: 'unset'}}>Communication interval <i className="material-icons t-tip">info<span className="t-txt">Configure device communication. InMotion and NoMotion applies for TRAK-X devices. Sampling and Reporting applies for TRAK-X1, TRAK-NXT devices</span></i></div>
                    <div className="config-sec-2-col">
                        <div className="config-sec-col-l">
                            <div className="fld-lbl-2">TRAK-X:</div>
                            <div className="f-l">
                                <div className="fld-lbl">Inmotion:</div>
                                <SelectBox
                                    popCss={'h-200'}
                                    style={{ width: '160px' }}
                                    css={'sort-lbl'}
                                    useProps
                                    list={INMOTION}
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.CONNECTIVITYCYCLEINMOTION?.value || ''}
                                    onChange={o => {
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLEINMOTION = configurationProfileDetails.thresholds.CONNECTIVITYCYCLEINMOTION || {};
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLEINMOTION.value = o.obj.id;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disable={this.props.disableEdit || this.props.disableCommunicationIntervalEdit}
                                />
                                {CONFIGURATION_PROFILE_DETAILS.errors?.CONNECTIVITYCYCLEINMOTION?
                                <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.errors.CONNECTIVITYCYCLEINMOTION}</div>
                                :""}
                            </div>
                            <div className="f-l">
                                <div className="fld-lbl">Nomotion:</div>
                                <SelectBox
                                    popCss={'h-200'}
                                    style={{ width: '160px' }}
                                    css={'sort-lbl'}
                                    useProps
                                    list={NOMOTION}
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.CONNECTIVITYCYCLENOMOTION?.value || ''}
                                    onChange={o => {
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLENOMOTION = configurationProfileDetails.thresholds.CONNECTIVITYCYCLENOMOTION || {};
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLENOMOTION.value = o.obj.id;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disable={this.props.disableEdit || this.props.disableCommunicationIntervalEdit}
                                />
                                {CONFIGURATION_PROFILE_DETAILS.errors?.CONNECTIVITYCYCLENOMOTION ?
                                <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.errors.CONNECTIVITYCYCLENOMOTION}</div>
                                :""}
                            </div>
                        </div>
                        <div className="config-sec-col-r">
                            <div className="fld-lbl-2">TRAK-X1, TRAK-NXT:</div>
                            <div className="f-l">
                                <div className="fld-lbl">Sampling:</div>
                                <SelectBox
                                    popCss={'h-200'}
                                    style={{ width: '160px' }}
                                    css={'sort-lbl'}
                                    useProps
                                    list={SAMPLING}
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.CONNECTIVITYCYCLEMONITORPERIOD?.value || ''}
                                    onChange={o => {
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLEMONITORPERIOD = configurationProfileDetails.thresholds.CONNECTIVITYCYCLEMONITORPERIOD || {};
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLEMONITORPERIOD.value = o.obj.id;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disable={this.props.disableEdit || this.props.disableCommunicationIntervalEdit}
                                />
                                {CONFIGURATION_PROFILE_DETAILS.errors?.CONNECTIVITYCYCLEMONITORPERIOD?
                                <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.errors?.CONNECTIVITYCYCLEMONITORPERIOD}</div>
                                :""}
                            </div>
                            <div className="f-l">
                                <div className="fld-lbl">Reporting:</div>
                                <SelectBox
                                    popCss={'h-200'}
                                    style={{ width: '160px' }}
                                    css={'sort-lbl'}
                                    useProps
                                    list={REPORTING}
                                    value={CONFIGURATION_PROFILE_DETAILS.thresholds.CONNECTIVITYCYCLECOMMSYNC?.value || ''}
                                    onChange={o => {
                                        const configurationProfileDetails = {...CONFIGURATION_PROFILE_DETAILS};
                                        configurationProfileDetails.thresholds.updated = true;
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLECOMMSYNC = configurationProfileDetails.thresholds.CONNECTIVITYCYCLECOMMSYNC || {};
                                        configurationProfileDetails.thresholds.CONNECTIVITYCYCLECOMMSYNC.value = o.obj.id;
                                        this.setState({configurationProfileDetails});
                                    }}
                                    disable={this.props.disableEdit || this.props.disableCommunicationIntervalEdit}
                                />
                                {CONFIGURATION_PROFILE_DETAILS.errors?.CONNECTIVITYCYCLECOMMSYNC?
                                <div className="error_msg">{CONFIGURATION_PROFILE_DETAILS.errors.CONNECTIVITYCYCLECOMMSYNC}</div>
                                :""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                       
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        productProfileList: state.configuration.productProfileList,
        productProfileListLoading: state.configuration.productProfileListLoading,
    };
};

export default connect(mapStateToProps, {
    getProductProfileList,
})(MetricConfiguration);