import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const intialState = {    
    data: {},
    error: false,
    loading: false
};
const getInsightsStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const getInsightsStop = (state, action) => {
    return updateObject(state, { loading: false });
};
const getInsightsSuccess = (state, action) => {
    return updateObject(state, { data: action.data });
};
const getInsightsFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const getLocCountriesStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const getLocCountriesSuccess = (state, action) => {
    return updateObject(state, { locCountries: action.data });
};
const getLocCountriesFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const getLocStatesStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const getLocStatesSuccess = (state, action) => {
    return updateObject(state, { locStates: action.data });
};
const getLocStatesFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const getLocCitiesStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const getLocCitiesSuccess = (state, action) => {
    return updateObject(state, { locCities: action.data });
};
const getLocCitiesFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const getLocRoutesStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const getLocRoutesSuccess = (state, action) => {
    return updateObject(state, { locRoutes: action.data });
};
const getLocRoutesFailed = (state, action) => {
    return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INSIGHTS_DATA_START:
            return getInsightsStart(state, action);
        case actionTypes.GET_INSIGHTS_DATA_STOP:
            return getInsightsStop(state, action);
        case actionTypes.GET_INSIGHTS_DATA_SUCCESS:
            return getInsightsSuccess(state, action);
        case actionTypes.GET_INSIGHTS_DATA_FAILED:
            return getInsightsFailed(state, action);
        case actionTypes.GET_LOC_COUNTRIES_START:
            return getLocCountriesStart(state, action);
        case actionTypes.GET_LOC_COUNTRIES_SUCCESS:
            return getLocCountriesSuccess(state, action);
        case actionTypes.GET_LOC_COUNTRIES_FAILED:
            return getLocCountriesFailed(state, action);
        case actionTypes.GET_LOC_STATES_START:
            return getLocStatesStart(state, action);
        case actionTypes.GET_LOC_STATES_SUCCESS:
            return getLocStatesSuccess(state, action);
        case actionTypes.GET_LOC_STATES_FAILED:
            return getLocStatesFailed(state, action);
        case actionTypes.GET_LOC_CITIES_START:
            return getLocCitiesStart(state, action);
        case actionTypes.GET_LOC_CITIES_SUCCESS:
            return getLocCitiesSuccess(state, action);
        case actionTypes.GET_LOC_CITIES_FAILED:
            return getLocCitiesFailed(state, action);
        case actionTypes.GET_LOC_ROUTES_START:
            return getLocRoutesStart(state, action);
        case actionTypes.GET_LOC_ROUTES_SUCCESS:
            return getLocRoutesSuccess(state, action);
        case actionTypes.GET_LOC_ROUTES_FAILED:
            return getLocRoutesFailed(state, action);
        default:
            return state;
    }
};

export default reducer;
