import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const intialState = {
    companyList: [],
    configurationProfileListLoading: false,
    configurationProfileList: [],
    configurationProfileListData: [],
    configurationProfile: '',
    configurationProfileLoading: false,
    configurationProfileSaving: false,
    alertProfileListLoading: false,
    alertProfileList: [],
    alertProfileListData: [],
    alertProfile: '',
    alertProfileLoading: false,
    alertProfileSaving: false,
    productProfileListLoading: false,
    productProfileList: [],
    productProfileListData: [],
    productProfile: '',
    productProfileMessage: {},
    productProfileLoading: false,
    productProfileSaving: false,
    shipmentProfileListLoading: false,
    shipmentProfileList: [],
    shipmentProfileListData: [],
    shipmentProfile: {config: ''},
    shipmentProfileMessage: {},
    shipmentProfileLoading: false,
    shipmentProfileSaving: false,
    productTypesLoading: false,
    productTypeSaving: false,
    productTypes: [],
    productTypesData: [],
    carrierTypesLoading: false,
    carrierTypeSaving: false,
    carrierTypeListLoading: true,
    carrierTypeList: [],
    carrierTypeListData: [],
    carrierTypeError: false,
    productTypeListLoading: false,
    laneIdListLoading: false,
    laneIdSaving: false,
    laneIdList: [],
    laneIdsData: [],
    packageTypeListLoading: false,
    packageTypeSaving: false,
    packageTypeList: [],
    packageTypeListData: [],
    productProfileListMeta: {total:0}
};

const getCompanyListSuccess = (state, action) => {
    return updateObject(state, { companyList: action.data, error: false });
};

const getConfigurationProfileListStart = (state, action) => {
    return updateObject(state, {
        configurationProfileListLoading: true
    });
};

const getConfigurationProfileListSuccess = (state, action) => {
    //console.log('------------>>>>', action);
    const DATA = action.data?.sort((a, b) => a.is_default_profile === 1 ? -1 : 1) || [];
    const DUP_DATA = state.configurationProfileListData?.sort((a, b) => a.is_default_profile === 1 ? -1 : 1) || [];
    if(action.deleteAction) {
        const profileIndex = DUP_DATA.findIndex(p => p.id === action.deleteAction);
        DUP_DATA.splice(profileIndex, 1);
    }
    return updateObject(state, {
        configurationProfileListLoading: false,
        configurationProfileList: DATA,
        configurationProfileListData: action.clear || action.deleteAction ? DUP_DATA : DATA,
    });
};

const getConfigurationProfileStart = (state, action) => {
    return updateObject(state, {
        configurationProfileLoading: true
    });
};

const getConfigurationProfileSuccess = (state, action) => {
    return updateObject(state, {
        configurationProfileLoading: false,
        configurationProfile: action.data,
    });
};

const saveConfigurationProfileSaving = (state, action) => {
    return updateObject(state, {
        configurationProfileSaving: true
    });
};
const saveConfigurationProfileSuccess = (state, action) => {
    return updateObject(state, { 
        updateConfigurationProfileMessage: action.message, 
        configurationProfileSaving: false, 
        error: action.error
    });
};

const deleteConfigurationProfileSuccess = (state, action) => {
    return updateObject(state, { 
        deleteConfigurationProfileMessage: action.message, 
        configurationProfileSaving: false,
        error: action.error
    });
};

const getAlertProfileListStart = (state, action) => {
    return updateObject(state, {
        alertProfileListLoading: true
    });
};

const getAlertProfileListSuccess = (state, action) => {
    const DATA = action.data?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    const DUP_DATA = state.alertProfileListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    if(action.deleteAction) {
        const profileIndex = DUP_DATA.findIndex(p => p.id === action.deleteAction);
        DUP_DATA.splice(profileIndex, 1);
    }
    return updateObject(state, {
        alertProfileListLoading: false,
        alertProfileList: DATA,
        alertProfileListData: action.clear || action.deleteAction ? DUP_DATA : DATA,
    });
};

const getAlertProfileStart = (state, action) => {
    return updateObject(state, {
        alertProfileLoading: true
    });
};

const getAlertProfileSuccess = (state, action) => {
    return updateObject(state, {
        alertProfileLoading: false,
        alertProfile: action.data,
    });
};

const saveAlertProfileSaving = (state, action) => {
    return updateObject(state, {
        alertProfileSaving: true
    });
};
const saveAlertProfileSuccess = (state, action) => {
    return updateObject(state, { 
        updateAlertProfileMessage: action.message, 
        alertProfileSaving: false, 
        error: action.error
    });
};

const deleteAlertProfileSuccess = (state, action) => {
    return updateObject(state, { 
        deleteAlertProfileMessage: action.message, 
        alertProfileSaving: false,
        error: action.error
    });
};

const getProductProfileListStart = (state, action) => {
    return updateObject(state, {
        productProfileListLoading: true,
        productProfileListMeta: {...state.productProfileListMeta}
    });
};

const getProductProfileListSuccess = (state, action) => {
    //console.log('------------>>>>', action);
    let LIST = [...action.data];
    if (action.productProfileListMeta && state.productProfileListLoading) {
        if(action.productProfileListMeta.current_page === 1) {
            state.productProfileList = [...LIST];
        } else {
            LIST = [...state.productProfileList, ...action.data];
            state.productProfileList = [...LIST];
        }
    }
    const DATA = LIST?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    const DUP_DATA = state.productProfileListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    if(action.deleteAction) {
        const profileIndex = DUP_DATA.findIndex(p => p.id === action.deleteAction);
        DUP_DATA.splice(profileIndex, 1);
    }
    if(action.updatedObj && action.status === 'update') {
        DATA.map(product => {
            if(product.product_type && product.product_type.id === action.updatedObj.id) {
                product.product_type.name = action.updatedObj.name;
                product.product_type.color = action.updatedObj.color;
            }
            return true;
        });
        
        DUP_DATA.map(product => {
            if(product.product_type && product.product_type.id === action.updatedObj.id) {
                product.product_type.name = action.updatedObj.name;
                product.product_type.color = action.updatedObj.color;
            }
            return true;
        });
    }
    // if(action.updatedObj && action.status === 'add') {
    //     DATA.map(product => {
    //         if(product.id === action.updatedObj.product_id) {
    //             product.product_type.name = action.updatedObj.name;
    //             product.product_type.color = action.updatedObj.color;
    //             product.product_type.id = action.updatedObj.id;
    //         }
    //     });
    //     DUP_DATA.map(product => {
    //         if(product.product_type.id === action.updatedObj.id) {
    //             product.product_type.name = action.updatedObj.name;
    //             product.product_type.color = action.updatedObj.color;
    //             product.product_type.id = action.updatedObj.id;
    //         }
    //     });
    // }
    //console.log('getProductProfileListSuccess action: ', action);
    if(action.updatedObj && action.status === 'delete') {
        DATA.map(product => {
            if(product.product_type?.id === action.updatedObj.id) {
                product.product_type.name = '';
                product.product_type.color = '';
            }
            return true;
        });
        DUP_DATA.map(product => {
            if(product.product_type?.id === action.updatedObj.id) {
                product.product_type.name = '';
                product.product_type.color = '';
            }
            return true;
        });
    }

    return updateObject(state, {
        productProfileListLoading: false,
        productProfileList: DATA,
        productProfileListData: action.clear || action.deleteAction ? DUP_DATA : DATA,
        productProfileListMeta: action.productProfileListMeta
    });
};

const getProductProfileStart = (state, action) => {
    return updateObject(state, {
        productProfileLoading: true
    });
};

const getProductProfileSuccess = (state, action) => {
    return updateObject(state, {
        productProfileLoading: false,
        productProfile: action.data,
    });
};

const saveProductProfileSaving = (state, action) => {
    return updateObject(state, {
        productProfileSaving: true
    });
};
const saveProductProfileSuccess = (state, action) => {
    return updateObject(state, { 
        updateProductProfileMessage: action.message,
        productProfileSaving: false, 
        error: action.error
    });
};

const deleteProductProfileSuccess = (state, action) => {
    return updateObject(state, { 
        deleteProductProfileMessage: action.message, 
        productProfileSaving: false,
        error: action.error
    });
};

const getProductTypeListStart = (state, action) => {
    return updateObject(state, {
        productTypeListLoading: true
    });
};

const getProductTypeListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.productTypeListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        productTypeListLoading: false,
        productTypeList: DATA,
        //productTypeListData: action.clear ? DUP_DATA : DATA,
    });
};


const getProductTypeStart = (state, action) => {
    return updateObject(state, {
        productTypeLoading: true
    });
};

const getProductTypeSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        productTypeLoading: false,
        productType: DATA,
    });
};

const saveProductTypeSaving = (state, action) => {
    return updateObject(state, {
        productTypeSaving: true
    });
};
const saveProductTypeSuccess = (state, action) => {
    return updateObject(state, { 
        updateProductTypeMessage: action.message, 
        productTypeSaving: false, 
        error: action.error
    });
};

const deleteProductTypeSuccess = (state, action) => {
    return updateObject(state, { 
        deleteProductTypeMessage: action.message, 
        productTypeSaving: false,
        error: action.error
    });
};

const getShipmentProfileListStart = (state, action) => {
    return updateObject(state, {
        shipmentProfileListLoading: true
    });
};

const getShipmentProfileListSuccess = (state, action) => {
    //console.log('------------>>>>', action);
    const data = action.data || state.shipmentProfileList;
    const DATA = data?.sort((a, b) => a.default_profile === 1 ? -1 : 1) || [];
    const DUP_DATA = state.shipmentProfileListData?.sort((a, b) => a.default_profile === 1 ? -1 : 1) || [];
    if(action.deleteAction) {
        const profileIndex = DUP_DATA.findIndex(p => p.id === action.deleteAction);
        DUP_DATA.splice(profileIndex, 1);
    }
    if(action.updatedObj && action.status === 'update') {
        DATA.map(product => {
            if(product.product_type && product.product_type.id === action.updatedObj.id) {
                product.product_type.name = action.updatedObj.name;
                product.product_type.color = action.updatedObj.color;
            }
            return true;
        });
        
        DUP_DATA.map(product => {
            if(product.product_type && product.product_type.id === action.updatedObj.id) {
                product.product_type.name = action.updatedObj.name;
                product.product_type.color = action.updatedObj.color;
            }
            return true;
        });
    }
    // if(action.updatedObj && action.status === 'add') {
    //     DATA.map(product => {
    //         if(product.id === action.updatedObj.product_id) {
    //             product.product_type.name = action.updatedObj.name;
    //             product.product_type.color = action.updatedObj.color;
    //             product.product_type.id = action.updatedObj.id;
    //         }
    //     });
    //     DUP_DATA.map(product => {
    //         if(product.product_type.id === action.updatedObj.id) {
    //             product.product_type.name = action.updatedObj.name;
    //             product.product_type.color = action.updatedObj.color;
    //             product.product_type.id = action.updatedObj.id;
    //         }
    //     });
    // }
    //console.log('getShipmentProfileListSuccess action: ', action);
    if(action.updatedObj && action.status === 'delete') {
        DATA.map(product => {
            if(product.product_type.id === action.updatedObj.id) {
                product.product_type.name = '';
                product.product_type.color = '';
            }
            return true;
        });
        DUP_DATA.map(product => {
            if(product.product_type.id === action.updatedObj.id) {
                product.product_type.name = '';
                product.product_type.color = '';
            }
            return true;
        });
    }

    return updateObject(state, {
        shipmentProfileListLoading: false,
        shipmentProfileList: DATA,
        shipmentProfileListData: action.clear || action.deleteAction ? DUP_DATA : DATA,
    });
};

const getShipmentProfileStart = (state, action) => {
    return updateObject(state, {
        shipmentProfileLoading: true
    });
};

const getShipmentProfileSuccess = (state, action) => {
    return updateObject(state, {
        shipmentProfileLoading: false,
        shipmentProfile: action.data,
    });
};

const saveShipmentProfileSaving = (state, action) => {
    return updateObject(state, {
        shipmentProfileSaving: true
    });
};
const saveShipmentProfileSuccess = (state, action) => {
    return updateObject(state, { 
        updateShipmentProfileMessage: action.message,
        shipmentProfileSaving: false, 
        error: action.error
    });
};

const deleteShipmentProfileSuccess = (state, action) => {
    return updateObject(state, { 
        deleteShipmentProfileMessage: action.message, 
        shipmentProfileSaving: false,
        error: action.error
    });
};

const getCarrierTypeListStart = (state, action) => {
    return updateObject(state, {
        carrierTypeListLoading: true
    });
};

const getCarrierTypeListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.productTypeListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        carrierTypeListLoading: false,
        carrierTypeList: DATA,
        //productTypeListData: action.clear ? DUP_DATA : DATA,
    });
};


const getCarrierTypeStart = (state, action) => {
    return updateObject(state, {
        carrierTypeLoading: true
    });
};

const getCarrierTypeSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        carrierTypeLoading: false,
        carrierType: DATA,
    });
};

const saveCarrierTypeSaving = (state, action) => {
    return updateObject(state, {
        carrierTypeSaving: true,
        carrierTypeError: false
    });
};
const saveCarrierTypeSuccess = (state, action) => {
    const carrierTypeList = [...state.carrierTypeList];
    const cIndex = carrierTypeList.findIndex(c => Number(c.id) === Number(action.data.data.id));
    if(cIndex >= 0) {
        carrierTypeList[cIndex].carrier_name = action.data.data.carrier_name;
    } else {
        carrierTypeList.unshift(action.data.data);
    }

    return updateObject(state, { 
        updateCarrierTypeMessage: action.data.message, 
        carrierTypeSaving: false, 
        carrierTypeList,
        carrierTypeError: action.data.error
    });
};

const deleteCarrierTypeSuccess = (state, action) => {
    return updateObject(state, { 
        deleteCarrierTypeMessage: action.message, 
        carrierTypeSaving: false,
        carrierTypeError: action.error
    });
};

const getLaneIdListStart = (state, action) => {
    return updateObject(state, {
        laneIdListLoading: true
    });
};

const getLaneIdListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.laneIdListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        laneIdListLoading: false,
        laneIdList: DATA,
        //laneIdListData: action.clear ? DUP_DATA : DATA,
    });
};

const getLaneIdStart = (state, action) => {
    return updateObject(state, {
        laneIdLoading: true
    });
};

const getLaneIdSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        laneIdLoading: false,
        laneId: DATA,
    });
};

const saveLaneIdSaving = (state, action) => {
    return updateObject(state, {
        laneIdSaving: true
    });
};
const saveLaneIdSuccess = (state, action) => {
    return updateObject(state, { 
        updateLaneIdMessage: action.message, 
        laneIdSaving: false, 
        error: action.error
    });
};

const deleteLaneIdSuccess = (state, action) => {
    return updateObject(state, { 
        deleteLaneIdMessage: action.message, 
        laneIdSaving: false,
        error: action.error
    });
};

const getPackageTypeListStart = (state, action) => {
    return updateObject(state, {
        packageTypeListLoading: true
    });
};

const getPackageTypeListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.packageTypeListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        packageTypeListLoading: false,
        packageTypeList: DATA,
        //packageTypeListData: action.clear ? DUP_DATA : DATA,
    });
};


const getPackageTypeStart = (state, action) => {
    return updateObject(state, {
        packageTypeLoading: true
    });
};

const getPackageTypeSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        packageTypeLoading: false,
        packageType: DATA,
    });
};

const savePackageTypeSaving = (state, action) => {
    return updateObject(state, {
        packageTypeSaving: true
    });
};
const savePackageTypeSuccess = (state, action) => {
    return updateObject(state, { 
        updatePackageTypeMessage: action.message, 
        packageTypeSaving: false, 
        error: action.error
    });
};

const deletePackageTypeSuccess = (state, action) => {
    return updateObject(state, { 
        deletePackageTypeMessage: action.message, 
        packageTypeSaving: false,
        error: action.error
    });
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_LIST_SUCCESS:
            return getCompanyListSuccess(state, action);
        case actionTypes.GET_CONFIGURATION_PROFILE_LIST_START:
            return getConfigurationProfileListStart(state, action);
        case actionTypes.GET_CONFIGURATION_PROFILE_LIST_SUCCESS:
            return getConfigurationProfileListSuccess(state, action);
        case actionTypes.GET_CONFIGURATION_PROFILE_START:
            return getConfigurationProfileStart(state, action);
        case actionTypes.GET_CONFIGURATION_PROFILE_SUCCESS:
            return getConfigurationProfileSuccess(state, action);
        case actionTypes.SAVE_CONFIGURATION_PROFILE_START:
            return saveConfigurationProfileSaving(state, action);
        case actionTypes.SAVE_CONFIGURATION_PROFILE_SUCCESS:
            return saveConfigurationProfileSuccess(state, action);
        case actionTypes.DELETE_CONFIGURATION_PROFILE_SUCCESS:
            return deleteConfigurationProfileSuccess(state, action);
        case actionTypes.GET_ALERT_PROFILE_LIST_START:
            return getAlertProfileListStart(state, action);
        case actionTypes.GET_ALERT_PROFILE_LIST_SUCCESS:
            return getAlertProfileListSuccess(state, action);
        case actionTypes.GET_ALERT_PROFILE_START:
            return getAlertProfileStart(state, action);
        case actionTypes.GET_ALERT_PROFILE_SUCCESS:
            return getAlertProfileSuccess(state, action);
        case actionTypes.SAVE_ALERT_PROFILE_START:
            return saveAlertProfileSaving(state, action);
        case actionTypes.SAVE_ALERT_PROFILE_SUCCESS:
            return saveAlertProfileSuccess(state, action);
        case actionTypes.DELETE_ALERT_PROFILE_SUCCESS:
            return deleteAlertProfileSuccess(state, action);
        case actionTypes.GET_PRODUCT_PROFILE_LIST_START:
            return getProductProfileListStart(state, action);
        case actionTypes.GET_PRODUCT_PROFILE_LIST_SUCCESS:
            return getProductProfileListSuccess(state, action);
        case actionTypes.GET_PRODUCT_PROFILE_START:
            return getProductProfileStart(state, action);
        case actionTypes.GET_PRODUCT_PROFILE_SUCCESS:
            return getProductProfileSuccess(state, action);
        case actionTypes.SAVE_PRODUCT_PROFILE_START:
            return saveProductProfileSaving(state, action);
        case actionTypes.SAVE_PRODUCT_PROFILE_SUCCESS:
            return saveProductProfileSuccess(state, action);
        case actionTypes.DELETE_PRODUCT_PROFILE_SUCCESS:
            return deleteProductProfileSuccess(state, action);
        case actionTypes.GET_PRODUCT_TYPE_LIST_START:
            return getProductTypeListStart(state, action);
        case actionTypes.GET_PRODUCT_TYPE_LIST_SUCCESS:
            return getProductTypeListSuccess(state, action);
        case actionTypes.GET_PRODUCT_TYPE_START:
            return getProductTypeStart(state, action);
        case actionTypes.GET_PRODUCT_TYPE_SUCCESS:
            return getProductTypeSuccess(state, action);
        case actionTypes.SAVE_PRODUCT_TYPE_START:
            return saveProductTypeSaving(state, action);
        case actionTypes.SAVE_PRODUCT_TYPE_SUCCESS:
            return saveProductTypeSuccess(state, action);
        case actionTypes.DELETE_PRODUCT_TYPE_SUCCESS:
            return deleteProductTypeSuccess(state, action); 
        case actionTypes.GET_SHIPMENT_PROFILE_LIST_START:
            return getShipmentProfileListStart(state, action);
        case actionTypes.GET_SHIPMENT_PROFILE_LIST_SUCCESS:
            return getShipmentProfileListSuccess(state, action);
        case actionTypes.GET_SHIPMENT_PROFILE_START:
            return getShipmentProfileStart(state, action);
        case actionTypes.GET_SHIPMENT_PROFILE_SUCCESS:
            return getShipmentProfileSuccess(state, action);
        case actionTypes.SAVE_SHIPMENT_PROFILE_START:
            return saveShipmentProfileSaving(state, action);
        case actionTypes.SAVE_SHIPMENT_PROFILE_SUCCESS:
            return saveShipmentProfileSuccess(state, action);
        case actionTypes.DELETE_SHIPMENT_PROFILE_SUCCESS:
            return deleteShipmentProfileSuccess(state, action);
        case actionTypes.GET_CARRIER_TYPE_LIST_START:
            return getCarrierTypeListStart(state, action);
        case actionTypes.GET_CARRIER_TYPE_LIST_SUCCESS:
            return getCarrierTypeListSuccess(state, action);
        case actionTypes.GET_CARRIER_TYPE_START:
            return getCarrierTypeStart(state, action);
        case actionTypes.GET_CARRIER_TYPE_SUCCESS:
            return getCarrierTypeSuccess(state, action);
        case actionTypes.SAVE_CARRIER_TYPE_START:
            return saveCarrierTypeSaving(state, action);
        case actionTypes.SAVE_CARRIER_TYPE_SUCCESS:
            return saveCarrierTypeSuccess(state, action);
        case actionTypes.DELETE_CARRIER_TYPE_SUCCESS:
            return deleteCarrierTypeSuccess(state, action);            
        case actionTypes.GET_LANE_ID_LIST_START:
            return getLaneIdListStart(state, action);
        case actionTypes.GET_LANE_ID_LIST_SUCCESS:
            return getLaneIdListSuccess(state, action);
        case actionTypes.GET_LANE_ID_START:
            return getLaneIdStart(state, action);
        case actionTypes.GET_LANE_ID_SUCCESS:
            return getLaneIdSuccess(state, action);
        case actionTypes.SAVE_LANE_ID_START:
            return saveLaneIdSaving(state, action);
        case actionTypes.SAVE_LANE_ID_SUCCESS:
            return saveLaneIdSuccess(state, action);
        case actionTypes.DELETE_LANE_ID_SUCCESS:
            return deleteLaneIdSuccess(state, action);
        case actionTypes.GET_PACKAGE_TYPE_LIST_START:
            return getPackageTypeListStart(state, action);
        case actionTypes.GET_PACKAGE_TYPE_LIST_SUCCESS:
            return getPackageTypeListSuccess(state, action);
        case actionTypes.GET_PACKAGE_TYPE_START:
            return getPackageTypeStart(state, action);
        case actionTypes.GET_PACKAGE_TYPE_SUCCESS:
            return getPackageTypeSuccess(state, action);
        case actionTypes.SAVE_PACKAGE_TYPE_START:
            return savePackageTypeSaving(state, action);
        case actionTypes.SAVE_PACKAGE_TYPE_SUCCESS:
            return savePackageTypeSuccess(state, action);
        case actionTypes.DELETE_PACKAGE_TYPE_SUCCESS:
            return deletePackageTypeSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
