import * as actionTypes from './actionTypes';
import { AXIOS } from '../../utility/Axios';
import { sendLogout, sendNetworkError, sendInternalServerError } from './company';
const apis = require(`config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;

export const getLanesListStart = () => {
    return {
        type: actionTypes.GET_LANES_LIST_START
    };
};

export const getLanesListSuccess = (data) => {
    return {
        type: actionTypes.GET_LANES_LIST_SUCCESS,
        data
    };
};

export const getLanesListFailed = (error) => {
    return {
        type: actionTypes.GET_LANES_LIST_FAILED,
        error
    };
};

export const getLanes = data => async dispatch => {
    dispatch(getLanesListStart());
    const Params = { ...data };
    delete(Params.onComplete);
    let requestUrl = apis.riskiq.get_lanes;
    await AXIOS({
        url: requestUrl,
        Params,
        service: process.env.REACT_APP_riskiq_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLanesListFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLanesListFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getLanesListFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getLanesListSuccess({ ...response.data }));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data);
            }
        }
    });
};

export const getLaneDetailsStart = () => {
    return {
        type: actionTypes.GET_LANE_DETAILS_START
    };
};

export const getLaneDetailsSuccess = (data,updateToList) => {
    return {
        type: actionTypes.GET_LANE_DETAILS_SUCCESS,
        data,
        updateToList,
    };
};

export const getLaneDetailsFailed = (error) => {
    return {
        type: actionTypes.GET_LANE_DETAILS_FAILED,
        error
    };
};

export const getLaneDetails = data => async dispatch => {
    if(data.updateToList) {}
    else {
        dispatch(getLaneDetailsStart());
    }
    let requestUrl = apis.riskiq.get_lane_details(data.id);
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_riskiq_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLaneDetailsFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLaneDetailsFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getLaneDetailsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getLaneDetailsSuccess({ ...response.data.data}, data.updateToList));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data);
            }
        }
    });
};

export const getLaneStabilityStart = () => {
    return {
        type: actionTypes.GET_LANE_STABILITY_START
    };
};

export const getLaneStabilitySuccess = (data, id) => {
    return {
        type: actionTypes.GET_LANE_STABILITY_SUCCESS,
        data,
        id,
    };
};

export const getLaneStabilityFailed = (error) => {
    return {
        type: actionTypes.GET_LANE_STABILITY_FAILED,
        error
    };
};

export const getLaneStability = data => async dispatch => {
    dispatch(getLaneStabilityStart());
    let requestUrl = apis.riskiq.get_lane_stability(data.id, data.risk_factor);
    const Params = {};
    if(data.duration) {
        Params.duration = data.duration;
    }
    await AXIOS({
        url: requestUrl,
        Params,
        service: process.env.REACT_APP_riskiq_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLaneStabilityFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLaneStabilityFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getLaneStabilityFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getLaneStabilitySuccess([ ...response.data.data], data.id));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data);
            }
        }
    });
};

export const getRouteDetailsStart = () => {
    return {
        type: actionTypes.GET_ROUTE_DETAILS_START
    };
};

export const getRouteDetailsSuccess = (data, id) => {
    return {
        type: actionTypes.GET_ROUTE_DETAILS_SUCCESS,
        data,
        id,
    };
};

export const getRouteDetailsFailed = (error) => {
    return {
        type: actionTypes.GET_ROUTE_DETAILS_FAILED,
        error
    };
};

export const getRouteDetails = data => async dispatch => {
    dispatch(getRouteDetailsStart());
    let requestUrl = apis.riskiq.get_route_details(data.id);
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_riskiq_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getRouteDetailsFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getRouteDetailsFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getRouteDetailsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getRouteDetailsSuccess({ ...response.data.data }, data.id));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data);
            }
        }
    });
};

export const getRouteStabilityStart = () => {
    return {
        type: actionTypes.GET_ROUTE_STABILITY_START
    };
};

export const getRouteStabilitySuccess = (data, id) => {
    return {
        type: actionTypes.GET_ROUTE_STABILITY_SUCCESS,
        data,
        id,
    };
};

export const getRouteStabilityFailed = (error) => {
    return {
        type: actionTypes.GET_ROUTE_STABILITY_FAILED,
        error
    };
};

export const getRouteStability = data => async dispatch => {
    dispatch(getRouteStabilityStart());
    let requestUrl = apis.riskiq.get_route_stability(data.id, data.risk_factor);
    const Params = {};
    if(data.duration) {
        Params.duration = data.duration;
    }
    await AXIOS({
        url: requestUrl,
        Params,
        service: process.env.REACT_APP_riskiq_api,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getRouteStabilityFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getRouteStabilityFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getRouteStabilityFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getRouteStabilitySuccess([ ...response.data.data ], data.id));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data);
            }
        }
    });
};
export const getLegStabilityStart = () => {
    return {
        type: actionTypes.GET_LEG_STABILITY_START
    };
};

export const getLegStabilitySuccess = (data, id) => {
    return {
        type: actionTypes.GET_LEG_STABILITY_SUCCESS,
        data,
        id,
    };
};

export const getLegStabilityFailed = (error) => {
    return {
        type: actionTypes.GET_LEG_STABILITY_FAILED,
        error
    };
};

export const getLegStability = data => async dispatch => {
    dispatch(getLegStabilityStart());
    let requestUrl = apis.riskiq.get_leg_stability(data.id, data.risk_factor);
    const Params = {};
    if(data.duration) {
        Params.duration = data.duration;
    }
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_riskiq_api,
        Params,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLegStabilityFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLegStabilityFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getLegStabilityFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getLegStabilitySuccess([ ...response.data.data ], data.id));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data);
            }
        }
    });
};

export const getLaneInsightsStart = () => {
    return {
        type: actionTypes.GET_LANE_INSIGHTS_START
    };
};

export const getLaneInsightsSuccess = (data, id, del) => {
    return {
        type: actionTypes.GET_LANE_INSIGHTS_SUCCESS,
        data,
        id,
        del,
    };
};

export const getLaneInsightsFailed = (error) => {
    return {
        type: actionTypes.GET_LANE_INSIGHTS_FAILED,
        error
    };
};

export const getLaneInsights = data => async dispatch => {
    dispatch(getLaneInsightsStart());
    let requestUrl = apis.riskiq.get_lane_insights(data.id);
    const Params = {duration: data.duration};
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_riskiq_api,
        Params,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLaneInsightsFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLaneInsightsFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getLaneInsightsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getLaneInsightsSuccess(response.data.data, data.id));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data.insights);
            }
        }
    });
};

export const getRouteInsightsStart = () => {
    return {
        type: actionTypes.GET_ROUTE_INSIGHTS_START
    };
};

export const getRouteInsightsSuccess = (data, id, del) => {
    return {
        type: actionTypes.GET_ROUTE_INSIGHTS_SUCCESS,
        data,
        id,
        del,
    };
};

export const getRouteInsightsFailed = (error) => {
    return {
        type: actionTypes.GET_ROUTE_INSIGHTS_FAILED,
        error
    };
};

export const getRouteInsights = data => async dispatch => {
    dispatch(getRouteInsightsStart());
    let requestUrl = apis.riskiq.get_route_insights(data.id);
    const Params = {duration: data.duration};
    if(data.risk_factor) {
        Params.risk_factor = data.risk_factor;
    }
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_riskiq_api,
        Params,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(async response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getRouteInsightsFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getRouteInsightsFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getRouteInsightsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            await dispatch(getRouteInsightsSuccess(response.data.data, `${data.lane_id}_${data.id}`));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data.insights);
            }
        }
    });
};

export const getLegInsightsStart = () => {
    return {
        type: actionTypes.GET_LEG_INSIGHTS_START
    };
};

export const getLegInsightsSuccess = (data, id, del, selectedLegs) => {
    return {
        type: actionTypes.GET_LEG_INSIGHTS_SUCCESS,
        data,
        id,
        del,
        selectedLegs,
    };
};

export const getLegInsightsFailed = (error) => {
    return {
        type: actionTypes.GET_LEG_INSIGHTS_FAILED,
        error
    };
};

export const getLegInsights = data => async dispatch => {
    dispatch(getLegInsightsStart());
    let requestUrl = apis.riskiq.get_leg_insights(data.id);
    const Params = {duration: data.duration};
    if(data.risk_factor) {
        Params.risk_factor = data.risk_factor;
    }
    if(data.external_route_id) {
        Params.external_route_id = data.external_route_id;
    }
    await AXIOS({
        url: requestUrl,
        service: process.env.REACT_APP_riskiq_api,
        Params,
        onInternalServerError: () => {
            dispatch(sendInternalServerError(true));
        }
    }).then(response => {
        if (response === undefined) {
            dispatch(sendLogout(true));
        } else if (response.networkError) {
            dispatch(sendNetworkError(true));
        } else if (response.response) {
            if (response.response.data && response.response.data.error) {
                dispatch(getLegInsightsFailed(response.response.data));
            } else if (response.response.data && response.response.data.errors) {
                dispatch(
                    getLegInsightsFailed(
                        `Could not process request. Please contact ${apis.help.api_help_email}`,
                        response.response.data
                    )
                );
            } else {
                dispatch(getLegInsightsFailed(`Could not process request. Please contact ${apis.help.api_help_email}`));
            }
        } else {
            dispatch(getLegInsightsSuccess(response.data.data, `${data.lane_id}_${data.route_id}_${data.id}`, false, data.selectedLegs));
            if (data.onComplete && typeof (data.onComplete) === 'function') {
                data.onComplete(response.data.data.insights);
            }
        }
    });
};