import axios from 'axios';

export const AXIOS = (params) => {
    if(window.navigator.onLine){}
    else {
        return new Promise(function(resolve, reject) {
            return resolve({networkError: true});
        });
    }


    if (params) {
        const getToken = localStorage.getItem('access_token');
        
        let baseURL = `${params.service||process.env.REACT_APP_api_domain}/api`;
        // if(params.removeAPI) {
        //     baseURL = baseURL.replace(/api\/$/, '');
        // }

        const [u, q] = params.url.split("?");
        let URL = u;
        if(q) {
            const P = q.split('&').map(s=>{const p1=s.split("="); const p2=encodeURIComponent(p1[1]||''); return p1[0]?`${p1[0]}=${p2}`:'' })
            URL = `${u}?${P.join("&")}`;
        }

        /*
        const DATA = {};
        if(params.data){
            for(let param in params.data) {
                DATA[param] = encodeURIComponent(params.data[param]);
            }
        }
        const PARAMS = {};
        if(params.Params){
            for(let param in params.Params) {
                PARAMS[param] = encodeURIComponent(params.Params[param]);
            }
        }
        */

        const payload = {
            baseURL,
            method: params.method || 'get',
            url: URL,
            timeout: 3 * 60 * 1000,
            data: params.data || '',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${getToken}`,
                'crossDomain': true,
                'Access-Control-Allow-Origin': '*',
                'x-api-key': process.env.REACT_APP_X_API_KEY,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            },
            responseType: 'json',
            params: params.Params || '',
        };
        if (params.cancelToken) {
            payload.cancelToken = params.cancelToken;
        }
        return axios(payload).then((data)=>{
            if(data && data.data && data.data.maintenanceMode) {
                window.localStorage.setItem('maintenance', true);
                window.localStorage.removeItem('access_token');
                window.location.href = '/dashboard';
                return {...data, maintenanceMode: true};
            }
            window.localStorage.removeItem('maintenance');
            return data;
        }).catch((e) => {
            if(e.response?.status === 503 || e.response?.data?.data?.message === "Service Unavailable") {
                window.localStorage.setItem('maintenance', true);
                window.localStorage.setItem('maintenanceModeData', JSON.stringify(e.response?.data?.data));
                window.localStorage.removeItem('access_token');
                window.location.href = '/signOut';
                return {...e.response?.data, maintenanceMode: true};
            } else if (
                (e.response?.status === 401 || e.response?.data?.message === 'Unauthenticated' || e.response?.data?.error === 'Unauthenticated' || e.response?.data?.message === "Unauthorized" || e.response?.data?.error === "Unauthorized")
            ) {
                //console.log(e.response.data);
                //showAlert = e.response.data.message;
                //showAlert = 'Session expired!';
            } else {
                //showAlert = 'Oooops... Somthing goes wrong.';
                if(e.message && e.message.match(/network error/i) && params.hasOwnProperty('onInternalServerError') && typeof(params.onInternalServerError) === 'function') {
                    //console.log('Internal server error............');
                    //window.localStorage.removeItem('access_token');
                    //window.location.href = '/';
                    //return {...e, serverError: true};
                    params.onInternalServerError(true);
                }
                if (e.message.match(/timeout/) || e.code === 'ECONNABORTED') {
                    return { ...e, timeout: true };
                } else {
                    return e;
                }
            }
        });
    }
};

// const useAlertStyles = makeStyles(stylesAlert);
// export const Unauthenticated = () => {
//   const alertClasses = useAlertStyles();
//   return <SweetAlert
//     warning
//     style={{ display: "block", marginTop: "-100px" }}
//     title={showAlert}
//     onConfirm={() => {
//       let email_p = '';
//       let password_p = '';
//       if (localStorage.getItem('email_paxafe')) {
//         email_p = localStorage.getItem('email_paxafe');
//       }
//       if (localStorage.getItem('password_paxafe')) {
//         password_p = localStorage.getItem('password_paxafe');
//       }
//       localStorage.clear();
//       if (email_p) {
//         localStorage.setItem('email_paxafe', email_p);
//       }
//       if (password_p) {
//         localStorage.setItem('password_paxafe', password_p);
//       }
//       window.location.href = '/auth/login-page';
//     }}
//     confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
//     confirmBtnText={'Log out'}
//   // onCancel={()=>{
//   //   if (showAlert !== "Unauthenticated.") {
//   //     window.location.reload();
//   //   }
//   // }}
//   // cancelBtnText={'Reload Page'}
//   // cancelBtnCssClass={alertClasses.button}
//   // showCancel={showAlert !== "Unauthenticated."}
//   ></SweetAlert>;
// }
